import { FC, useRef } from 'react';
import { FloatingPortal } from '@floating-ui/react';

import { PropsWithClassName } from '../../../types';
import { useShowContentClick } from '../../hooks/useShowContentClick';
import { TagsView } from '../../properties/Tags/TagsView';
import { TagsEdit } from '../../properties/Tags/TagsEdit';
import { twMerge } from '../../../utils/twMerge';
import { useRememberFloating } from '../hooks/useRememberFloating';

type TagList = string[];
interface CellContentTagsProps extends PropsWithClassName {
  tags: TagList | null | undefined;
  onChange: (value: TagList) => void;
  cellId?: string;
  editable: boolean;
  disabled: boolean;
}

export const CellContentTags: FC<CellContentTagsProps> = ({ tags, onChange, cellId, editable, disabled }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { floatingCellId, remember, forget } = useRememberFloating();

  const { floatingPortalContentProps, referenceProps, isOpen, forceClose } = useShowContentClick({
    defaultOpen: floatingCellId === cellId,
    onClose: forget,
    disabled,
  });

  floatingPortalContentProps.className = twMerge(
    'flex h-fit w-full rounded-md focus-within:ring-2 focus-within:ring-m-blue-400 hover:bg-m-gray-300',
    floatingPortalContentProps.className
  );

  const handleUpdate = (value: TagList) => {
    remember(cellId ?? null);
    onChange?.(value);
  };

  return (
    <div className="grow rounded-md border-0 px-2 py-1.5 focus-within:ring-2 focus-within:ring-m-blue-400 hover:bg-m-gray-300" {...referenceProps}>
      <TagsView value={tags ?? []} />
      {isOpen && (
        <FloatingPortal>
          <div {...floatingPortalContentProps}>
            <TagsEdit onClose={forceClose} value={tags ?? []} onChange={handleUpdate} ref={inputRef} editable={editable} />
          </div>
        </FloatingPortal>
      )}
    </div>
  );
};
