import { twMerge } from 'tailwind-merge';
import { forwardRef } from 'react';

import { PropsWithClassName } from '../../../types';
import { addUrlProtocol } from '../../../shared/utils/addUrlProtocol';

interface LinkTagProperties extends PropsWithClassName {
  value: string;
}

export const LinkTag = forwardRef<HTMLAnchorElement, LinkTagProperties>(({ value, className, ...rest }, ref) => {
  return (
    <a
      {...rest}
      href={addUrlProtocol(value as string)}
      target="_blank"
      className={twMerge('inline-block rounded-lg border border-m-blue-200 px-2 py-0.5 text-xs text-m-blue-600', className)}
      ref={ref}
    >
      {value}
    </a>
  );
});
