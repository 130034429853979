import { createColumnHelper, useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { FC, useMemo } from 'react';
import { ToolsTable } from '../../../shared/components/ToolsTable';
import LoadingIcon from '../../../assets/icons/loading-05.svg?react';

import { suppressConsoleWarn } from '../../../shared/utils/suppressConsoleWarn';
import { formatDistanceToNow } from 'date-fns';

const columnHelper = createColumnHelper<any[number]>();

interface StripeSyncsTableProps {
  syncs?: any;
}

export const StripeSyncsTable: FC<StripeSyncsTableProps> = ({ syncs }) => {
  const columns = useMemo(
    () => [
      columnHelper.accessor('data', {
        id: 'data',
        header: 'Data',
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          return value;
        },
      }),
      columnHelper.accessor('last_sync_at', {
        id: 'status',
        header: 'Status',
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          return (
            <div className="flex items-center gap-x-1 text-sm">
              {!value && <LoadingIcon className="w-5 animate-spin" />}
              <div>{value ? 'Completed' : 'Syncing...'}</div>
            </div>
          );
        },
      }),
      columnHelper.accessor('last_sync_at', {
        id: 'last_sync_at',
        header: 'Last synced',
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          if (!value) {
            return 'N/A';
          }

          return formatDistanceToNow(new Date(value ?? ''), { addSuffix: true });
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    columns,
    data: syncs ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  return <ToolsTable data={syncs} table={table} />;
};
