import { RelationshipsCreatePayload } from '@bigdelta/lib-api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { toastError, toastSuccess } from '../../../utils/toast';

interface UseAddRelationshipMutationArgs {
  onSuccess?: () => void;
}

export const useAddRelationshipMutation = ({ onSuccess }: UseAddRelationshipMutationArgs) => {
  const queryClient = useQueryClient();
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  return useMutation({
    mutationFn: (payload: RelationshipsCreatePayload) => bigdeltaAPIClient.v1.relationshipsCreate({ workspace_id: currentWorkspaceId }, payload),
    onSuccess: () => {
      toastSuccess('Relationship added', 'The object relationship has been added successfully');
      onSuccess?.();
    },
    onError: () => {
      toastError('Failed to add object relationship');
    },
    onSettled: () => {
      return queryClient.invalidateQueries(queryKeys.relationships());
    },
  });
};
