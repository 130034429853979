import { FC } from 'react';

interface NumberTextViewProps {
  value: number | null | undefined;
  onEdit?: () => void;
}

export const NumberTextView: FC<NumberTextViewProps> = ({ value, onEdit }) => {
  return <div onClick={onEdit}>{value ?? <span>&nbsp;</span>}</div>;
};
