import { Control, useController } from 'react-hook-form';
import { FormElementWrapper, FormElementWrapperProps } from '../FormElementWrapper/FormElementWrapper.tsx';
import { Checkbox } from '../Checkbox/Checkbox.tsx';

type FormCheckboxProps = FormElementWrapperProps & {
  name: string;
  required?: boolean;
  control: Control<any>;
};

export const FormCheckbox = ({ control, name, label, errorMessage, size, ...rest }: FormCheckboxProps) => {
  const { field } = useController({
    control,
    name,
  });

  return (
    <FormElementWrapper label={label} errorMessage={errorMessage} size={size}>
      <Checkbox {...rest} {...field} checked={field.value} value={field.name} />
    </FormElementWrapper>
  );
};
