import { Popover } from '@headlessui/react';
import { ReportSourceType, useReportsStore } from '../store';
import { ReportCreateSelectedMetricContainer } from './ReportCreateSelectedMetricContainer';
import { Combobox } from '../../../components/Combobox';
import { MetricsListData } from '@bigdelta/lib-api-client';
import { useMemo } from 'react';
import { OpenTrigger } from '../../../shared/components/OpenTrigger';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { useFilterStore } from '../../../shared/filters/store';

import MetricIcon from '../../../assets/icons/calculator.svg?react';
import { useQuery } from '@tanstack/react-query';

import { ReportCreateFilterMetrics } from './ReportCreateFilterEventsMetrics';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { FloatWrapper } from './FloatWrapper.tsx';
import { useReportMetric } from '../../auth/hooks/useReportMetric';
import { FormulaMetricReference } from './FormulaMetricReference.tsx';
import { tracking, TrackingEvent } from '../../../tracking';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

export const ReportCreateSelectedMetricMetric = () => {
  const { inline, filterKey, onRemoveMetric } = useReportMetric();
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  const metricMetric = useReportsStore((state) => state.config.metricMetric);

  const clearMetric = useReportsStore((state) => state.clearMetric);
  const setMetricMetric = useReportsStore((state) => state.setMetricMetric);
  const reportId = useReportsStore((state) => state.id);

  const clearFilter = useFilterStore((state) => state.clearFilter);

  const metricsQuery = useQuery({
    queryKey: queryKeys.metrics(),
    queryFn: () => bigdeltaAPIClient.v1.metricsList({ workspace_id: currentWorkspaceId }),
  });

  const selectedMetric = useMemo(() => {
    return metricsQuery.data?.items.find((obj) => obj.id === metricMetric);
  }, [metricsQuery.data?.items, metricMetric]);

  const handleMetricChange = (data: MetricsListData['items'][number] | null) => {
    tracking.track(TrackingEvent.ReportDataSelected, {
      'report id': reportId,
      'data type': ReportSourceType.METRIC,
      'data name': data?.display_name,
    });
    setMetricMetric(data?.id ?? undefined);
    clearFilter(filterKey);
  };

  const handleRemoveMetric = () => {
    clearMetric();
    clearFilter(filterKey);
    onRemoveMetric && onRemoveMetric();
  };

  return (
    <ReportCreateSelectedMetricContainer
      filterKey={filterKey}
      onRemove={handleRemoveMetric}
      source={
        <Popover className="min-w-0 flex-1">
          {({ close }) => (
            <FloatWrapper portal adaptiveWidth offset={2} inline={inline}>
              <Popover.Button className="flex-start flex w-full items-center gap-x-2 rounded p-2 text-sm hover:bg-m-gray-200">
                <FormulaMetricReference />
                <OpenTrigger shouldOpen={!metricMetric} />
                <MetricIcon className="h-5 w-5 shrink-0 text-m-blue-600" />
                {!selectedMetric && <span className="text-md text-m-olive-600">Select metric</span>}
                {selectedMetric && (
                  <span className="overflow-hidden text-ellipsis whitespace-nowrap text-md capitalize text-m-olive-900">
                    {selectedMetric?.display_name}
                  </span>
                )}
              </Popover.Button>
              <Popover.Panel>
                <Combobox
                  items={metricsQuery.data?.items ?? []}
                  // selectedItems={objectsQuery.data?.objects.find((obj) => obj.id === metricObject) ?? null}
                  onChange={(data) => {
                    handleMetricChange(data);
                    close();
                  }}
                  catchInputFocus
                  height={400}
                  renderOption={(metric) => {
                    return (
                      <div className="flex items-center gap-x-2">
                        <MetricIcon className="h-4 w-4" />
                        <span className="capitalize">{metric.display_name}</span>
                      </div>
                    );
                  }}
                  filterCompare={(metric, query) => metric.display_name.toLowerCase().includes(query.toLowerCase())}
                />
              </Popover.Panel>
            </FloatWrapper>
          )}
        </Popover>
      }
    >
      {metricMetric ? (
        <>
          <hr className="h-px bg-m-gray-300" />
          <div className="pb-2">
            <ReportCreateFilterMetrics filterKey={filterKey} />
          </div>
        </>
      ) : (
        <></>
      )}
    </ReportCreateSelectedMetricContainer>
  );
};
