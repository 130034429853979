import { twMerge } from 'tailwind-merge';
import { FloatingPortal } from '@floating-ui/react';

import CursorClickIcon from '../../../assets/icons/cursor-click-01.svg?react';
import { useShowContentClick } from '../../../shared/hooks/useShowContentClick';
import { QueryReportsByObjectTypeData } from '@bigdelta/lib-api-client';

interface IconProps {
  monthEvents: QueryReportsByObjectTypeData['result'];
}

export const RecordTimelineEvents = ({ monthEvents }: IconProps) => {
  const { floatingPortalContentProps, referenceProps, isOpen } = useShowContentClick();
  floatingPortalContentProps.className = twMerge('max-w-xs rounded-md p-2 shadow-lg', floatingPortalContentProps.className);
  const total = monthEvents.reduce((acc, { metric }) => acc + metric, 0);

  if (total === 0) {
    return null;
  }

  return (
    <div role="button" className="relative cursor-pointer" {...referenceProps}>
      <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-m-blue-600 text-m-white">
        <CursorClickIcon className="h-4 w-4" />
      </div>
      <div className="absolute -right-2 -top-2 flex min-h-4 w-auto min-w-4 items-center justify-center rounded-full bg-m-coconut-800 px-1 text-center text-xxs text-m-white">
        {total}
      </div>
      {isOpen && (
        <FloatingPortal>
          <div {...floatingPortalContentProps}>
            {monthEvents.map(({ event_name, metric }) => (
              <div key={event_name} className="flex w-full items-center justify-between whitespace-nowrap text-xxs text-m-olive-500">
                <span>{event_name}</span>
                <span className="ml-4 font-bold">{metric}</span>
              </div>
            ))}
          </div>
        </FloatingPortal>
      )}
    </div>
  );
};
