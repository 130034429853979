export const ColumnResizeHandle = ({ onDoubleClick, onMouseDown, onTouchStart }) => (
  <div
    onDoubleClick={onDoubleClick}
    onMouseDown={onMouseDown}
    onTouchStart={onTouchStart}
    className="absolute right-0 top-0 flex min-h-full w-3 cursor-col-resize justify-end opacity-0 group-hover:opacity-100"
  >
    <div className="w-1 bg-m-gray-300" />
  </div>
);
