import { PanelSize, PanelType, TableColumnType } from '@bigdelta/lib-shared';
import { FC } from 'react';
import { RecordProfilePanelBase } from './RecordProfilePanelBase';
import { useQuery } from '@tanstack/react-query';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { ObjectsProfileLayoutsDetailData, QueryRecordsCreateData } from '@bigdelta/lib-api-client';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useRecordsTableColumns } from '../hooks/useRecordsTableColumns';
import { useObjectQuery } from '../../../shared/data/useObjectQuery';
import { DataTable } from '../../../shared/tables/components/DataTable';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { useContainer } from '../../../shared/tables/hooks/useContainer';

interface RecordProfilePanelTableProps {
  panel: ObjectsProfileLayoutsDetailData['tabs'][number]['panels'][number];
}

const columnHelper = createColumnHelper<QueryRecordsCreateData['items'][number]>();

export const RecordProfilePanelTable: FC<RecordProfilePanelTableProps> = ({ panel }) => {
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  const { containerProps, tableProps } = useContainer();

  // TODO: this is a workaround to get the workspace object id from the panel data
  const workspaceObjectId = panel.data.table?.query.query.resource.id;
  const { data: object } = useObjectQuery({ objectSlug: workspaceObjectId, workspaceId: currentWorkspaceId });

  const tableQueryPayload = panel.data.table?.query;

  const records = useQuery({
    queryKey: queryKeys.single('object', 'profile', 'panel', panel.name, tableQueryPayload, 'table'),
    queryFn: () => bigdeltaAPIClient.v1.queryRecordsCreate({ workspace_id: currentWorkspaceId }, tableQueryPayload!),
    enabled: !!tableQueryPayload,
  });

  const columns = useRecordsTableColumns({
    columnHelper,
    workspaceObject: object,
    displayProperties: true,
    enableActions: false,
    layout: panel.data.table?.layout_config,
    onLayoutChange: () => {},
    editable: false,
    allowCreate: false,
  });

  const labelColumnId = panel.data.table?.layout_config?.columns.find((c) => c.type === TableColumnType.LABEL)?.id;
  const columnPinning = {
    right: [],
    left: labelColumnId ? [labelColumnId] : [],
  };

  const table = useReactTable({
    columns,
    data: records.data?.items ?? [],
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      enableResizing: false,
      enableSorting: false,
    },
    state: {
      columnPinning,
    },
  });

  if (panel.type !== PanelType.TABLE) {
    return null;
  }

  return (
    <RecordProfilePanelBase title={panel.name} size={panel.size as PanelSize}>
      <div className="overflow-x-auto overscroll-none" {...containerProps}>
        <DataTable isDataFetching={false} isHeaderFetching={false} table={table} {...tableProps} />
      </div>
    </RecordProfilePanelBase>
  );
};
