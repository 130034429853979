import React, { HTMLProps } from 'react';
import { OverflowText } from '../../components/OverflowText';
import { toastError } from '../../../utils/toast';

interface DateTimeViewProps extends HTMLProps<HTMLDivElement> {
  value: string;
  onClick?: () => void;
}

const formatDate = (dateString: string): string => {
  try {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat(undefined, {
      dateStyle: 'medium',
      timeStyle: 'short',
      timeZone: 'UTC',
      hour12: false,
    }).format(date);
  } catch (error) {
    toastError('Error formatting date');
    return dateString;
  }
};

export const DateTimeView: React.FC<DateTimeViewProps> = ({ value, ...props }) => {
  const formattedDate = value ? formatDate(value) : '';

  return (
    <OverflowText visible={false} {...props}>
      {formattedDate || <span>&nbsp;</span>}
    </OverflowText>
  );
};
