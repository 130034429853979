import { QueryValueFilterOperator } from '@bigdelta/lib-shared';

type OperatorValue = `${QueryValueFilterOperator}`;

const OperatorLabelMap: Partial<Record<OperatorValue, string>> = {
  [QueryValueFilterOperator.EQUALS]: 'is',
  [QueryValueFilterOperator.NOT_EQUALS]: 'is not',
  [QueryValueFilterOperator.IS_SET]: 'is not empty',
  [QueryValueFilterOperator.NOT_IS_SET]: 'is empty',
};

export function getOperatorLabel(operator: string) {
  return OperatorLabelMap[operator] ?? operator;
}
