import React, { useState, useEffect, useRef, MouseEvent } from 'react';
import { PropsWithClassName } from '../../../types';
import { twMerge } from '../../../utils/twMerge';
import { Key } from 'ts-key-enum';

interface DateTimeEditProps extends PropsWithClassName {
  value: string;
  onSave: (newValue: string) => void;
  onCancel: () => void;
}

const isoToInputFormat = (isoString: string): string => {
  return isoString.slice(0, 16);
};

export const DateTimeEdit: React.FC<DateTimeEditProps> = ({ value, onSave, onCancel, className }) => {
  const [editValue, setEditValue] = useState(isoToInputFormat(value));

  const dateInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setEditValue(isoToInputFormat(value));
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setEditValue(newValue);
  };

  const handleSave = () => {
    onSave(editValue + ':00Z');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Key.Enter && !e.shiftKey) {
      e.preventDefault();
      handleSave();
    }
    if (e.key === Key.Escape) {
      onCancel();
    }
  };

  const onDateInputRef = (node: HTMLInputElement | null) => {
    dateInputRef.current = node;
    if (node) {
      node.focus();

      setTimeout(() => {
        node.click();
      }, 0);
    }
  };

  const handleClick = (e: MouseEvent<HTMLInputElement>) => {
    if (e.target instanceof HTMLInputElement) {
      try {
        e.target.showPicker();
      } catch (error) {
        console.error('Error showing picker', error);
      }
    }
  };

  return (
    <input
      type="datetime-local"
      ref={onDateInputRef}
      value={editValue}
      onChange={handleChange}
      onBlur={handleSave}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      className={twMerge('datetime-input w-full border-none bg-transparent p-0 text-sm outline-none focus:outline-none focus:ring-0', className)}
    />
  );
};
