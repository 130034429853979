import { NestedObjectDef } from '@bigdelta/lib-api-client';

export interface WithProperties {
  id?: string | null;
  properties?: NestedObjectDef['number'];
}

export const getRecordLabel = <T extends WithProperties>(labelProperties?: string[] | string, record?: T) => {
  let label: NestedObjectDef['number'];

  if (Array.isArray(labelProperties)) {
    label = record && labelProperties?.map((property) => record?.properties?.[property]).find(Boolean);
  } else {
    label = labelProperties && record && record?.properties?.[labelProperties];
  }

  const objectName = Array.isArray(label) ? label?.[0] : label;
  return objectName ? String(objectName) : record?.id;
};
