import { EventsQueryRequestQueryDef, RecordQueryFiltersRequestDef } from '@bigdelta/lib-api-client';
import { FilterItem, FilterItemType } from '../../../shared/filters/store';
import { NestedQueryFilterOperator, QueryValueFilterOperator, ResourcePropertyType } from '@bigdelta/lib-shared';
import { getRelatedRecordConditions } from '../../../shared/utils/getRelatedRecordConditions';
import { REMOTE_ID } from '../../records/const';
import { getPropertyCondition } from '../../../shared/filters/utils/getPropertyCondition';

type Condition = NonNullable<Exclude<RecordQueryFiltersRequestDef['conditions'][number], RecordQueryFiltersRequestDef>>;

type PropertyCondition = NonNullable<Exclude<Condition, Condition['record_property']>>['record_property'];

export function getEventsQueryWithImplicitFilters(
  recordFilterItems: { items: FilterItem[]; operator: 'and' | 'or' } | null,
  eventFilterItems: { items: FilterItem[]; operator: 'and' | 'or' } | null
) {
  const objectFilter = recordFilterItems?.items.find((filter) => filter.itemType === FilterItemType.EVENTS_OBJECT);
  const eventFilter = eventFilterItems?.items.find((filter) => filter.itemType === FilterItemType.EVENTS_NAME);

  let relatedObjectConditions: EventsQueryRequestQueryDef['conditions'] | undefined;

  if (objectFilter) {
    const objectCondition: Condition = {
      record_property: {
        name: REMOTE_ID,
        property_id: objectFilter.property?.attributeId ?? REMOTE_ID,
        property_type: ResourcePropertyType.STRING,
        operator: QueryValueFilterOperator.IS_SET,
      },
    };

    const objectPropertyConditions = recordFilterItems?.items
      .filter((filter) => filter.itemType === FilterItemType.EVENTS_RECORD_PROPERTY)
      .map((item) => {
        if (!item.property?.attributeName || !item.propertyOperator) {
          return null;
        }

        return getRelatedRecordConditions(item.propertyRelationships.slice(1), getPropertyCondition(item))[0];
      })
      .filter(Boolean);

    const objectRelationshipName = objectFilter.propertyRelationships[0]?.relationshipName;

    relatedObjectConditions = objectRelationshipName
      ? [
          {
            related_records: {
              relationship_name: objectRelationshipName,
              filter: {
                operator: NestedQueryFilterOperator.AND,
                conditions: [
                  objectCondition,
                  objectPropertyConditions
                    ? {
                        operator: recordFilterItems?.operator as NestedQueryFilterOperator,
                        conditions: objectPropertyConditions,
                      }
                    : { operator: NestedQueryFilterOperator.AND, conditions: [] },
                ],
              },
            },
          },
        ]
      : [];
  }

  const eventNameFilterCondition: Omit<PropertyCondition, 'name'> | null = eventFilter
    ? { operator: 'equals', value: eventFilter.data.value?.toString() }
    : null;

  const eventPropertyConditions = eventFilterItems?.items
    .filter((filter) => filter.itemType === FilterItemType.EVENTS_PROPERTY)
    .map((filter) => {
      return getPropertyCondition(filter);
    })
    .filter(Boolean);

  const topLevelConditions: EventsQueryRequestQueryDef['conditions'] = [
    ...(relatedObjectConditions?.length
      ? relatedObjectConditions
      : [
          {
            operator: 'and' as const,
            conditions: [],
          },
        ]),
    {
      operator: 'and' as const,
      conditions: [
        ...((eventNameFilterCondition ? [{ event_name: eventNameFilterCondition }] : []) as any),
        ...(eventPropertyConditions?.map((condition) => ({ event_property: condition })) ?? []),
      ],
    },
  ];

  return { filter: { conditions: topLevelConditions, operator: 'and' as const } };
}
