import { HTMLAttributes, PropsWithChildren, useCallback, useRef, ReactNode, Dispatch, SetStateAction } from 'react';

import CloseCircle from '../../assets/icons/x-circle.svg?react';
import { IconButton } from './IconButton';
import { useOutsideClickHandler } from '../hooks/useOutsideClickHandler';

type Props = PropsWithChildren & {
  title: ReactNode;
  closeOnClickOutside?: boolean;
  isOpen?: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
};

export const SidePanelFooterWrapper = (props: HTMLAttributes<HTMLDivElement>) => {
  return <div className="overflow fixed bottom-0 right-[-1px] w-full max-w-[450px] border-t border-m-olive-100 bg-white p-4" {...props} />;
};

export type SidePanelStateHandlers = {
  closeSidebar: () => void;
  openSidebar: () => void;
  isOpen: boolean;
};

export const SidePanel = ({ title, children, isOpen = true, setIsOpen, onClose, closeOnClickOutside = false }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const closeSidebar = useCallback(() => {
    onClose?.();
    setIsOpen?.(false);
  }, [setIsOpen, onClose]);

  const handleOutsideClose = useCallback(() => {
    if (closeOnClickOutside && isOpen) {
      closeSidebar();
    }
  }, [closeOnClickOutside, closeSidebar, isOpen]);

  useOutsideClickHandler(ref, handleOutsideClose);

  return (
    isOpen && (
      <div
        ref={ref}
        className="fixed right-0 top-0 h-screen w-full max-w-[450px] overflow-auto border-l border-m-olive-100 bg-white pb-16 shadow-lg"
        style={{ zIndex: 9999 }}
      >
        <div className="flex items-center justify-between p-4">
          <div className="text-lg font-bold">{title}</div>
          <IconButton onClick={closeSidebar} icon={CloseCircle} />
        </div>
        {children}
      </div>
    )
  );
};
