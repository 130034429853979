import { EventsQueryRequestQueryDef, RecordQueryFiltersRequestDef, RelationshipsListData } from '@bigdelta/lib-api-client';
import { FilterItem, FilterItemType } from '../filters/store';
import { ValueSelectType, attributeTypeOperatorValueSelectMap } from '../filters/const';
import { QueryValueFilterOperator, RelationshipEntityType } from '@bigdelta/lib-shared';
import { QueryRecordProperty, RelationshipObjectData } from '../types';
import { getTopLevelProperty } from './getTopLevelProperty';

interface RelationshipNamesWithRecordProperty {
  names: string[];
  recordProperty?: QueryRecordProperty;
}

function extractRelationshipNames(
  condition: EventsQueryRequestQueryDef['conditions'][number] | RecordQueryFiltersRequestDef['conditions'][number]
): RelationshipNamesWithRecordProperty {
  const names: string[] = [];
  let recordProperty: QueryRecordProperty | undefined = undefined;

  const traverse = (condition: EventsQueryRequestQueryDef['conditions'][number] | RecordQueryFiltersRequestDef['conditions'][number]) => {
    if (condition && 'related_records' in condition) {
      if (condition.related_records) {
        names.push(condition.related_records.relationship_name);
        const nestedCondition = condition.related_records.filter?.conditions[0];
        if (nestedCondition) {
          traverse(nestedCondition);
        }
      }
    }

    if (condition && 'record_property' in condition && condition.record_property?.property_id && condition.record_property?.property_type) {
      recordProperty = condition.record_property;
    }
  };

  traverse(condition);

  return { names, recordProperty };
}

const getPropertyRelationships = (
  relationshipNames: string[],
  relationships: RelationshipsListData['relationships'],
  entityType: RelationshipEntityType,
  entityId?: string
) => {
  let previousEntityId = entityId;

  return relationshipNames.map((name, index) => {
    const relationship = relationships.find((rel) => rel.name === name);
    if (!relationship) {
      return;
    }

    if (entityType === RelationshipEntityType.EVENT && index === 0) {
      const { objectId, objectWorkspaceId } =
        relationship.first_entity_type === entityType
          ? {
              objectId: relationship.second_entity_id,
              objectWorkspaceId: relationship.second_entity_workspace_id,
            }
          : {
              objectId: relationship.first_entity_id,
              objectWorkspaceId: relationship.first_entity_workspace_id,
            };

      if (!objectId || !objectWorkspaceId) {
        return;
      }

      previousEntityId = objectId;

      return {
        relationshipName: name,
        objectId,
        objectWorkspaceId,
      };
    }

    if (entityType === RelationshipEntityType.OBJECT || (entityType === RelationshipEntityType.EVENT && index > 0)) {
      const { objectId, objectWorkspaceId } =
        relationship.first_entity_id === previousEntityId
          ? {
              objectId: relationship.second_entity_id,
              objectWorkspaceId: relationship.second_entity_workspace_id,
            }
          : {
              objectId: relationship.first_entity_id,
              objectWorkspaceId: relationship.first_entity_workspace_id,
            };

      if (!objectId || !objectWorkspaceId) {
        return;
      }

      return {
        relationshipName: name,
        objectId,
        objectWorkspaceId,
      };
    }
  });
};

export const getRelatedRecordFilterFromCondition = (
  condition: EventsQueryRequestQueryDef['conditions'][number],
  relationships: RelationshipsListData['relationships'],
  filterItemType: FilterItemType,
  entityType: RelationshipEntityType,
  entityId?: string
): FilterItem | undefined => {
  const { names, recordProperty } = extractRelationshipNames(condition);

  const propertyRelationships = getPropertyRelationships(names, relationships, entityType, entityId);

  if (propertyRelationships.some(() => undefined)) {
    throw 'Relationship not found';
  }

  if (!recordProperty || !recordProperty.property_id || !recordProperty.property_type) {
    throw 'Missing record property';
  }

  if (entityType === RelationshipEntityType.OBJECT && !entityId) {
    throw 'Object id not found';
  }

  const filterProperty = getTopLevelProperty(recordProperty) ?? recordProperty;

  if (!filterProperty.property_type || !filterProperty.property_id) {
    throw 'Missing property type or id';
  }

  const dataValType: ValueSelectType = attributeTypeOperatorValueSelectMap[filterProperty.property_type][filterProperty.operator];

  const operatorKey = Object.entries(QueryValueFilterOperator).find(([, value]) => value === filterProperty.operator)?.[0];

  if (!operatorKey) {
    throw 'Operator not found';
  }

  // TODO fix non null assertion
  return {
    itemType: filterItemType,
    propertyRelationships: [
      ...(entityType === RelationshipEntityType.OBJECT ? [{ relationshipName: '', objectId: entityId!, objectWorkspaceId: '' }] : []),
      ...(propertyRelationships as RelationshipObjectData[]),
    ],
    property: {
      attributeId: filterProperty.property_id,
      attributeName: filterProperty.name,
      attributeType: filterProperty.property_type,
    },
    data: {
      valueType: dataValType,
      value: filterProperty.value as any, // Fix any,
    },
    propertyOperator: QueryValueFilterOperator[operatorKey],
    timeframe: {},
  };
};
