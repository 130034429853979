import { useMemo } from 'react';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { useObjectsQuery } from '../../../shared/data/useObjectsQuery';

export const useObjectNameById = () => {
  const { currentWorkspaceId } = useWorkspace();

  const objectsQuery = useObjectsQuery({ workspaceId: currentWorkspaceId });

  return useMemo(() => {
    return new Map(objectsQuery.data?.objects?.map((o) => [o.id, o.singular_noun]));
  }, [objectsQuery.data?.objects]);
};
