import { ReportSourceType, ReportsState, useReportQueries, useReportStore } from '../store';
import { FC, useCallback } from 'react';
import { useReportMetric } from '../../auth/hooks/useReportMetric';
import CloseIcon from '../../../assets/icons/x-close.svg?react';
import { ChartType, FormulaNamedMetricVO } from '@bigdelta/lib-shared';
import { useReportStoreContext } from '../context/reportStoreContext.ts';
import { useReportQueryBuilder } from '../hooks/useReportQueryBuilder.tsx';
import { isEqual } from 'lodash';
import { OnBlurInput } from '../../../shared/ui/OnBlurInput/OnBlurInput.tsx';
import { MetricTypeSelector } from './MetricTypeSelector.tsx';

type Props = {
  reportKey: string;
  formulaNamedMetric: FormulaNamedMetricVO;
  onMetricQueryChange: (query: any, state: ReportsState) => void;
  onRemoveMetric: () => void;
};

const NamedFormulaMetricBuilder: FC<Props> = ({ reportKey, formulaNamedMetric, onMetricQueryChange, onRemoveMetric }) => {
  const { builder } = useReportQueryBuilder({
    inline: true,
    reportKey,
    formulaNamedMetric,
    hydrateReportStoreFromFormulaMetric: true,
    chartType: ChartType.LINE,
    reportBuilderProps: {
      selectorIntent: 'secondary',
      isFormulaMetricChild: true,
      formulaReference: formulaNamedMetric.name,
      onRemoveMetric,
    },
    onMetricQueryChange,
  });

  return builder;
};

export const ReportCreateSelectedMetricFormula = () => {
  const { reportKey } = useReportStoreContext();
  const { onRemoveMetric, disableRemoval } = useReportMetric();
  const {
    config: { formulaExpression, formulaMetrics },
    clearMetric,
    setFormulaNamedMetric,
    addFormulaNamedMetric,
    setFormulaExpression,
    removeFormulaNamedMetric,
  } = useReportStore()((state) => state);
  const { deleteQueryStore, addQueryStore } = useReportQueries();
  const handleRemoveMetric = () => {
    clearMetric();
    onRemoveMetric && onRemoveMetric();
  };
  const handleNamedFormulaQueryChange = useCallback(
    (idx: number, query: any) => {
      const namedFormulaMetric = formulaMetrics[idx];
      const updatedFormulaMetric = {
        name: namedFormulaMetric.name,
        ...query,
      };

      if (!isEqual(namedFormulaMetric, updatedFormulaMetric)) {
        setFormulaNamedMetric(idx, updatedFormulaMetric);
      }
    },
    [formulaMetrics, setFormulaNamedMetric]
  );
  const handleRemoveFormulaNamedMetric = (idx: number, reportKey: string) => {
    removeFormulaNamedMetric(idx);
    deleteQueryStore(reportKey);
  };
  const handleAddNewFormulaNamedMetric = useCallback(
    (metricType: ReportSourceType) => {
      addFormulaNamedMetric();

      const store = addQueryStore(`${reportKey}-${formulaMetrics.length}`, {
        topLevelReportQuery: false,
        formulaMetricChildQuery: true,
      });

      store.getState().setMetricType(metricType);
    },
    [addFormulaNamedMetric, addQueryStore, formulaMetrics.length, reportKey]
  );

  return (
    <div className="group/card flex w-full flex-col items-stretch rounded-lg border border-m-olive-100">
      <div className="flex items-center justify-between gap-x-1.5 px-4 py-3">
        <div className="text-sm">Formula</div>
        {!disableRemoval && (
          <button onClick={handleRemoveMetric} className="cursor-pointer rounded text-m-olive-400 hover:bg-m-red-100 hover:text-m-red-600">
            <CloseIcon className="h-5 w-5" />
          </button>
        )}
      </div>
      <hr className="h-px bg-m-gray-300" />
      <div className="flex items-center justify-between gap-x-1.5 px-4 py-3">
        <OnBlurInput
          type="text"
          value={formulaExpression}
          onChange={(value) => setFormulaExpression(value)}
          placeholder="e.g. A + B"
          className="w-full"
        />
      </div>
      <hr className="h-px bg-m-gray-300" />
      <div className="flex flex-col items-center justify-between gap-2 px-4 py-3">
        {(formulaMetrics || []).map((item, idx) => {
          const formulaNamedMetricReportKey = `${reportKey}-${idx}`;

          return (
            <NamedFormulaMetricBuilder
              key={formulaNamedMetricReportKey}
              reportKey={formulaNamedMetricReportKey}
              formulaNamedMetric={item}
              onMetricQueryChange={(query) => handleNamedFormulaQueryChange(idx, query)}
              onRemoveMetric={() => handleRemoveFormulaNamedMetric(idx, formulaNamedMetricReportKey)}
            />
          );
        })}
        {/*<NewNamedFormulaMetricBuilder reportKey={newNamedFormulaReportKey} onMetricQueryChange={handleAddNewFormulaNamedMetric} />*/}
        <MetricTypeSelector selectorIntent="secondary" onChange={(value) => handleAddNewFormulaNamedMetric(value)} inline />
      </div>
    </div>
  );
};
