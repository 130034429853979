import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { FC, useMemo } from 'react';

import { ToolsTable } from '../../../../shared/components/ToolsTable.tsx';
import { suppressConsoleWarn } from '../../../../shared/utils/suppressConsoleWarn.ts';
import { RelationshipCell } from '../relationships/RelationshipCell.tsx';
import { mapToUserFriendlyDataType } from '../../utils/propertyTypeMapper.ts';
import { Checkbox } from '../../../../shared/ui/Checkbox/Checkbox.tsx';
import { REMOTE_ID } from '../../../records/const.ts';
import { usePropertyFormData } from '../../hooks/usePropertyFormData';
import { Badge } from '../../../../shared/components/Badge';
import { ConstraintBadge } from './ConstraintBadge';
import { ObjectProperty } from '../../types';

const columnHelper = createColumnHelper<ObjectProperty[][number]>();

interface ObjectPropertiesTableProps {
  objectId: string;
  properties?: ObjectProperty[];
  onPropertyUpdate?: (property: ObjectProperty) => void;
}

export const ObjectPropertiesTable: FC<ObjectPropertiesTableProps> = ({ objectId, properties, onPropertyUpdate }) => {
  const { relationshipsByProperty } = usePropertyFormData(objectId);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Name',
        maxSize: 75,
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          return value === REMOTE_ID ? 'id' : value;
        },
      }),
      columnHelper.accessor('type', {
        id: 'type',
        header: 'Type',
        maxSize: 50,
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          return mapToUserFriendlyDataType(value);
        },
      }),
      columnHelper.display({
        id: 'flags',
        header: 'Property flags',
        maxSize: 50,
        cell: (context) => {
          return (
            <div className="flex flex-row gap-1">
              {context.row.id === REMOTE_ID && (
                <Badge variant="default" className="bg-m-gray-300 text-m-gray-700">
                  system
                </Badge>
              )}
            </div>
          );
        },
      }),
      columnHelper.display({
        id: 'show_in_record_creation_flow',
        header: 'Show during creation',
        maxSize: 50,
        cell: (context) => (
          <div className="flex flex-row items-center">
            <Checkbox
              onChange={(val) => {
                onPropertyUpdate?.({ ...context.row.original, show_in_record_creation_flow: val as boolean });
              }}
              checked={context.row.original.show_in_record_creation_flow}
              disabled={context.row.original.read_only}
            />
          </div>
        ),
      }),
      columnHelper.display({
        id: 'constraints',
        header: 'Constraints',
        maxSize: 75,
        cell: (context) => {
          return (
            <div className="flex flex-row flex-wrap gap-1">
              {context.row.original.unique && <ConstraintBadge variant="unique" />}
              {context.row.original.name === REMOTE_ID && <ConstraintBadge variant="required" />}
              {context.row.original.read_only && <ConstraintBadge variant="read-only" />}
            </div>
          );
        },
      }),
      columnHelper.display({
        id: 'relationships',
        header: 'Relates to',
        cell: (context) => {
          return (
            <div className="flex flex-row flex-wrap gap-2">
              {relationshipsByProperty.get(context.row.id)?.map((or) => <RelationshipCell key={or.id} relationship={or} />)}
            </div>
          );
        },
      }),
    ],
    [relationshipsByProperty, onPropertyUpdate]
  );

  const table = useReactTable({
    columns,
    data: properties ?? [],
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.name,
  });

  return <ToolsTable data={properties} table={table} />;
};
