import { useQuery } from '@tanstack/react-query';

import { useEffect, useState } from 'react';
import { ReportChartTimeRange } from '../../reports/components/ReportChartTimeRange';
import { useParams } from 'react-router-dom';
import { tracking, TrackingEvent } from '../../../tracking';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { ChartGranularitySelect } from '../../reports/components/ChartGranularitySelect.tsx';
import { orderBy } from 'lodash';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { EmptyState } from '../../../shared/components/EmptyState.tsx';
import PlusIcon from '../../../assets/icons/plus-circle.svg?react';
import EditIcon from '../../../assets/icons/edit-02.svg?react';
import ReportsIcon from '../../../assets/icons/line-chart-up-01.svg?react';
import SearchIcon from '../../../assets/icons/search-md.svg?react';
import { CenteredLoader } from '../../../shared/components/CenteredLoader.tsx';
import { AddReportDialog } from '../components/AddReportDialog.tsx';
import { ReportStoreContext } from '../../reports/context/reportStoreContext.ts';
import { Container } from '../../../shared/ui/Container/Container';
import { Divider } from '../../../shared/ui/Divider/Divider';

import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

import { DashboardHeader } from '../components/DashboardHeader';

import { useDashboardStore } from '../store';
import { DashboardPanel } from '../components/DashboardPanel';

const EMPTY_STATE_STAGES = [
  {
    icon: PlusIcon,
    title: 'Add reports',
    description: 'Select one or more saved reports and add them to the dashboard.',
  },
  {
    icon: EditIcon,
    title: 'Edit dashboard',
    description: 'Add new or remove existing reports from dashboard any time.',
  },
  {
    icon: ReportsIcon,
    title: 'Create new reports',
    description: 'If you need more reports to be tracked, create them in the Report tab.',
  },
];

export const Dashboard = () => {
  const { dashboardId } = useParams();
  const queryKeys = useQueryKeys();

  const { timerange, granularity, search, setTimerange, setGranularity, setSearch } = useDashboardStore();

  useEffect(() => {
    tracking.track(TrackingEvent.DashboardViewed, { 'dashboard id': dashboardId });
  }, [dashboardId]);

  const dashboardQuery = useQuery({
    queryKey: queryKeys.dashboard(dashboardId ?? ''),
    queryFn: () => bigdeltaAPIClient.v1.dashboardsDetail(dashboardId ?? ''),
    select: (dashboard) => {
      return {
        ...dashboard,
        panels: orderBy(dashboard.panels, (i) => i, 'asc'),
      };
    },
    enabled: !!dashboardId,
  });

  const isEmpty = !dashboardQuery?.data?.panels || dashboardQuery?.data?.panels.length === 0;

  const [isAddReportDialogOpen, setIsAddReportDialogOpen] = useState(false);

  const handleAddReportDialogClose = () => {
    setIsAddReportDialogOpen(false);
  };

  return (
    <ReportStoreContext.Provider value={{ reportKey: 'default' }}>
      <>
        {!dashboardQuery.isInitialLoading && (
          <>
            <Container className="flex items-center justify-between">
              <DashboardHeader />
              <div className="flex items-center gap-x-2">
                {!isEmpty && (
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                      <SearchIcon className="h-5 w-5 text-m-gray-600" />
                    </div>
                    <input
                      type="text"
                      id="simple-search"
                      className="block w-full rounded-md border border-m-gray-300 p-2 ps-10 text-md"
                      placeholder="Search reports"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                )}
                <Button intent={isEmpty ? 'secondary' : 'brand'} size="lg" label="Add reports" onClick={() => setIsAddReportDialogOpen(true)} />
              </div>
            </Container>
            <Divider />
            <Container className="py-3">
              <div className="flex flex-col gap-4">
                {dashboardId && !isEmpty ? (
                  <>
                    <div className="relative flex items-center justify-between">
                      <ReportChartTimeRange onChange={setTimerange} value={timerange} />
                      <div className="flex items-center">
                        <ChartGranularitySelect onChange={setGranularity} value={granularity} />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 overflow-y-auto">
                      {dashboardQuery?.data?.panels.map((panel) => {
                        return <DashboardPanel key={panel.id} panel={panel} dashboardId={dashboardId} />;
                      })}
                    </div>
                  </>
                ) : (
                  <EmptyState heading="Add reports to the dashboard" stages={EMPTY_STATE_STAGES}>
                    <Button intent="brand" size="xl" label="Add reports" onClick={() => setIsAddReportDialogOpen(true)} />
                  </EmptyState>
                )}
              </div>
            </Container>
          </>
        )}
        {dashboardQuery.isInitialLoading && <CenteredLoader />}
        <AddReportDialog isOpen={isAddReportDialogOpen} onClose={handleAddReportDialogClose} dashboard={dashboardQuery?.data} />
      </>
    </ReportStoreContext.Provider>
  );
};
