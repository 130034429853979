import { ReactNode, useState } from 'react';
import { capitalize } from 'lodash';

import * as Tabs from '../../../ui/Tabs/Tabs';
import { RecordPropertyMultilevelSelectContent } from '../../../components/RecordPropertyMultilevelSelectContent.tsx';
import FunnelIcon from '../../../../assets/icons/filter-funnel-01.svg?react';
import { EventNameComboboxContent } from './EventNameComboboxContent.tsx';
import { RelationshipEntityType } from '@bigdelta/lib-shared';
import { MetadataEventsListData } from '@bigdelta/lib-api-client';
import { PropertyNameObject, RelationshipObjectData } from '../../../types.ts';
import { getObjectRelationshipToEvent } from '../../../utils/getObjectRelationshipToEvent.ts';
import { useRelationshipsQuery } from '../../../data/useRelationshipsQuery.ts';

type EventListItem = MetadataEventsListData['items'][number];
export type FilterTab = 'saved' | 'properties' | 'events';

type EventFilterSelected = {
  type: RelationshipEntityType.EVENT;
  event: EventListItem;
  relationships: RelationshipObjectData[] | null;
};

type RecordPropertyFilterSelected = {
  type: RelationshipEntityType.OBJECT;
  property: PropertyNameObject;
  relationships: RelationshipObjectData[] | null;
};

export type OnOptionSelectHandler = (option: EventFilterSelected | RecordPropertyFilterSelected) => void;

type RecordsFilterOptionsProps = {
  objectId: string;
  workspaceId: string;
  onOptionSelect: OnOptionSelectHandler;
  supportedTabs?: FilterTab[];
};

export const FilterOptions = ({
  objectId,
  workspaceId,
  supportedTabs = ['saved', 'properties', 'events'],
  onOptionSelect,
}: RecordsFilterOptionsProps) => {
  const [recordSearch, setRecordSearch] = useState('');
  const [activeTab, setActiveTab] = useState<FilterTab>(supportedTabs.includes('properties') ? 'properties' : supportedTabs[0]);
  const { data: relationshipData } = useRelationshipsQuery({ workspaceId });

  const TAB_CONTENT: Record<FilterTab, ReactNode> = {
    ['saved']: (
      <Tabs.Content value="saved" key="saved">
        <div className="flex flex-col gap-1 px-4 py-20">
          <div className="text-center text-md font-medium leading-normal text-m-olive-900">No saved filters</div>
          <div className="text-center text-xs leading-tight text-m-olive-500">Apply filters and save them to have ready to use filter presets</div>
        </div>
      </Tabs.Content>
    ),
    ['properties']: (
      <Tabs.Content value="properties" key="properties">
        <RecordPropertyMultilevelSelectContent
          searchQuery={recordSearch}
          setSearchQuery={setRecordSearch}
          maxLevels={3}
          parentEntityType={RelationshipEntityType.OBJECT}
          parentEntityId={objectId}
          className="border-0"
          onChange={(property, relationships) => {
            onOptionSelect({
              type: RelationshipEntityType.OBJECT,
              property,
              relationships,
            });
          }}
        />
      </Tabs.Content>
    ),
    ['events']: (
      <Tabs.Content value="events" key="events">
        <EventNameComboboxContent
          value={''} // no need to pass this in
          className="border-0"
          onChange={(event) => {
            const eventRel = getObjectRelationshipToEvent({ relationships: relationshipData.relationships, objectId });
            onOptionSelect({
              type: RelationshipEntityType.EVENT,
              event,
              relationships: eventRel?.name
                ? [
                    {
                      objectId: '',
                      relationshipName: eventRel.name,
                      objectWorkspaceId: '',
                    },
                  ]
                : null,
            });
          }}
          includeAny={true}
        />
      </Tabs.Content>
    ),
  };

  return (
    <Tabs.Root value={activeTab} onValueChange={setActiveTab as never}>
      <Tabs.List className="flex shrink-0 border-b border-b-m-olive-100 px-2">
        {supportedTabs.map((tab) => (
          <Tabs.Trigger value={tab} key={tab}>
            <FunnelIcon className="h-4 w-4 text-m-olive-400" />
            {capitalize(tab)}
          </Tabs.Trigger>
        ))}
      </Tabs.List>
      {supportedTabs.map((tab) => TAB_CONTENT[tab])}
    </Tabs.Root>
  );
};
