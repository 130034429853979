import { PropsWithChildren, ReactNode, forwardRef } from 'react';
import { Divider } from '../Divider/Divider';
import { twMerge } from '../../../utils/twMerge';
import { PropsWithClassName } from '../../../types';

interface FeedProps extends PropsWithClassName, PropsWithChildren {}

const Root = forwardRef<HTMLDivElement, FeedProps>(({ children, className, ...props }, ref) => (
  <div ref={ref} style={{ height: 'calc(100vh - 140px)' }} className={twMerge('overflow-auto pb-4', className)} {...props}>
    <ul className="ml-3 flex flex-col gap-7 pt-8">{children}</ul>
  </div>
));

interface FeedItemProps extends PropsWithClassName, PropsWithChildren {}

const Item = forwardRef<HTMLLIElement, FeedItemProps>(({ children, className, ...props }, ref) => (
  <li ref={ref} className={twMerge('-ml-3 flex items-start text-md', className)} {...props}>
    {children}
  </li>
));

interface FeedItemIconProps extends PropsWithClassName {
  icon: ReactNode;
}

const ItemIcon = forwardRef<HTMLDivElement, FeedItemIconProps>(({ icon, className, ...props }, ref) => (
  <div
    ref={ref}
    className={twMerge('mr-3 flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-m-blue-600 text-m-white', className)}
    {...props}
  >
    {icon}
  </div>
));

interface FeedItemContentProps extends PropsWithClassName, PropsWithChildren {}

const ItemContent = forwardRef<HTMLDivElement, FeedItemContentProps>(({ children, className, ...props }, ref) => (
  <div ref={ref} className={twMerge(className)} {...props}>
    {children}
  </div>
));

interface FeedItemTitleProps extends PropsWithClassName, PropsWithChildren {}

const ItemTitle = forwardRef<HTMLDivElement, FeedItemTitleProps>(({ children, className, ...props }, ref) => (
  <div ref={ref} className={twMerge('flex h-6 items-center', className)} {...props}>
    {children}
  </div>
));

interface FeedItemMetaProps extends PropsWithClassName, PropsWithChildren {}

const ItemMeta = forwardRef<HTMLSpanElement, FeedItemMetaProps>(({ children, className, ...props }, ref) => (
  <span ref={ref} className={twMerge('text-m-gray-600', className)} {...props}>
    {children}
  </span>
));

interface FeedItemDetailsProps extends PropsWithClassName, PropsWithChildren {}

const ItemDetails = forwardRef<HTMLDivElement, FeedItemDetailsProps>(({ children, className, ...props }, ref) => (
  <div ref={ref} className={twMerge('text-m-olive-500', className)} {...props}>
    {children}
  </div>
));

interface FeedLoadMoreProps extends PropsWithClassName {
  onLoadMore: () => void;
}

const LoadMore = forwardRef<HTMLDivElement, FeedLoadMoreProps>(({ onLoadMore, className, ...props }, ref) => (
  <div ref={ref} className={twMerge('pt-6', className)} {...props}>
    <button className="text-md text-m-blue-600" onClick={onLoadMore}>
      Load more...
    </button>
  </div>
));

interface FeedItemGroupProps extends PropsWithClassName, PropsWithChildren {
  title: string;
}

const ItemGroup = forwardRef<HTMLLIElement, FeedItemGroupProps>(({ title, children, className, ...props }, ref) => (
  <li ref={ref} className={className} {...props}>
    {title && (
      <div className="flex items-center justify-between gap-x-2 pb-4">
        <h3 className="whitespace-nowrap text-sm font-medium text-m-olive-500">{title}</h3>
        <Divider direction="horizontal" />
      </div>
    )}
    <ul className="ml-3 flex flex-col gap-7 border-l border-m-gray-300">{children}</ul>
  </li>
));

export { Root, Item, ItemIcon, ItemContent, ItemTitle, ItemMeta, ItemDetails, LoadMore, ItemGroup };
