import { RelationshipEntityType } from '@bigdelta/lib-shared';
import { useQuery } from '@tanstack/react-query';
import { capitalize } from 'lodash';
import { useMemo } from 'react';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient';
import { useObjectsQuery } from '../../../shared/data/useObjectsQuery';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { ObjectRelationship } from '../components/relationships/RelationshipCell';
import { useObjectNameById } from './useObjectNameById';

export const usePropertyFormData = (objectId: string) => {
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  const objectsQuery = useObjectsQuery({ workspaceId: currentWorkspaceId });

  const objectNameById = useObjectNameById();

  const relationshipsQuery = useQuery({
    queryKey: queryKeys.relationships(),
    queryFn: () => bigdeltaAPIClient.v1.relationshipsList({ workspace_id: currentWorkspaceId }),
    enabled: !!objectsQuery.data,
  });

  const eligibleObjects = useMemo(() => {
    return objectsQuery.data?.objects.filter((o) => o.id !== objectId) || [];
  }, [objectId, objectsQuery.data?.objects]);

  const relationshipsByProperty = useMemo(() => {
    const result: Map<string, ObjectRelationship[]> = new Map();

    const workspaceRelationships = relationshipsQuery.data?.relationships?.filter(
      (r) => r.first_entity_workspace_id === r.second_entity_workspace_id
    );

    const outgoingObjectRelationships: ObjectRelationship[] =
      workspaceRelationships
        ?.filter(
          (r) =>
            r.first_entity_type === RelationshipEntityType.OBJECT &&
            r.first_entity_id === objectId &&
            r.second_entity_type === RelationshipEntityType.OBJECT
        )
        .map((r) => {
          return {
            id: r.id,
            property: r.first_entity_property,
            related_entity_id: r.second_entity_id,
            related_entity_name: capitalize(objectNameById.get(r.second_entity_id)),
            related_entity_property: r.second_entity_property,
          };
        }) || [];

    const incomingObjectRelationships: ObjectRelationship[] =
      workspaceRelationships
        ?.filter(
          (r) =>
            r.second_entity_type === RelationshipEntityType.OBJECT &&
            r.second_entity_id === objectId &&
            r.first_entity_type === RelationshipEntityType.OBJECT
        )
        .map((r) => {
          return {
            id: r.id,
            property: r.second_entity_property,
            related_entity_id: r.first_entity_id,
            related_entity_name: capitalize(objectNameById.get(r.first_entity_id)),
            related_entity_property: r.first_entity_property,
          };
        }) || [];

    [...outgoingObjectRelationships, ...incomingObjectRelationships].forEach((or) => {
      const propertyRelationships = result.get(or.property);
      propertyRelationships ? propertyRelationships.push(or) : result.set(or.property, [or]);
    });

    return result;
  }, [objectId, objectNameById, relationshipsQuery.data?.relationships]);

  return { eligibleObjects, relationshipsByProperty };
};
