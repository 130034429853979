import { ResourcePropertyType } from '@bigdelta/lib-shared';
import { STANDARD_STRUCT_PROPERTY_TYPES } from '../../../../shared/properties/const';
import { PropertyNameObject } from '../../../../shared/types';
import { WithTopLevelPropertyType } from '../../store/common';
import { v5 as uuidv5 } from 'uuid';

export const createDisplayMathProperty = (property: PropertyNameObject & WithTopLevelPropertyType): PropertyNameObject => {
  if (property.top_level_property_type && STANDARD_STRUCT_PROPERTY_TYPES.includes(property.top_level_property_type as ResourcePropertyType)) {
    const [propertyName] = property.property_name.split('.');
    return {
      property_id: uuidv5(propertyName, import.meta.env.VITE_APP_METADATA_PROPERTY_NAMESPACE),
      property_name: propertyName,
      property_type: property.top_level_property_type,
    };
  }
  return property;
};
