import { NestedObjectDef } from '@bigdelta/lib-api-client';
import { CurrencyProperty } from './types';

export const isStringArrayOrEmpty = (value: NestedObjectDef[string] | undefined): value is string[] | undefined | null => {
  return value === undefined || value === null || (Array.isArray(value) && value.every((item) => typeof item === 'string'));
};

export const isStringOrEmpty = (value: NestedObjectDef[string] | undefined): value is string => {
  return typeof value === 'string' || value === null || value === undefined;
};

export const isNumberOrEmpty = (value: NestedObjectDef[string] | undefined): value is number | null | undefined => {
  return typeof value === 'number' || value === null || value === undefined;
};

export const isBooleanOrEmpty = (value: NestedObjectDef[string] | undefined): value is boolean | null | undefined => {
  return typeof value === 'boolean' || value === null || value === undefined;
};

export const isCurrencyOrEmpty = (value: NestedObjectDef[string] | undefined): value is CurrencyProperty | null | undefined => {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value !== 'object' || Array.isArray(value)) {
    return false;
  }

  return (
    'amount_in_cents' in value && typeof value.amount_in_cents === 'number' && 'currency_code' in value && typeof value.currency_code === 'string'
  );
};
