import { ObjectsDetailData, ObjectsRecordDetailData } from '@bigdelta/lib-api-client';
import { getWorkspaceObjectIcon } from '../../../shared/utils/getWorkspaceObjectIcon';
import { getRecordLabel } from '../../../shared/utils/getRecordLabel';

const getLogoFromRecord = (object: ObjectsDetailData, record: ObjectsRecordDetailData): string | null => {
  if (!object.icon_property || !record.properties) return null;

  const iconValue = record.properties[object.icon_property];
  return iconValue ? String(iconValue) : null;
};

interface RecordNameProps {
  record: ObjectsRecordDetailData;
  object: ObjectsDetailData;
}

export const RecordName = ({ object, record }: RecordNameProps) => {
  const label = getRecordLabel(object?.label_properties, record);
  const logo = getLogoFromRecord(object, record);
  const FallbackIcon = getWorkspaceObjectIcon(object.object_type);

  return (
    <div className="mb-6 flex flex-row items-center justify-start">
      <div className="mr-2 flex h-16 items-center justify-start">
        {logo ? (
          <img className="max-h-full w-auto rounded-sm" src={logo} alt={`${label} logo`} />
        ) : (
          <FallbackIcon className="inline-block h-6 w-6 shrink-0" />
        )}
      </div>
      <h2 className="border-m-blue-400 text-xl text-m-black">{label}</h2>
    </div>
  );
};
