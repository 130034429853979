import { ObjectSelect } from '../../../../features/events/components/ObjectSelect.tsx';
import * as Popover from '@radix-ui/react-popover';

import FilterIcon from '../../../../assets/icons/filter-funnel-01.svg?react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RelationshipObjectData } from '../../../types.ts';
import { ALL_EVENTS, Attribute, FilterItemType, getFilter, useFilterStore } from '../../store';
import { useWorkspace } from '../../../../features/auth/hooks/useWorkspace.tsx';
import { useRelationshipsQuery } from '../../../data/useRelationshipsQuery.ts';

import Cross2Icon from '../../../../assets/icons/x.svg?react';
import CursorClickIcon from '../../../../assets/icons/cursor-click-01.svg?react';
import { FilterOptionsPopover } from '../common/FilterOptionsPopover.tsx';
import { twMerge } from '../../../../utils/twMerge.ts';
import { EventPropertyList } from '../common/EventPropertyList.tsx';
import { FilterContext } from '../../context/context.ts';
import { EventNameComboboxContent } from '../common/EventNameComboboxContent.tsx';
import { Tooltip } from 'react-tooltip';
import { EventProperty } from './EventProperty.tsx';
import { EventTimeframe } from './EventTimeframe.tsx';
import { WhiteElevatedCard } from '../common/WhiteElevatedCard.tsx';
import { EventRecordProperty } from './EventRecordProperty.tsx';
import { getObjectRelationshipToEvent } from '../../../utils/getObjectRelationshipToEvent.ts';

type EventsFilterNewProps = {
  workspaceId: string;
  baseFilterKey?: string[];
  objectId?: string;
};

export function EventsFilterNew({ workspaceId, baseFilterKey = ['events'] }: EventsFilterNewProps) {
  const [eventFilterIndex, setEventFilterIndex] = useState<number | null>(null);
  const { data: relationshipData } = useRelationshipsQuery({ workspaceId });

  const filterKeyObject = useMemo(() => [...baseFilterKey, 'object'], [baseFilterKey]);
  const filterKeyEvent = useMemo(() => [...baseFilterKey, 'event'], [baseFilterKey]);

  const objectFilters = useFilterStore((state) => getFilter(state, filterKeyObject));
  const eventFilters = useFilterStore((state) => getFilter(state, filterKeyEvent));

  const selectedObjectId =
    objectFilters?.items.find((item) => item.itemType === FilterItemType.EVENTS_OBJECT)?.propertyRelationships[0]?.objectId ?? '';

  const objectToEventRelationship = useMemo(() => {
    if (!relationshipData?.relationships) {
      return null;
    }
    return getObjectRelationshipToEvent({ relationships: relationshipData.relationships, objectId: selectedObjectId });
  }, [selectedObjectId, relationshipData?.relationships]);

  const { currentWorkspaceId } = useWorkspace();

  const clearFilter = useFilterStore((state) => state.clearFilter);
  const addFilterItem = useFilterStore((state) => state.addFilterItem);
  const removeItem = useFilterStore((state) => state.removeItem);
  const setPropertyWithRelationships = useFilterStore((state) => state.setPropertyWithRelationships);
  const setItemDataValue = useFilterStore((state) => state.setItemDataValue);
  const setItemEventTimeframeOperator = useFilterStore((state) => state.setItemEventTimeframeOperator);

  const closeRef = useRef<HTMLButtonElement>(null);

  const handleObjectSelect = useCallback(
    ({ relationship, attribute }: { relationship: RelationshipObjectData | null; attribute: Attribute | null }) => {
      clearFilter(filterKeyObject);
      clearFilter(filterKeyEvent);

      const { index } = addFilterItem(filterKeyObject, FilterItemType.EVENTS_OBJECT, {});

      if (!relationship) {
        setPropertyWithRelationships(filterKeyObject, index, undefined, []);
      }

      if (relationship && attribute) {
        setPropertyWithRelationships(filterKeyObject, index, attribute, [relationship]);
      }

      const { index: timeframeIndex } = addFilterItem(filterKeyEvent, FilterItemType.EVENTS_TIMEFRAME, {});
      setItemEventTimeframeOperator(filterKeyEvent, timeframeIndex, 'OVER_ALL_TIME');

      setEventFilterIndex(null);
    },
    [clearFilter, filterKeyObject, filterKeyEvent, addFilterItem, setItemEventTimeframeOperator, setPropertyWithRelationships]
  );

  useEffect(() => {
    if (!objectFilters?.items.length) {
      handleObjectSelect({ relationship: null, attribute: null });
    }
  }, [addFilterItem, filterKeyObject, handleObjectSelect, objectFilters?.items]);

  const eventName = eventFilters?.items.find((item) => item.itemType === FilterItemType.EVENTS_NAME)?.data.value?.toString() ?? ALL_EVENTS;

  return (
    <>
      <div className="flex items-center justify-between gap-2 rounded-t-lg border border-m-olive-100 bg-white py-2 pl-2.5 pr-2.5">
        <ObjectSelect value={selectedObjectId} workspaceId={currentWorkspaceId} onObjectSelect={handleObjectSelect} />
        {selectedObjectId && (
          <FilterOptionsPopover
            objectId={selectedObjectId}
            workspaceId={workspaceId}
            onOptionSelect={(option) => {
              if (!option?.relationships) {
                return;
              }

              if (option.type === 'OBJECT') {
                const prop = {
                  attributeId: option.property.property_id,
                  attributeType: option.property.property_type ?? '',
                  attributeName: option.property.property_name,
                };

                const relationshipsWithEventRelationship =
                  typeof objectToEventRelationship !== 'undefined'
                    ? [
                        {
                          objectId: option.relationships[0].objectId,
                          relationshipName: objectToEventRelationship?.name ?? null,
                          objectWorkspaceId: workspaceId,
                        },
                        ...option.relationships.slice(1),
                      ]
                    : option.relationships;
                const { index } = addFilterItem(filterKeyObject, FilterItemType.EVENTS_RECORD_PROPERTY, {});

                setPropertyWithRelationships(filterKeyObject, index, prop, relationshipsWithEventRelationship);
              }
            }}
            trigger={
              <button className="rounded p-1 text-m-olive-300 hover:bg-m-gray-300" data-tooltip-id="add-filter">
                <FilterIcon className="h-4 w-4 text-m-olive-300" />
              </button>
            }
          />
        )}
      </div>
      <div className="flex flex-col gap-2.5 rounded-b-lg border border-t-0 border-m-olive-100 bg-m-gray-200 px-2 py-3">
        <FilterContext.Provider value={{ filterKey: filterKeyObject, workspaceId, mode: 'inline', inlineElements: false }}>
          {objectFilters?.items
            .map((item, index) => ({ item, index }))
            .filter((descriptor) => descriptor.item.itemType === FilterItemType.EVENTS_RECORD_PROPERTY)
            .map((descriptor) => {
              return (
                <>
                  <EventRecordProperty
                    filterKey={filterKeyObject}
                    index={descriptor.index}
                    objectId={selectedObjectId}
                    filterItem={descriptor.item}
                    workspaceId={workspaceId}
                  />
                  {/*{arr.length > 1 && index !== arr.length - 1 && (*/}
                  {/*  <>*/}
                  {/*    <div className="flex flex-row items-center justify-between">*/}
                  {/*      <div className="flex h-[1px] flex-1 bg-m-olive-100"></div>*/}
                  {/*      <OperatorSelect*/}
                  {/*        value={objectFilters.operator}*/}
                  {/*        onValueChange={(newOperator) => setOperator(filterKeyObject, newOperator)}*/}
                  {/*        disabled={index !== 0}*/}
                  {/*      />*/}
                  {/*      <div className="flex h-[1px] flex-1 bg-m-olive-100"></div>*/}
                  {/*    </div>*/}
                  {/*  </>*/}
                  {/*)}*/}
                </>
              );
            })}
        </FilterContext.Provider>
        <WhiteElevatedCard>
          <div className="flex flex-1 items-center gap-2">
            <Popover.Root>
              <Popover.Trigger className="flex w-full flex-1 items-center justify-start rounded px-2 py-1.5 hover:bg-m-gray-300">
                <div className="flex items-center gap-2">
                  <CursorClickIcon className="h-4 w-4 text-m-olive-300" />
                  <span className="whitespace-nowrap text-sm font-regular text-m-olive-700">{eventName}</span>
                </div>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content align="start" alignOffset={-20} side="bottom" sideOffset={0}>
                  <EventNameComboboxContent
                    className="max-h-60"
                    value={eventName} // no need to pass this in
                    onChange={(option) => {
                      closeRef.current?.click();
                      if (!option) {
                        return;
                      }
                      if (eventFilterIndex === null) {
                        if (option?.event_name !== ALL_EVENTS) {
                          const { index } = addFilterItem(filterKeyEvent, FilterItemType.EVENTS_NAME, {});
                          setItemDataValue(filterKeyEvent, index, option.event_name);
                          setItemEventTimeframeOperator(filterKeyEvent, index, 'OVER_ALL_TIME');
                          setEventFilterIndex(index);
                        }
                      } else {
                        if (option.event_name === ALL_EVENTS) {
                          removeItem(filterKeyEvent, eventFilterIndex);
                          setEventFilterIndex(null);
                        } else {
                          setItemDataValue(filterKeyEvent, eventFilterIndex, option.event_name);
                        }
                      }
                    }}
                    includeAny={true}
                  />
                  <Popover.Close ref={closeRef} className="hidden" aria-label="Close">
                    <Cross2Icon />
                  </Popover.Close>
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
            <Popover.Root>
              <Popover.Trigger asChild>
                <button data-tooltip-id={'add-event-filter'} className={twMerge('rounded p-1 text-m-olive-300 hover:bg-m-gray-300')}>
                  <FilterIcon className="h-4 w-4 text-m-olive-300" />
                </button>
              </Popover.Trigger>
              <Tooltip className="!z-10 !px-2 !py-0.5" id="add-event-filter" place="top">
                Add event filter
              </Tooltip>
              <Popover.Portal>
                <Popover.Content align="start" alignOffset={-20} side="bottom" sideOffset={0}>
                  <EventPropertyList
                    workspaceId={workspaceId}
                    eventName={eventName}
                    onChange={(attribute) => {
                      const { index } = addFilterItem(filterKeyEvent, FilterItemType.EVENTS_PROPERTY, {});
                      setPropertyWithRelationships(filterKeyEvent, index, attribute, []);
                    }}
                  />
                  <Popover.Close ref={closeRef} className="hidden" aria-label="Close">
                    <Cross2Icon />
                  </Popover.Close>
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          </div>
        </WhiteElevatedCard>
        <div className="flex flex-col gap-2 px-2">
          <FilterContext.Provider value={{ filterKey: filterKeyEvent, workspaceId, mode: 'inline', inlineElements: false }}>
            {eventFilters?.items
              .map((item, index) => ({ item, index }))
              .filter((descriptor) => descriptor.item.itemType !== FilterItemType.EVENTS_NAME)
              .map((descriptor) => (
                <div key={descriptor.index}>
                  {descriptor.item.itemType === FilterItemType.EVENTS_PROPERTY ? (
                    <EventProperty index={descriptor.index} filterItem={descriptor.item} filterKey={filterKeyEvent} />
                  ) : descriptor.item.itemType === FilterItemType.EVENTS_TIMEFRAME ? (
                    <EventTimeframe filterIndex={descriptor.index} />
                  ) : null}
                  {/*{arr.length > 1 && index !== arr.length - 1 && (*/}
                  {/*  <div className="flex flex-row items-center justify-between">*/}
                  {/*    <div className="flex h-[1px] flex-1 bg-m-olive-100"></div>*/}
                  {/*    <OperatorSelect*/}
                  {/*      value={eventFilters.operator}*/}
                  {/*      onValueChange={(newOperator) => setOperator(filterKeyEvent, newOperator)}*/}
                  {/*      disabled={index !== 0}*/}
                  {/*    />*/}
                  {/*    <div className="flex h-[1px] flex-1 bg-m-olive-100"></div>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </div>
              ))}
          </FilterContext.Provider>
        </div>
      </div>
    </>
  );
}
