import { createContext, PropsWithChildren } from 'react';
import { defaultValues, ReportMetricContextType } from './reportMetricsDefault';

type Props = PropsWithChildren & ReportMetricContextType;


export const ReportMetricContext = createContext<ReportMetricContextType | null>(defaultValues);

export const ReportMetricProvider: React.FC<Props> = ({ children, ...rest }) => {
  return <ReportMetricContext.Provider value={rest}>{children}</ReportMetricContext.Provider>;
};

