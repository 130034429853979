import { ChartType, ReportTypeVO, TimeGranularity } from '@bigdelta/lib-shared';
import { useReportStore } from '../store/Report';
import { ButtonHTMLAttributes, FC } from 'react';

import LineChartUpIcon from '../../../assets/icons/line-chart-up-01.svg?react';
import BarChartIcon from '../../../assets/icons/bar-chart-03.svg?react';
// import GridIcon from '../../../assets/icons/grid-01.svg?react';
// import GlobeIcon from '../../../assets/icons/globe-01.svg?react';
import { SVGComponent } from '../../../types';
import { ReportTimerange } from '../const';

interface ReportTypeSelectorButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  description: string;
  icon: SVGComponent;
}

const ReportTypeSelectorButton: FC<ReportTypeSelectorButtonProps> = ({ label, description, icon, ...props }) => {
  const Icon = icon;

  return (
    <button className="group flex gap-x-4 rounded-lg border border-m-olive-100 p-4 text-left" {...props}>
      <div className="rounded-lg border border-m-olive-100 p-2 transition-colors group-hover:border-m-blue-200 group-hover:bg-m-blue-100 group-focus:border-m-blue-200 group-focus:bg-m-blue-100">
        <Icon className="h-4 w-4 text-m-olive-500 transition-colors group-hover:text-m-blue-600 group-focus:text-m-blue-600" />
      </div>
      <div>
        <div className="text-md font-medium text-m-black">{label}</div>
        <div className="text-xs text-m-olive-400">{description}</div>
      </div>
    </button>
  );
};

const reportTypeOptions = [
  {
    label: 'Trends',
    defaultVisualization: ChartType.LINE,
    description: 'Identify patterns and trends in your product',
    icon: LineChartUpIcon,
    reportType: ReportTypeVO.CHART,
  },
  {
    label: 'Funnel',
    defaultVisualization: ChartType.STACKED,
    description: 'Understand conversion and drop-off',
    icon: BarChartIcon,
    reportType: ReportTypeVO.FUNNEL,
  },
  // {
  //   label: 'Retention',
  //   description: 'Analyze how often users return to your product',
  //   icon: GridIcon,
  //   reportType: ReportTypeVO.RETENTION
  // },
  // {
  //   label: 'Maps',
  //   description: 'See how it looks on a map',
  //   icon: GlobeIcon,
  //   reportType: ReportTypeVO.MAPS
  // },
];

// TODO: set default data for report type
export const ReportTypeSelector = () => {
  const { setReportType, setGranularity, setTimerange, setVisualization } = useReportStore();

  return (
    <div className="flex flex-col gap-y-6">
      <div className="py-1 text-lg font-medium">What you would like to learn?</div>
      <div className="flex flex-col gap-y-2">
        {reportTypeOptions.map((reportTypeOption) => (
          <ReportTypeSelectorButton
            label={reportTypeOption.label}
            description={reportTypeOption.description}
            icon={reportTypeOption.icon}
            key={reportTypeOption.label}
            onClick={() => {
              setReportType(reportTypeOption.reportType);
              setGranularity(TimeGranularity.DAY);
              setTimerange(ReportTimerange['7D']);
              setVisualization(reportTypeOption.defaultVisualization || ChartType.LINE);
            }}
          />
        ))}
      </div>
    </div>
  );
};
