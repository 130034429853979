import { FC } from 'react';
import { Badge } from '../../../../shared/components/Badge';

interface ConstraintBadgeProps {
  variant: 'unique' | 'required' | 'read-only';
}

export const ConstraintBadge: FC<ConstraintBadgeProps> = ({ variant }) => {
  const badgeProps = {
    unique: { className: 'bg-m-blue-100 text-m-blue-600', label: 'unique' },
    required: { className: 'bg-m-red-100 text-m-red-600', label: 'required' },
    'read-only': { className: 'bg-m-gray-300 text-m-gray-700', label: 'read-only' },
  }[variant];

  return (
    <Badge variant="default" className={badgeProps.className}>
      {badgeProps.label}
    </Badge>
  );
};
