import React, { useState } from 'react';
import { TextEdit } from './TextEdit';
import { TextView } from './TextView';
import { type EditablePropertyType } from '../types';
import { twMerge } from 'tailwind-merge';

export interface TextProps extends EditablePropertyType<string> {
  overflowVisible?: boolean;
}

// TODO: Move this into record profile feature
export const Text: React.FC<TextProps> = ({ value, overflowVisible = false, onChange, disabled }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [textValue, setTextValue] = useState(value ?? '');
  const handleStartEdit = () => {
    if (!disabled) {
      setIsEditing(true);
    }
  };

  const handleSave = (newValue: string) => {
    setTextValue(newValue);
    setIsEditing(false);
    onChange?.(newValue || undefined);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <div
      className={twMerge(
        'flex w-full cursor-text rounded-md px-2 py-2 focus-within:ring-2 focus-within:ring-m-blue-400 ',
        !disabled && 'hover:bg-m-gray-300'
      )}
    >
      {isEditing ? (
        <TextEdit value={textValue} onSave={handleSave} onCancel={handleCancel} className="w-full rounded-md text-sm" />
      ) : (
        <TextView value={textValue} onClick={handleStartEdit} className="grow" overflowVisible={overflowVisible} />
      )}
    </div>
  );
};
