import { Combobox as ComboboxPrimitive, ComboboxInputProps as OriginalComboboxInputProps } from '@headlessui/react';
import { ChangeEvent, forwardRef } from 'react';
import { Tooltip } from 'react-tooltip';
import { twMerge } from '../../utils/twMerge';
import SearchIcon from '../../assets/icons/search-md.svg?react';

interface ComboboxInputProps extends OriginalComboboxInputProps<any, any> {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const ComboboxInput = forwardRef<HTMLInputElement, ComboboxInputProps>(({ className, ...comboboxProps }, ref) => {
  return (
    <div>
      <div className="relative">
        <SearchIcon className="absolute left-2.5 top-1/2 h-5 w-5 -translate-y-1/2 transform text-m-olive-300" />
        <ComboboxPrimitive.Input
          {...comboboxProps}
          className={twMerge(
            'w-full rounded-lg border-m-olive-100 py-2 pl-9 pr-3 text-sm placeholder:text-sm placeholder:font-regular focus:border-m-olive-100 focus:ring-0',
            className
          )}
          placeholder="Search..."
          ref={ref}
        />
      </div>
      <Tooltip id="combobox-tooltip" className="z-10" />
    </div>
  );
});
