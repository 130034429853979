import { type MonetaryFormat } from '../types';

export const formatMonetary = (rawValue: number, monetaryFormat: MonetaryFormat) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: monetaryFormat.code,
  }).format;

  let denominator = 1;

  if (monetaryFormat.unit === 'cents') {
    denominator = 100;
  }

  return formatter(rawValue / denominator);
};
