import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { AppRoutes } from '../../../routes';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { Link } from '../../../shared/ui/Link/Link.tsx';
import { FormInput } from '../../../shared/ui/FormInput/FormInput.tsx';
import { AuthScreenContainer } from '../components/AuthScreenContainer.tsx';
import { AuthScreenLogo } from '../components/AuthScreenLogo.tsx';
import { GoogleSignIn } from '../components/GoogleSignIn.tsx';
import { LoginFormSchema, useAuth } from '../hooks/useAuth.tsx';

const hideSignUpLink = import.meta.env.VITE_APP_HIDE_SIGN_UP_LINK === 'true';

export const LoginPage = () => {
  const {
    register,
    handleSubmit,
    setError: setFieldError,
    formState: { errors },
  } = useForm<LoginFormSchema>();

  const { userContext, searchParams, onSignIn, onGoogleSignIn, error, loading, invited } = useAuth(setFieldError);

  if (userContext.user && userContext.authenticated && userContext.ready) {
    return <Navigate to={AppRoutes.ROOT} />;
  }

  return (
    <AuthScreenContainer>
      <AuthScreenLogo />
      <div className="flex items-center justify-center text-display-xs font-medium">{invited ? 'Join your team on Bigdelta' : 'Login'}</div>
      <div className="flex items-center justify-center py-3">
        <GoogleSignIn onSuccess={(idToken) => onGoogleSignIn(idToken)} onError={() => onGoogleSignIn(null)} />
      </div>
      <div className="flex items-center justify-center">
        {error && <div className="w-full rounded-lg border border-m-red-300 bg-m-red-200 px-4 py-2 text-md text-m-red-700">{error}</div>}
      </div>
      <form onSubmit={handleSubmit(onSignIn)} className="pt-3">
        <div className="flex flex-col gap-4">
          <FormInput
            label="Work email"
            inputProps={{
              ...register('email', { required: true }),
              autoComplete: 'email username',
              type: 'email',
              placeholder: 'name@example.com',
            }}
            errorMessage={errors.email?.message}
          />
          <div>
            <FormInput
              label="Password"
              inputProps={{
                ...register('password', { required: true }),
                autoComplete: 'password',
                type: 'password',
                placeholder: '••••••••',
              }}
              errorMessage={errors.password?.message}
            />
            <Link to="/forgot-password" className="mt-2 text-sm">
              Forgot password?
            </Link>
          </div>
          <Button label="Submit" className="w-full" size="lg" type="submit" loading={loading} disabled={loading} />
        </div>
      </form>
      {!hideSignUpLink && (
        <div className="align-center mt-6 flex justify-center text-sm">
          Don't have an account?
          <Link className="ml-1" to={{ pathname: '/sign-up', search: searchParams.toString() }}>
            {' '}
            Sign up
          </Link>
        </div>
      )}
    </AuthScreenContainer>
  );
};
