import { ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Divider } from '../../../shared/ui/Divider/Divider';

interface RecordDetailsProps {
  label: string | null | undefined;
  icon?: ReactNode;
  slug?: string;
  id?: string;
}

const RecordSkeleton = () => (
  <div className="flex w-full flex-row items-center justify-start py-4">
    <div className="mr-4 size-5 animate-pulse rounded-full bg-m-gray-400"></div>
    <div className="h-3 w-full animate-pulse rounded-full bg-m-gray-400" />
  </div>
);

export const RecordDetails = ({ label, icon, slug, id }: RecordDetailsProps) => {
  const isLoading = !label || !slug || !id;
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(`/records/${slug}/${id}`);
  }, [slug, id, navigate]);

  return isLoading ? (
    <RecordSkeleton />
  ) : (
    <>
      <div role="button" onClick={handleClick} className="flex w-full cursor-pointer items-center justify-start gap-x-4 rounded-lg bg-m-gray-100 p-4">
        <div className="flex items-center gap-x-4">{icon}</div>
        <div>{label}</div>
      </div>
      <Divider />
    </>
  );
};
