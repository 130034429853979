import { FC, HTMLAttributes, useMemo } from 'react';
import { ALL_EVENTS, FilterItemType, FilterKey } from '../../../shared/filters/store';
import { EventsFilter } from '../../../shared/filters/components/events/EventsFilter';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { useReportsStore } from '../store';
import { useReportMetric } from '../../auth/hooks/useReportMetric';

type ReportCreateFilterEvents = HTMLAttributes<HTMLElement> & {
  filterKey: FilterKey;
};

export const ReportCreateFilterEvents: FC<ReportCreateFilterEvents> = ({ filterKey, ...rest }) => {
  const { inline } = useReportMetric();
  const { currentWorkspaceId } = useWorkspace();
  const metricEvent = useReportsStore((state) => state.config.metricEvent);

  const filterItemTypes = useMemo(() => {
    return [
      ...(metricEvent === ALL_EVENTS ? [FilterItemType.EVENTS_NAME] : []),
      FilterItemType.EVENTS_PROPERTY,
      FilterItemType.EVENTS_RECORD_PROPERTY,
    ];
  }, [metricEvent]);

  return (
    <EventsFilter
      workspaceId={currentWorkspaceId}
      filterKey={filterKey}
      mode="stacked"
      filterItemTypes={filterItemTypes}
      defaultFilterItem={metricEvent ? { event: metricEvent } : undefined}
      inlineElements={inline}
      {...rest}
    />
  );
};
