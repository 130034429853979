import { v4 as uuid } from 'uuid';

import { Combobox } from '../../../../components/Combobox';
import { useQueryKeys } from '../../../auth/hooks/useQueryKeys';
import { useQuery } from '@tanstack/react-query';
import { useWorkspace } from '../../../auth/hooks/useWorkspace';
import { capitalize } from 'lodash';
import { useRef } from 'react';
import { ObjectIcon } from '../common/ObjectIcon';
import { WorkspaceObjectType } from '@bigdelta/lib-shared';
import { ObjectsListData } from '@bigdelta/lib-api-client';
import { useReportStore } from '../../store/Report';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient';

export const DataSelect = () => {
  const {
    addFunnelBuilder,
    report: {
      funnel: { builder },
    },
  } = useReportStore();

  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object', 'active_and_virtual'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId, status: 'ACTIVE_AND_VIRTUAL' }),
  });

  const popoverCloseRef = useRef<HTMLButtonElement | null>(null);

  const onSelectObject = (object: ObjectsListData['objects'][number] | null) => {
    if (object === null) {
      return;
    }

    addFunnelBuilder({
      id: uuid(),
      data: {
        object: {
          workspaceObjectId: object.id,
          count: {
            type: 'conversion_rate',
          },
          conversionWindow: {
            unit: 'day',
            value: 7,
          },
        },
      },
    });
    popoverCloseRef.current?.click();
  };

  return !builder ? (
    <Combobox
      items={objectsQuery.data?.objects ?? []}
      height={300}
      renderOption={(object) => (
        <div className="flex items-center gap-x-1 text-sm font-regular">
          <ObjectIcon objectType={WorkspaceObjectType[object.object_type]} />
          {capitalize(object.plural_noun)}
        </div>
      )}
      className="w-full gap-y-2 border-0 p-2"
      catchInputFocus={false}
      onChange={onSelectObject}
      filterCompare={(objectItem, search) => {
        if (!search) {
          return true;
        }

        if (!objectItem) return false;

        if (objectItem.plural_noun.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
          return true;
        }

        return false;
      }}
    />
  ) : null;
};
