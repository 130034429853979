import { Button } from '../../../../shared/ui/Button/Button';

import { FC, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../../auth/hooks/useQueryKeys.ts';
import { useWorkspace } from '../../../auth/hooks/useWorkspace.tsx';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient.ts';
import { ResourcePropertyType } from '@bigdelta/lib-shared';
import { REMOTE_ID } from '../../../records/const';
import { ObjectProperty } from '../../types';
import { ObjectPropertiesTable } from './ObjectPropertiesTable';
import { Dialog } from '@headlessui/react';
import { PropertyForm } from './PropertyForm';
import { PropertyFormSchema } from './PropertyFormSchema';

interface PropertiesTabProps {
  objectId: string;
  filteredObjectProperties: ObjectProperty[];
  onEditProperty: (input: PropertyFormSchema) => void;
  onAddProperty: (input: PropertyFormSchema) => void;
  isAddPropertyDialogOpen: boolean;
  setIsAddPropertyDialogOpen: (isOpen: boolean) => void;
}

export const PropertiesTab: FC<PropertiesTabProps> = ({
  objectId,
  filteredObjectProperties,
  onEditProperty,
  onAddProperty,
  isAddPropertyDialogOpen,
  setIsAddPropertyDialogOpen,
}) => {
  const queryKeys = useQueryKeys();
  const { currentWorkspaceId } = useWorkspace();
  const objectQuery = useQuery({
    queryKey: queryKeys.object(objectId),
    queryFn: () => bigdeltaAPIClient.v1.objectsDetail(objectId, { workspace_id: currentWorkspaceId }),
    enabled: !!objectId,
  });

  const allObjectProperties = useMemo<ObjectProperty[]>(() => {
    return (
      objectQuery.data?.properties?.map<ObjectProperty>(({ name, type, show_in_record_creation_flow, read_only }) => ({
        name,
        type: type as ResourcePropertyType,
        show_in_record_creation_flow,
        read_only,
        unique: name === REMOTE_ID,
      })) || []
    );
  }, [objectQuery.data?.properties]);

  return (
    <div className="flex justify-center">
      <div className="w-full">
        <ObjectPropertiesTable objectId={objectId} properties={filteredObjectProperties} onPropertyUpdate={onEditProperty} />
        <Dialog open={isAddPropertyDialogOpen} onClose={() => setIsAddPropertyDialogOpen(false)} className="relative z-[999]">
          <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
          <div className="fixed inset-0 flex w-screen items-center justify-center">
            <Dialog.Panel className="mx-auto flex w-3/5 max-w-lg flex-col gap-y-6 rounded-xl bg-white">
              <Dialog.Title className="p-6 pb-0 text-xl text-m-olive-700">Add property</Dialog.Title>
              <PropertyForm
                initialValues={{ name: null, type: ResourcePropertyType.STRING, show_in_record_creation_flow: false }}
                reservedNames={allObjectProperties.map((p) => p.name)}
                onCancel={() => setIsAddPropertyDialogOpen(false)}
                renderSubmitButton={(isValid, handleSubmit) => (
                  <Button label="Add" intent="brand" size="sm" onClick={handleSubmit(onAddProperty)} disabled={!isValid} />
                )}
                objectId={objectId}
              />
            </Dialog.Panel>
          </div>
        </Dialog>
      </div>
    </div>
  );
};
