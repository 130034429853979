import { v4 as uuid } from 'uuid';

import { EventsQueryRequestQueryPropertyConditions, RelationshipsListData, ReportsDetailData } from '@bigdelta/lib-api-client';
import { TrendsBuilderStateData, TrendsBuilderType, TrendsCount } from '../../../store/TrendsBuilder';
import { MetricMath, QueryValueFilterOperator, RelationshipEntityType, ResourcePropertyType } from '@bigdelta/lib-shared';
import { ObjectCountType } from '../../../store/common';
import { REMOTE_ID } from '../../../../records/const';

const extractParentObjectIdFromEventFilter = (
  conditions: Extract<EventsQueryRequestQueryPropertyConditions[number], { conditions: Array<any> }>['conditions'],
  relationships: RelationshipsListData['relationships']
): string | undefined => {
  const extractBaseRelatedRecordsCondition = (conditions: Parameters<typeof extractParentObjectIdFromEventFilter>[0]) => {
    return conditions.find((condition) => {
      if (!condition) {
        return false;
      }

      if ('related_records' in condition) {
        return !!condition.related_records?.filter?.conditions.find((relatedRecordCondition) => {
          if (!relatedRecordCondition) {
            return false;
          }

          if ('record_property' in relatedRecordCondition) {
            if (!relatedRecordCondition.record_property) {
              return false;
            }

            const { name, operator } = relatedRecordCondition.record_property;

            if (name === REMOTE_ID && operator === QueryValueFilterOperator.IS_SET) {
              return true;
            }

            return false;
          }
        });
      }
    });
  };

  const getRelationshipFromBaseRelatedRecordsCondition = (
    condition: ReturnType<typeof extractBaseRelatedRecordsCondition>,
    relationships: RelationshipsListData['relationships']
  ) => {
    if (!condition || !('related_records' in condition)) {
      return;
    }

    const relationshipName = condition.related_records?.relationship_name;

    if (!relationshipName) {
      return;
    }

    return relationships.find((rel) => rel.name === relationshipName);
  };

  const baseRelatedRecordsCondition = extractBaseRelatedRecordsCondition(conditions);

  if (baseRelatedRecordsCondition && 'related_records' in baseRelatedRecordsCondition) {
    const relationship = getRelationshipFromBaseRelatedRecordsCondition(baseRelatedRecordsCondition, relationships);

    if (!relationship) {
      return;
    }

    const relatedObjectId =
      relationship.first_entity_type === RelationshipEntityType.EVENT ? relationship.second_entity_id : relationship.first_entity_id;

    if (!relatedObjectId) {
      return;
    }

    return relatedObjectId;
  }
};

const extractEventNameFromEventFilter = (
  conditions: Extract<EventsQueryRequestQueryPropertyConditions[number], { conditions: Array<any> }>['conditions']
): string | undefined => {
  const eventNameCondition = conditions.find((condition) => {
    if (!condition) {
      return false;
    }

    if ('event_name' in condition) {
      if (!condition.event_name) {
        return false;
      }

      const { operator, value } = condition.event_name;
      if (operator === QueryValueFilterOperator.EQUALS && value) {
        return true;
      }
    }
  });

  if (eventNameCondition && 'event_name' in eventNameCondition) {
    if (Array.isArray(eventNameCondition.event_name?.value)) {
      return;
    }

    return eventNameCondition.event_name?.value;
  }
};

export const getBuilderFromEventMetric = (
  queryMetric: NonNullable<ReportsDetailData['query']>['metrics'][number],
  relationships: RelationshipsListData['relationships']
): TrendsBuilderStateData | undefined => {
  if (!queryMetric.events) {
    return;
  }

  const defaultFilter = queryMetric.events.filter?.conditions?.[0];

  if (!defaultFilter || !('conditions' in defaultFilter)) {
    return;
  }

  const defaultConditions = defaultFilter.conditions;

  const [objectRelationshipFilter, eventNameFilter, countPropertiesFilter] = defaultConditions;

  if (!objectRelationshipFilter || !eventNameFilter || !countPropertiesFilter) {
    return;
  }

  if (!('conditions' in objectRelationshipFilter) || !('conditions' in eventNameFilter) || !('conditions' in countPropertiesFilter)) {
    return;
  }

  const parentObjectId = extractParentObjectIdFromEventFilter(objectRelationshipFilter.conditions, relationships);
  const eventName = extractEventNameFromEventFilter(eventNameFilter.conditions);

  if (!parentObjectId) {
    return;
  }

  let count: TrendsCount;

  if (queryMetric.events.math === MetricMath.TOTAL && !eventName) {
    // all events

    count = {
      aggregate: MetricMath.TOTAL,
      type: ObjectCountType.EVENT,
      event: {
        type: 'allEvents',
        data: {},
      },
    };
  }

  if (queryMetric.events.math === MetricMath.TOTAL && eventName && !queryMetric.events.math_target) {
    count = {
      aggregate: MetricMath.TOTAL,
      type: ObjectCountType.EVENT,
      event: {
        type: 'event',
        data: {
          event: eventName,
        },
      },
    };
  }

  // Distinct count of records who did eventName event
  if (queryMetric.events.math === MetricMath.DISTINCT_TOTAL && queryMetric.events.math_target?.related_records && eventName) {
    const relationshipName = queryMetric.events.math_target?.related_records.relationship_name;

    if (!relationshipName) {
      return;
    }

    const relationship = relationships.find((rel) => rel.name === relationshipName);

    if (!relationship) {
      return;
    }

    const relatedObjectId =
      relationship.first_entity_type === RelationshipEntityType.EVENT ? relationship.second_entity_id : relationship.first_entity_id;

    if (!relatedObjectId || relatedObjectId !== parentObjectId) {
      // Distinct count unique records relationship does not match with parent object
      return;
    }

    count = {
      aggregate: MetricMath.DISTINCT_TOTAL,
      type: ObjectCountType.EVENT,
      event: {
        type: 'event',
        data: {
          event: eventName,
        },
      },
    };
  }

  // Distinct count of records who did all events
  if (queryMetric.events.math === MetricMath.DISTINCT_TOTAL && queryMetric.events.math_target?.related_records && !eventName) {
    const relationshipName = queryMetric.events.math_target?.related_records.relationship_name;

    if (!relationshipName) {
      return;
    }

    const relationship = relationships.find((rel) => rel.name === relationshipName);

    if (!relationship) {
      return;
    }

    const relatedObjectId =
      relationship.first_entity_type === RelationshipEntityType.EVENT ? relationship.second_entity_id : relationship.first_entity_id;

    if (!relatedObjectId || relatedObjectId !== parentObjectId) {
      // Distinct count unique records relationship does not match with parent object
      return;
    }

    count = {
      aggregate: MetricMath.DISTINCT_TOTAL,
      type: ObjectCountType.EVENT,
      event: {
        type: 'allEvents',
        data: {},
      },
    };
  }

  if (queryMetric.events.math_target?.event_property && eventName) {
    count = {
      aggregate: queryMetric.events.math as MetricMath,
      type: ObjectCountType.EVENT,
      event: {
        type: 'property',
        data: {
          property: {
            event: eventName,
            property: {
              property_id: queryMetric.events.math_target.event_property.name,
              property_name: queryMetric.events.math_target.event_property.name,
              property_type: queryMetric.events.math_target.event_property.property_type,
              top_level_property_type: queryMetric.events.math_target.event_property.top_level_property_type as ResourcePropertyType,
            },
          },
        },
      },
    };
  }

  if (!count!) {
    return;
  }

  return {
    id: uuid(),
    name: queryMetric.name ?? 'X',
    type: TrendsBuilderType.OBJECT,
    label: queryMetric.query_name ?? undefined,
    data: {
      [TrendsBuilderType.OBJECT]: {
        workspaceObjectId: parentObjectId,
        count,
      },
    },
  };
};
