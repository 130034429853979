import { CSSProperties, ReactNode, forwardRef } from 'react';
import { PropsWithClassName } from '../../types';
import { twMerge } from '../../utils/twMerge';

type SpanElementProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

type BadgeVariant = 'default' | 'grey';

export interface BadgeProps extends PropsWithClassName, SpanElementProps {
  children: ReactNode;
  style?: CSSProperties;
  variant?: BadgeVariant;
  title?: string;
}

export const Badge = forwardRef<HTMLSpanElement, BadgeProps>(({ children, variant = 'default', className, ...rest }, ref) => {
  const textColor = {
    ['default']: '',
    ['grey']: 'border border-m-gray-400 bg-m-gray-300 text-m-olive-500',
  }[variant];
  return (
    <span
      {...rest}
      ref={ref}
      className={twMerge(
        'inline-block rounded-lg px-2 py-0.5 text-center text-xs',
        'max-w-32 overflow-hidden text-ellipsis whitespace-nowrap',
        textColor,
        className
      )}
    >
      {children}
    </span>
  );
});
