import { Popover } from '@headlessui/react';
import { SelectTriggerSmall } from '../../../../components/SelectTriggerSmall';
import { FC  } from 'react';
import { useFilterContext } from '../../context/useFilterContext';
import { MetadataEventsListData } from '@bigdelta/lib-api-client';
import { twMerge } from 'tailwind-merge';
import { OpenTrigger } from '../../../components/OpenTrigger';
import { FloatWrapper } from '../../../../features/reports/components/FloatWrapper.tsx';
import { EventNameComboboxContent } from './EventNameComboboxContent.tsx';

type EventListItem = MetadataEventsListData['items'][number];

interface EventNameComboboxSingleProps {
  includeAny?: boolean;
  value: string | undefined;
  onChange: (value: EventListItem | null) => void;
  inlineElements?: boolean;
}

export const EventNameComboboxSingle: FC<EventNameComboboxSingleProps> = ({ includeAny = false, value, onChange, inlineElements }) => {
  const { mode } = useFilterContext();

  return (
    <Popover>
      {({ open, close }) => (
        <FloatWrapper placement="bottom-start" offset={4} portal inline={inlineElements}>
          <Popover.Button
            as={SelectTriggerSmall}
            open={open}
            textClassName="overflow-hidden text-ellipsis"
            className={twMerge(mode === 'stacked' && 'w-full')}
          >
            <OpenTrigger shouldOpen={!value} />
            {value ?? 'Select event'}
          </Popover.Button>
          <Popover.Panel>
            <EventNameComboboxContent
              value={value}
              onChange={(value) => {
                onChange(value);
                close();
              }}
              inlineElements={inlineElements}
              includeAny={includeAny}
            />
          </Popover.Panel>
        </FloatWrapper>
      )}
    </Popover>
  );
};
