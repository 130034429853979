import React, { useState } from 'react';
import { useShowContentClick } from '../../hooks/useShowContentClick';
import { FloatingPortal } from '@floating-ui/react';
import { twMerge } from '../../../utils/twMerge';
import { DateTimeView } from '../../properties/Datetime/DateTimeView';
import { DateTimeEdit } from '../../properties/Datetime/DateTimeEdit';
import { DateTimeProps } from '../../properties/Datetime/DateTime';

interface CellContentDateTimeProps extends DateTimeProps {
  disabled?: boolean;
}

export const CellContentDateTime: React.FC<CellContentDateTimeProps> = ({ value, onChange, disabled = false }) => {
  const [textValue, setTextValue] = useState(value ?? '');
  const { floatingPortalContentProps, referenceProps, forceClose, isOpen } = useShowContentClick({ disabled });
  floatingPortalContentProps.className = twMerge(
    floatingPortalContentProps.className,
    'flex h-fit w-full rounded-md p-2 focus-within:ring-2 focus-within:ring-m-blue-400 hover:bg-m-gray-300'
  );

  const handleSave = (newValue: string) => {
    setTextValue(newValue);
    forceClose();
    onChange?.(newValue);
  };

  return (
    <>
      <div {...referenceProps} className={twMerge('flex h-full w-full cursor-text items-center px-3', disabled && 'text-m-olive-400')}>
        <DateTimeView value={textValue} className="grow" />
      </div>
      {isOpen && (
        <FloatingPortal>
          <div {...floatingPortalContentProps}>
            <DateTimeEdit value={textValue} onSave={handleSave} onCancel={() => {}} />
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
