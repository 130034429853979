import { get, includes } from 'lodash';
import { Datapoint, Dataset } from '../types';
import { toastError } from '../../../utils/toast';
import { QueryReportsCreateData } from '@bigdelta/lib-api-client';

import Color from 'color';
import { PropertyNameObject } from '../../../shared/types';
import { AliasPrefix } from '../store';
import { ReportTypeVO } from '@bigdelta/lib-shared';
import { getBreakdownPropertyKey } from './getBreakdownPropertyKey';

enum DatasetDisplayMode {
  GROUP = 'group',
  QUERY = 'query',
}

const DEFAULT_DATASET_COLOR = '#5B70DF';

const basePositiveColor = '#5B70DF';
const baseNegativeColor = '#EF5C5E';

const positiveColors = [
  Color(basePositiveColor).hex(),
  Color(basePositiveColor).lighten(0.2).hex(),
  Color(basePositiveColor).lighten(0.4).hex(),
  Color(basePositiveColor).lighten(0.475).hex(),
  Color(basePositiveColor).lighten(0.525).hex(),
];

export const getPositiveColor = (index: number) => positiveColors[index % positiveColors.length];

const negativeColors = [
  Color(baseNegativeColor).hex(),
  Color(baseNegativeColor).lighten(0.2).hex(),
  Color(baseNegativeColor).lighten(0.4).hex(),
  Color(baseNegativeColor).lighten(0.475).hex(),
  Color(baseNegativeColor).lighten(0.525).hex(),
];

export const getNegativeColor = (index: number) => negativeColors[index % negativeColors.length];

const getDataset = ({ label, color, data }: { data: Datapoint[]; label: string; color: string }) => ({
  label,
  color,
  regularColor: color,
  pointBackgroundColor: data.length === 1 ? color : 'transparent',
  pointHoverBackgroundColor: color,
  pointHoverBorderColor: '#fff',
  barHoverBackgroundColor: color,
  backgroundColor: color,
  pointBorderColor: data.length === 1 ? color : 'transparent',
  pointBorderWidth: 2,
  pointHoverBorderWidth: 2,
  pointRadius: 3,
  pointHoverRadius: 6,
  borderRadius: 5,
  data,
});

const getDatasetDisplayMode = ({ breakdownProperty, dataQueries }) => {
  if (breakdownProperty) {
    if (dataQueries.length > 1) {
      toastError('Cannot group by property when multiple metrics are used');
    }
    return DatasetDisplayMode.GROUP;
  }
  if (dataQueries.length) {
    return DatasetDisplayMode.QUERY;
  }
};

type DataQuery = QueryReportsCreateData['queries'][number];

interface GetDataMapArgs {
  reportType: ReportTypeVO;
  dataQueries: QueryReportsCreateData['queries'];
  breakdownProperty?: PropertyNameObject;
  breakdownPropertyPrefix: AliasPrefix | undefined;
  breakdownSelectedValues:
    | {
        property_value: string;
      }[]
    | undefined;
  breakdownSelectedValuesColorMap: Record<string, string> | undefined;
}

export const byAggregationResult = (a: DataQuery, b: DataQuery) => {
  if (!a.metadata?.aggregation?.result?.length || !b.metadata?.aggregation?.result?.length) {
    return 0;
  }

  return b.metadata.aggregation.result[0].average - a.metadata.aggregation.result[0].average;
};

export const getDataMap = ({
  reportType,
  dataQueries,
  breakdownProperty,
  breakdownPropertyPrefix,
  breakdownSelectedValues,
  breakdownSelectedValuesColorMap,
}: GetDataMapArgs): { [key: string]: Dataset } => {
  if (!dataQueries?.length) {
    return {};
  }

  const datasetDisplayMode = getDatasetDisplayMode({ breakdownProperty, dataQueries });

  if (datasetDisplayMode === DatasetDisplayMode.QUERY) {
    const sortedQueries = dataQueries.slice().sort(byAggregationResult);

    let positiveCount = 0;
    let negativeCount = 0;

    return sortedQueries.reduce((acc, dataQuery) => {
      const tempId = dataQuery.id;

      let color;

      if (!dataQuery.result?.length) {
        return acc;
      }

      if (dataQuery.metadata?.aggregation?.result?.length) {
        if (dataQuery.metadata.aggregation.result[0]?.average >= 0) {
          color = getPositiveColor(positiveCount);
          positiveCount++;
        } else {
          color = getNegativeColor(negativeCount);
          negativeCount++;
        }
      } else {
        color = DEFAULT_DATASET_COLOR;
      }

      return {
        ...acc,
        [tempId]: getDataset({
          label: tempId,
          color,
          data: dataQuery.result.map((item) => ({
            x:
              reportType === ReportTypeVO.FUNNEL ? `${item.event_sequence_step?.event_name?.name}_${item.event_sequence_step_index}` : item.timestamp,
            y: item.metric,
            conversion_rate: item.conversion_rate,
          })),
        }),
      };
    }, {});
  }

  if (datasetDisplayMode === DatasetDisplayMode.GROUP) {
    const getFilteredData = () => {
      if (dataQueries.length > 1) {
        return;
      }

      if (dataQueries.length === 1) {
        const dataQuery = dataQueries[0];

        if (breakdownSelectedValues?.length === 0 || !breakdownProperty) {
          return dataQuery?.result || [];
        }

        const breakdownPropertyKey = getBreakdownPropertyKey(breakdownPropertyPrefix, breakdownProperty?.property_name);

        const valuesToDisplay = breakdownSelectedValues?.map((item) => item.property_value);

        return dataQuery?.result.filter((item) => includes(valuesToDisplay, item[breakdownPropertyKey]));
      }
    };

    const filteredData = getFilteredData();

    return (
      filteredData?.reduce((acc, item) => {
        let label;
        let datasetColor = DEFAULT_DATASET_COLOR;
        let key;
        const propertyName = Object.keys(item).find((key) => key !== 'timestamp' && key !== 'metric');

        if (!propertyName || !item[propertyName]) {
          label = '';
        } else {
          key = item[propertyName];

          datasetColor = get(breakdownSelectedValuesColorMap ?? {}, key, DEFAULT_DATASET_COLOR);

          label = `${propertyName}: ${item[propertyName]}`;
        }

        return {
          ...acc,
          [key]: getDataset({
            label,
            color: datasetColor,
            data: [
              ...(acc[key]?.data ?? []),
              {
                x: item.timestamp,
                y: item.metric,
              },
            ],
          }),
        };
      }, {}) ?? {}
    );
  }

  return {};
};
