import {
  EventsQueryRequestQueryDef,
  MetadataResourcesPropertiesNamesListData,
  MetadataResourcesPropertiesValuesListData,
  NestedObjectDef,
  QueryReportsCreateData,
  RecordQueryFiltersRequestDef,
} from '@bigdelta/lib-api-client';

export interface RelationshipObjectData {
  relationshipName: string | null;
  objectId: string;
  objectWorkspaceId: string;
}
export interface GenericResponse {
  items: any[];
}

export type PropertyNameObject = MetadataResourcesPropertiesNamesListData['items'][number];
export type PropertyValueObject = MetadataResourcesPropertiesValuesListData['items'][number];

export type NestedValue = NestedObjectDef[string];

export enum VariableInputElementType {
  PropertyVariable = 'property_variable',
  Metric = 'metric',
  Paragraph = 'paragraph',
}

export type QueryEventProperty = NonNullable<Exclude<EventsQueryRequestQueryDef['conditions'][number], EventsQueryRequestQueryDef>>['event_property'];
export type QueryRecordProperty = NonNullable<
  Exclude<RecordQueryFiltersRequestDef['conditions'][number], RecordQueryFiltersRequestDef>
>['record_property'];

export type QueryMetaData = NonNullable<QueryReportsCreateData['queries'][number]['metadata']>;

export type MonetaryFormat = NonNullable<NonNullable<NonNullable<QueryMetaData>['format']>['monetary']>;
