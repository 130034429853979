import { ObjectsListData, RelationshipsListData } from '@bigdelta/lib-api-client';
import { RelationshipEntityType } from '@bigdelta/lib-shared';

type RelationshipList = RelationshipsListData['relationships'];
type Relationship = RelationshipList[number];

const getOtherEnd = <T extends string | RelationshipEntityType>(identifier: T, relation: Relationship): string | undefined => {
  if (identifier in RelationshipEntityType) {
    if (identifier === relation.first_entity_type) {
      return relation.second_entity_id;
    } else if (identifier === relation.second_entity_type) {
      return relation.first_entity_id;
    }
  } else {
    if (relation.first_entity_id === identifier) {
      return relation.second_entity_id;
    } else if (relation.second_entity_id === identifier) {
      return relation.first_entity_id;
    }
  }
  return undefined;
};

export const getRelatedObjectsList = (
  relationshipNames: string[],
  startEntityType: RelationshipEntityType,
  startEntityId: string | undefined,
  relationships: RelationshipList,
  objects: ObjectsListData['objects']
) => {
  let parentId = startEntityId;

  const toRelationship = (relationshipName: string) => relationships.find((rel) => rel.name === relationshipName);
  const toOtherRelationEnd = (relationship: Relationship) => {
    parentId = getOtherEnd(parentId || startEntityType, relationship);
    return parentId;
  };
  const toObject = (objectId: string) => objects.find((object) => object.id === objectId);

  return relationshipNames.map(toRelationship).map(toOtherRelationEnd).map(toObject);
};
