import { Popover } from '@headlessui/react';
import { SelectTriggerSmall } from '../../../../components/SelectTriggerSmall';
import { ALL_EVENTS, FilterItem, useFilterStore } from '../../store';
import { ChangeEvent, FC, useMemo, useState } from 'react';
import { useFilterContext } from '../../context/useFilterContext';
import { useMetadataEventsInfiniteQuery } from '../../../data/useMetadataEventsInfiniteQuery';
import { useDebounce } from '@uidotdev/usehooks';
import { ComboboxMultipleInfinite } from '../../../../components/Combobox';
import { ComboboxMultipleLabel } from './ComboboxMultipleLabel';
import { useReportMetric } from '../../../../features/auth/hooks/useReportMetric';
import { FloatWrapper } from '../../../../features/reports/components/FloatWrapper.tsx';
import { twMerge } from 'tailwind-merge';

// TODO: event_name types

type EventListItem = { event_name: string };

interface EventNameValueSelectMultipleProps {
  filterDataItem: FilterItem;
  index: number;
  includeAny?: boolean;
}

export const EventNameValueSelectMultiple: FC<EventNameValueSelectMultipleProps> = ({ filterDataItem, index, includeAny = false }) => {
  const { inline } = useReportMetric();
  const { workspaceId } = useFilterContext();
  const { filterKey } = useFilterContext();
  const setItemDataValue = useFilterStore((state) => state.setItemDataValue);

  const [rawSearchQuery, setRawSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(rawSearchQuery, 700);

  const {
    data: eventNames,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isFetching,
    isSuccess,
  } = useMetadataEventsInfiniteQuery({
    workspaceId: workspaceId,
    searchQuery: debouncedSearchQuery,
  });

  const flatEventNames = useMemo(
    () => (eventNames ? [...(includeAny ? [{ event_name: ALL_EVENTS }] : []), ...eventNames.pages.flatMap((d) => d.items)] : []),
    [eventNames, includeAny]
  );

  const handleEventChange = (data: EventListItem[]) => {
    setItemDataValue(
      filterKey,
      index,
      data.map(({ event_name }) => event_name)
    );
  };

  const getSelectedItems = () => {
    if (filterDataItem.data?.value && Array.isArray(filterDataItem.data?.value)) {
      return filterDataItem.data.value?.map((value) => ({ event_name: value }));
    }

    return [];
  };

  const value = filterDataItem.data.value || [];

  if (!Array.isArray(value)) {
    return null;
  }

  return (
    <Popover>
      {({ open }) => (
        <FloatWrapper placement="bottom-start" offset={4} portal inline={inline}>
          <Popover.Button as={SelectTriggerSmall} open={open}>
            <ComboboxMultipleLabel value={value} />
          </Popover.Button>
          <Popover.Panel>
            <ComboboxMultipleInfinite
              items={flatEventNames}
              selectedItems={getSelectedItems() as any}
              onChange={(data) => {
                handleEventChange(data);
              }}
              onQueryChange={(e: ChangeEvent<HTMLInputElement>) => setRawSearchQuery(e.target.value)}
              query={rawSearchQuery}
              height={450}
              catchInputFocus
              renderOption={(item) => {
                return item?.event_name;
              }}
              by={(a, b) => a?.event_name === b?.event_name}
              isFetching={isFetching}
              hasNextPage={!!hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              fetchNextPage={fetchNextPage}
              isSuccess={isSuccess}
              className={twMerge(inline && 'mt-2')}
            />
          </Popover.Panel>
        </FloatWrapper>
      )}
    </Popover>
  );
};
