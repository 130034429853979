// import { PageHeading } from '../../../components/PageHeading';
import { useQuery } from '@tanstack/react-query';

import { useWorkspace } from '../../auth/hooks/useWorkspace';
// import { Button } from '../../../shared/ui/Button/Button';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
// import { DashboardsTable } from '../components/DashboardsTable.tsx';
import { useEffect } from 'react';
// import { EmptyState } from '../../../shared/components/EmptyState.tsx';
// import PlusIcon from '../../../assets/icons/plus-circle.svg?react';
// import EditIcon from '../../../assets/icons/edit-02.svg?react';
// import RepeatIcon from '../../../assets/icons/repeat-04.svg?react';
// import SearchIcon from '../../../assets/icons/search-md.svg?react';
import { useNavigate } from 'react-router-dom';
import { DashboardsRoutes } from '../../../routes';
import { CenteredLoader } from '../../../shared/components/CenteredLoader.tsx';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

// const EMPTY_STATE_STAGES = [
//   {
//     icon: PlusIcon,
//     title: 'Create a new dashboard',
//     description: 'Start by creating a new blank dashboard.',
//   },
//   {
//     icon: EditIcon,
//     title: 'Customize dashboard',
//     description: 'Add selected saved reports to dashboard.',
//   },
//   {
//     icon: RepeatIcon,
//     title: 'Create next dashboard if needed',
//     description: 'Repeat the process as many times as needed.',
//   },
// ];

export const DashboardsList = () => {
  const queryKeys = useQueryKeys();
  const { currentWorkspaceId } = useWorkspace();
  const navigate = useNavigate();

  const dashboardsQuery = useQuery({
    queryKey: queryKeys.dashboards(),
    queryFn: () => bigdeltaAPIClient.v1.dashboardsList({ workspace_id: currentWorkspaceId }),
  });

  // const isEmpty = !dashboardsQuery?.data?.items || dashboardsQuery?.data?.items.length === 0;

  // const [searchTerm, setSearchTerm] = useState('');

  // const filteredDashboards = useMemo(() => {
  //   return dashboardsQuery?.data?.items.filter((dashboard) => dashboard.name.toLowerCase().includes(searchTerm.toLowerCase()));
  // }, [dashboardsQuery, searchTerm]);

  /*DO NOT REMOVE. Temporarily hiding part of the dashboard's functionality. */
  useEffect(() => {
    const webAnalyticsDashboardId = dashboardsQuery.data?.items?.find((d) => d.is_default && d.name === 'Web Analytics')?.id;

    if (webAnalyticsDashboardId) {
      navigate(`${DashboardsRoutes.INDEX}/${webAnalyticsDashboardId}`);
    }
  }, [dashboardsQuery.data?.items, navigate]);

  return (
    <div className="flex h-screen w-full flex-col">
      <div className="relative flex h-full flex-col">
        {/*DO NOT REMOVE. Temporarily hiding part of the dashboard's functionality. */}

        {/*{!dashboardsQuery.isInitialLoading && (*/}
        {/*  <>*/}
        {/*    <div className="flex items-center justify-between" style={{ zIndex: 1 }}>*/}
        {/*      <PageHeading breadcrumbs={[{ label: 'Dashboards' }]} />*/}
        {/*      <div className="flex items-center gap-x-2">*/}
        {/*        {!isEmpty && (*/}
        {/*          <div className="relative">*/}
        {/*            <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">*/}
        {/*              <SearchIcon className="h-5 w-5 text-m-gray-600" />*/}
        {/*            </div>*/}
        {/*            <input*/}
        {/*              type="text"*/}
        {/*              id="simple-search"*/}
        {/*              className="block w-full rounded-md border border-m-gray-300 p-2 ps-10 text-md"*/}
        {/*              placeholder="Search dashboard"*/}
        {/*              value={searchTerm}*/}
        {/*              onChange={(e) => setSearchTerm(e.target.value)}*/}
        {/*            />*/}
        {/*          </div>*/}
        {/*        )}*/}
        {/*        <Link to={DashboardsRoutes.CREATE_DASHBOARD}>*/}
        {/*          <Button intent={isEmpty ? 'secondary' : 'brand'} size="lg" label="Create dashboard" />*/}
        {/*        </Link>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <hr className="mt-4 h-px bg-m-gray-300" />*/}
        {/*    {isEmpty && (*/}
        {/*      <EmptyState heading="Track the most important metrics at a glance" stages={EMPTY_STATE_STAGES}>*/}
        {/*        <Link to={DashboardsRoutes.CREATE_DASHBOARD}>*/}
        {/*          <Button intent="brand" size="xl" label="Create dashboard" />*/}
        {/*        </Link>*/}
        {/*      </EmptyState>*/}
        {/*    )}*/}
        {/*    {!isEmpty && <DashboardsTable data={filteredDashboards} />}*/}
        {/*    <Outlet />*/}
        {/*  </>*/}
        {/*)}*/}
        {/* <Outlet /> */}
        {dashboardsQuery.isInitialLoading && <CenteredLoader />}
      </div>
    </div>
  );
};
