import React, { FC, HTMLAttributes, useEffect, useMemo } from 'react';
import { useForm, UseFormRegister, FieldErrors } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { NativeSelect } from '../../../../shared/ui/NativeSelect/NativeSelect.tsx';
import { FormElementWrapper } from '../../../../shared/ui/FormElementWrapper/FormElementWrapper.tsx';
import { ResourcePropertyType } from '@bigdelta/lib-shared';
import { Button } from '../../../../shared/ui/Button/Button.tsx';
import { PropertyFormSchema } from './PropertyFormSchema.ts';
import { FormInput } from '../../../../shared/ui/FormInput/FormInput.tsx';
import { mapToUserFriendlyDataType } from '../../utils/propertyTypeMapper.ts';
import { FormCheckbox } from '../../../../shared/ui/FormCheckbox/FormCheckbox.tsx';
import { capitalize } from 'lodash';
import { ObjectsListData } from '@bigdelta/lib-api-client';
import { usePropertyFormData } from '../../hooks/usePropertyFormData';

interface EntityProperty {
  id: string;
  displayName: string;
}

interface Props extends HTMLAttributes<HTMLElement> {
  objectId: string;
  initialValues?: PropertyFormSchema;
  reservedNames: string[];
  onCancel: () => void;
  renderSubmitButton?: (isValid: boolean, handleSubmit: (handler: (data: PropertyFormSchema) => any) => any) => React.ReactNode;
}

const ALLOWED_RELATION_PROPERTY_TYPES = [
  ResourcePropertyType.STRING,
  ResourcePropertyType.NUMBER,
  ResourcePropertyType.STRING_ARRAY,
  ResourcePropertyType.NUMBER_ARRAY,
];

interface NameInputProps {
  register: UseFormRegister<PropertyFormSchema>;
  errors: FieldErrors<PropertyFormSchema>;
}

const NameInput: FC<NameInputProps> = ({ register, errors }) => (
  <FormInput
    label="Name"
    inputProps={{
      ...register('name', { required: true }),
      placeholder: 'Name',
      size: 'sm',
    }}
    size="sm"
    errorMessage={errors.name?.message}
  />
);

interface TypeSelectProps {
  register: UseFormRegister<PropertyFormSchema>;
  errors: FieldErrors<PropertyFormSchema>;
}

const TypeSelect: FC<TypeSelectProps> = ({ register, errors }) => (
  <FormElementWrapper label="Type" errorMessage={errors.type?.message} size="sm">
    <NativeSelect {...register('type', { required: true })} className="text-xs">
      {Object.values(ResourcePropertyType).map((type) => (
        <option key={type} value={type}>
          {mapToUserFriendlyDataType(type)}
        </option>
      ))}
    </NativeSelect>
  </FormElementWrapper>
);

interface EntitySelectProps {
  register: UseFormRegister<PropertyFormSchema>;
  errors: FieldErrors<PropertyFormSchema>;
  eligibleObjects: ObjectsListData['objects'];
  initialValues: Partial<PropertyFormSchema>;
}

const EntitySelect: FC<EntitySelectProps> = ({ register, errors, eligibleObjects, initialValues }) => (
  <FormElementWrapper label="Entity" errorMessage={errors.entity_id?.message} size="sm">
    <NativeSelect {...register('entity_id', { required: true, disabled: !!initialValues?.entity_id })} className="text-xs">
      <option value="">Select object</option>
      {eligibleObjects?.map((object) => (
        <option key={object.id} value={object.id}>
          {capitalize(object.singular_noun)}
        </option>
      ))}
    </NativeSelect>
  </FormElementWrapper>
);

interface PropertySelectProps {
  register: UseFormRegister<PropertyFormSchema>;
  errors: FieldErrors<PropertyFormSchema>;
  objectPropertiesWithoutRelationships: EntityProperty[];
  initialValues: Partial<PropertyFormSchema>;
}

const PropertySelect: FC<PropertySelectProps> = ({ register, errors, objectPropertiesWithoutRelationships, initialValues }) => (
  <FormElementWrapper label="Property" errorMessage={errors.entity_property?.message} size="sm">
    <NativeSelect
      {...register('entity_property', {
        required: true,
        disabled: !!initialValues?.entity_property || objectPropertiesWithoutRelationships?.length === 0,
      })}
      className="text-xs"
    >
      <option value="">Select property</option>
      {objectPropertiesWithoutRelationships?.map((property) => (
        <option key={property.id} value={property.id}>
          {property.displayName}
        </option>
      ))}
    </NativeSelect>
  </FormElementWrapper>
);

export const PropertyForm: FC<Props> = ({ className, objectId, initialValues, reservedNames, onCancel, renderSubmitButton }) => {
  const {
    register,
    control,
    formState: { errors, isValid },
    reset,
    handleSubmit,
    watch,
    setError,
    clearErrors,
  } = useForm<PropertyFormSchema>({
    defaultValues: initialValues,
  });

  const { eligibleObjects, relationshipsByProperty } = usePropertyFormData(objectId);

  useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  const name = watch('name');
  const propertyType = watch('type');
  const isRelationProperty = propertyType === ResourcePropertyType.RELATION || propertyType === ResourcePropertyType.RELATION_ARRAY;
  const selectedEntityId = watch('entity_id');

  useEffect(() => {
    if (reservedNames.includes(name)) {
      setError('name', { type: 'custom', message: `Such property already exists!` });
    } else {
      clearErrors('name');
    }
  }, [name, reservedNames, setError, clearErrors]);

  const selectedEntity = useMemo(() => {
    return eligibleObjects?.find((o) => o.id === selectedEntityId);
  }, [selectedEntityId, eligibleObjects]);

  const existingRelationships = relationshipsByProperty.get(name);

  const objectPropertiesWithoutRelationships: EntityProperty[] = useMemo(() => {
    const existingRelationshipsForSelectedObject = existingRelationships
      ?.filter((r) => r.related_entity_id === selectedEntity?.id)
      .map((r) => r.related_entity_property);

    return selectedEntity?.properties
      ?.filter((i) => ALLOWED_RELATION_PROPERTY_TYPES.includes(i.type as ResourcePropertyType))
      .map((i) => {
        return { id: i.name, displayName: i.name };
      })
      .filter((i) => !existingRelationshipsForSelectedObject?.includes(i.id));
  }, [selectedEntity, existingRelationships]);

  return (
    <div className={twMerge('relative flex flex-col gap-4 px-6 pb-6', className)}>
      <NameInput register={register} errors={errors} />
      <TypeSelect register={register} errors={errors} />
      {isRelationProperty && (
        <>
          <EntitySelect register={register} errors={errors} eligibleObjects={eligibleObjects} initialValues={initialValues} />
          <PropertySelect
            register={register}
            errors={errors}
            objectPropertiesWithoutRelationships={objectPropertiesWithoutRelationships}
            initialValues={initialValues}
          />
        </>
      )}
      <FormCheckbox
        name="show_in_record_creation_flow"
        control={control}
        label="Show during creation"
        size="sm"
        errorMessage={errors.name?.message}
      />
      <div className="flex justify-end gap-x-2">
        <Button size="sm" intent="tertiary" onClick={onCancel} label="Cancel" />
        {renderSubmitButton && renderSubmitButton(isValid && !errors.name?.message && !errors.type?.message, (handler) => handleSubmit(handler))}
      </div>
    </div>
  );
};
