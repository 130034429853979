import { FC, useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import { ObjectsRecordDetailData, ObjectsRecordsChangesDetailData } from '@bigdelta/lib-api-client';
import * as Feed from '../../../shared/ui/Feed/Feed';

import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useObjectQuery } from '../../../shared/data/useObjectQuery';
import { ChangeItem } from './ChangeItem.tsx';

const groupChangesByMonth = (changes: ObjectsRecordsChangesDetailData | undefined): Record<string, ObjectsRecordsChangesDetailData> => {
  if (!changes) {
    return {};
  }

  return changes.reduce(
    (acc, change) => {
      const monthKey = format(parseISO(change.modified_at), 'MMMM yyyy');
      if (!acc[monthKey]) {
        acc[monthKey] = [];
      }
      acc[monthKey].push(change);
      return acc;
    },
    {} as Record<string, ObjectsRecordsChangesDetailData>
  );
};

interface RecordHistoryProps {
  record: ObjectsRecordDetailData;
}

export const RecordHistory: FC<RecordHistoryProps> = ({ record }) => {
  const { remoteRecordId } = useParams();
  const { objectSlug } = useParams();
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  const { data: object } = useObjectQuery({ objectSlug: objectSlug ?? '', workspaceId: currentWorkspaceId });

  const changesQuery = useQuery({
    queryKey: queryKeys.single('object-record', remoteRecordId, 'history'),
    queryFn: () => bigdeltaAPIClient.v1.objectsRecordsChangesDetail(remoteRecordId!, objectSlug!, { workspace_id: currentWorkspaceId }),
    enabled: !!remoteRecordId && !!objectSlug && !!currentWorkspaceId,
  });

  const groupedChanges = useMemo(() => groupChangesByMonth(changesQuery.data), [changesQuery.data]);

  if (!object) return null;

  return (
    <Feed.Root>
      {Object.entries(groupedChanges).map(([month, monthChanges]) => (
        <Feed.ItemGroup key={month} title={month}>
          {monthChanges.map((change, index) => (
            <ChangeItem key={index} change={change} object={object} record={record} />
          ))}
        </Feed.ItemGroup>
      ))}
    </Feed.Root>
  );
};
