import { FC, useRef, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import * as Tabs from '../../../../shared/ui/Tabs/Tabs';

import ListIcon from '../../../../assets/icons/list.svg?react';
import CursorBoxIcon from '../../../../assets/icons/cursor-box.svg?react';
import CursorIcon from '../../../../assets/icons/cursor-click-01.svg?react';

import {
  ListItem,
  RecordPropertyMultilevelSelectContent,
  RecordPropertyMultilevelSelectContentProps,
} from '../../../../shared/components/RecordPropertyMultilevelSelectContent';
import { RelationshipEntityType } from '@bigdelta/lib-shared';
import { useTrendsBuilderStore } from '../../store/Report';
import { TrendsBuilderType } from '../../store/TrendsBuilder';
import { RecordPropertyIcon } from '../../../../shared/components/RecordPropertyIcon';
import { capitalize } from 'lodash';
import { useObjectsQuery } from '../../../../shared/data/useObjectsQuery';
import { useWorkspace } from '../../../auth/hooks/useWorkspace';
import { Tooltip } from '../../../../shared/ui/Tooltip/Tooltip';
import { useFilterContext } from '../../../../shared/filters/context/useFilterContext';
import { getFilter, useFilterStore } from '../../../../shared/filters/store';
import { EventCountData } from '../../store/common';
import { EventPropertyCountCombobox } from '../common/EventPropertyCountCombobox';
import { createMathPropertyDetails } from '../../utils/trends/createMathPropertyDetails';
import { createDisplayMathProperty } from '../../utils/trends/createDisplayMathProperty';

interface TrendsCountPropertySelectTriggerProps {
  id: string;
}

// TODO: Add tooltip
const TrendsCountPropertySelectTrigger: FC<TrendsCountPropertySelectTriggerProps> = ({ id }) => {
  const { currentWorkspaceId } = useWorkspace();

  const count = useTrendsBuilderStore(id, (state) => state.data[TrendsBuilderType.OBJECT]?.count);

  const { data: objectList } = useObjectsQuery({ workspaceId: currentWorkspaceId });

  if (count?.type === 'record' && count.record) {
    if (count.record.type === 'property' && count.record.data.property && count.record.data.relationships) {
      const mathProperty = createDisplayMathProperty(count.record.data.property);

      const relatedObjects = count.record.data.relationships.map((rel) => objectList?.objects.find((o) => o.id === rel.objectId)).filter(Boolean);

      const relatedObjectNames = relatedObjects.map((o) => capitalize(o?.singular_noun));

      const tooltipText = `${relatedObjectNames.join(' -> ')}  ->  ${count.record.data.property.property_name}`;

      return (
        <Tooltip content={tooltipText}>
          <div className="flex items-center gap-x-2 overflow-hidden truncate whitespace-nowrap">
            <RecordPropertyIcon className="h-4 w-4 shrink-0 text-m-olive-300" propertyType={mathProperty.property_type} />
            <span className="min-w-0 shrink-0 grow basis-0 overflow-hidden text-ellipsis whitespace-nowrap text-m-olive-300">
              {relatedObjectNames.join(' -> ')}
              {' -> '}
            </span>
            <span className="shrink grow-0 overflow-hidden text-ellipsis whitespace-nowrap accent-m-olive-700">{mathProperty.property_name}</span>
          </div>
        </Tooltip>
      );
    }

    if (count.record.type === 'total') {
      return (
        <>
          <ListIcon className="h-4 w-4 text-m-olive-300" />
          Total Records
        </>
      );
    }
  }

  if (count?.type === 'event' && count.event) {
    if (count.event.type === 'allEvents') {
      return (
        <>
          <CursorBoxIcon className="h-4 w-4 text-m-olive-300" />
          All Events
        </>
      );
    }

    if (count.event.type === 'event' && count.event.data.event) {
      return (
        <>
          <CursorIcon className="h-4 w-4 text-m-olive-300" />
          <span className="min-w-0 shrink overflow-hidden truncate text-left">{count.event.data.event}</span>
        </>
      );
    }

    const tooltipText = `${count.event.data.property?.event}  ->  ${count.event.data.property?.property.property_name}`;

    if (count.event.type === 'property' && count.event.data.property) {
      const mathProperty = createDisplayMathProperty(count.event.data.property.property);

      return (
        <Tooltip content={tooltipText}>
          <div className="flex items-center gap-x-2 overflow-hidden truncate whitespace-nowrap">
            <RecordPropertyIcon className="h-4 w-4 shrink-0 text-m-olive-300" propertyType={mathProperty.property_type} />
            <span className="min-w-0 shrink-0 grow basis-0 overflow-hidden text-ellipsis whitespace-nowrap text-m-olive-300">
              {count.event.data.property.event}
            </span>
            <span className="whitespace-nowrap text-m-olive-300">{' -> '}</span>
            <span className="max-w-fit shrink grow-0 overflow-hidden text-ellipsis whitespace-nowrap text-left accent-m-olive-700">
              {mathProperty.property_name}
            </span>
          </div>
        </Tooltip>
      );
    }
  }

  return null;
};

interface TrendsCountPropertySelectProps {
  id: string;
}

export const TrendsCountPropertySelect: FC<TrendsCountPropertySelectProps> = ({ id }) => {
  const workspaceObjectId = useTrendsBuilderStore(id, (state) => state.data[TrendsBuilderType.OBJECT]?.workspaceObjectId);

  const { filterKey } = useFilterContext();
  const filterItems = useFilterStore((state) => getFilter(state, filterKey)?.items) ?? [];
  const removeFilterItem = useFilterStore((state) => state.removeItem);

  const setCountRecordData = useTrendsBuilderStore(id, (state) => state.setCountRecordData);
  const setCountEventData = useTrendsBuilderStore(id, (state) => state.setCountEventData);
  const [tab, setTab] = useState<'records' | 'events' | string>('records');
  const [recordSearch, setRecordSearch] = useState('');
  const tabsRef = useRef<HTMLDivElement | null>(null);
  const closeRef = useRef<HTMLButtonElement | null>(null);

  const close = () => {
    closeRef.current?.click();
  };

  const handleRecordPropertySelect: RecordPropertyMultilevelSelectContentProps['onChange'] = (property, relationships) => {
    if (!relationships || !property) {
      close();
      return;
    }

    const mathProperty = createMathPropertyDetails(property);

    setCountRecordData({
      type: 'property',
      data: {
        property: mathProperty,
        relationships,
      },
    });
    close();
  };

  const handleRecordTotalSelect = () => {
    setCountRecordData({
      type: 'total',
      data: {},
    });
    close();
  };

  const handleChangeEventCountData = (eventCountData: EventCountData | null) => {
    if (!eventCountData) {
      close();
      return;
    }

    setCountEventData(eventCountData);

    filterItems.forEach((_, index) => {
      removeFilterItem(filterKey, index);
    });

    close();
  };

  if (!workspaceObjectId) {
    return null;
  }

  return (
    <Popover.Root>
      <Popover.Trigger className="flex min-w-0 shrink grow items-center gap-x-2 overflow-hidden truncate whitespace-nowrap rounded-l-lg px-3 py-2 text-sm font-medium hover:bg-m-gray-200 radix-state-open:bg-m-gray-300">
        <TrendsCountPropertySelectTrigger id={id} />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          sideOffset={4}
          className="flex max-h-[--radix-popover-content-available-height] w-[22rem] flex-col overflow-hidden rounded-lg border border-m-olive-100 bg-m-white shadow-md"
          onOpenAutoFocus={() => {
            setTimeout(() => tabsRef?.current?.focus());
          }}
          collisionPadding={4}
          align="start"
        >
          <Popover.Close className="hidden" ref={closeRef} />
          <Tabs.Root value={tab} onValueChange={setTab} className="flex flex-col overflow-hidden">
            <Tabs.List ref={tabsRef} className="flex shrink-0 border-b border-b-m-olive-100 px-2">
              <Tabs.Trigger value="records">
                <ListIcon className="h-4 w-4 shrink-0 text-m-olive-400" />
                Records
              </Tabs.Trigger>
              <Tabs.Trigger value="events">
                <CursorBoxIcon className="h-4 w-4 shrink-0 text-m-olive-400" />
                Events
              </Tabs.Trigger>
            </Tabs.List>

            <Tabs.Content value="records" className="flex flex-col overflow-hidden">
              <RecordPropertyMultilevelSelectContent
                searchQuery={recordSearch}
                setSearchQuery={setRecordSearch}
                parentEntityType={RelationshipEntityType.OBJECT}
                parentEntityId={workspaceObjectId}
                onChange={handleRecordPropertySelect}
                className="border-0"
                topOptions={
                  <ListItem onClick={handleRecordTotalSelect}>
                    <div className="flex items-center gap-x-2">
                      <ListIcon className="h-4 w-4 shrink-0 text-m-olive-600" />
                      <span className="overflow-hidden text-ellipsis whitespace-nowrap text-left text-sm leading-tight text-m-olive-800">
                        Total records
                      </span>
                    </div>
                  </ListItem>
                }
              />
            </Tabs.Content>

            <Tabs.Content value="events" className="flex flex-col overflow-hidden">
              <div className="flex flex-col overflow-hidden p-2">
                <EventPropertyCountCombobox onChange={handleChangeEventCountData} />
              </div>
            </Tabs.Content>
          </Tabs.Root>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
