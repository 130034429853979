import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AppRoutes } from '../../../routes';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CenteredLoader } from '../../../shared/components/CenteredLoader.tsx';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { toastError } from '../../../utils/toast.tsx';
import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

export const IntegrationsSlackAuthPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();
  const { currentWorkspaceId } = useWorkspace();

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const errorDescription = searchParams.get('error_description');

  const createSlackIntegrationMutation = useMutation({
    mutationFn: (code: string) => bigdeltaAPIClient.v1.integrationsSlackCreate({ workspace_id: currentWorkspaceId }, { code }),
    onSuccess: (data) => {
      queryClient.invalidateQueries(queryKeys.integrations()).then(() => navigate(`${AppRoutes.INTEGRATIONS}/${data.id}`));
    },
    onError: () => {
      toastError('An error occurred while creating Slack integration');
      navigate(AppRoutes.INTEGRATIONS);
    },
  });

  useEffect(() => {
    if (code) {
      createSlackIntegrationMutation.mutate(code);
    } else {
      if (errorDescription) {
        toastError(errorDescription);
      } else {
        toastError('An error occurred while creating Slack integration');
      }
      navigate(AppRoutes.INTEGRATIONS);
    }
  }, [code, createSlackIntegrationMutation, errorDescription, navigate]);

  return <CenteredLoader />;
};
