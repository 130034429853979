import { Chart, ChartType, TooltipModel } from 'chart.js';
import { HTMLAttributes, useCallback, useMemo, useRef, useState } from 'react';
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react';

import { twMerge } from '../../../utils/twMerge';
import { PropsWithClassName } from '../../../types';
import { useMemoBuffer } from '../../../shared/hooks/useMemoBuffer';

type TooltipContext<T extends ChartType> = { chart: Chart; tooltip: TooltipModel<T> };

interface TooltipProps<T extends ChartType> extends HTMLAttributes<HTMLDivElement>, PropsWithClassName {
  render: (context: TooltipModel<T>) => JSX.Element;
}

export type ExternalTooltipHandler<T extends ChartType> = (context: TooltipContext<T>) => void;

interface HandlerOptions {
  isStatic: boolean;
}

export const useExternalChartTooltip = <T extends ChartType>({ isStatic = false }: HandlerOptions = { isStatic: false }) => {
  const [label, setLabel] = useState({ x: 0, y: 0 });
  const [opacity, setOpacity] = useState(0);
  const [tooltipContext, setTooltipContext] = useState<TooltipModel<T>>();

  const isMouseOver = useRef(false);
  const [destroyTimer, setDestroyTimer] = useState<NodeJS.Timeout>();

  const { refs, floatingStyles } = useFloating({
    placement: isStatic ? 'top-start' : 'bottom-start',
    open: true,
    middleware: [offset(({ rects }) => ({ alignmentAxis: -(rects.floating.width / 2), mainAxis: 8 })), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const floatingProps = useMemoBuffer({ style: floatingStyles, ref: refs.setFloating });
  const containerProps = useMemoBuffer({ ref: refs.setReference });

  const externalTooltipHandler = useCallback(
    (context: TooltipContext<T>) => {
      const { chart, tooltip } = context;
      const isHidden = tooltip.opacity === 0;
      const { caretX, caretY } = tooltip;
      const { offsetLeft, offsetTop } = chart.canvas;
      const x = offsetLeft + caretX;
      const y = offsetTop + caretY;
      const isTooltipPresent = !!tooltip.x && !!tooltip.y;

      const element = tooltip.dataPoints?.[0].element;
      const width = (element as unknown as { width: number })?.width || 0;

      const isStaticPositionSame = label.x === element?.x + width / 2 && label.y === element?.y;
      const isTooltipRendered = opacity !== 0;

      if (isMouseOver.current && destroyTimer) {
        clearTimeout(destroyTimer);
        setDestroyTimer(undefined);
      } else if (isHidden && isTooltipRendered && !isMouseOver.current && !destroyTimer && tooltipContext) {
        setDestroyTimer(
          setTimeout(() => {
            if (!isMouseOver.current) {
              setOpacity(0);
              setLabel({ x: 0, y: 0 });
              setTooltipContext(undefined);
            }
            setDestroyTimer(undefined);
          }, 300)
        );
      }

      if (!isHidden) {
        setOpacity(tooltip.opacity);
      }

      if ((label?.x !== x || label?.y !== y) && isTooltipPresent && !isStatic) {
        setLabel({ x, y });
        setTooltipContext(tooltip);
      }

      if (isStatic && !isStaticPositionSame && isTooltipPresent) {
        const width = (element as unknown as { width: number }).width;

        setLabel({
          x: offsetLeft + element?.x + width / 2,
          y: offsetTop + element?.y,
        });
        setTooltipContext(tooltip);
      }
    },
    [isMouseOver, destroyTimer, label, opacity, isStatic, tooltipContext]
  );

  const Tooltip = useMemo(
    () =>
      ({ render, className, ...rest }: TooltipProps<T>) => (
        <div className="absolute" style={{ left: label?.x || 0, top: label?.y || 0 }} {...containerProps}>
          <div
            className={twMerge(
              'flex -translate-x-16 translate-y-2 items-start justify-start rounded-md bg-m-gray-200 p-2 text-center text-sm font-medium text-m-black shadow-md',
              opacity === 1 ? 'opacity-100' : 'opacity-0',
              className
            )}
            {...floatingProps}
            {...rest}
            onMouseEnter={() => {
              isMouseOver.current = true;
            }}
            onMouseLeave={() => {
              isMouseOver.current = false;
            }}
          >
            {tooltipContext ? render(tooltipContext) : null}
          </div>
        </div>
      ),
    [floatingProps, label, opacity, containerProps, tooltipContext]
  );

  return { externalTooltipHandler, Tooltip };
};
