import { FC } from 'react';
import { Input } from '../../../shared/ui/Input/Input';
import { TimeUnit } from '@bigdelta/lib-shared';

const TIME_UNIT_VALUES = [
  {
    label: 'Hour',
    value: TimeUnit.HOUR,
  },
  {
    label: 'Day',
    value: TimeUnit.DAY,
  },
  {
    label: 'Week',
    value: TimeUnit.WEEK,
  },
  {
    label: 'Month',
    value: TimeUnit.MONTH,
  },
];

interface ConversionWindowSelectProps {
  value?: number | null;
  timeUnit?: TimeUnit | null;

  onChangeValue: (value: number) => void;
  onChangeTimeUnit: (timeUnit: TimeUnit) => void;
}

export const ConversionWindowSelect: FC<ConversionWindowSelectProps> = ({ value, timeUnit, onChangeValue, onChangeTimeUnit }) => {
  return (
    <div className="flex flex-col gap-y-2">
      <div>Conversion window</div>
      <div className="flex gap-x-2">
        <Input
          type="number"
          min={1}
          placeholder="Number of time units"
          className="w-6/12"
          value={value as number}
          onChange={(evt) => onChangeValue(Number(evt.target.value))}
        />
        <select
          value={timeUnit as string}
          className="w-6/12 rounded-lg border border-m-olive-100 text-sm"
          onChange={(evt) => onChangeTimeUnit(evt.target.value as TimeUnit)}
        >
          {TIME_UNIT_VALUES.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
