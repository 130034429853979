import { ObjectsDetailData, ObjectsRecordDetailData } from '@bigdelta/lib-api-client';
import { FC } from 'react';
import { RecordName } from './RecordName';
import { PropertyName } from './PropertyName';

import { useOptimisticObjectRecordMutation } from '../../../shared/data/useOptimisticObjectRecordMutation';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { ResourcePropertyType } from '@bigdelta/lib-shared';
import { REMOTE_ID } from '../const';
import { get } from 'lodash';
import { PropertyValue } from './PropertyValue';

interface PropertiesTableProps {
  object: ObjectsDetailData;
  record: ObjectsRecordDetailData;
}

// TODO: Fix ObjectsDetailData type in bigdeltaClient
interface ObjectProperty {
  type: ResourcePropertyType;
  name: string;
  properties?: ObjectProperty[];
}

const PropertiesTable = ({ record, object }: PropertiesTableProps) => {
  const queryKeys = useQueryKeys();
  const notTitleOrLogo = (objectProperty: ObjectProperty) =>
    !object.label_properties.includes(objectProperty.name) && objectProperty.name !== object.icon_property;

  const flattenObject = (acc: ObjectProperty[], objectProperty: ObjectProperty) => {
    const rename = (el: ObjectProperty) => ({ ...el, name: `${objectProperty.name}.${el.name}` });

    if (objectProperty.type === ResourcePropertyType.OBJECT) {
      return [...acc, ...(objectProperty.properties?.reduce(flattenObject, []) || []).map(rename)];
    }

    return [...acc, objectProperty];
  };

  const properties = (object.properties as ObjectProperty[] | null)
    ?.filter(notTitleOrLogo)
    .reduce(flattenObject, [])
    .map((objectProperty) => ({
      schema: objectProperty,
      value: get(record.properties, objectProperty.name),
    }));

  const handleUpdateProperty = useOptimisticObjectRecordMutation({
    id: record.id,
    objectSlug: object.api_slug,
    workspaceId: object.workspace_id,
    key: queryKeys.single('object-record', record.id),
  });

  return properties?.map(({ schema, value }) => {
    const propertyValue = schema.name === REMOTE_ID ? record.id : value;

    return (
      <div className="flex items-center" key={schema.name}>
        <div className="mr-2 w-2/5 text-ellipsis capitalize text-m-olive-500">
          <PropertyName type={schema.type} propertyName={schema.name} />
        </div>
        <div className="flex w-3/5 min-w-32 flex-wrap items-center justify-start gap-2 text-m-olive-600">
          <PropertyValue
            overflowVisible
            value={propertyValue}
            propertyType={schema.type}
            onChange={(value) => {
              handleUpdateProperty(schema.name, value);
            }}
            disabled={schema.name === REMOTE_ID}
          />
        </div>
      </div>
    );
  });
};

interface RecordPropertiesProps {
  record: ObjectsRecordDetailData;
  object: ObjectsDetailData;
}

export const RecordProperties: FC<RecordPropertiesProps> = ({ record, object }) => (
  <>
    <h4 className="w-full border-b border-m-olive-100 p-4 text-xs font-medium leading-none text-m-olive-600">
      <div className="flex h-4 items-center">Record Details</div>
    </h4>
    <div className="flex min-w-0 flex-1 grow basis-0 flex-col gap-y-2 overflow-y-auto border-r border-m-olive-100 p-4 text-sm">
      <RecordName object={object} record={record} />
      <PropertiesTable object={object} record={record} />
    </div>
  </>
);
