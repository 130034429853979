import { FC, useRef, useState } from 'react';
import { TagsEdit } from './TagsEdit';
import { TagsView } from './TagsView';
import { EditablePropertyType } from '../types';
import { twMerge } from '../../../utils/twMerge';

type TagsProps = EditablePropertyType<Array<string>>;

export const Tags: FC<TagsProps> = ({ value, onChange, disabled }) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleContainerClick = () => {
    setIsEditing(true);
    inputRef.current?.focus();
  };

  const handleChange = (val: Array<string>) => {
    onChange?.(val.length ? val : undefined);
  };

  return (
    <div
      className={twMerge('grow rounded-md border-0 px-2 py-1.5 focus-within:ring-2 focus-within:ring-m-blue-400', !disabled && 'hover:bg-m-gray-300')}
      onClick={handleContainerClick}
    >
      {isEditing ? (
        <TagsEdit onClose={() => setIsEditing(false)} value={value ?? []} onChange={handleChange} ref={inputRef} editable={!disabled} />
      ) : (
        <TagsView value={value ?? []} />
      )}
    </div>
  );
};
