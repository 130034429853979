import ColorHash from 'color-hash';
import Color from 'color';
import { forwardRef, HTMLProps } from 'react';

import { NestedObjectDef } from '@bigdelta/lib-api-client';
import { PropsWithClassName } from '../../types';
import { Badge } from './Badge';

import CrossIcon from '../../assets/icons/x-close.svg?react';

type TagValue = Extract<NestedObjectDef[string], any[]>[number];

const colorHash = new ColorHash({
  lightness: [0.85, 0.9],
  saturation: [0.5, 0.6],
});

const getTagStyle = (tag: TagValue) => {
  const tagString = String(tag);
  const bgColor = Color(colorHash.hex(tagString));
  const borderColor = bgColor.darken(0.1);
  const textColor = bgColor.darken(0.75);

  return {
    backgroundColor: bgColor.hex(),
    borderColor: borderColor.hex(),
    color: textColor.hex(),
    borderWidth: '1px',
    borderStyle: 'solid',
  };
};

interface TagProperties extends PropsWithClassName, Omit<HTMLProps<HTMLSpanElement>, 'value'> {
  value: TagValue;
  onRemove?: (value: TagValue) => void;
}

export const Tag = forwardRef<HTMLDivElement, TagProperties>(({ value, onRemove, className, ...rest }, ref) => {
  return (
    <Badge {...rest} title={value as string} style={getTagStyle(value)} ref={ref} className={`flex items-center gap-x-1 ${className}`}>
      <span className="shrink truncate">{value}</span>
      {onRemove && (
        <button onClick={() => onRemove(value)} className="shrink-0">
          <CrossIcon className="h-2 w-2" />
        </button>
      )}
    </Badge>
  );
});
