import { forwardRef, SelectHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { cva, VariantProps } from 'class-variance-authority';

const selectVariants = cva('', {
  variants: {
    size: {
      xl: 'text-lg',
      lg: 'text-md',
      md: 'text-sm',
      sm: 'text-sm',
      xs: 'text-xs',
    },
  },
  defaultVariants: {
    size: 'lg',
  },
});

export type NativeSelectProps = Omit<SelectHTMLAttributes<HTMLSelectElement>, 'size'> & VariantProps<typeof selectVariants>;

export const NativeSelect = forwardRef<HTMLSelectElement, NativeSelectProps>(({ className, size, ...rest }, ref) => (
  <select
    ref={ref}
    className={twMerge(
      'rounded-lg border-m-olive-100 px-4 py-2 focus:border-m-blue-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-m-olive-500',
      selectVariants({ size }),
      className
    )}
    {...rest}
  />
));
