import { EventsQueryRequestQueryDef, QueryEventsCreatePayload } from '@bigdelta/lib-api-client';
import { FilterItem, FilterItemType } from '../../../shared/filters/store';
import { getEventPropertyOperator } from '../../../shared/utils/getEventPropertyOperator';
import { getRelatedRecordConditions } from '../../../shared/utils/getRelatedRecordConditions';
import { QueryValueFilterOperator } from '@bigdelta/lib-shared';
import { getPropertyCondition } from '../../../shared/filters/utils/getPropertyCondition';

export const getConditions = (filterItems: FilterItem[] | null): EventsQueryRequestQueryDef['conditions'] => {
  if (!filterItems?.length) {
    return [];
  }

  return filterItems.reduce<EventsQueryRequestQueryDef['conditions']>(
    (acc, item) => {
      if (item.itemType === FilterItemType.EVENTS_NAME) {
        const operator = getEventPropertyOperator(item.propertyOperator);
        if (item.data?.value && operator) {
          return [
            ...acc,
            {
              event_name: {
                operator: operator,
                value: item.data.value,
              },
            },
          ];
        }
        return acc as any;
      }

      if (item.itemType === FilterItemType.EVENTS_PROPERTY) {
        const condition = { event_property: getPropertyCondition(item) };
        if (condition) {
          return [...acc, condition];
        }
        return acc;
      }

      if (item.itemType === FilterItemType.EVENTS_RECORD_PROPERTY) {
        const property = item.property;
        const relationships = item.propertyRelationships.filter((r) => r.relationshipName !== null);
        if (
          property &&
          relationships.length &&
          item.propertyOperator &&
          [QueryValueFilterOperator.IS_SET, QueryValueFilterOperator.NOT_IS_SET].includes(item.propertyOperator)
        ) {
          return [...acc, ...getRelatedRecordConditions(relationships, getPropertyCondition(item))];
        }
        if (property && item.data?.value && relationships.length && item.propertyOperator) {
          return [...acc, ...getRelatedRecordConditions(relationships, getPropertyCondition(item))];
        }
      }

      if (item.itemType === FilterItemType.EVENTS_OBJECT) {
        return [...acc, ...getRelatedRecordConditions(item.propertyRelationships, getPropertyCondition(item))];
      }

      return acc;
    },
    [] as EventsQueryRequestQueryDef['conditions']
  );
};

export const getEventsQuery = (stateFilterItems: FilterItem[] | null): QueryEventsCreatePayload['query'] => {
  const conditions = getConditions(stateFilterItems);
  return conditions.length ? { filter: { conditions, operator: 'and' as const } } : undefined;
};
