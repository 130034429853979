import { NestedObjectDef, ObjectsDetailData } from '@bigdelta/lib-api-client';
import { RelationType } from '../../../features/records/hooks/useRecordsTableColumns/utils/resolveRelations';
import { FC } from 'react';
import { getRecordLabel } from '../../utils/getRecordLabel';
import { ResourcePropertyType } from '@bigdelta/lib-shared';
import { useShowContentClick } from '../../hooks/useShowContentClick';
import { FloatingPortal } from '@floating-ui/react';
import { twMerge } from '../../../utils/twMerge';
import { CurrencyView } from '../../properties/Currency/CurrencyView';
import { NumberTextView } from '../../properties/NumberText/NumberTextView';
import { TagsView } from '../../properties/Tags/TagsView';
import { DateTimeView } from '../../properties/Datetime/DateTimeView';
import { TextView } from '../../properties/Text/TextView';
import { Checkbox } from '@radix-ui/react-checkbox';
import { isCurrencyOrEmpty, isNumberOrEmpty, isBooleanOrEmpty, isStringArrayOrEmpty, isStringOrEmpty } from '../../properties/utils';
import { RecordIcon } from '../../components/RecordIcon';
import { Divider } from '../../ui/Divider/Divider';
import { ConditionalWrap } from '../../utils/ConditionalWrap';
import { generatePath, Link } from 'react-router-dom';
import { RecordsRoutes } from '../../../routes';
import { CellContentDefault } from './CellContentDefault';

interface RelationPropertyProps {
  value: NestedObjectDef | NestedObjectDef[string] | undefined;
  propertyType: ResourcePropertyType | undefined;
  compact: boolean;
}

const RelationProperty: FC<RelationPropertyProps> = ({ value, propertyType, compact }) => {
  if (!propertyType) {
    return null;
  }

  if (propertyType === ResourcePropertyType.CURRENCY && isCurrencyOrEmpty(value)) {
    return <CurrencyView value={value} />;
  }

  if (propertyType === ResourcePropertyType.NUMBER && isNumberOrEmpty(value)) {
    return <NumberTextView value={value} />;
  }

  if (propertyType === ResourcePropertyType.BOOLEAN && isBooleanOrEmpty(value)) {
    return (
      <div className="grow rounded-md border-0 px-2 py-1.5 focus-within:ring-2 focus-within:ring-m-blue-400 hover:bg-m-gray-300">
        <Checkbox checked={value ?? undefined} />;
      </div>
    );
  }

  if (propertyType === ResourcePropertyType.STRING_ARRAY && isStringArrayOrEmpty(value)) {
    return <TagsView value={value ?? []} showAll={true} className={compact ? 'flex-nowrap overflow-hidden' : ''} />;
  }

  if ([ResourcePropertyType.DATETIME64, ResourcePropertyType.STRING].includes(propertyType) && isStringOrEmpty(value)) {
    switch (propertyType) {
      case ResourcePropertyType.DATETIME64:
        return <DateTimeView value={value} />;
      case ResourcePropertyType.STRING:
      default:
        return <TextView value={value} overflowVisible={true} />;
    }
  }

  return <CellContentDefault text={JSON.stringify(value)} />;
};

interface CellContentRelationProps {
  relation: RelationType;
  property: { property_id: string; property_name: string };
  object: ObjectsDetailData;
  compact: boolean;
}

const CellContentRelation: FC<CellContentRelationProps> = ({ relation, property, object, compact }) => {
  const propertyType = object.properties?.find((prop) => prop.name === property.property_name)?.type;

  if (!relation.id) {
    return null;
  }

  return (
    <div
      className={twMerge(
        'flex min-w-16 max-w-60 flex-col gap-1 overflow-hidden rounded-md border border-m-olive-100 p-0.5 px-1 text-xs',
        compact ? 'flex-row' : 'flex-col'
      )}
    >
      <ConditionalWrap
        condition={!compact}
        wrap={(children) => (
          <Link to={generatePath(RecordsRoutes.VIEW_RECORD, { objectSlug: object.api_slug, remoteRecordId: relation.id })} className="block truncate">
            {children}
          </Link>
        )}
      >
        <div className="flex items-center gap-1 text-m-olive-400">
          <RecordIcon record={relation} object={object} className="h-3.5 w-3.5" />
          {!compact && (
            <div className="shrink truncate">{getRecordLabel(object.label_properties, { ...relation, id: relation.id ?? undefined })}</div>
          )}
        </div>
      </ConditionalWrap>
      {!compact && <Divider />}
      <div className="flex flex-row items-center">
        <RelationProperty
          value={relation.properties?.[property.property_name]}
          propertyType={propertyType as ResourcePropertyType | undefined}
          compact={compact}
        />
      </div>
    </div>
  );
};

interface CellContentRelationPropertiesProps {
  property: { property_id: string; property_name: string };
  relations: RelationType[];
  workspaceObject: ObjectsDetailData;
}

export const CellContentRelationProperties = ({ property, relations, workspaceObject }: CellContentRelationPropertiesProps) => {
  const { floatingPortalContentProps, referenceProps, isOpen } = useShowContentClick();

  floatingPortalContentProps.className = twMerge(
    floatingPortalContentProps.className,
    'flex h-fit w-full flex-wrap gap-1 rounded-md focus-within:ring-2 focus-within:ring-m-blue-400 hover:bg-m-gray-300'
  );

  return (
    <div
      className="h-full grow rounded-md  border-0 px-2 py-0.5 focus-within:ring-2 focus-within:ring-m-blue-400 hover:bg-m-gray-300"
      {...referenceProps}
    >
      <div className="flex h-full gap-1 overflow-hidden">
        {relations.map((relation) => (
          <CellContentRelation key={relation.id} relation={relation} property={property} object={workspaceObject} compact={true} />
        ))}
      </div>
      {isOpen && (
        <FloatingPortal>
          <div {...floatingPortalContentProps}>
            {relations.map((relation) => (
              <CellContentRelation key={relation.id} relation={relation} property={property} object={workspaceObject} compact={false} />
            ))}
          </div>
        </FloatingPortal>
      )}
    </div>
  );
};
