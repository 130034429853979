import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../../routes';
import { tracking, TrackingEvent } from '../../../../tracking';
import { Button } from '../../../../shared/ui/Button/Button.tsx';
import { UserContext } from '../../../auth/context/userContext.tsx';
import { NativeSelect } from '../../../../shared/ui/NativeSelect/NativeSelect.tsx';
import { FormInput } from '../../../../shared/ui/FormInput/FormInput.tsx';
import { handleFormErrorResponse } from '../../../../utils/handleFormErrorResponse.ts';
import { FormElementWrapper } from '../../../../shared/ui/FormElementWrapper/FormElementWrapper.tsx';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient.ts';

const companySizeOptions = ['1 - 10', '11 - 50', '51 - 250', '> 251'];

interface MemberOnboardingFormSchema {
  name: string;
  website: string;
  company_size: string;
}

export const AccountOnboardingPage = () => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError: setFieldError,
    formState: { isValid, errors },
  } = useForm<MemberOnboardingFormSchema>({
    defaultValues: {
      company_size: companySizeOptions[0],
    },
  });

  const onSubmit: SubmitHandler<MemberOnboardingFormSchema> = async (data) => {
    setLoading(true);

    try {
      setError('');

      try {
        await bigdeltaAPIClient.v1.accountsOnboardingCreate(data);

        if (userContext.fetchUser) {
          const result = await userContext.fetchUser();

          if (result) {
            setError('');
            tracking.track(TrackingEvent.SignupStepThreeCompleted);
            navigate(AppRoutes.ROOT);
          }
        }
      } catch (e: any) {
        handleFormErrorResponse(e, setError, setFieldError);
      }
    } finally {
      setLoading(false);
    }
  };

  if (userContext.user && userContext.authenticated && userContext.ready && userContext?.accounts?.length) {
    return <Navigate to={AppRoutes.ROOT} />;
  }

  return (
    <div className="relative">
      <div className="absolute flex h-full min-h-full w-screen items-center justify-center bg-m-coconut-600" />
      <div className="relative flex min-h-screen items-center justify-center">
        <div className="m-8 flex h-fit w-full max-w-[32rem] flex-col rounded-xl bg-white p-12 text-m-olive-700">
          <div className="mb-12 flex items-center justify-center">
            <p className="text-display-md font-regular">Bigdelta</p>
          </div>
          <div className="flex justify-center text-display-xs font-medium">What you are working on?</div>
          <div className="flex h-16 items-center justify-center">
            {error && <div className="w-full rounded-lg border border-m-red-300 bg-m-red-200 px-4 py-2 text-md text-m-red-700">{error}</div>}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4">
              <FormInput
                label="Company name"
                inputProps={{ ...register('name', { required: true }), placeholder: 'Company name' }}
                errorMessage={errors.name?.message}
              />
              <FormInput
                label="Company website"
                inputProps={{ ...register('website', { required: true }), placeholder: 'Company website' }}
                errorMessage={errors.website?.message}
              />
              <FormElementWrapper label="Company size" errorMessage={errors.company_size?.message}>
                <NativeSelect {...register('company_size', { required: true })}>
                  {companySizeOptions.map((value) => (
                    <option key={value} id={value}>
                      {value}
                    </option>
                  ))}
                </NativeSelect>
              </FormElementWrapper>
              <Button label="Continue" className="w-full" size="lg" type="submit" disabled={!isValid || loading} loading={loading} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
