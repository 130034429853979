import { TableResourceType } from '@bigdelta/lib-shared';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useQueryKeys } from '../../../features/auth/hooks/useQueryKeys';
import { useWorkspace } from '../../../features/auth/hooks/useWorkspace';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient';
import { MembersConfigTableLayoutUpdatePayload } from '@bigdelta/lib-api-client';
import { produce } from 'immer';
import { v4 as uuid } from 'uuid';
interface UseTableLayoutDataArgs {
  resourceType: TableResourceType;
  resourceId?: string;
}

// TODO: generate and append ids to the layout data for each column to later use for keys and comparisons
export const useTableLayoutData = ({ resourceType, resourceId }: UseTableLayoutDataArgs) => {
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();
  const queryClient = useQueryClient();

  const queryKey = queryKeys.tableLayout(resourceType, resourceId);

  const tableLayoutQuery = useQuery({
    queryKey: queryKey,
    queryFn: () =>
      bigdeltaAPIClient.v1.membersConfigTableLayoutList({ workspace_id: currentWorkspaceId, resource_type: resourceType, resource_id: resourceId }),
    enabled: resourceType === TableResourceType.EVENT || (resourceType === TableResourceType.OBJECT && !!resourceId),
  });

  const tableLayoutUpdateMutation = useMutation({
    mutationFn: (payload: MembersConfigTableLayoutUpdatePayload) => {
      const payloadWithIds = produce(payload, (draft) => {
        draft.columns.forEach((col) => {
          if (!col.id) {
            col.id = uuid();
          }
        });
      });

      return bigdeltaAPIClient.v1.membersConfigTableLayoutUpdate({ workspace_id: currentWorkspaceId }, payloadWithIds);
    },
    onMutate: async (payload) => {
      await queryClient.cancelQueries(queryKey);

      const previousLayout = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, () => payload);

      return { previousLayout };
    },
    onError: (_err, _payload, context) => {
      queryClient.setQueryData(queryKey, context?.previousLayout);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  const mutateColumnWidth = (id: string, width: number) => {
    if (!tableLayoutQuery.data) {
      return;
    }

    tableLayoutUpdateMutation.mutate(
      produce(tableLayoutQuery.data, (draft) => {
        const idx = draft.columns.findIndex((c) => c.id === id);
        if (idx === -1) {
          return;
        }

        draft.columns[idx].width = width;
      })
    );
  };

  return { tableLayoutQuery, tableLayoutUpdateMutation, mutateColumnWidth };
};
