import { ResourcePropertyType } from '@bigdelta/lib-shared';

export const STANDARD_STRUCT_PROPERTY_TYPES = [ResourcePropertyType.CURRENCY];

export const NESTED_PROPERTIES = {
  [ResourcePropertyType.CURRENCY]: {
    name: 'amount_in_cents',
    type: ResourcePropertyType.NUMBER,
  },
};
