import { FC, useState } from 'react';
import { NumberTextEdit } from './NumberTextEdit';
import { NumberTextView } from './NumberTextView';
import { type EditablePropertyType } from '../types';
import { twMerge } from '../../../utils/twMerge';

export type NumberTextProps = EditablePropertyType<number>;

export const NumberText: FC<NumberTextProps> = ({ value, onChange, disabled }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    if (!disabled) {
      setIsEditing(true);
    }
  };
  const handleCancel = () => setIsEditing(false);
  const handleChange = (val: number | null | undefined) => {
    onChange?.(val);
    setTimeout(() => {
      setIsEditing(false);
    });
  };

  return (
    <div
      className={twMerge('flex w-full rounded-md px-2 py-2 focus-within:ring-2 focus-within:ring-m-blue-400', !disabled && 'hover:bg-m-gray-300')}
      onClick={handleEdit}
    >
      {isEditing ? (
        <NumberTextEdit value={value} onChange={handleChange} onCancel={handleCancel} />
      ) : (
        <NumberTextView value={value} onEdit={handleEdit} />
      )}
    </div>
  );
};
