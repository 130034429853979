import { autoUpdate, offset, size, useFloating, useClick, useInteractions, flip, shift, useDismiss } from '@floating-ui/react';
import { useMemo, useState } from 'react';

interface UseShowContentClickOptions {
  disabled?: boolean;
  defaultOpen?: boolean;
  onClose?: () => void;
}

export const useShowContentClick = ({ disabled, defaultOpen, onClose }: UseShowContentClickOptions = {}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: (open) => {
      if (disabled) {
        return;
      }

      if (open) {
        setIsOpen(true);
        document.body.classList.add('overscroll-none');
      } else {
        setIsOpen(false);
        onClose?.();
        document.body.classList.remove('overscroll-none');
      }
    },
    middleware: [
      offset(({ rects }) => ({
        mainAxis: -rects.reference.height,
      })),
      flip(),
      shift(),
      size({
        apply({ availableHeight, rects, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${rects.reference.width}px`,
            maxHeight: `${Math.max(0, availableHeight)}px`,
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const click = useClick(context, { toggle: false });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  const floatingPortalContentProps = useMemo(
    () => ({
      ref: refs.setFloating,
      style: floatingStyles,
      ...getFloatingProps(),
      className: 'p-2 z-50 bg-m-white text-sm shadow-lg',
    }),
    [floatingStyles, getFloatingProps, refs.setFloating]
  );

  const referenceProps = useMemo(
    () => ({
      ref: refs.setReference,
      ...getReferenceProps(),
    }),
    [getReferenceProps, refs.setReference]
  );

  return useMemo(
    () => ({
      floatingPortalContentProps,
      referenceProps,
      isOpen,
      forceClose: () => {
        setIsOpen(false);
      },
    }),
    [floatingPortalContentProps, isOpen, referenceProps]
  );
};
