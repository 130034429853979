import { FC, useMemo } from 'react';
import * as Select from '../../../../components/SelectFilter.tsx';
import { Operator, useFilterStore } from '../../store';
import { useFilterContext } from '../../context/useFilterContext';
import { attributeTypeOperatorValueSelectMap } from '../../const';
import { twMerge } from 'tailwind-merge';
import { getOperatorLabel } from '../../../utils/getOperatorLabel.ts';

interface PropertyOperatorSelectProps {
  index: number;
  value?: Operator;
  onChange: (index: number, value: Operator) => void;
}

export const PropertyOperatorSelect: FC<PropertyOperatorSelectProps> = ({ index, value, onChange }) => {
  const { filterKey, mode } = useFilterContext();
  const filterItem = useFilterStore((state) => state.getFilterItem([...filterKey, index]));

  const operators = useMemo(() => {
    if (!filterItem?.property?.attributeType) {
      return null;
    }

    const operatorsForPropertyType = attributeTypeOperatorValueSelectMap?.[filterItem.property.attributeType];

    return operatorsForPropertyType ? Object.keys(operatorsForPropertyType) : null;
  }, [filterItem?.property?.attributeType]);

  if (!filterItem?.property?.attributeType) {
    return null;
  }

  const labelText = getLabelText(value);

  return (
    <Select.Root value={value} onValueChange={(v) => onChange(index, v as Operator)}>
      <Select.Trigger placeholder="[operator]" label={labelText} className={twMerge(mode === 'stacked' && 'w-full')} />
      {operators && (
        <Select.Content>
          {operators.map((operator) => (
            <Select.Item key={operator} value={operator} label={getOperatorLabel(operator)} />
          ))}
        </Select.Content>
      )}
    </Select.Root>
  );
};

const getLabelText = (value: Operator | undefined): string => {
  if (!value) {
    return '';
  }
  return getOperatorLabel(value) || '';
};
