import { ButtonIntent } from '../../../shared/ui/Button/Button.tsx';
import { FilterKey } from '../../../shared/filters/store';


export interface ReportMetricContextType {
  formulaReference?: string;
  inline?: boolean;
  selectorIntent?: ButtonIntent;
  disableCreateMetric?: boolean;
  disableRemoval?: boolean;
  filterKey: FilterKey;
  onRemoveMetric?: () => void;
}

export const defaultValues: ReportMetricContextType = {
  inline: false,
  disableCreateMetric: false,
  filterKey: null,
  formulaReference: null,
};
