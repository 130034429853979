import { NestedObjectDef } from '@bigdelta/lib-api-client';
import { FC, PropsWithChildren } from 'react';
import { PropertyValue } from './PropertyValue';
import { ResourcePropertyType } from '@bigdelta/lib-shared';

interface ValueContainerProps extends PropsWithChildren {}

const ValueContainer: FC<ValueContainerProps> = ({ children }) => (
  <span className="flex min-w-60 flex-col gap-y-2 break-words text-sm font-medium leading-6 text-m-olive-600">{children}</span>
);

interface LabelContainerProps extends PropsWithChildren {}

const LabelContainer: FC<LabelContainerProps> = ({ children }) => <span className="text-xs text-m-gray-500">{children}</span>;

interface ActionLabelProps {
  action: 'Added' | 'Removed';
}

const ActionLabel: FC<ActionLabelProps> = ({ action }) => (
  <LabelContainer>
    <span className="pr-2 capitalize leading-6">{action}:</span>
  </LabelContainer>
);

interface UpdatedValueProps {
  oldValue: NestedObjectDef[string] | undefined;
  newValue: NestedObjectDef[string] | undefined;
  propertyType: ResourcePropertyType | undefined;
}

const UpdatedValue: FC<UpdatedValueProps> = ({ oldValue, newValue, propertyType }) => (
  <>
    <LabelContainer>
      <span className="flex items-center leading-6">
        <ValueContainer>
          <PropertyValue value={oldValue ?? null} overflowVisible={true} propertyType={propertyType} disabled={true} />
        </ValueContainer>
        <span className="mx-1">{' -> '}</span>
      </span>
    </LabelContainer>
    <ValueContainer>
      <PropertyValue value={newValue ?? null} overflowVisible={true} propertyType={propertyType} disabled={true} />
    </ValueContainer>
  </>
);

interface AddedValueProps {
  value: NestedObjectDef[string] | undefined;
  propertyType: ResourcePropertyType | undefined;
}

const AddedValue: FC<AddedValueProps> = ({ value, propertyType }) => (
  <>
    <ActionLabel action="Added" />
    <ValueContainer>
      <PropertyValue value={value ?? null} overflowVisible={true} propertyType={propertyType} disabled={true} />
    </ValueContainer>
  </>
);

interface RemovedValueProps {
  value: NestedObjectDef[string] | undefined;
  propertyType: ResourcePropertyType | undefined;
}

const RemovedValue: FC<RemovedValueProps> = ({ value, propertyType }) => (
  <>
    <ActionLabel action="Removed" />
    <ValueContainer>
      <PropertyValue value={value ?? null} overflowVisible={true} propertyType={propertyType} disabled={true} />
    </ValueContainer>
  </>
);

export { UpdatedValue, AddedValue, RemovedValue };
