import CursorClickIcon from '../../../assets/icons/cursor-click-01.svg?react';
import { useParams } from 'react-router-dom';
import { useObjectQuery } from '../../../shared/data/useObjectQuery';
import { MetadataResourcesPropertiesNamesListData, QueryEventsCreateData } from '@bigdelta/lib-api-client';
import { FC, useMemo, useState } from 'react';
import { useRelationshipsQuery } from '../../../shared/data/useRelationshipsQuery';
import { SkeletonBlock } from '../../../components/SkeletonBlock';
import { useRelatedEventsInfiniteQuery } from '../data/useRelatedEventsQuery';
import { useRecordQuery } from '../data/useRecordQuery';
import { getObjectRelationshipToEvent } from '../../../shared/utils/getObjectRelationshipToEvent';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { formatDistanceToNow, format, parseISO } from 'date-fns';
import { NO_EVENTS_TABLE_EMPTY } from '../../../shared/tables/common';
import { DataTableEmpty } from '../../../shared/tables/components/DataTableEmpty';
import * as Feed from '../../../shared/ui/Feed/Feed';
import { Tooltip } from '../../../shared/ui/Tooltip/Tooltip';
import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { ResourcePropertyType, ResourceType } from '@bigdelta/lib-shared';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient';
import { PropertyName } from './PropertyName';
import { STANDARD_STRUCT_PROPERTY_TYPES } from '../../../shared/properties/const';
import { get } from 'lodash';
import { PropertyValue } from './PropertyValue';

interface EventDataTableProps {
  properties: QueryEventsCreateData['items'][number]['properties'];
  metadataProperties: MetadataResourcesPropertiesNamesListData['items'] | undefined;
}

const EventDataTable: FC<EventDataTableProps> = ({ properties, metadataProperties }) => {
  if (!properties || !Object.keys(properties).length) {
    return null;
  }

  return metadataProperties?.map(({ property_name, property_type }) => {
    const value = get(properties, property_name);
    const propertyType = property_type as ResourcePropertyType | undefined;

    if (value === undefined) {
      return null;
    }

    return (
      <div className="flex w-full items-center justify-start" key={property_name}>
        <div className="flex w-2/5 truncate capitalize text-m-olive-500">
          <PropertyName type={propertyType ?? ResourcePropertyType.STRING} propertyName={property_name} />
        </div>
        <div className="flex w-3/5 flex-wrap items-center justify-start gap-1 text-m-olive-600">
          <PropertyValue value={value} propertyType={propertyType} disabled={true} />
        </div>
      </div>
    );
  });
};

const EventSkeleton = () => (
  <li className="-ml-3 flex items-center text-sm">
    <SkeletonBlock className="mr-3 h-6 w-6 rounded-full" />
    <div className="flex flex-col items-start gap-y-2">
      <div className="mt-0.5 flex items-center gap-x-2">
        <span className="text-m-olive-600">
          <SkeletonBlock className="my-4 w-20" />
        </span>
        <span className="text-m-gray-600 ">
          <SkeletonBlock className="my-4 w-20" />
        </span>
      </div>
    </div>
  </li>
);

interface EventProps {
  event: QueryEventsCreateData['items'][number];
}

const SHOW_PATH = ['Page View', 'Form Submitted'];
const PATH_KEY = '$path';

const Event: FC<EventProps> = ({ event }) => {
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  const [showProperties, setShowProperties] = useState(false);
  const isPathVisible = SHOW_PATH.includes(event.event_name);

  const eventPropertiesQuery = useQuery({
    queryKey: queryKeys.list('metadata', ResourceType.EVENT, event.event_name, 'names'),
    queryFn: () =>
      bigdeltaAPIClient.v1.metadataResourcesPropertiesNamesList({
        resource_type: ResourceType.EVENT,
        resource_id: event.event_name,
        workspace_id: currentWorkspaceId,
      }),
    enabled: showProperties,
  });

  const topLevelStructProperties = eventPropertiesQuery.data?.items.filter((property) =>
    STANDARD_STRUCT_PROPERTY_TYPES.includes(property.property_type as ResourcePropertyType)
  );

  const eventProperties = eventPropertiesQuery.data?.items.filter((property) => {
    const isNestedStandardStructProperty = topLevelStructProperties?.some((structProperty) =>
      property.property_name.startsWith(structProperty.property_name + '.')
    );

    return !isNestedStandardStructProperty;
  });

  return (
    <Feed.Item>
      <Feed.ItemIcon icon={<CursorClickIcon className="h-3 w-3" />} />
      <Feed.ItemContent className="flex grow flex-col items-start gap-y-2">
        <Feed.ItemTitle className="text-md">
          <span className="text-m-olive-600">{event.event_name}</span>
          <span className="mx-2 text-m-gray-600">•</span>
          <Tooltip content={format(new Date(event.created_at), 'PPpp')}>
            <Feed.ItemMeta>{formatDistanceToNow(new Date(event.created_at), { addSuffix: true })}</Feed.ItemMeta>
          </Tooltip>
        </Feed.ItemTitle>
        {isPathVisible && (
          <Feed.ItemDetails>
            <PropertyValue value={event.properties?.[PATH_KEY] ?? null} propertyType={ResourcePropertyType.STRING} disabled={true} />
          </Feed.ItemDetails>
        )}
        <button className="text-sm text-m-blue-600 underline" onClick={() => setShowProperties(!showProperties)}>
          {showProperties ? 'Hide properties' : 'Show properties'}
        </button>
        {showProperties && (
          <div className="w-full min-w-0 shrink text-sm">
            <EventDataTable properties={event.properties} metadataProperties={eventProperties} />
          </div>
        )}
      </Feed.ItemContent>
    </Feed.Item>
  );
};

// Helper function to group events by month
const groupEventsByMonth = (events: QueryEventsCreateData['items']): Record<string, QueryEventsCreateData['items']> => {
  return events.reduce(
    (acc, event) => {
      const monthKey = format(parseISO(event.created_at), 'MMMM yyyy');
      if (!acc[monthKey]) {
        acc[monthKey] = [];
      }
      acc[monthKey].push(event);
      return acc;
    },
    {} as Record<string, QueryEventsCreateData['items']>
  );
};

export const RecordEvents = () => {
  const { objectSlug, remoteRecordId } = useParams();
  const { currentWorkspaceId } = useWorkspace();

  const { data: object } = useObjectQuery({ objectSlug: objectSlug ?? '', workspaceId: currentWorkspaceId });
  const { data: relationshipData } = useRelationshipsQuery({ workspaceId: currentWorkspaceId });
  const { data: record } = useRecordQuery({ recordId: remoteRecordId, workspaceId: currentWorkspaceId, workspaceObjectId: objectSlug });

  const rel =
    relationshipData?.relationships && object?.id
      ? getObjectRelationshipToEvent({ relationships: relationshipData.relationships, objectId: object.id })
      : undefined;

  const {
    data: events,
    isLoading: isEventsLoading,
    isSuccess: isEventsSuccess,
    fetchNextPage,
    hasNextPage,
  } = useRelatedEventsInfiniteQuery({ recordId: record?.system_id ?? '', relationshipName: rel?.name, workspaceId: currentWorkspaceId });
  const totalEventsCount = events?.pages?.[0]?.totalEventsCount || 0;

  const flatEvents = useMemo(() => (events ? events.pages.flatMap((d) => d.items) : []), [events]);

  const eventsEmpty = isEventsSuccess && !flatEvents.length;
  const noRelations = !!relationshipData?.relationships && !rel;

  if (!isEventsLoading && totalEventsCount === 0) {
    return <DataTableEmpty {...NO_EVENTS_TABLE_EMPTY} />;
  }

  if (!isEventsLoading && (eventsEmpty || noRelations)) {
    return <div className="py-4 text-md text-m-olive-400">This {object?.singular_noun} has no events</div>;
  }

  const groupedEvents = groupEventsByMonth(flatEvents);

  return (
    <Feed.Root>
      {isEventsLoading && (
        <>
          <EventSkeleton />
          <EventSkeleton />
        </>
      )}
      {Object.entries(groupedEvents).map(([month, monthEvents]) => (
        <Feed.ItemGroup key={month} title={month}>
          {monthEvents.map((event) => (
            <Event event={event} key={event.id} />
          ))}
        </Feed.ItemGroup>
      ))}
      {hasNextPage && <Feed.LoadMore onLoadMore={() => fetchNextPage()} />}
    </Feed.Root>
  );
};
