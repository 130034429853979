import { DashboardsDetailData } from '@bigdelta/lib-api-client';
import { FC } from 'react';
import { DashboardPanelReport } from './DashboardPanelReport';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';

interface DashboardPanelProps {
  dashboardId: string;
  panel: NonNullable<DashboardsDetailData['panels']>[number];
}

export const DashboardPanel: FC<DashboardPanelProps> = ({ panel, dashboardId }) => {
  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();

  const deletePanelMutation = useMutation({
    mutationFn: (panelId: string) => bigdeltaAPIClient.v1.dashboardsPanelsDelete(dashboardId, panelId),
    onSuccess: () => {
      return queryClient.invalidateQueries(queryKeys.dashboard(dashboardId));
    },
  });

  const handleRemovePanel = () => {
    deletePanelMutation.mutate(panel.id);
  };

  return <DashboardPanelReport reportId={panel.configuration.report_id} onRemovePanel={handleRemovePanel} />;
};
