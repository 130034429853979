import React, { FC, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../../auth/hooks/useQueryKeys.ts';
import { useWorkspace } from '../../../auth/hooks/useWorkspace.tsx';
import { CenteredLoader } from '../../../../shared/components/CenteredLoader.tsx';
import { RelationshipFormSchema } from './RelationshipFormSchema.ts';
import { capitalize } from 'lodash';
import { Button } from '../../../../shared/ui/Button/Button.tsx';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient.ts';

interface LabeledDisplayProps {
  label: string;
  value: string | undefined;
}

const LabeledDisplay: React.FC<LabeledDisplayProps> = ({ label, value }) => (
  <div className="mb-4">
    <label className="mb-1 block text-sm font-medium text-m-gray-700">{label}</label>
    <div className="mt-1 rounded-md bg-m-gray-300 p-2 text-sm text-m-gray-900">{value ?? ''}</div>
  </div>
);

interface RelationshipDetailsProps {
  value?: RelationshipFormSchema;
  onClose: () => void;
}

export const RelationshipDetails: FC<RelationshipDetailsProps> = ({ value, onClose }) => {
  const queryKeys = useQueryKeys();
  const { currentWorkspaceId } = useWorkspace();

  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId }),
  });

  const obj = useMemo(() => {
    return objectsQuery.data?.objects.find((o) => o.id === value?.entity_id);
  }, [objectsQuery.data?.objects, value?.entity_id]);

  return (
    <>
      {!objectsQuery.isInitialLoading && value && (
        <div className={twMerge('relative flex flex-col gap-4 px-6 pb-6')}>
          <LabeledDisplay label="Entity" value={capitalize(obj?.singular_noun || '')} />
          <LabeledDisplay label="Property" value={value.entity_property} />
          <div className="flex justify-end gap-x-2">
            <Button size="sm" intent="tertiary" onClick={onClose} label="Cancel" />
          </div>
        </div>
      )}
      {objectsQuery.isInitialLoading && <CenteredLoader />}
    </>
  );
};
