import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { get as lodashGet, set as lodashSet, merge } from 'lodash';
import { Draft } from 'immer';
import { ValueSelectType, attributeTypeOperatorValueSelectMap, eventNameOperatorMap } from '../const';
import { errorHandler } from '../../../store/middleware/ErrorHandler';
import { devtools, persist } from 'zustand/middleware';
import { DateRangeType, QueryFrequencyValueFilterOperator, QueryValueFilterOperator } from '@bigdelta/lib-shared';
import { startOfDay, format, sub } from 'date-fns';
import { RecordQueryFiltersRequestDef } from '@bigdelta/lib-api-client';
import { RelationshipObjectData } from '../../types';

// TODO: Refactor keys into something that makes more sense
type EmptyKey = ['', ''];

type ReportsKey = ['reports', 'create'];
type RecordsKey = ['records', string];
type EventsObjectFiltersKey = ['events', 'object'];
type EventsEventFiltersKey = ['events', 'event'];
type GraphKey = ['graph', 'create'];

export type FilterKey = ReportsKey | RecordsKey | EventsObjectFiltersKey | EventsEventFiltersKey | GraphKey | EmptyKey | string[];

export const ALL_EVENTS = 'All events';

export type FilterItemKey = [...FilterKey, number];

export type Operator = QueryValueFilterOperator;

export enum FilterItemType {
  RECORDS_PROPERTY = 'RECORDS_PROPERTY',
  RECORDS_EVENT_NAME = 'RECORDS_EVENT_NAME',
  RECORDS_EVENT_PROPERTY = 'RECORDS_EVENT_PROPERTY',

  RECORDS_EVENT = 'RECORDS_EVENT',

  EVENTS_OBJECT = 'EVENTS_OBJECT',

  EVENTS_PROPERTY = 'EVENTS_PROPERTY',
  EVENTS_RECORD_PROPERTY = 'EVENTS_RECORD_PROPERTY',
  EVENTS_NAME = 'EVENTS_NAME',
  EVENTS_TIMEFRAME = 'EVENTS_TIMEFRAME',

  METRICS_PROPERTY = 'METRICS_PROPERTY',
  METRICS_RECORD_PROPERTY = 'METRICS_RECORD_PROPERTY',

  TRENDS_COUNT_EVENT_PROPERTY = 'TRENDS_COUNT_EVENT_PROPERTY',
}

export interface Attribute {
  attributeId: string;
  attributeType: string;
  attributeName: string;
}

interface FilterItemDataComboboxSingle {
  valueType: ValueSelectType.COMBOBOX_SINGLE;
  value?: string;
}

interface FilterItemDataComboboxMultiple {
  valueType: ValueSelectType.COMBOBOX_MULTIPLE;
  value?: (string | number | boolean)[];
}

interface FilterItemDataTextField {
  valueType: ValueSelectType.TEXT_FIELD;
  value?: string;
}

interface FilterItemDataNumberField {
  valueType: ValueSelectType.NUMBER_FIELD;
  value?: number;
}

interface FilterItemDataDateField {
  valueType: ValueSelectType.DATE_FIELD;
  value?: string;
}

interface FilterItemDataDateRangeField {
  valueType: ValueSelectType.DATE_RANGE_FIELD;
  value?: string;
}

interface FilterItemDataEmpty {
  valueType: undefined;
  value: undefined;
}

type FilterItemData =
  | FilterItemDataComboboxMultiple
  | FilterItemDataComboboxSingle
  | FilterItemDataTextField
  | FilterItemDataNumberField
  | FilterItemDataDateField
  | FilterItemDataDateRangeField
  | FilterItemDataEmpty;

type TimeframeReqType = NonNullable<
  NonNullable<Exclude<RecordQueryFiltersRequestDef['conditions'][number], RecordQueryFiltersRequestDef | null>['related_events']>['time']
>;

export interface Filter {
  items: FilterItem[];
  operator: 'and' | 'or';
}

export interface Timeframe {
  dateRangeType?: TimeframeReqType['date_range_type'];
  window?: Partial<TimeframeReqType['window']>;
  startAt?: NonNullable<TimeframeReqType['start_at']>;
  endAt?: NonNullable<TimeframeReqType['end_at']>;
}

export interface FilterItem {
  itemType: FilterItemType;
  propertyOperator?: Operator;
  eventOperator?: QueryFrequencyValueFilterOperator;
  data: FilterItemData;
  items?: Omit<FilterItem, 'items'>[];
  event?: string;
  eventValue?: number;
  timeframe: Timeframe;
  property?: Attribute;
  propertyRelationships: RelationshipObjectData[];
}

// TODO any record key
export interface FilterState {
  reports: {
    create: {
      items: FilterItem[];
      operator: 'and' | 'or';
    };
  };
  records: {
    companies: {
      items: FilterItem[];
      operator: 'and' | 'or';
    };
    people: {
      items: FilterItem[];
      operator: 'and' | 'or';
    };
  };
  events: {
    object: {
      items: FilterItem[];
      operator: 'and' | 'or';
    };
    event: {
      items: FilterItem[];
      operator: 'and' | 'or';
    };
  };
  graph: {
    create: {
      items: FilterItem[];
      operator: 'and' | 'or';
    };
  };
  touched: Record<string, boolean>;
  getFilter: (key: FilterKey) => Filter | null;
  createRecordFilter: (objectSlug: string) => void;
  addFilterItem: (key: FilterKey, itemType: FilterItemType, defaultFilterItem: Partial<FilterItem>) => { index: number };

  removeItem: (key: FilterKey, index: number) => void;
  setItemDataValueType: (key: FilterKey, index: number, type: ValueSelectType) => void;
  updateItemDataValueType: (key: FilterKey, index: number) => void;
  setItemDataValue: (key: FilterKey, index: number, value: string | string[] | number | undefined) => void;
  setItemPropertyOperator: (key: FilterKey, index: number, operator: Operator) => void;
  setItemEventOperator: (key: FilterKey, index: number, operator: QueryFrequencyValueFilterOperator) => void;
  setItemEvent: (key: FilterKey, index: number, event: string) => void;
  setItemEventValue: (key: FilterKey, index: number, value: number) => void;
  setItemEventTimeframeOperator: (key: FilterKey, index: number, value: Timeframe['dateRangeType']) => void;
  setItemEventTimeframeWindowUnit: (key: FilterKey, index: number, value: NonNullable<Timeframe['window']>['unit']) => void;
  setItemEventTimeframeWindowValue: (key: FilterKey, index: number, value: number) => void;
  setItemEventTimeframeStartAt: (key: FilterKey, index: number, value: string) => void;
  setItemEventTimeframeEndAt: (key: FilterKey, index: number, value: string) => void;
  clearFilter: (key: FilterKey) => void;

  setFilterItems: (key: FilterKey, filterItems: FilterItem[]) => void;

  setFilter: (key: FilterKey, filter: Filter) => void;

  getFilterItem: (filterItemKey: FilterItemKey) => FilterItem | null;

  setPropertyWithRelationships: (
    key: FilterKey,
    index: number,
    property: Attribute | undefined,
    relationships: RelationshipObjectData[] | undefined
  ) => void;
  setPropertyRelationships: (key: FilterKey, index: number, relationships: RelationshipObjectData[]) => void;

  setOperator: (key: FilterKey, operator: 'and' | 'or') => void;
  addNestedItem: (key: FilterKey, index, itemType: FilterItemType, defaultFilterItem: Partial<FilterItem>) => { nestedIndex: number };
}

export const getFilter = (state: Draft<FilterState>, key: FilterKey): Filter | null => {
  return lodashGet(state, key, null);
};

export const getFilterItem = (state: Draft<FilterState>, key: FilterItemKey): FilterItem | null => {
  return lodashGet(state, [...key.slice(0, -1), 'items', ...key.slice(-1)], null);
};

const createDefaultFilterItem = ({ itemType, defaultFilterItem }: { itemType: FilterItemType; defaultFilterItem: Partial<FilterItem> }) => {
  const propertyOperator = itemType === FilterItemType.EVENTS_NAME ? QueryValueFilterOperator.EQUALS : undefined;

  const item = merge(defaultFilterItem, {
    itemType,
    property: undefined,
    propertyRelationships: [],
    eventOperator: undefined,
    propertyOperator,
    data: {
      valueType: undefined,
      value: undefined,
    },
    timeframe: {},
  });

  return item;
};

export const useFilterStore = create<FilterState>()(
  devtools(
    persist(
      errorHandler(
        immer((set, get) => ({
          reports: {
            create: {
              items: [] as FilterItem[],
              operator: 'and',
            },
          },
          records: {
            companies: {
              items: [] as FilterItem[],
              operator: 'and',
            },
            people: {
              items: [] as FilterItem[],
              operator: 'and',
            },
          },
          events: {
            object: {
              items: [] as FilterItem[],
              operator: 'and',
            },
            event: {
              items: [] as FilterItem[],
              operator: 'and',
            },
          },
          graph: {
            create: {
              items: [] as FilterItem[],
              operator: 'and',
            },
          },
          touched: {},
          createRecordFilter: (objectSlug) => {
            set((state) => {
              state.records[objectSlug] = [];
            });
          },
          addFilterItem: (key, itemType, defaultFilterItem = {}) => {
            let propertyOperator;
            let tempIdx;

            set((state) => {
              let filter = getFilter(state, key);
              if (!filter) {
                lodashSet(state, key, { items: [], operator: 'and' });
                filter = lodashGet(state, key, { items: [], operator: 'and' });
              }

              if (!Array.isArray(filter?.items)) {
                throw Error('Filter is not an array');
              }

              const item = createDefaultFilterItem({ itemType, defaultFilterItem });

              filter.items.push(item);

              tempIdx = filter.items.length - 1;
            });

            if (propertyOperator && tempIdx !== undefined) {
              get().updateItemDataValueType(key, tempIdx);
            }

            return { index: tempIdx };
          },

          removeItem: (key, index) =>
            set((state) => {
              const filter = getFilter(state, key);
              if (!filter?.items) {
                throw Error('Filter not found');
              }
              filter.items.splice(index, 1);
            }),
          setItemPropertyOperator: (key, index, operator) => {
            set((state) => {
              const filter = getFilter(state, key);
              if (!filter?.items) {
                throw Error('Filter not found');
              }
              filter.items[index].propertyOperator = operator;
            });
            get().updateItemDataValueType(key, index);
          },

          setItemEventOperator: (key, index, operator) => {
            set((state) => {
              const filter = getFilter(state, key);
              if (!filter?.items) {
                throw Error('Filter not found');
              }
              filter.items[index].eventOperator = operator;
            });
            get().updateItemDataValueType(key, index);
          },

          setItemDataValueType: (key, index, type) =>
            set(
              (state) => {
                const filter = getFilter(state, key);
                if (!filter?.items) {
                  throw Error('Filter not found');
                }

                filter.items[index].data.valueType = type;

                // TODO: Move setting defaults to a separate action
                switch (type) {
                  case ValueSelectType.DATE_FIELD:
                    filter.items[index].data.value = new Date().toISOString();
                    break;
                  case ValueSelectType.DATE_RANGE_FIELD:
                    filter.items[index].data.value = [sub(new Date(), { days: 3 }).toISOString(), new Date().toISOString()];
                    break;
                  default:
                    filter.items[index].data.value = undefined;
                    break;
                }
              },
              false,
              'setItemDataValueType'
            ),
          updateItemDataValueType: (key, index) => {
            const filterItem = getFilterItem(get(), [...key, index]);

            if (
              filterItem?.itemType &&
              [
                FilterItemType.EVENTS_RECORD_PROPERTY,
                FilterItemType.RECORDS_PROPERTY,
                FilterItemType.RECORDS_EVENT,
                FilterItemType.EVENTS_PROPERTY,
                FilterItemType.RECORDS_EVENT_PROPERTY,
                FilterItemType.METRICS_PROPERTY,
                FilterItemType.METRICS_RECORD_PROPERTY,
                FilterItemType.TRENDS_COUNT_EVENT_PROPERTY,
              ].includes(filterItem.itemType)
            ) {
              if (filterItem?.property?.attributeType && filterItem.propertyOperator) {
                const dataValType = attributeTypeOperatorValueSelectMap?.[filterItem.property.attributeType]?.[filterItem.propertyOperator];
                if (dataValType === undefined || dataValType === filterItem.data.valueType) {
                  return;
                }

                get().setItemDataValueType(key, index, dataValType);
              }
            }

            if (filterItem?.itemType == FilterItemType.EVENTS_NAME) {
              if (filterItem.propertyOperator) {
                const dataValType = eventNameOperatorMap[filterItem.propertyOperator];
                if (!dataValType || dataValType === filterItem.data.valueType) {
                  return;
                }

                get().setItemDataValueType(key, index, dataValType);
              }
            }
          },
          setItemDataValue: (key, index, value) =>
            set(
              (state) => {
                const filter = getFilter(state, key);
                if (!filter?.items) {
                  throw Error('Filter not found');
                }

                filter.items[index].data.value = value;
              },
              false,
              'setItemDataValue'
            ),
          setItemEvent: (key, index, event) => {
            set((state) => {
              const filter = getFilter(state, key);
              if (!filter?.items) {
                throw Error('Filter not found');
              }

              // TODO: hacky, refactor later
              if (
                filter.items[index].itemType === FilterItemType.EVENTS_PROPERTY ||
                filter.items[index].itemType === FilterItemType.METRICS_PROPERTY
              ) {
                filter.items[index].event = event;
                filter.items[index].propertyOperator = QueryValueFilterOperator.EQUALS;
                filter.items[index].eventValue = undefined;
                filter.items[index].timeframe = {
                  dateRangeType: undefined,
                  window: undefined,
                };
              } else {
                filter.items[index].event = event;
                filter.items[index].eventOperator = QueryFrequencyValueFilterOperator.AT_LEAST;
                filter.items[index].eventValue = 1;
                filter.items[index].timeframe = {
                  dateRangeType: DateRangeType.OVER_ALL_TIME,
                };
                // filter[index].timeframe = {
                //   dateRangeType: DateRangeType.IN_THE_LAST,
                //   window: {
                //     unit: 'day',
                //     value: 7,
                //   },
                // };
              }
            });
          },
          setItemEventValue: (key, index, value) => {
            set((state) => {
              const filter = getFilter(state, key);
              if (!filter?.items) {
                throw Error('Filter not found');
              }

              filter.items[index].eventValue = value;
            });
          },
          setItemEventTimeframeOperator: (key, index, value) => {
            set((state) => {
              const filterItem = getFilterItem(state, [...key, index]);
              if (!filterItem) {
                throw Error('Filter item not found');
              }

              switch (value) {
                case DateRangeType.OVER_ALL_TIME:
                  filterItem.timeframe = {
                    dateRangeType: DateRangeType.OVER_ALL_TIME,
                  };
                  break;
                case DateRangeType.ABSOLUTE:
                  filterItem.timeframe = {
                    dateRangeType: DateRangeType.ABSOLUTE,
                    startAt: format(sub(startOfDay(new Date()), { days: 7 }), 'yyyy-MM-dd'),
                    endAt: format(startOfDay(new Date()), 'yyyy-MM-dd'),
                  };
                  break;
                case DateRangeType.AFTER:
                  filterItem.timeframe = {
                    dateRangeType: DateRangeType.AFTER,
                    startAt: format(sub(startOfDay(new Date()), { days: 7 }), 'yyyy-MM-dd'),
                  };
                  break;
                case DateRangeType.BEFORE:
                  filterItem.timeframe = {
                    dateRangeType: DateRangeType.BEFORE,
                    endAt: format(startOfDay(new Date()), 'yyyy-MM-dd'),
                  };
                  break;
                case DateRangeType.IN_THE_LAST:
                  filterItem.timeframe = {
                    dateRangeType: DateRangeType.IN_THE_LAST,
                    window: {
                      unit: 'day',
                      value: 7,
                    },
                  };
                  break;
              }
            });
          },
          setItemEventTimeframeWindowUnit: (key, index, unit) => {
            set((state) => {
              const filter = getFilter(state, key);
              if (!filter) {
                throw Error('Filter not found');
              }

              const window = filter[index].timeframe.window;

              if (!window) {
                filter[index].timeframe.window = {
                  unit,
                };
              } else {
                window.unit = unit;
              }
            });
          },
          setItemEventTimeframeWindowValue: (key, index, value) => {
            set((state) => {
              const filter = getFilter(state, key);
              if (!filter?.items) {
                throw Error('Filter not found');
              }
              const window = filter.items[index].timeframe.window;

              if (!window) {
                filter.items[index].timeframe.window = {
                  value,
                };
              } else {
                window.value = value;
              }
            });
          },
          setItemEventTimeframeStartAt: (key, index, value) => {
            set((state) => {
              const filter = getFilter(state, key);
              if (!filter) {
                throw Error('Filter not found');
              }
              filter[index].timeframe.startAt = value;
            });
          },
          setItemEventTimeframeEndAt: (key, index, value) => {
            set((state) => {
              const filter = getFilter(state, key);
              if (!filter) {
                throw Error('Filter not found');
              }
              filter[index].timeframe.endAt = value;
            });
          },
          clearFilter: (key) => {
            set(
              (state) => {
                lodashSet(state, key, { items: [], operator: 'and' });
              },
              false,
              'clearFilter'
            );
          },

          setFilterItems: (key, filterItems) => {
            set(
              (state) => {
                lodashSet(state, [...key, 'items'], filterItems);
              },
              false,
              'setFilterItems'
            );
          },

          setFilter: (key, filter) => {
            set(
              (state) => {
                lodashSet(state, key, filter);
              },
              false,
              'setFilter'
            );
          },

          getFilterItem: (filterItemKey) => {
            return getFilterItem(get(), filterItemKey);
          },

          setPropertyWithRelationships: (key, index, property, relationships) => {
            set((state) => {
              const filter = getFilter(state, key);
              if (!filter?.items) {
                throw Error('Filter not found');
              }

              if (!relationships) {
                throw Error('Relationships not found');
              }

              filter.items[index].property = property;
              filter.items[index].propertyRelationships = relationships;

              if (property?.attributeType === 'datetime64') {
                filter.items[index].propertyOperator = QueryValueFilterOperator.BEFORE;
              } else {
                filter.items[index].propertyOperator = QueryValueFilterOperator.EQUALS;
              }
              filter.items[index].data.value = undefined;
            });
            get().updateItemDataValueType(key, index);
          },

          setPropertyRelationships: (key, index, relationships) => {
            set((state) => {
              const filter = getFilter(state, key);
              if (!filter?.items) {
                throw Error('Filter not found');
              }
              filter.items[index].propertyRelationships = relationships;
            });
          },

          getFilter: (key) => {
            return getFilter(get(), key);
          },

          setOperator: (key: FilterKey, operator: 'and' | 'or') => {
            set((state) => {
              const filter = getFilter(state, key);
              if (!filter) {
                throw Error('Filter not found');
              }
              filter.operator = operator;
            });
          },

          addNestedItem: (key: FilterKey, index, itemType: FilterItemType, defaultFilterItem: Partial<FilterItem>) => {
            let nestedIndex = 0;
            set((state) => {
              const filter = getFilter(state, key);
              if (!filter) {
                throw Error('Filter not found');
              }
              const eventFilter = filter.items[index];
              if (!eventFilter) {
                throw Error('Filter item not found');
              }
              if (eventFilter.itemType !== FilterItemType.RECORDS_EVENT) {
                throw Error('Filter item not RECORDS_EVENT');
              }
              if (itemType !== FilterItemType.RECORDS_EVENT_PROPERTY) {
                throw Error('Added event is not RECORDS_EVENT_PROPERTY');
              }

              if (!eventFilter.items) {
                eventFilter.items = [];
              }

              const item = createDefaultFilterItem({ itemType, defaultFilterItem });
              nestedIndex = eventFilter.items.push(item);
              if (item.propertyOperator && nestedIndex !== undefined) {
                get().updateItemDataValueType([...key, 'items'], nestedIndex);
              }
            });
            return {
              nestedIndex,
            };
          },
        }))
      ),
      {
        skipHydration: true,
        name: 'filters',
        partialize: (state) => {
          return Object.fromEntries(Object.entries(state).filter(([key]) => !['reports'].includes(key)));
        },
        version: 8,
      }
    )
  )
);
