import { FC, useState, useEffect } from 'react';
import { DebouncedInput } from './DebouncedInput';

interface PropertyValueNumberFieldProps {
  onChange: (value: number | undefined) => void;
  value: number | undefined;
}

export const PropertyValueNumberField: FC<PropertyValueNumberFieldProps> = ({ value, onChange }) => {
  const [stringValue, setStringValue] = useState(() => value?.toString() ?? '');

  useEffect(() => {
    setStringValue(value?.toString() ?? '');
  }, [value]);

  const handleChange = (newValue: string) => {
    setStringValue(newValue);
    if (newValue === '') {
      onChange(undefined);
    } else {
      const numberValue = parseFloat(newValue);
      if (!isNaN(numberValue)) {
        onChange(numberValue);
      }
    }
  };

  return <DebouncedInput type="number" value={stringValue} onChange={handleChange} placeholder="Enter number value..." />;
};
