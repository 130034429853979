import { QueryReportsCreatePayload } from '@bigdelta/lib-api-client';
import { DateRangeType } from '@bigdelta/lib-shared';
import { ReportTimerange } from '../../const';

export const getQueryTimeFromTimerange = (timerange: ReportTimerange): QueryReportsCreatePayload['time'] => {
  switch (timerange) {
    case ReportTimerange['24H']:
      return {
        date_range_type: DateRangeType.IN_THE_LAST,
        window: {
          value: 24,
          unit: 'hour',
        },
      };
    case ReportTimerange['7D']:
      return {
        date_range_type: DateRangeType.IN_THE_LAST,
        window: {
          value: 7,
          unit: 'day',
        },
      };
    case ReportTimerange['30D']:
      return {
        date_range_type: DateRangeType.IN_THE_LAST,
        window: {
          value: 30,
          unit: 'day',
        },
      };
    case ReportTimerange['3M']:
      return {
        date_range_type: DateRangeType.IN_THE_LAST,
        window: {
          value: 3,
          unit: 'month',
        },
      };
    case ReportTimerange['6M']:
      return {
        date_range_type: DateRangeType.IN_THE_LAST,
        window: {
          value: 6,
          unit: 'month',
        },
      };
    case ReportTimerange['12M']:
      return {
        date_range_type: DateRangeType.IN_THE_LAST,
        window: {
          value: 12,
          unit: 'month',
        },
      };
    case ReportTimerange['24M']:
      return {
        date_range_type: DateRangeType.IN_THE_LAST,
        window: {
          value: 24,
          unit: 'month',
        },
      };
  }
};
