import { ReportSourceType, useReportsStore } from '../store';
import { ReportCreateSelectMetricType } from './ReportCreateSelectMetricType';
import { ReportCreateSelectedMetricEvents } from './ReportCreateSelectedMetricEvents';
import { ReportCreateSelectedMetricMetric } from './ReportCreateSelectedMetricMetric';
import { ReportCreateSelectedMetricRecords } from './ReportCreateSelectedMetricRecords';
import { ReportMetricProvider } from '../../auth/context/reportMetricContext.tsx';
import { FC } from 'react';
import { ReportCreateSelectedMetricFormula } from './ReportCreateSelectedMetricFormula.tsx';
import { type ReportMetricContextType } from '../../auth/context/reportMetricsDefault.ts';

export const ReportMetric: FC<ReportMetricContextType> = ({
  inline = false,
  disableCreateMetric = false,
  selectorIntent,
  filterKey,
  formulaReference,
  onRemoveMetric,
  disableRemoval,
}) => {
  const metricType = useReportsStore((state) => state.config.metricType);

  return (
    <ReportMetricProvider
      inline={inline}
      disableCreateMetric={disableCreateMetric}
      selectorIntent={selectorIntent}
      filterKey={filterKey}
      formulaReference={formulaReference}
      onRemoveMetric={onRemoveMetric}
      disableRemoval={disableRemoval}
    >
      {!metricType && <ReportCreateSelectMetricType />}
      {metricType === ReportSourceType.EVENTS && <ReportCreateSelectedMetricEvents />}
      {metricType === ReportSourceType.RECORDS && <ReportCreateSelectedMetricRecords />}
      {metricType === ReportSourceType.METRIC && <ReportCreateSelectedMetricMetric />}
      {metricType === ReportSourceType.FORMULA && <ReportCreateSelectedMetricFormula />}
    </ReportMetricProvider>
  );
};
