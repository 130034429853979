import { useQuery } from '@tanstack/react-query';

import { useQueryKeys } from '../../features/auth/hooks/useQueryKeys.ts';
import { useWorkspace } from '../../features/auth/hooks/useWorkspace.tsx';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';

interface UseReportQueryParameters {
  reportId?: string;
}

export const useReportQuery = ({ reportId }: UseReportQueryParameters) => {
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  return useQuery({
    queryKey: queryKeys.report(reportId),
    queryFn: () => bigdeltaAPIClient.v1.reportsDetail(reportId ?? '', { workspace_id: currentWorkspaceId }),
    enabled: !!reportId,
    staleTime: Infinity,
  });
};

interface UseReportsJourneyQueryParameters {
  recordId?: string;
  objectSlug: string;
  workspaceId: string;
}

export const JOURNEY_SUPPORTED_SLUGS = ['companies', 'accounts'];

export const useReportsJourney = ({ recordId, objectSlug, workspaceId }: UseReportsJourneyQueryParameters) => {
  const queryKeys = useQueryKeys();
  const enabled = JOURNEY_SUPPORTED_SLUGS.includes(objectSlug) && !!recordId && !!objectSlug && !!workspaceId;

  return useQuery({
    queryKey: queryKeys.journey(recordId, objectSlug),
    queryFn: () =>
      bigdeltaAPIClient.v1.queryReportsByObjectType(
        'journey',
        { workspace_id: workspaceId },
        {
          object_slug: objectSlug,
          record_id: recordId,
        }
      ),
    enabled,
    staleTime: Infinity,
  });
};
