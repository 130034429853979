import { useReportStore } from '../store/Report';
import { ReportTypeSelector } from './ReportTypeSelector';
import { ChartType, ReportTypeVO } from '@bigdelta/lib-shared';
import { TrendsConfig } from './trends/TrendsConfig';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { VisualizationSelect } from './VisualizationSelect';
import LineChartIcon from '../../../assets/icons/line-chart-up-01.svg?react';
import BarChartIcon from '../../../assets/icons/bar-chart-02.svg?react';

import { FunnelConfig } from './funnel/FunnelConfig';
import { FunnelBreakdown } from './FunnelBreakdown';
import { QueryReportsCreateData } from '@bigdelta/lib-api-client';
import { UseQueryResult } from '@tanstack/react-query';

export const ReportConfig = ({ reportQuery }: { reportQuery: UseQueryResult<QueryReportsCreateData, unknown> }) => {
  const { reportType, reset } = useReportStore();

  const location = useLocation();

  useEffect(() => {
    reset();
  }, [location.pathname, reset]);

  switch (reportType) {
    case ReportTypeVO.CHART:
      return (
        <div className="gap-y- flex flex-col">
          <TrendsConfig reportQuery={reportQuery} />
          <VisualizationSelect
            items={[
              { label: 'Line', value: ChartType.LINE, icon: LineChartIcon },
              { label: 'Bar', value: ChartType.STACKED, icon: BarChartIcon },
              { label: 'Horizontal bar', value: ChartType.HORIZONTAL, icon: BarChartIcon, iconClassName: 'rotate-90' },
            ]}
          />
        </div>
      );
    case ReportTypeVO.FUNNEL:
      return (
        <div className="gap-y- flex flex-col">
          <FunnelConfig />
          <FunnelBreakdown disabled />
          <VisualizationSelect items={[{ label: 'Funnel steps', value: ChartType.STACKED, icon: BarChartIcon }]} />
        </div>
      );
    case ReportTypeVO.RETENTION:
      return null;
    case ReportTypeVO.MAPS:
      return null;
    case null:
    default:
      return <ReportTypeSelector />;
  }
};
