import { useState, useRef, forwardRef } from 'react';
import { Key } from 'ts-key-enum';

import { Tag } from '../../components/Tag';
import { useOutsideClickHandler } from '../../hooks/useOutsideClickHandler';
import { PropsWithClassName } from '../../../types';
import { twMerge } from '../../../utils/twMerge';

interface TagsEditProps extends PropsWithClassName {
  value: Array<string> | undefined;
  name?: string;
  onChange?: (value: Array<string>) => void;
  onBlur?: () => void;
  editable?: boolean;
  onClose?: () => void;
}

type CustomInputProps = Omit<HTMLInputElement, 'onChange'>;

export const TagsEdit = forwardRef<CustomInputProps, TagsEditProps>(({ value = [], onChange, onClose, className, editable, ...inputProps }, ref) => {
  const [focusedTagIndex, setFocusedTagIndex] = useState<number | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const containerRef = useRef<HTMLDivElement | null>(null);

  const removeLastTag = () => {
    const newValue = value.slice(0, -1);
    onChange?.(newValue);
  };

  const removeTagAtIndex = (index: number) => {
    const newValue = value.filter((_, i) => i !== index);
    onChange?.(newValue);
    setFocusedTagIndex((prevIndex) => (prevIndex !== null && prevIndex > 0 ? prevIndex - 1 : null));
  };

  const handleBackspace = () => {
    if (inputValue === '' && value.length > 0) {
      if (focusedTagIndex !== null) {
        removeTagAtIndex(focusedTagIndex);
      } else {
        removeLastTag();
      }
    }
  };

  const handleArrowLeft = () => {
    if (inputValue !== '') return;

    setFocusedTagIndex((prevIndex) => {
      if (prevIndex === null) {
        return value.length - 1;
      } else {
        return Math.max(prevIndex - 1, 0);
      }
    });
  };

  const handleArrowRight = () => {
    if (inputValue === '') {
      setFocusedTagIndex((prevIndex) => {
        if (prevIndex === null) {
          return 0;
        }

        if (prevIndex < value.length - 1) {
          return prevIndex + 1;
        }

        return null;
      });
    }
  };

  const handleEnter = () => {
    if (inputValue.trim() !== '') {
      const newValue = [...value, inputValue.trim()];
      onChange?.(newValue);
      setInputValue('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case Key.Backspace:
        handleBackspace();
        break;
      case Key.ArrowLeft:
        handleArrowLeft();
        break;
      case Key.ArrowRight:
        handleArrowRight();
        break;
      case Key.Enter:
        e.preventDefault();
        handleEnter();
        break;
      default:
        break;
    }
  };

  const handleTagClick = (index: number) => {
    setFocusedTagIndex(index);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setFocusedTagIndex(null);
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      onClose?.();
      setFocusedTagIndex(null);
    }
  };

  const handleKeyDownEscape = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      onClose?.();
      setFocusedTagIndex(null);
    }
  };

  useOutsideClickHandler(containerRef, () => {
    onClose?.();
    setFocusedTagIndex(null);
  });

  return (
    <div
      ref={containerRef}
      className={twMerge('flex min-h-6 flex-wrap items-center justify-start gap-2 font-regular', className)}
      onMouseDown={handleMouseDown}
      onKeyDown={handleKeyDownEscape}
    >
      {value.map((element, index) => (
        <Tag
          key={`${JSON.stringify(element)}-${index}`}
          value={element}
          onRemove={editable && onChange ? () => removeTagAtIndex(index) : undefined}
          className={focusedTagIndex === index ? 'ring-2 ring-m-blue-400' : ''}
          onClick={() => handleTagClick(index)}
        />
      ))}
      {editable && onChange && (
        <input
          ref={ref}
          {...inputProps}
          autoFocus
          className="border-0 bg-transparent p-0 text-sm focus:border-0 focus:outline-none focus:ring-0"
          value={inputValue}
          onKeyDown={handleKeyDown}
          onChange={handleInputChange}
        />
      )}
    </div>
  );
});
