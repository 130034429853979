import { FC, useState, useRef, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { LinkTag } from '../../../features/records/components/LinkTag';
import { Badge } from '../../components/Badge';
import { Tag } from '../../components/Tag';
import { PropsWithClassName } from '../../../types';

const DEFAULT_BADGE_WIDTH = 50;
const TAG_GAP = 8; // gap-2

const isValidURL = (_: string | null | number | boolean) => false; // URL_PATTERN.test(value as string);

interface TagsViewProps extends PropsWithClassName {
  value: Array<string>;
  showAll?: boolean;
}

const PlaceholderTag = () => <Badge>&nbsp;</Badge>;

export const TagsView: FC<TagsViewProps> = ({ value = [], showAll = false, className }) => {
  const [visibleTags, setVisibleTags] = useState<Array<string>>(showAll ? value : value.slice(0, 1));

  const defaultHiddenCount = value.length > 0 ? value.length - 1 : 0;
  const [hiddenCount, setHiddenCount] = useState(showAll ? 0 : defaultHiddenCount);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const tagRefs = useRef<Array<HTMLDivElement | HTMLAnchorElement | null>>([]);
  const maxWidthBadgeRef = useRef<HTMLDivElement | null>(null);

  const [hiddenCountHasHover, setHiddenCountHasHover] = useState(false);

  useEffect(() => {
    const calculateVisibleTags = () => {
      const container = containerRef.current;
      if (!container) return;

      const containerWidth = container.offsetWidth;
      let currentWidth = maxWidthBadgeRef.current?.offsetWidth ?? DEFAULT_BADGE_WIDTH;
      let visibleCount = 0;

      for (let i = 0; i < value.length; i++) {
        const tagElement = tagRefs.current[i];
        if (tagElement) {
          const tagWidth = tagElement.scrollWidth + TAG_GAP;
          if (currentWidth + tagWidth > containerWidth) {
            break;
          }
          currentWidth += tagWidth;
          visibleCount++;
        }
      }

      setVisibleTags(value.slice(0, visibleCount));
      setHiddenCount(Math.max(0, value.length - visibleCount));
    };

    if (!showAll) {
      calculateVisibleTags();
      window.addEventListener('resize', calculateVisibleTags);
    }

    return () => {
      window.removeEventListener('resize', calculateVisibleTags);
    };
  }, [value, containerRef, maxWidthBadgeRef, showAll]);

  return (
    <>
      <div className={twMerge('flex min-h-6 flex-wrap items-start justify-start gap-2 font-regular', className)} ref={containerRef}>
        <Badge variant="grey" className="pointer-events-none invisible absolute cursor-pointer" ref={maxWidthBadgeRef}>
          Show more
        </Badge>
        {!value.length && <PlaceholderTag />}
        {visibleTags.map((element, index) =>
          isValidURL(element) ? (
            <LinkTag
              key={`${JSON.stringify(element)}-${index}`}
              value={element as string}
              ref={(el) => {
                tagRefs.current[index] = el;
              }}
            />
          ) : (
            <Tag key={`${JSON.stringify(element)}-${index}`} value={element} ref={(el) => (tagRefs.current[index] = el)} />
          )
        )}
        {hiddenCount > 0 && (
          <Badge
            variant="grey"
            className={twMerge('cursor-pointer')}
            onMouseEnter={() => setHiddenCountHasHover(true)}
            onMouseLeave={() => setHiddenCountHasHover(false)}
          >
            {hiddenCountHasHover ? 'Show more' : `+${hiddenCount}`}
          </Badge>
        )}
      </div>
    </>
  );
};
