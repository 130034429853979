import { EventsQueryRequestQueryDef } from '@bigdelta/lib-api-client';
import { FilterItem, FilterItemType } from '../../../shared/filters/store';
import { getRelatedRecordConditions } from '../../../shared/utils/getRelatedRecordConditions';
import { QueryValueFilterOperator, ResourcePropertyType } from '@bigdelta/lib-shared';

export const getMetricsFilterConditions = (filterItems: FilterItem[] | null): EventsQueryRequestQueryDef['conditions'] => {
  if (!filterItems?.length) {
    return [];
  }

  return filterItems.reduce<EventsQueryRequestQueryDef['conditions']>(
    (acc, item) => {
      if (item.itemType === FilterItemType.METRICS_PROPERTY) {
        if (!item.property) {
          return acc;
        }

        if (item.propertyOperator && [QueryValueFilterOperator.IS_SET, QueryValueFilterOperator.NOT_IS_SET].includes(item.propertyOperator)) {
          return [
            ...acc,
            {
              event_property: {
                property_id: item.property.attributeId,
                property_type: item.property.attributeType as ResourcePropertyType,
                name: item.property.attributeName,
                operator: item.propertyOperator,
              },
            },
          ];
        }
        if (item.propertyOperator && item.data.value) {
          return [
            ...acc,
            {
              event_property: {
                property_id: item.property.attributeId,
                property_type: item.property.attributeType as ResourcePropertyType,
                name: item.property.attributeName,
                operator: item.propertyOperator,
                value: item.data.value,
              },
            },
          ];
        }
        return acc;
      }

      if (item.itemType === FilterItemType.METRICS_RECORD_PROPERTY) {
        if (!item.property) {
          return acc;
        }

        if (
          item.propertyRelationships.length &&
          item.propertyOperator &&
          [QueryValueFilterOperator.IS_SET, QueryValueFilterOperator.NOT_IS_SET].includes(item.propertyOperator)
        ) {
          return [
            ...acc,
            ...getRelatedRecordConditions(item.propertyRelationships, {
              property_id: item.property.attributeId,
              property_type: item.property.attributeType as ResourcePropertyType,
              name: item.property.attributeName,
              operator: item.propertyOperator,
            }),
          ];
        }
        if (item.data?.value && item.propertyRelationships.length && item.propertyOperator) {
          return [
            ...acc,
            ...getRelatedRecordConditions(item.propertyRelationships, {
              property_id: item.property.attributeId,
              property_type: item.property.attributeType as ResourcePropertyType,
              name: item.property.attributeName,
              operator: item.propertyOperator,
              value: item.data.value,
            }),
          ];
        }
      }
      return acc;
    },
    [] as EventsQueryRequestQueryDef['conditions']
  );
};
