import { useReportStore } from '../../store/Report';
import { TrendsBuilder } from './TrendsBuilder';
import { DataSelect } from './DataSelect';
import { AddFormulaButton } from './AddFormulaButton';
import { TrendsBuilderType } from '../../store/TrendsBuilder';
import { v4 as uuid } from 'uuid';
import { byAggregationResult, getNegativeColor, getPositiveColor } from '../../utils/getDataMap';
import { UseQueryResult } from '@tanstack/react-query';
import { QueryReportsCreateData } from '@bigdelta/lib-api-client';

export const TrendsConfig = ({ reportQuery }: { reportQuery: UseQueryResult<QueryReportsCreateData> }) => {
  const {
    report: {
      trends: { builders },
    },
    addTrendsBuilder,
  } = useReportStore();
  const handleAddFormulaBuilder = () => {
    const name = String.fromCharCode(builders.length + 65);

    const defaultExpression = builders.map((builder) => builder.name).join('+');

    addTrendsBuilder({
      id: uuid(),
      name,
      type: TrendsBuilderType.FORMULA,
      data: {
        [TrendsBuilderType.FORMULA]: {
          expression: defaultExpression,
        },
      },
    });
  };
  const sortedQueries = reportQuery.data?.queries.sort(byAggregationResult) || [];

  const getColor = (builderName: string) => {
    const queryIndex = sortedQueries.findIndex((query) => query?.metadata.name === builderName);
    const avg = reportQuery.data?.queries[queryIndex]?.metadata.aggregation.result[0]?.average || 1;

    return avg >= 0 ? getPositiveColor(queryIndex) : getNegativeColor(queryIndex);
  };

  return (
    <div className="flex flex-col gap-y-2 py-4">
      <div className="flex items-center justify-between ">
        <div className="text-md font-medium">Data</div>
        <DataSelect />
      </div>
      <div className="flex flex-col gap-y-2">
        {builders.map((builder) => (
          <TrendsBuilder id={builder.id} key={builder.id} color={getColor(builder.name)} />
        ))}
        <AddFormulaButton onClick={handleAddFormulaBuilder} />
      </div>
    </div>
  );
};
