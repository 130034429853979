import { FC, useCallback, useRef, useState } from 'react';
import { CurrencyProperty } from '../types';
import { Select, SelectItem } from '../../ui/Select/Select';
import { useOutsideClickHandler } from '../../hooks/useOutsideClickHandler';
import { ResourcePropertyType } from '@bigdelta/lib-shared';
import { Key } from 'ts-key-enum';
import { CurrencyCode } from './const';
import { getStringValue } from './utils';

interface CurrencyEditProps {
  value: CurrencyProperty | undefined | null;
  onChange: (value: CurrencyProperty | undefined | null) => void;
}

const DEFAULT_VALUE = {
  currency_code: CurrencyCode.USD,
  amount_in_cents: '',
};

export const CurrencyEdit: FC<CurrencyEditProps> = ({ value, onChange }) => {
  const [currencyCode, setCurrencyCode] = useState(value?.currency_code || DEFAULT_VALUE.currency_code);
  const [amountInUnits, setAmountInUnits] = useState(getStringValue(value ? value?.amount_in_cents : undefined) || DEFAULT_VALUE.amount_in_cents);

  const [isCurrencyOpen, setIsCurrencyOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleInputRefChange = useCallback((node: HTMLInputElement | null) => {
    inputRef.current = node;
    if (node) {
      node.focus();
    }
  }, []);

  const handleCurrencyChange = (value: string) => {
    setCurrencyCode(value);
    // Set timeout is needed here to get around Radix Select focus trap
    setTimeout(() => {
      inputRef.current?.focus();
    }, 1);
  };

  const handleChange = () => {
    if (amountInUnits.length === 0) {
      onChange(undefined);
      return;
    }

    onChange({
      $type: ResourcePropertyType.CURRENCY,
      currency_code: currencyCode,
      amount_in_cents: parseFloat(amountInUnits) * 100,
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Key.Enter) {
      handleChange();
    }
  };

  useOutsideClickHandler(containerRef, () => {
    if (!isCurrencyOpen) {
      handleChange();
    }
  });

  const numberInputOnWheelPreventChange = (e: React.WheelEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    target.blur();

    e.stopPropagation();

    setTimeout(() => {
      target.focus();
    }, 0);
  };

  return (
    <div className="flex grow items-baseline gap-x-1 overflow-hidden" ref={containerRef}>
      <Select
        value={currencyCode}
        onValueChange={handleCurrencyChange}
        onOpenChange={(open) => {
          setIsCurrencyOpen(open);
        }}
        size="sm"
        className="rounded-none border-y-0 border-l-0 bg-transparent uppercase focus:border-0 focus:outline-none focus:ring-0"
      >
        {Object.values(CurrencyCode).map((code) => (
          <SelectItem key={code} value={code} className="uppercase">
            {code}
          </SelectItem>
        ))}
      </Select>
      <input
        type="number"
        value={amountInUnits}
        ref={handleInputRefChange}
        onChange={(e) => setAmountInUnits(e.target.value)}
        onKeyDown={handleKeyDown}
        className="min-w-0 max-w-80 shrink grow rounded-none border-0 bg-transparent p-0 px-1 text-sm focus:ring-0"
        onWheel={numberInputOnWheelPreventChange}
      />
    </div>
  );
};
