import * as SelectPrimitive from '@radix-ui/react-select';
import { ComponentType, FC, PropsWithChildren, forwardRef } from 'react';
import { PropsWithClassName } from '../types';
import { twMerge } from 'tailwind-merge';

interface SelectItemContentProps {
  label?: string;
  icon?: ComponentType<PropsWithClassName>;
}

const TriggerItemContent: FC<SelectItemContentProps> = ({ label, icon }) => {
  const Icon = icon;
  return (
    <div className="flex items-center gap-x-1.5 p-2">
      {Icon && <Icon className="h-5 w-5 text-m-olive-600" />}
      <span className="whitespace-nowrap text-xs leading-tight text-m-olive-400">{label}</span>
    </div>
  );
};

const SelectItemContent: FC<SelectItemContentProps> = ({ label, icon }) => {
  const Icon = icon;

  return (
    <div className="flex items-center gap-x-1.5 px-3 py-1.5">
      {Icon && <Icon className="h-5 w-5 text-m-olive-600" />}
      <span className="whitespace-nowrap text-md font-medium text-m-olive-600">{label}</span>
    </div>
  );
};

interface SelectItemProps extends PropsWithChildren, SelectPrimitive.SelectItemProps, SelectItemContentProps {}

const Item = forwardRef<HTMLDivElement, SelectItemProps>(({ icon, label, ...props }, ref) => {
  return (
    <SelectPrimitive.Item className="cursor-default outline-none hover:bg-m-gray-200 radix-highlighted:bg-m-gray-200" {...props} ref={ref}>
      <SelectItemContent icon={icon} label={label} />
    </SelectPrimitive.Item>
  );
});

interface ContentProps extends SelectPrimitive.SelectPortalProps, PropsWithClassName {
  fullWidth?: boolean;
}

const Content = forwardRef<HTMLDivElement, ContentProps>(({ fullWidth = false, children, className, ...props }, ref) => {
  return (
    <SelectPrimitive.Portal {...props}>
      <SelectPrimitive.Content
        position="popper"
        className={twMerge(
          'mt-1 max-h-64 overflow-hidden rounded-lg border border-m-gray-300 bg-m-white',
          fullWidth && 'w-[var(--radix-select-trigger-width)]',
          className
        )}
        ref={ref}
      >
        <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  );
});

interface TriggerProps extends SelectPrimitive.SelectTriggerProps, SelectItemContentProps {
  placeholder: string;
}

const Trigger = forwardRef<HTMLButtonElement, TriggerProps>(({ label, icon, placeholder, className, ...props }, ref) => {
  return (
    <SelectPrimitive.Trigger
      className={twMerge('flex items-center justify-between rounded group-hover:bg-m-gray-100 radix-state-open:bg-m-gray-100', className)}
      {...props}
      ref={ref}
    >
      <SelectPrimitive.Value placeholder={<SelectItemContent label={placeholder} />}>
        <TriggerItemContent label={label} icon={icon} />
      </SelectPrimitive.Value>
      {/*<SelectPrimitive.Icon>*/}
      {/*  <ChevronDownIcon className="mr-3 h-5 w-5 text-m-olive-400" />*/}
      {/*</SelectPrimitive.Icon>*/}
    </SelectPrimitive.Trigger>
  );
});

const Root = SelectPrimitive.Root;

export { Item, Content, Trigger, Root, TriggerItemContent };
