import { FC } from 'react';
import { Checkbox } from '../../ui/Checkbox/Checkbox';
import { EditablePropertyType } from '../types';
import { twMerge } from '../../../utils/twMerge';

type BooleanTextProps = EditablePropertyType<boolean>;

export const BooleanText: FC<BooleanTextProps> = ({ value, onChange, disabled }) => {
  return (
    <div className={twMerge('flex w-full rounded-md px-2 py-2 focus-within:ring-2 focus-within:ring-m-blue-400', !disabled && 'hover:bg-m-gray-300')}>
      <Checkbox checked={value ?? false} onChange={onChange} disabled={disabled} />
    </div>
  );
};
