import React, { useState, useEffect } from 'react';
import { DateTimeEdit } from './DateTimeEdit';
import { DateTimeView } from './DateTimeView';
import { EditablePropertyType } from '../types';
import { twMerge } from '../../../utils/twMerge';

export interface DateTimeProps extends EditablePropertyType<string> {
  overflowVisible?: boolean;
}

export const DateTime: React.FC<DateTimeProps> = ({ value, onChange, disabled }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [dateTimeValue, setDateTimeValue] = useState(value ?? '');

  useEffect(() => {
    setDateTimeValue(value ?? '');
  }, [value]);

  const handleStartEdit = () => {
    if (!disabled) {
      setIsEditing(true);
    }
  };

  const handleSave = (newValue: string) => {
    setDateTimeValue(newValue);
    setIsEditing(false);
    onChange?.(newValue);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <div
      className={twMerge(
        'cursor-Datetime flex w-full rounded-md px-2 py-1 focus-within:ring-2 focus-within:ring-m-blue-400',
        !disabled && 'hover:bg-m-gray-300'
      )}
    >
      {isEditing ? (
        <DateTimeEdit value={dateTimeValue} onSave={handleSave} onCancel={handleCancel} className="w-full rounded-md text-sm" />
      ) : (
        <DateTimeView value={dateTimeValue} onClick={handleStartEdit} className="grow" />
      )}
    </div>
  );
};
