import { TableColumnType, TableResourceType, TableSortDirection } from '@bigdelta/lib-shared';
import { MembersConfigTableLayoutListData } from '@bigdelta/lib-api-client';
import { useCallback, useMemo } from 'react';
import { ColumnAction } from '../components/DataTableColumnActions';
import { produce } from 'immer';
import { ColumnActionType } from '../types.ts';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
export interface UseTableLayoutArgs {
  resourceType: TableResourceType;
  resourceId?: string;
  labelProperty?: string;
  onChange: (layout: MembersConfigTableLayoutListData) => void;
  layout: MembersConfigTableLayoutListData | undefined;
}

const createColumn = (column: MembersConfigTableLayoutListData['columns'][number]) => {
  return {
    ...column,
    id: uuidv4(),
  };
};

export const useTableLayout = ({ resourceType, labelProperty, onChange, layout }: UseTableLayoutArgs) => {
  const moveColumn = useCallback(
    (direction: 'left' | 'right', column: MembersConfigTableLayoutListData['columns'][number]) => {
      return produce(layout, (draft) => {
        if (!draft) {
          return;
        }

        const fromIndex = draft.columns.findIndex((col) => col.id === column.id);
        const toIndex = direction === 'left' ? fromIndex - 1 : fromIndex + 1;

        if (toIndex >= 1 && toIndex < draft?.columns.length) {
          const [movedElement] = draft.columns.splice(fromIndex, 1);
          draft.columns.splice(toIndex, 0, movedElement);
        }

        draft.columns.forEach((col, colIdx) => {
          col.order = colIdx;
        });
      });
    },
    [layout]
  );

  const addEventColumn = useCallback(
    (action: ColumnAction, column: MembersConfigTableLayoutListData['columns'][number] | undefined) => {
      if (action.type !== ColumnActionType.ADD_LEFT && action.type !== ColumnActionType.ADD_RIGHT && action.type !== ColumnActionType.ADD_TO_END) {
        return;
      }

      const relationshipsDepth = action.data[action.type]?.relationships?.length ?? 0;

      if (relationshipsDepth > 0) {
        const propertyToAdd = action.data?.[action.type]?.property;
        const relationships = action.data?.[action.type]?.relationships;

        if (!propertyToAdd || !relationships) {
          return;
        }

        const rels = relationships;

        const nestedRelationships = rels.reduceRight<MembersConfigTableLayoutListData['columns'][number][TableColumnType.RELATIONSHIP] | null>(
          (acc, curr, index) => ({
            name: curr.relationshipName || '',
            ...(acc ? { relationship: acc, type: TableColumnType.RELATIONSHIP } : { property: propertyToAdd, type: TableColumnType.PROPERTY }),
            ...(index === 0 ? { property: propertyToAdd } : {}),
          }),
          null
        );

        const payload = produce(layout, (draft) => {
          if (!draft) {
            return;
          }

          const idx = draft.columns.findIndex((col) => col.id === column?.id);

          if (idx === 0 && action.type === ColumnActionType.ADD_LEFT) {
            return;
          }

          const insertAtIdx = idx + (action.type === ColumnActionType.ADD_LEFT ? 0 : 1);

          draft.columns.splice(
            insertAtIdx,
            0,
            createColumn({
              order: -1,
              sort: null,
              type: 'relationship',
              relationship: nestedRelationships,
            })
          );

          draft.columns.forEach((col, colIdx) => {
            col.order = colIdx;
          });
        });

        return payload;
      } else {
        const propertyToAdd = action.data?.[action.type]?.property;

        if (!propertyToAdd) {
          return;
        }

        return produce(layout, (draft) => {
          if (!draft) {
            return;
          }

          const idx = draft.columns.findIndex((col) => col.id === column?.id);

          if (idx === 0 && action.type === ColumnActionType.ADD_LEFT) {
            return;
          }

          const insertAtIdx = idx + (action.type === ColumnActionType.ADD_LEFT ? 0 : 1);

          draft.columns.splice(
            insertAtIdx,
            0,
            createColumn({
              order: -1,
              sort: null,
              type: 'property',
              property: {
                property_id: propertyToAdd.property_id,
                property_name: propertyToAdd.property_name,
              },
            })
          );

          draft.columns.forEach((col, colIdx) => {
            col.order = colIdx;
          });
        });
      }
    },
    [layout]
  );

  const addColumn = useCallback(
    (action: ColumnAction, column: MembersConfigTableLayoutListData['columns'][number] | undefined) => {
      if (action.type !== ColumnActionType.ADD_LEFT && action.type !== ColumnActionType.ADD_RIGHT && action.type !== ColumnActionType.ADD_TO_END) {
        return;
      }

      if (resourceType === TableResourceType.EVENT) {
        return addEventColumn(action, column);
      }

      const relationshipsDepth = action.data[action.type]?.relationships?.length ?? 0;

      if (relationshipsDepth > 1) {
        const propertyToAdd = action.data?.[action.type]?.property;
        const relationships = action.data?.[action.type]?.relationships;

        if (!propertyToAdd || !relationships) {
          return;
        }

        const [_topLevelRelationship, ...rels] = relationships;

        const nestedRelationships = rels.reduceRight<MembersConfigTableLayoutListData['columns'][number][TableColumnType.RELATIONSHIP] | null>(
          (acc, curr, index) => ({
            name: curr.relationshipName || '',
            ...(acc ? { relationship: acc, type: TableColumnType.RELATIONSHIP } : { property: propertyToAdd, type: TableColumnType.PROPERTY }),
            ...(index === 0 ? { property: propertyToAdd } : {}),
          }),
          null
        );

        const payload = produce(layout, (draft) => {
          if (!draft) {
            return;
          }

          const idx = draft.columns.findIndex((col) => col.id === column?.id);

          if (idx === 0 && action.type === ColumnActionType.ADD_LEFT) {
            return;
          }

          const insertAtIdx = idx + (action.type === ColumnActionType.ADD_LEFT ? 0 : 1);

          draft.columns.splice(
            insertAtIdx,
            0,
            createColumn({
              order: -1,
              sort: null,
              type: 'relationship',
              relationship: nestedRelationships,
            })
          );

          draft.columns.forEach((col, colIdx) => {
            col.order = colIdx;
          });
        });

        return payload;
      } else {
        const propertyToAdd = action.data?.[action.type]?.property;

        if (!propertyToAdd) {
          return layout;
        }

        return produce(layout, (draft) => {
          if (!draft) {
            return;
          }

          const idx = draft.columns.findIndex((col) => col.id === column?.id);

          if (idx === 0 && action.type === ColumnActionType.ADD_LEFT) {
            return;
          }

          const insertAtIdx = idx + (action.type === ColumnActionType.ADD_LEFT ? 0 : 1);

          draft.columns.splice(
            insertAtIdx,
            0,
            createColumn({
              order: -1,
              sort: null,
              type: 'property',
              property: {
                property_id: uuidv5(propertyToAdd.property_name, import.meta.env.VITE_APP_METADATA_PROPERTY_NAMESPACE),
                property_name: propertyToAdd.property_name,
              },
            })
          );

          draft.columns.forEach((col, colIdx) => {
            col.order = colIdx;
          });
        });
      }
    },
    [addEventColumn, layout, resourceType]
  );

  const hideColumn = useCallback(
    (column: MembersConfigTableLayoutListData['columns'][number]) => {
      return produce(layout, (draft) => {
        if (!draft) {
          return;
        }

        const idx = draft.columns.findIndex((col) => col.id === column?.id);

        if (idx === -1) {
          return;
        }

        draft.columns.splice(idx, 1);

        draft.columns.forEach((col, colIdx) => {
          col.order = colIdx;
        });
      });
    },
    [layout]
  );

  // TODO: doesnt work if adding after related column
  const addColumnToEnd = useCallback(
    (action: ColumnAction) => {
      if (action.type === ColumnActionType.ADD_TO_END) {
        const lastCol = layout?.columns[layout.columns.length - 1];

        return addColumn(action, lastCol);
      }
    },
    [addColumn, layout?.columns]
  );

  const sortColumn = useCallback(
    (column: MembersConfigTableLayoutListData['columns'][number], sort: TableSortDirection) => {
      return produce(layout, (draft) => {
        if (!draft) {
          return;
        }

        draft.columns.forEach((col) => {
          if (col.id === column.id) {
            // Toggle action - reset sort
            if (col.sort === sort) {
              col.sort = null;
              return;
            }

            col.sort = sort;
            return;
          }
          col.sort = null;
        });
      });
    },
    [layout]
  );

  // TODO: passing column id would be enough here instad of the full column definition
  const onActionSelect = useCallback(
    (propertyName: string, action: ColumnAction, column?: MembersConfigTableLayoutListData['columns'][number]) => {
      if (resourceType === TableResourceType.OBJECT && propertyName === labelProperty) {
        if ([ColumnActionType.HIDE, ColumnActionType.ADD_LEFT, ColumnActionType.MOVE_LEFT, ColumnActionType.MOVE_RIGHT].includes(action.type)) {
          return;
        }
      }

      let payload: MembersConfigTableLayoutListData | undefined;

      switch (action.type) {
        case ColumnActionType.ADD_LEFT:
        case ColumnActionType.ADD_RIGHT:
          payload = addColumn(action, column);
          break;
        case ColumnActionType.ADD_TO_END:
          payload = addColumnToEnd(action);
          break;
        case ColumnActionType.MOVE_LEFT:
          payload = column ? moveColumn('left', column) : undefined;
          break;
        case ColumnActionType.MOVE_RIGHT:
          payload = column ? moveColumn('right', column) : undefined;
          break;
        case ColumnActionType.SORT:
          payload =
            column && action.data[ColumnActionType.SORT]?.direction ? sortColumn(column, action.data[ColumnActionType.SORT]?.direction) : undefined;
          break;
        case ColumnActionType.HIDE:
          payload = column ? hideColumn(column) : undefined;
          break;
        default:
          return;
      }

      if (!payload) {
        return;
      }

      payload = produce(payload, (draft) => {
        if (draft.columns[0].type !== TableColumnType.LABEL) {
          draft.columns.unshift(createColumn({ type: TableColumnType.LABEL, order: -1 }));
          draft.columns = draft.columns.filter((col, index) => index === 0 || col.type !== TableColumnType.LABEL);
        }

        draft.columns.forEach((col, idx) => {
          col.order = idx;
        });
      });

      onChange(payload);
    },
    [addColumn, addColumnToEnd, hideColumn, labelProperty, moveColumn, resourceType, sortColumn, onChange]
  );

  const extractRelationshipInfo = useCallback(
    (
      nested: MembersConfigTableLayoutListData['columns'][number]['relationship'] | null
    ): {
      names: string[];
      property?: { property_id: string; property_name: string } | null;
    } => {
      const unfold = (
        current: MembersConfigTableLayoutListData['columns'][number]['relationship'] | null,
        names: string[] = []
      ): { names: string[]; property?: { property_id: string; property_name: string } | null } => {
        if (!current) {
          return { names };
        }

        const updatedNames = [...names, current.name];

        if (current.relationship) {
          return unfold(current.relationship, updatedNames);
        } else {
          return {
            names: updatedNames,
            property: current.property,
          };
        }
      };

      return unfold(nested);
    },
    []
  );

  return useMemo(() => ({ onActionSelect, extractRelationshipInfo }), [onActionSelect, extractRelationshipInfo]);
};
