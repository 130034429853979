import { useAccount } from '../../auth/hooks/useAccount.tsx';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import QuestionIcon from '../../../assets/icons/question.svg?react';
import { Link } from 'react-router-dom';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { useQuery } from '@tanstack/react-query';

import { SkeletonBlock } from '../../../components/SkeletonBlock.tsx';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

export const BillingPage = () => {
  const { currentAccountId } = useAccount();
  const queryKeys = useQueryKeys();

  const billingStatsQuery = useQuery({
    queryKey: queryKeys.billing(),
    queryFn: () => bigdeltaAPIClient.v1.billingStatsList({ account_id: currentAccountId }),
  });

  const currentDate = new Date();
  const nextMonthFirstDay = new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth() + 1, 1));
  const nextMonthFirstDayFormatted = nextMonthFirstDay
    .toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
    .replace(/(\d{1,2}) (\d{4})/, '$1, $2');

  return (
    <div className="flex h-screen w-full flex-col items-center">
      <div className="flex w-full max-w-screen-md flex-col gap-8">
        <div className="flex flex-col">
          <div className="text-2xl font-medium text-m-olive-900">Plan & Billing</div>
          <div className="flex items-center gap-1 text-md text-m-olive-500">
            View your account current plan or switch plan according to your needs
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex w-full flex-col">
            <div className="pb-3 text-xl font-medium text-m-olive-900">Plan</div>
            <div className="flex w-full flex-row">
              <div className="w-1/2">
                <div className="flex w-full flex-col">
                  <div className="text-medium text-l pb-4 text-m-olive-600">
                    Free
                    <span className="ml-1 rounded-full border border-m-green-200 bg-m-blue-100 px-2 text-xs font-regular text-m-blue-700">
                      monthly
                    </span>
                  </div>
                  <span className="text-m font-regular text-m-olive-500">0€ per 100K Monthly Events</span>
                </div>
              </div>
              <div className="w-1/2">
                <div className="flex w-full flex-col">
                  <div className="text-medium text-l pb-4 text-m-olive-600">Billing Period</div>
                  <span className="text-m font-regular text-m-olive-500">Monthly</span>
                </div>
              </div>
            </div>
            <Link target="_blank" to={'https://www.bigdelta.com/pricing'} className="mt-3">
              <Button intent="brand" label="Explore plans" size="lg" />
            </Link>
            <hr className="my-4 h-px bg-m-gray-300" />
            <div className="flex w-full flex-col">
              <div className="pb-3 text-xl font-medium text-m-olive-900">Usage</div>
              <span className="text-m flex items-center gap-1 font-regular text-m-olive-500">
                <span>
                  {billingStatsQuery.isLoading && <SkeletonBlock className="h-5 w-10" />}
                  {!billingStatsQuery.isLoading && billingStatsQuery.data?.current_month_total_tracked_events}
                </span>
                used / 100 000 included
              </span>
              <div className="flex items-center gap-1 pt-2 text-xs text-m-olive-500">
                <QuestionIcon className="h-4 w-4" />
                <span>Limits will reset on</span>
                <span>{nextMonthFirstDayFormatted}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
