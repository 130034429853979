import { QueryValueFilterOperator, QueryFrequencyValueFilterOperator, ResourcePropertyType } from '@bigdelta/lib-shared';

export enum ValueSelectType {
  TEXT_FIELD = 'TEXT_FIELD',
  COMBOBOX_SINGLE = 'COMBOBOX_SINGLE',
  COMBOBOX_MULTIPLE = 'COMBOBOX_MULTIPLE',
  NUMBER_FIELD = 'NUMBER_FIELD',
  DATE_FIELD = 'DATE_FIELD',
  DATE_RANGE_FIELD = 'DATE_RANGE_FIELD',
}

// TODO: add type back in when all operators implemented
// type MapType = Record<'string' | 'list', Record<RecordQueryFilterOperator, ValueSelectType>>

export const attributeTypeOperatorValueSelectMap = {
  string: {
    [QueryValueFilterOperator.EQUALS]: ValueSelectType.COMBOBOX_SINGLE,
    [QueryValueFilterOperator.NOT_EQUALS]: ValueSelectType.COMBOBOX_SINGLE,
    [QueryValueFilterOperator.CONTAINS]: ValueSelectType.TEXT_FIELD,
    [QueryValueFilterOperator.NOT_CONTAINS]: ValueSelectType.TEXT_FIELD,
    [QueryValueFilterOperator.IN]: ValueSelectType.COMBOBOX_MULTIPLE,
    [QueryValueFilterOperator.NOT_IN]: ValueSelectType.COMBOBOX_MULTIPLE,
    [QueryValueFilterOperator.STARTS_WITH]: ValueSelectType.TEXT_FIELD,
    [QueryValueFilterOperator.NOT_STARTS_WITH]: ValueSelectType.TEXT_FIELD,
    [QueryValueFilterOperator.ENDS_WITH]: ValueSelectType.TEXT_FIELD,
    [QueryValueFilterOperator.NOT_ENDS_WITH]: ValueSelectType.TEXT_FIELD,
    [QueryValueFilterOperator.IS_SET]: null,
    [QueryValueFilterOperator.NOT_IS_SET]: null,
  },
  'string[]': {
    [QueryValueFilterOperator.EQUALS]: ValueSelectType.COMBOBOX_MULTIPLE,
    [QueryValueFilterOperator.CONTAINS]: ValueSelectType.COMBOBOX_MULTIPLE,
    [QueryValueFilterOperator.NOT_EQUALS]: ValueSelectType.COMBOBOX_MULTIPLE,
    [QueryValueFilterOperator.NOT_CONTAINS]: ValueSelectType.COMBOBOX_MULTIPLE,
    [QueryValueFilterOperator.IS_SET]: null,
    [QueryValueFilterOperator.NOT_IS_SET]: null,
  },
  number: {
    [QueryValueFilterOperator.LESS_THAN]: ValueSelectType.NUMBER_FIELD,
    [QueryValueFilterOperator.AT_MOST]: ValueSelectType.NUMBER_FIELD,
    [QueryValueFilterOperator.EQUALS]: ValueSelectType.NUMBER_FIELD,
    [QueryValueFilterOperator.AT_LEAST]: ValueSelectType.NUMBER_FIELD,
    [QueryValueFilterOperator.MORE_THAN]: ValueSelectType.NUMBER_FIELD,
    [QueryValueFilterOperator.IS_SET]: null,
    [QueryValueFilterOperator.NOT_IS_SET]: null,
  },
  datetime64: {
    [QueryValueFilterOperator.BEFORE]: ValueSelectType.DATE_FIELD,
    [QueryValueFilterOperator.AFTER]: ValueSelectType.DATE_FIELD,
    [QueryValueFilterOperator.BETWEEN]: ValueSelectType.DATE_RANGE_FIELD,
    [QueryValueFilterOperator.IS_SET]: null,
    [QueryValueFilterOperator.NOT_IS_SET]: null,
  },
  boolean: {
    [QueryValueFilterOperator.EQUALS]: ValueSelectType.COMBOBOX_SINGLE,
    [QueryValueFilterOperator.NOT_EQUALS]: ValueSelectType.COMBOBOX_SINGLE,
    [QueryValueFilterOperator.IS_SET]: null,
    [QueryValueFilterOperator.NOT_IS_SET]: null,
  },
  [ResourcePropertyType.CURRENCY]: {
    [QueryValueFilterOperator.LESS_THAN]: ValueSelectType.NUMBER_FIELD,
    [QueryValueFilterOperator.AT_MOST]: ValueSelectType.NUMBER_FIELD,
    [QueryValueFilterOperator.EQUALS]: ValueSelectType.NUMBER_FIELD,
    [QueryValueFilterOperator.AT_LEAST]: ValueSelectType.NUMBER_FIELD,
    [QueryValueFilterOperator.MORE_THAN]: ValueSelectType.NUMBER_FIELD,
    [QueryValueFilterOperator.IS_SET]: null,
    [QueryValueFilterOperator.NOT_IS_SET]: null,
  },
};

export const eventOperators = [
  QueryFrequencyValueFilterOperator.ZERO_TIMES,
  QueryFrequencyValueFilterOperator.AT_LEAST,
  QueryFrequencyValueFilterOperator.MORE_THAN,
  QueryFrequencyValueFilterOperator.AT_MOST,
  QueryFrequencyValueFilterOperator.LESS_THAN,
  QueryFrequencyValueFilterOperator.EQUALS,
  QueryFrequencyValueFilterOperator.NOT_EQUALS,
];

export const eventNameOperatorMap = {
  [QueryValueFilterOperator.EQUALS]: ValueSelectType.COMBOBOX_SINGLE,
  [QueryValueFilterOperator.NOT_EQUALS]: ValueSelectType.COMBOBOX_SINGLE,
  [QueryValueFilterOperator.IN]: ValueSelectType.COMBOBOX_MULTIPLE,
  [QueryValueFilterOperator.NOT_IN]: ValueSelectType.COMBOBOX_MULTIPLE,
};
