import { Cell, CellContext, Row, flexRender } from '@tanstack/react-table';
import { FC, useRef, useCallback } from 'react';
import { To, Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import { ConditionalWrap } from '../../utils/ConditionalWrap';
import { BodyCell } from '../../../components/Table';

interface DataTableCellProps {
  cell: Cell<any, unknown>;
  index: number;
  cellIndex: number;
  measureElement: (element: HTMLElement | null) => void;
  onCellMouseOver?: (e: React.MouseEvent<HTMLTableCellElement>, context: CellContext<any, any>) => void;
  link?: (row: Row<any>) => To;
}

export const DataTableCell: FC<DataTableCellProps> = ({ cell, index, cellIndex, measureElement, onCellMouseOver, link }) => {
  const cellRef = useRef<HTMLTableCellElement | null>(null);

  const onRefChange = useCallback(
    (element: HTMLElement | null) => {
      measureElement(element as HTMLTableCellElement | null);
      cellRef.current = element as HTMLTableCellElement;
    },
    [measureElement]
  );

  const isTruncated = cellRef.current ? cellRef.current?.scrollWidth > cellRef.current?.offsetWidth : false;

  const value = cell.getValue();
  const isValueString = typeof value === 'string';

  return (
    <BodyCell
      data-index={index}
      ref={onRefChange}
      key={cell.id}
      data-tooltip-id={cell.id}
      className={twMerge(
        'bg-m-white group-hover/row:bg-m-gray-200',
        (cell.column.columnDef.meta as any)?.cellClassName,
        cell.column.getIsPinned() && 'sticky left-0 z-10',
        link && 'overflow-hidden p-0',
        cellIndex > 0 && 'shadow-border',
        cellIndex === 0 && 'shadow-[inset_-0.5px_-0.5px_0px_0.25px_var(--shadow-color)]'
      )}
      onMouseOver={(e) => onCellMouseOver && onCellMouseOver(e, cell.getContext())}
    >
      <ConditionalWrap
        condition={!!link}
        wrap={(children) => (
          <Link to={link ? link(cell.row) : ''} className="block truncate">
            {children}
          </Link>
        )}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </ConditionalWrap>

      {isValueString && isTruncated && (
        <Tooltip id={cell.id} className="z-50" positionStrategy="fixed" place="right" delayShow={400}>
          {cell.getValue() as string}
        </Tooltip>
      )}
    </BodyCell>
  );
};
