import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useDebounce } from '@uidotdev/usehooks';
import isNil from 'lodash/isNil';

import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { useReportViewContext } from '../../../shared/reports/view/context/useReportViewContext';
import { Divider } from '../../../shared/ui/Divider/Divider';
import { Input } from '../../../shared/ui/Input/Input';
import { getWorkspaceObjectIcon } from '../../../shared/utils/getWorkspaceObjectIcon';
import { RecordDetails } from './EventRecordDetails';

export const EventDetails = () => {
  const [search, setSearch] = useState('');
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();
  const { reportQuery, pointData } = useReportViewContext();
  const { from, to, queryName, stage } = pointData || { from: null, to: null, stage: null };
  const query = { ...reportQuery, metrics: [reportQuery?.metrics.find(({ name }) => name === queryName)] };
  const { object_type, api_slug, singular_noun, plural_noun } = pointData?.objectData || {};
  const debouncedSearch = useDebounce(search, 200);

  const FallbackIcon = useMemo(() => getWorkspaceObjectIcon(object_type), [object_type]);
  const { data } = useQuery({
    queryKey: queryKeys.eventRecords(reportQuery),
    queryFn: () =>
      bigdeltaAPIClient.v1.queryReportsRecordsCreate(
        { workspace_id: currentWorkspaceId },
        // TODO: Remove the cast when API math type is fixed
        { query: query as never, from_timestamp: from, to_timestamp: to, stage }
      ),
    enabled: !!reportQuery && (!!from || !isNil(stage)),
  });

  const remoteIds = data?.items.map<string>((item) => item?.id as string).filter(Boolean) || [];
  const length = data?.items?.length || 0;

  const { data: itemLabels } = useQuery({
    queryKey: queryKeys.resolveLabels(api_slug as string, remoteIds),
    queryFn: () => bigdeltaAPIClient.v1.objectsRecordsResolveLabelsCreate(api_slug as string, { workspace_id: currentWorkspaceId }, { remoteIds }),
    enabled: !!api_slug && !!currentWorkspaceId && remoteIds?.length > 0,
  });
  const filteredRecords = useMemo(
    () =>
      debouncedSearch
        ? data?.items.filter((item) =>
            Object.values(item?.properties as object).some((value) => value?.toString().toLowerCase().includes(debouncedSearch.trim().toLowerCase()))
          )
        : data?.items,
    [data, debouncedSearch]
  );

  const getObjectName = () => {
    if (pointData?.objectData) {
      return length === 1 ? singular_noun : plural_noun;
    }

    return length === 1 ? 'record' : 'records';
  };

  return (
    <>
      <Divider />
      <div className="p-4">
        <div className="flex flex-col items-center justify-start gap-y-2">
          <p className="text-sm text-m-gray-700">
            {filteredRecords?.length === data?.items?.length ? '' : `${filteredRecords?.length} of `}
            {length} {getObjectName()}
          </p>
          <Input
            value={search}
            disabled={length === 0}
            className="w-full"
            placeholder="Search..."
            onChange={(event) => {
              setSearch(event.target.value);
            }}
          />
          {filteredRecords?.map((item) => {
            const details = itemLabels?.[item?.id as string];
            const icon = details?.icon ? (
              <div className="h-5">
                <img className="max-h-full w-auto rounded-sm" src={details?.icon} alt={`${singular_noun} logo`} />
              </div>
            ) : (
              <FallbackIcon className="size-4" />
            );

            return <RecordDetails label={details?.label} icon={icon} slug={api_slug} id={item?.id} key={item?.id} />;
          })}
        </div>
      </div>
    </>
  );
};
