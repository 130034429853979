import { Popover } from '@headlessui/react';
import { RecordsMath, ReportSourceType, useReportsStore } from '../store';
import { ReportCreateSelectedMetricContainer } from './ReportCreateSelectedMetricContainer';
import { useObjectsQuery } from '../../../shared/data/useObjectsQuery';
import { Combobox } from '../../../components/Combobox';
import { ObjectsListData } from '@bigdelta/lib-api-client';
import { useMemo } from 'react';
import ObjectIcon from '../../../assets/icons/cube-01.svg?react';
import { getWorkspaceObjectIcon } from '../../../shared/utils/getWorkspaceObjectIcon';
import { OpenTrigger } from '../../../shared/components/OpenTrigger';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { useFilterStore } from '../../../shared/filters/store';
import { MetricRecordsMath } from './MetricRecordsMath';
import { RecordPropertyMultilevelSelect } from '../../../shared/components/RecordPropertyMultilevelSelect';
import { RelationshipEntityType } from '@bigdelta/lib-shared';
import { RecordPropertyMultilevelTriggerLabel } from '../../../shared/components/RecordPropertyMultilevelTriggerLabel';
import { PropertyNameObject, RelationshipObjectData } from '../../../shared/types';
import { ReportCreateFilterRecords } from './ReportCreateFilterRecords';
import { FloatWrapper } from './FloatWrapper.tsx';
import { useReportMetric } from '../../auth/hooks/useReportMetric';
import { FormulaMetricReference } from './FormulaMetricReference.tsx';
import { tracking, TrackingEvent } from '../../../tracking';

export const ReportCreateSelectedMetricRecords = () => {
  const { inline, filterKey, onRemoveMetric } = useReportMetric();
  const { currentWorkspaceId } = useWorkspace();

  const metricObject = useReportsStore((state) => state.config.metricObject);

  const clearMetric = useReportsStore((state) => state.clearMetric);
  const setMetricObject = useReportsStore((state) => state.setMetricObject);
  const reportId = useReportsStore((state) => state.id);

  const setRecordsMathPropertyWithRelationships = useReportsStore((state) => state.setRecordsMathPropertyWithRelationships);
  const recordMathRelationships = useReportsStore((state) => state.config.recordMathRelationships);
  const recordMathProperty = useReportsStore((state) => state.config.recordMathProperty);
  const recordsMath = useReportsStore((state) => state.config.recordMath);

  const clearFilter = useFilterStore((state) => state.clearFilter);

  const objectsQuery = useObjectsQuery({ workspaceId: currentWorkspaceId });

  const selectedMetricObject = useMemo(() => {
    return objectsQuery.data?.objects.find((obj) => obj.id === metricObject);
  }, [objectsQuery.data?.objects, metricObject]);

  const handleObjectChange = (data: ObjectsListData['objects'][number] | null) => {
    tracking.track(TrackingEvent.ReportDataSelected, {
      'report id': reportId,
      'data type': ReportSourceType.RECORDS,
      'data name': data?.singular_noun,
    });
    setMetricObject(data?.id);
    clearFilter(filterKey);
  };

  const handleRemoveMetric = () => {
    clearMetric();
    clearFilter(filterKey);
    onRemoveMetric && onRemoveMetric();
  };

  const handlePropertyChange = (property: PropertyNameObject | null, relationships: RelationshipObjectData[] | null) => {
    if (!property?.property_type || !relationships) {
      return;
    }

    setRecordsMathPropertyWithRelationships(
      {
        attributeName: property.property_name,
        attributeId: property.property_id,
        attributeType: property.property_type,
      },
      relationships
    );
  };

  const MetricObjectIcon = selectedMetricObject ? getWorkspaceObjectIcon(selectedMetricObject.object_type) : ObjectIcon;

  const showRecordPropertySelect =
    metricObject &&
    (recordsMath === RecordsMath.AGGREGATE_AVERAGE ||
      recordsMath === RecordsMath.AGGREGATE_SUM ||
      recordsMath === RecordsMath.AGGREGATE_MEDIAN ||
      recordsMath === RecordsMath.AGGREGATE_NPS ||
      recordsMath === RecordsMath.AGGREGATE_DISTINCT_COUNT);

  const maxDepth = recordsMath === RecordsMath.AGGREGATE_NPS ? 0 : 1;

  return (
    <ReportCreateSelectedMetricContainer
      filterKey={filterKey}
      onRemove={handleRemoveMetric}
      source={
        <Popover className="flex-1">
          {({ close }) => (
            <FloatWrapper portal adaptiveWidth offset={2} inline={inline}>
              <Popover.Button className="flex-start flex w-full items-center gap-x-2 rounded p-2 text-sm hover:bg-m-gray-200">
                <FormulaMetricReference />
                <OpenTrigger shouldOpen={!metricObject} />
                <MetricObjectIcon className="h-5 w-5 text-m-blue-600" />
                {!metricObject && <span className="text-md text-m-olive-600">Select object</span>}
                {metricObject && <span className="text-md capitalize text-m-olive-900">{selectedMetricObject?.plural_noun}</span>}
              </Popover.Button>
              <Popover.Panel>
                <Combobox
                  items={objectsQuery.data?.objects ?? []}
                  selectedItems={objectsQuery.data?.objects.find((obj) => obj.id === metricObject) ?? null}
                  onChange={(data) => {
                    handleObjectChange(data);
                    close();
                  }}
                  catchInputFocus
                  height={400}
                  renderOption={(object) => {
                    const Icon = getWorkspaceObjectIcon(object.object_type);
                    return (
                      <div className="flex items-center gap-x-2">
                        <Icon className="h-4 w-4" />
                        <span className="capitalize">{object.plural_noun}</span>
                      </div>
                    );
                  }}
                  filterCompare={(object, query) => object.plural_noun.toLowerCase().includes(query.toLowerCase())}
                />
              </Popover.Panel>
            </FloatWrapper>
          )}
        </Popover>
      }
    >
      <hr className="h-px bg-m-gray-300" />
      <div className="p-2">
        <div className="flex w-full items-center gap-x-2">
          <MetricRecordsMath />
          {showRecordPropertySelect && (
            <RecordPropertyMultilevelSelect
              parentEntityType={RelationshipEntityType.OBJECT}
              parentEntityId={metricObject}
              onChange={handlePropertyChange}
              buttonClassName="border-0 text-sm font-regular hover:bg-m-gray-300 rounded p-0.5 pl-1.5 text-m-olive-400 gap-x-1.5 flex items-center"
              reset={() => {
                setRecordsMathPropertyWithRelationships(null, []);
              }}
              triggerLabel={
                <RecordPropertyMultilevelTriggerLabel propertyRelationships={recordMathRelationships} property={recordMathProperty ?? undefined} />
              }
              maxLevels={maxDepth}
            />
          )}
        </div>
      </div>
      {metricObject ? (
        <>
          <hr className="h-px bg-m-gray-300" />
          <div className="pb-2">
            <ReportCreateFilterRecords filterKey={filterKey} objectId={metricObject} />
          </div>
        </>
      ) : (
        <></>
      )}
    </ReportCreateSelectedMetricContainer>
  );
};
