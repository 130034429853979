import { DataQueryRelationshipPropertiesVO, MembersConfigTableLayoutListData, QueryRecordsCreateData } from '@bigdelta/lib-api-client';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { FilterItem } from '../../../shared/filters/store';
import { getRecordsQuery } from './getRecordsQuery';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { RecordsDataQueryRequest, RelationshipEntityType, TrendType } from '@bigdelta/lib-shared';
import { mapToDataQueryRelationshipProperties } from '../../../shared/tables/utils/mapToDataQueryRelationshipProperties.ts';
import { getQuerySort } from '../../../shared/tables/utils/getQuerySort.ts';
import { useObjectsQuery } from '../../../shared/data/useObjectsQuery.ts';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';
import { getQueryKeyDependentColumnProperties } from '../../../shared/tables/utils/getQueryKeyDependentColumnProperties';

const LIMIT = 50;

interface ObjectRecordsInfiniteQueryArgs {
  id?: string;
  filter: { items: FilterItem[]; operator: 'and' | 'or' } | null;
  workspaceId?: string;
  tableLayoutData?: MembersConfigTableLayoutListData;
}

interface IsEnabledArgs {
  id?: string;
  workspaceId?: string;
}

const isEnabled = ({ id, workspaceId }: IsEnabledArgs) => {
  const hasIds = !!id && !!workspaceId;

  return hasIds;
};

export const useObjectRecordsInfiniteQuery = ({ id, filter: stateFilter, workspaceId, tableLayoutData }: ObjectRecordsInfiniteQueryArgs) => {
  const queryKeys = useQueryKeys();

  const relationshipsQuery = useQuery({
    queryKey: queryKeys.relationships(),
    queryFn: () => bigdeltaAPIClient.v1.relationshipsList({ workspace_id: workspaceId! }),
    enabled: !!workspaceId,
  });

  const objectsQuery = useObjectsQuery({ workspaceId });

  const currentObject = objectsQuery.data?.objects.find((object) => object.id === id);

  const queryKeyDependentColumnProperties = getQueryKeyDependentColumnProperties(tableLayoutData?.columns ?? []);
  const queryKey = queryKeys.list('object-record', id, stateFilter, queryKeyDependentColumnProperties);

  const result = useInfiniteQuery<QueryRecordsCreateData>({
    queryKey,
    queryFn: async ({ pageParam = 0 }) => {
      const limit = LIMIT;
      const offset = pageParam * limit;

      let sort: RecordsDataQueryRequest['sort'];

      if (!id || !relationshipsQuery.data || !objectsQuery.data || !currentObject || !tableLayoutData) {
        return Promise.reject('Invalid query parameters');
      }

      let relationships: RecordsDataQueryRequest['relationships'] = undefined;
      let relationshipProperties: DataQueryRelationshipPropertiesVO[] | null = null;
      tableLayoutData?.columns.forEach((column) => {
        if (column.type === 'relationship' && column.relationship) {
          try {
            relationshipProperties = mapToDataQueryRelationshipProperties(
              relationshipProperties,
              column.relationship,
              relationshipsQuery.data.relationships,
              RelationshipEntityType.OBJECT,
              id,
              objectsQuery.data.objects
            );
          } catch (e) {
            console.error(e);
          }
        }
      });

      if (relationshipProperties) {
        relationships = {
          relationship_properties: relationshipProperties,
        };
      }

      try {
        sort = getQuerySort(
          tableLayoutData,
          RelationshipEntityType.OBJECT,
          currentObject,
          objectsQuery.data.objects,
          relationshipsQuery.data.relationships
        );
      } catch (e) {
        console.error(e);
      }

      return await bigdeltaAPIClient.v1.queryRecordsCreate(
        {
          workspace_id: workspaceId!,
        },
        {
          relationships,
          query: getRecordsQuery(id, stateFilter),
          sort,
          limit,
          offset,
          include_trends: [TrendType.activity],
        }
      );
    },
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = lastPage.items.length === LIMIT ? allPages.length : undefined;
      return nextPage;
    },
    refetchOnWindowFocus: false,
    enabled: isEnabled({ id, workspaceId }) && !!tableLayoutData && !!relationshipsQuery.data && !!objectsQuery.data && !!currentObject,
  });

  return { ...result, queryKey };
};
