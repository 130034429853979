import { useCallback, useRef, useState } from 'react';

export type UseContainerReturnType = ReturnType<typeof useContainer>;
export type UseContainerTableProps = UseContainerReturnType['tableProps'];

export const useContainer = () => {
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const [isReady, setIsReady] = useState(false);

  const setRef = useCallback((node: HTMLDivElement | null) => {
    tableContainerRef.current = node;
    if (node) {
      setIsReady(true);
    }
  }, []);

  return {
    containerProps: {
      ref: setRef,
    },
    tableProps: {
      tableContainerRef,
      isReady,
    },
  };
};
