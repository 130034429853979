import { QueryEventProperty, QueryRecordProperty } from '../types';
import { v5 as uuidv5 } from 'uuid';

export const getTopLevelProperty = (
  eventPropertyCondition: QueryEventProperty | (QueryRecordProperty & { top_level_property_type?: string })
): QueryEventProperty | QueryRecordProperty | undefined => {
  if (eventPropertyCondition.top_level_property_type) {
    const [propertyName] = eventPropertyCondition.name.split('.');

    return {
      property_id: uuidv5(propertyName, import.meta.env.VITE_APP_METADATA_PROPERTY_NAMESPACE),
      name: propertyName,
      property_type: eventPropertyCondition.top_level_property_type,
      operator: eventPropertyCondition.operator,
      value: eventPropertyCondition.value,
    };
  }

  return undefined;
};
