import { ObjectsListData } from '@bigdelta/lib-api-client';
import { capitalize, last } from 'lodash';
import { REMOTE_ID } from '../../../features/records/const';
import { getUserFriendlyPropertyName } from '../../../features/records/hooks/useRecordsTableColumns/utils/getUserFriendlyPropertyName';

export const renderRelatedRecordColumnHeader = (
  property: { property_id: string; property_name: string },
  relatedObjectsList: ObjectsListData['objects']
) => {
  const isPropertyId = property.property_name === REMOTE_ID;

  return isPropertyId
    ? capitalize(last(relatedObjectsList)?.singular_noun)
    : relatedObjectsList
        .map((o) => capitalize(o?.singular_noun))
        .concat(` ${getUserFriendlyPropertyName(property?.property_name)}`)
        .join(' -> ');
};
