import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { forwardRef } from 'react';
import { twMerge } from '../../../utils/twMerge';

const Item = forwardRef<HTMLDivElement, DropdownMenu.DropdownMenuItemProps>(({ children, className, ...props }, ref) => (
  <DropdownMenu.Item
    className={twMerge(
      'flex select-none items-center gap-x-2 rounded px-2 py-1.5 text-xs text-m-olive-800 outline-none radix-highlighted:bg-m-gray-200',
      className
    )}
    {...props}
    ref={ref}
  >
    {children}
  </DropdownMenu.Item>
));

const Content = forwardRef<HTMLDivElement, DropdownMenu.DropdownMenuContentProps>(({ children, className, ...props }, ref) => (
  <DropdownMenu.Content className={twMerge('rounded-md border border-m-olive-100 bg-m-white p-2 shadow-sm', className)} {...props} ref={ref}>
    {children}
  </DropdownMenu.Content>
));

export { Item, Content };
// eslint-disable-next-line react-refresh/only-export-components
export * from '@radix-ui/react-dropdown-menu';
