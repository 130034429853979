import React from 'react';
import { NumberTextEdit } from '../../properties/NumberText/NumberTextEdit';
import { NumberTextView } from '../../properties/NumberText/NumberTextView';
import { useShowContentClick } from '../../hooks/useShowContentClick';
import { FloatingPortal } from '@floating-ui/react';
import { twMerge } from '../../../utils/twMerge';
import { type NumberTextProps } from '../../properties/NumberText/NumberText';

interface CellContentNumberProps extends NumberTextProps {
  disabled?: boolean;
}

export const CellContentNumber: React.FC<CellContentNumberProps> = ({ value, onChange, disabled }) => {
  const { floatingPortalContentProps, referenceProps, forceClose, isOpen } = useShowContentClick({ disabled });
  floatingPortalContentProps.className = twMerge(
    'flex h-fit w-full rounded-md px-2 py-1 focus-within:ring-2 focus-within:ring-m-blue-400 hover:bg-m-gray-300',
    floatingPortalContentProps.className
  );

  const handleSave = (newValue: CellContentNumberProps['value']) => {
    forceClose();
    onChange?.(newValue);
  };

  return (
    <>
      <div {...referenceProps} className={twMerge('flex h-full w-full cursor-text items-center px-2', disabled && 'text-m-olive-400')}>
        <NumberTextView value={value} />
      </div>
      {isOpen && (
        <FloatingPortal>
          <div {...floatingPortalContentProps}>
            <NumberTextEdit value={value} onChange={handleSave} onCancel={forceClose} />
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
