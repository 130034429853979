import { TableState } from '@tanstack/react-table';
import { useRef, useEffect } from 'react';

export const useTableResizeObserver = (state: TableState, callback: (columnId: string, columnSize: number) => void) => {
  const columnResizeIdRef = useRef<string | false>();

  const columnBeingResizedId = state.columnSizingInfo?.isResizingColumn;

  useEffect(() => {
    if (state.columnSizingInfo && !columnBeingResizedId && columnResizeIdRef.current) {
      callback(columnResizeIdRef.current, state.columnSizing[columnResizeIdRef.current]);
    }
    columnResizeIdRef.current = columnBeingResizedId;
  }, [callback, state.columnSizingInfo, state.columnSizing, columnBeingResizedId]);
};
