import {
  MembersConfigTableLayoutListData,
  ObjectsListData,
  QueryEventsCreatePayload,
  QueryRecordsCreatePayload,
  RelationshipsListData,
} from '@bigdelta/lib-api-client';
import { RelationshipEntityType, TableColumnType, TableSortDirection } from '@bigdelta/lib-shared';
import { capitalize } from 'lodash';
import { REMOTE_ID } from '../../../features/records/const';

type ColumnDefTopLevelOrNested = Omit<MembersConfigTableLayoutListData['columns'][number], 'order'> & {
  order?: MembersConfigTableLayoutListData['columns'][number]['order'];
};

const getRelationshipSortColumnConfig = (
  column: ColumnDefTopLevelOrNested,
  order: TableSortDirection,
  entityType: RelationshipEntityType,
  object: ObjectsListData['objects'][number] | null,
  objects: ObjectsListData['objects'],
  relationships: RelationshipsListData['relationships'],
  deepRelatedRecordProperty: string
): NonNullable<QueryEventsCreatePayload['sort'] | QueryRecordsCreatePayload['sort']>[number] => {
  if (column.type === TableColumnType.PROPERTY && column.property) {
    return {
      property: {
        name: deepRelatedRecordProperty,
        order,
      },
    };
  }

  if (column.type === TableColumnType.RELATIONSHIP && column.relationship) {
    let relatedObjectId: string | undefined;

    const relationshipName = column.relationship.name;
    const relationship = relationships.find((rel) => rel.name === relationshipName);

    if (!relationship) {
      throw new Error(`Relationship ${relationshipName} not found`);
    }

    if (entityType === RelationshipEntityType.EVENT) {
      relatedObjectId = relationship.first_entity_type === RelationshipEntityType.EVENT ? relationship.second_entity_id : undefined;
      relatedObjectId = relationship.second_entity_type === RelationshipEntityType.EVENT ? relationship.first_entity_id : relatedObjectId;
    }

    if (entityType === RelationshipEntityType.OBJECT && object) {
      relatedObjectId = relationship.first_entity_id === object.id ? relationship.second_entity_id : undefined;
      relatedObjectId = relationship.second_entity_id === object.id ? relationship.first_entity_id : relatedObjectId;
    }

    const relatedObject = objects.find((obj) => obj.id === relatedObjectId);

    if (!relatedObject) {
      throw new Error(`Related object ${relatedObjectId} not found`);
    }

    return {
      related_records: {
        relationship_name: column.relationship.name,
        sort: {
          ...getRelationshipSortColumnConfig(
            column.relationship,
            order as TableSortDirection,
            RelationshipEntityType.OBJECT,
            relatedObject,
            objects,
            relationships,
            deepRelatedRecordProperty
          ),
        },
      },
    };
  }

  return null;
};

export const getQuerySort = (
  tableLayout: MembersConfigTableLayoutListData,
  entityType: RelationshipEntityType,
  parentObject: ObjectsListData['objects'][number] | null,
  objects: ObjectsListData['objects'],
  relationships: RelationshipsListData['relationships']
): QueryEventsCreatePayload['sort'] | QueryRecordsCreatePayload['sort'] => {
  const sortedCol = tableLayout.columns.find((col) => col.sort);

  if (!sortedCol || !sortedCol.sort) {
    return null;
  }

  if (sortedCol.type === TableColumnType.FIELD && sortedCol.field) {
    return [
      {
        field: {
          name: sortedCol.field.field_name
            .split('_')
            .map((w, i) => (i > 0 ? capitalize(w) : w))
            .join(''),
          order: sortedCol.sort as TableSortDirection,
        },
      },
    ];
  }

  if (sortedCol.type === TableColumnType.LABEL && entityType === RelationshipEntityType.OBJECT && parentObject) {
    const labelProperties = parentObject.label_properties;

    return labelProperties.map((name) => ({
      property: {
        name,
        order: sortedCol.sort as TableSortDirection,
      },
    }));
  }

  if (sortedCol.type === TableColumnType.PROPERTY && sortedCol.property) {
    const sortPropertyName = sortedCol.property.property_name;

    return [
      {
        property: {
          name: sortPropertyName,
          order: sortedCol.sort as TableSortDirection,
        },
      },
    ];
  }

  if (sortedCol.type === TableColumnType.RELATIONSHIP && sortedCol.relationship) {
    const { objectId: deepRelatedObjectId, propertyName: deepRelatedObjectPropertyName } =
      getDeepRelatedObjectIdWithProperty(sortedCol, relationships, entityType, parentObject?.id) ?? {};

    if (!deepRelatedObjectPropertyName) {
      throw new Error('Deep related object property name not found');
    }

    // TODO: Sorting by labels with fallbacks
    if (deepRelatedObjectPropertyName === REMOTE_ID) {
      const deepRelatedObject = objects.find((obj) => obj.id === deepRelatedObjectId);

      if (!deepRelatedObject) {
        throw new Error(`Related object ${deepRelatedObjectId} not found`);
      }

      const deepRelatedObjectLabelProperties = [...(deepRelatedObject?.label_properties ?? []), REMOTE_ID];

      return deepRelatedObjectLabelProperties.map((propertyName) =>
        getRelationshipSortColumnConfig(
          sortedCol,
          sortedCol.sort as TableSortDirection,
          entityType,
          parentObject,
          objects,
          relationships,
          propertyName
        )
      );
    }

    if (deepRelatedObjectPropertyName !== REMOTE_ID) {
      return [
        getRelationshipSortColumnConfig(
          sortedCol,
          sortedCol.sort as TableSortDirection,
          entityType,
          parentObject,
          objects,
          relationships,
          deepRelatedObjectPropertyName
        ),
      ];
    }
  }
};

const getDeepRelatedObjectIdWithProperty = (
  column: ColumnDefTopLevelOrNested,
  relationships: RelationshipsListData['relationships'],
  entityType: RelationshipEntityType,
  currentObjectId?: string
) => {
  if (column.type === TableColumnType.RELATIONSHIP && column.relationship) {
    const relationship = relationships.find((rel) => rel.name === column.relationship?.name);

    if (!relationship) {
      throw new Error(`Relationship ${column.relationship?.name} not found`);
    }

    let nextObjectId;

    if (entityType === RelationshipEntityType.EVENT) {
      nextObjectId = relationship.first_entity_type === RelationshipEntityType.EVENT ? relationship.second_entity_id : undefined;
      nextObjectId = relationship.second_entity_type === RelationshipEntityType.EVENT ? relationship.first_entity_id : nextObjectId;
    }

    if (entityType === RelationshipEntityType.OBJECT) {
      nextObjectId = relationship.first_entity_id === currentObjectId ? relationship.second_entity_id : relationship.first_entity_id;
    }

    if (!nextObjectId) {
      throw new Error(`Related object ${nextObjectId} not found`);
    }

    return getDeepRelatedObjectIdWithProperty(column.relationship, relationships, RelationshipEntityType.OBJECT, nextObjectId);
  }
  if (column.type === TableColumnType.PROPERTY && column.property) {
    return {
      objectId: currentObjectId,
      propertyName: column.property.property_name,
    };
  }
};
