import { ButtonHTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import * as RadixCheckbox from '@radix-ui/react-checkbox';

import CheckIcon from '../../../assets/icons/check.svg?react';
import MinusIcon from '../../../assets/icons/minus.svg?react';
import { PropsWithClassName } from '../../../types.js';
import { twConfig } from '../../../utils/tailwindConfig.js';

const {
  theme: { colors },
} = twConfig;

export type CheckedState = boolean | 'indeterminate';

export type HTMLButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'defaultChecked' | 'onChange' | 'value'>;

interface CheckboxProps extends PropsWithClassName, HTMLButtonProps {
  checked?: CheckedState;
  defaultChecked?: CheckedState;
  onChange?(checked: CheckedState): void;
  disabled?: boolean;
  value?: string;
}

const getGeneralClasses = () => 'h-4 w-4 rounded bg-white cursor-pointer';
const getNormalClasses = () => 'border border-m-olive-100';
const getHoverClasses = () => 'hover:border-m-olive-200 aria-checked:hover:bg-m-blue-700 aria-[checked=mixed]:hover:bg-m-blue-700';
const getCheckedClasses = () => 'aria-checked:border-0 aria-[checked=mixed]:border-0 aria-checked:bg-m-blue-600 aria-[checked=mixed]:bg-m-blue-600';
const getActiveClasses = () => 'active:border-m-olive-200 active:ring';
const getDisabledClasses = () => 'disabled:cursor-not-allowed disabled:pointer-events-none disabled:bg-m-olive-200 disabled:border-m-olive-100';
const getFlexProps = () => 'flex items-center justify-center shrink-0';

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(({ className, value, checked, disabled, onChange, ...checkboxProps }, ref) => (
  <RadixCheckbox.Root
    {...checkboxProps}
    ref={ref}
    checked={checked}
    disabled={disabled}
    onCheckedChange={onChange}
    value={value}
    className={twMerge(
      getFlexProps(),
      getNormalClasses(),
      getHoverClasses(),
      getCheckedClasses(),
      getActiveClasses(),
      getDisabledClasses(),
      getGeneralClasses(),
      className
    )}
  >
    <RadixCheckbox.Indicator className="CheckboxIndicator">
      {checked === 'indeterminate' && <MinusIcon color={disabled ? colors['m-olive'][300] : colors['m-white']} className="h-3 w-3" />}
      {checked === true && <CheckIcon color={disabled ? colors['m-olive'][200] : colors['m-white']} className="h-3 w-3" />}
    </RadixCheckbox.Indicator>
  </RadixCheckbox.Root>
));
