import { useContext } from "react";
import { ReportMetricContext } from "../context/reportMetricContext";
import { defaultValues, type ReportMetricContextType } from "../context/reportMetricsDefault";

export const useReportMetric = (): ReportMetricContextType => {
  const context = useContext(ReportMetricContext);
  if (!context) {
    return defaultValues;
  }

  return context;
};
