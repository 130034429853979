import { ButtonHTMLAttributes, Dispatch, FC, PropsWithChildren, ReactElement, SetStateAction, useEffect, useMemo, useState } from 'react';

import ChevronLeftIcon from '../../assets/icons/chevron-left.svg?react';

import { RelationshipEntityType, ResourcePropertyType } from '@bigdelta/lib-shared';
import { capitalize, last } from 'lodash';
import { twMerge } from 'tailwind-merge';
import { useWorkspace } from '../../features/auth/hooks/useWorkspace';
import { useRelationshipsQuery } from '../data/useRelationshipsQuery';
import { PropertyNameObject, RelationshipObjectData } from '../types';

import { Input } from '../ui/Input/Input.tsx';
import { RecordPropertyIcon } from './RecordPropertyIcon.tsx';
import { PropsWithClassName } from '../../types.ts';
import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../features/auth/hooks/useQueryKeys.ts';
import { REMOTE_ID } from '../../features/records/const.ts';
import { getObjectRelationships } from '../utils/getObjectRelationships.tsx';
import { ObjectIcon } from '../../features/reports/components/common/ObjectIcon.tsx';
import { ObjectsListData, RelationshipsListData } from '@bigdelta/lib-api-client';
import { toastError } from '../../utils/toast';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';
import { getWorkspaceObjectIcon } from '../utils/getWorkspaceObjectIcon.ts';
import { v5 as uuidv5 } from 'uuid';

type ListItemProps = PropsWithChildren & ButtonHTMLAttributes<HTMLButtonElement>;

type ObjectProperty = NonNullable<ObjectsListData['objects'][number]['properties']>[number];

interface RelatedOption {
  label: string;
  type: 'related';
  icon: JSX.Element;
  related: {
    object: ObjectsListData['objects'][number];
    relationship: RelationshipsListData['relationships'][number] | null;
  };
}
interface PropertyOption {
  label: string;
  type: 'property';
  icon: JSX.Element;
  property: ObjectProperty;
}

type Option = RelatedOption | PropertyOption;

export const ListItem: FC<ListItemProps> = ({ children, ...props }) => (
  <button
    className="flex w-full flex-row items-center justify-between gap-x-2 rounded-md px-2.5 py-3 hover:bg-m-gray-200 focus:bg-m-gray-200 focus:outline-none"
    {...props}
  >
    {children}
  </button>
);

export interface RecordPropertyMultilevelSelectContentProps extends PropsWithClassName {
  parentEntityType: RelationshipEntityType;
  parentEntityId: string | null;
  onChange: (property: PropertyNameObject, relationships: RelationshipObjectData[] | null) => void;
  maxLevels?: number;
  topOptions?: ReactElement;
  showRelatedObjectOption?: boolean;
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
}

// TODO: Keyboard navigation
// TODO: Reimplement max levels?
export const RecordPropertyMultilevelSelectContent: FC<RecordPropertyMultilevelSelectContentProps> = ({
  parentEntityType,
  parentEntityId,
  onChange,
  // maxLevels,
  topOptions = null,
  showRelatedObjectOption = false,
  className,
  searchQuery,
  setSearchQuery,
}) => {
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  const [selectedRelatedData, setSelectedRelatedData] = useState<RelatedOption['related'][] | null>();

  // TODO: this is a temporary solution to convert selectedRelatedData structure to the old structure
  const selectedRelObjects = useMemo(() => {
    return (
      selectedRelatedData?.map((d) => {
        return {
          objectId: d.object.id,
          objectWorkspaceId: d.object.workspace_id,
          relationshipName: d.relationship?.name ?? null,
        };
      }) ?? null
    );
  }, [selectedRelatedData]);

  const { data: objectList } = useQuery({
    queryKey: queryKeys.list('object', 'active_and_virtual'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId, status: 'ACTIVE_AND_VIRTUAL' }),
  });

  const parentObject = useMemo(() => {
    if (!objectList || !parentEntityId) {
      return;
    }

    return objectList.objects.find((obj) => obj.id === parentEntityId);
  }, [objectList, parentEntityId]);

  // TODO: pass parentObject rather than parentEntityId and remove this
  useEffect(() => {
    if (parentObject && !selectedRelatedData) {
      setSelectedRelatedData([
        {
          object: parentObject,
          relationship: null,
        },
      ]);
    }
  }, [parentObject, selectedRelatedData]);

  const { data: relationshipData } = useRelationshipsQuery({ workspaceId: currentWorkspaceId });

  const currentObject = useMemo(() => {
    if (!selectedRelatedData || !objectList) {
      return;
    }
    return last(selectedRelatedData)?.object;
  }, [objectList, selectedRelatedData]);

  const showCurrentObject = currentObject?.id !== parentEntityId;

  const handleOptionRelatedClick = (related: RelatedOption['related']) => {
    if (!related) {
      return;
    }
    setSearchQuery('');
    setSelectedRelatedData((oldSelectedData) => {
      return [...(oldSelectedData ?? []), related];
    });
  };

  const handleOptionClick = (option: Option) => {
    if (option.type === 'related') {
      handleOptionRelatedClick(option.related);
      setSearchQuery('');
      return;
    }

    onChange(transformObjectPropertyToMetadataProperty(option.property), selectedRelObjects);
  };

  const transformObjectPropertyToMetadataProperty = (property: ObjectProperty) => {
    return {
      property_name: property.name,
      property_id: uuidv5(property.name, import.meta.env.VITE_APP_METADATA_PROPERTY_NAMESPACE),
      property_type: property.type,
    };
  };

  const eventRelationships = useMemo(() => {
    return relationshipData?.relationships.filter((rel) => {
      return (
        (rel.first_entity_type === RelationshipEntityType.EVENT && rel.second_entity_type === RelationshipEntityType.OBJECT) ||
        (rel.first_entity_type === RelationshipEntityType.OBJECT && rel.second_entity_type === RelationshipEntityType.EVENT)
      );
    });
  }, [relationshipData?.relationships]);

  const eventRelationshipsWithObjects = useMemo(() => {
    return eventRelationships
      ?.map((rel) => {
        const object = objectList?.objects.find((obj) => obj.id === rel.second_entity_id || obj.id === rel.first_entity_id);

        if (!object) {
          return;
        }

        return {
          object,
          relationship: rel,
        };
      })
      .filter((el) => (searchQuery ? el?.object.plural_noun.includes(searchQuery) : el))
      .filter(Boolean);
  }, [eventRelationships, searchQuery, objectList?.objects]);
  const objectRelationships = useMemo(() => {
    if (!currentObject?.id || !relationshipData?.relationships) {
      return [];
    }

    return getObjectRelationships({ entityId: currentObject.id, relationships: relationshipData?.relationships });
  }, [currentObject?.id, relationshipData?.relationships]);

  const relatedObjectsWithRelationships = useMemo(() => {
    return objectRelationships
      .map((rel) => {
        const object = objectList?.objects.find(
          (obj) => (obj.id === rel.second_entity_id || obj.id === rel.first_entity_id) && obj.id !== currentObject?.id
        );

        if (!object) {
          return;
        }

        return {
          object,
          relationship: rel,
        };
      })
      .flatMap((objWithRel) => (objWithRel ? [objWithRel] : []));
  }, [currentObject?.id, objectList?.objects, objectRelationships]);

  const flatProperties = useMemo(() => {
    return currentObject?.properties?.filter((p) => p.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())) ?? [];
  }, [currentObject, searchQuery]);

  // TODO: add event relationships to options
  const options: Option[] = useMemo(() => {
    const optionsFromProperties = flatProperties
      .filter((property) => {
        if (showRelatedObjectOption && property.name === REMOTE_ID) {
          return false;
        }

        return true;
      })
      .map((property) => {
        return {
          label: property.name,
          type: 'property' as const,
          icon: <RecordPropertyIcon propertyType={property.type} className="h-4 w-4 shrink-0 text-m-olive-600" />,
          property,
        };
      });

    const previousSelectedRelatedData = selectedRelatedData?.length ? selectedRelatedData[selectedRelatedData.length - 2] : undefined;

    const optionsFromRelationships = relatedObjectsWithRelationships
      .filter(
        ({ object }) =>
          object.plural_noun.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()) ||
          object.singular_noun.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
      )
      .filter((objectRelationshipData) => {
        if (!previousSelectedRelatedData) {
          return true;
        }

        return objectRelationshipData.object.id !== previousSelectedRelatedData.object.id;
      })
      .map((objectRelationshipData) => {
        return {
          label: capitalize(objectRelationshipData.object.singular_noun),
          type: 'related' as const,
          icon: <ObjectIcon objectType={objectRelationshipData.object.object_type} />,
          related: objectRelationshipData,
        };
      });

    return [...optionsFromProperties, ...optionsFromRelationships].sort((a, b) => a.label.localeCompare(b.label));
  }, [flatProperties, relatedObjectsWithRelationships, searchQuery, selectedRelatedData, showRelatedObjectOption]);

  const objectHasBackButton = selectedRelatedData?.length && selectedRelatedData.length > 1;
  const eventHasBackButton = selectedRelatedData?.length && selectedRelatedData.length > 0;

  const hasBackButton =
    !!(parentEntityType === RelationshipEntityType.OBJECT && objectHasBackButton) ||
    !!(parentEntityType === RelationshipEntityType.EVENT && eventHasBackButton);

  const handleClickBack = () => {
    setSelectedRelatedData((oldSelectedRelatedData) => {
      return oldSelectedRelatedData ? oldSelectedRelatedData?.slice(0, oldSelectedRelatedData.length - 1) : oldSelectedRelatedData;
    });
  };

  if (!objectList) {
    return null;
  }

  const selectedRelationsUpToLastLabel = () => {
    const objectNames = selectedRelatedData?.slice(0, -1).map((d) => d.object.plural_noun) ?? [];
    const endingArrow = objectNames.length > 0 ? '->' : '';

    return `${objectNames.join(' -> ')} ${endingArrow}`.trim();
  };

  return (
    <div
      className={twMerge(
        'flex cursor-default flex-col overflow-hidden rounded-lg border border-m-gray-300 bg-m-white px-2 py-2 shadow-xl',
        className
      )}
    >
      {hasBackButton && (
        <button
          className={twMerge(
            'mb-2 flex w-full items-center rounded-md py-2 text-sm font-medium capitalize text-m-gray-700',
            hasBackButton && 'hover:bg-m-gray-200 focus:bg-m-gray-200 focus:outline-none'
          )}
          disabled={!hasBackButton}
          onClick={handleClickBack}
        >
          {hasBackButton && <ChevronLeftIcon className="h-4 w-4 text-m-beige-800" />}
          {selectedRelationsUpToLastLabel() && <span className="pl-2 text-m-olive-300">{selectedRelationsUpToLastLabel()}</span>}
          <span className="pl-2 text-m-beige-800">{`${last(selectedRelatedData)?.object.plural_noun}`}</span>
        </button>
      )}
      <Input
        ref={(node: HTMLInputElement) => {
          setTimeout(() => {
            node?.focus();
          }, 0);
        }}
        size="sm"
        className="mb-2 w-full focus:border-m-gray-300 focus:ring-0"
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        placeholder="Search properties…"
      />
      {showRelatedObjectOption && showCurrentObject && currentObject && (
        <ListItem
          key={currentObject.id}
          onClick={() => {
            const property = flatProperties.find((p) => p.name === REMOTE_ID);

            if (!property) {
              toastError(`ID property for ${capitalize(currentObject.singular_noun)} not found`);
              return;
            }

            property && onChange(transformObjectPropertyToMetadataProperty(property), selectedRelObjects);
          }}
        >
          <RecordPropertyIcon propertyType={ResourcePropertyType.OBJECT} className="h-4 w-4 shrink-0 text-m-olive-600" />
          <span className="flex flex-1 flex-row justify-start overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-tight text-m-olive-800">
            {capitalize(currentObject.singular_noun)}
          </span>
        </ListItem>
      )}
      {topOptions}
      {!!selectedRelatedData?.length && !!currentObject && (
        <div className="text-[11px] text-xs text-m-olive-400">
          <span className="block px-1 py-1 first-letter:capitalize">{`${currentObject.singular_noun} properties (${currentObject.properties?.length})`}</span>
        </div>
      )}

      <div className="max-h-80 overflow-y-auto overflow-x-hidden">
        {parentEntityType === RelationshipEntityType.EVENT && !selectedRelatedData?.length && (
          <>
            {eventRelationshipsWithObjects
              ?.flatMap((d) => (d ? [d] : []))
              .map(({ object, relationship }) => {
                const Icon = getWorkspaceObjectIcon(object.object_type);
                return (
                  <ListItem
                    key={object.id}
                    onClick={() => {
                      handleOptionRelatedClick({ object, relationship });
                    }}
                  >
                    <Icon className="h-4 w-4 shrink-0 text-m-olive-600" />
                    <span className="flex flex-1 flex-row justify-start overflow-hidden text-ellipsis whitespace-nowrap text-sm capitalize leading-tight text-m-olive-800">
                      {object.plural_noun}
                    </span>
                  </ListItem>
                );
              })}
          </>
        )}
        {!!options.length && (
          <>
            <div className="flex flex-col">
              {options.map((option) => {
                return (
                  <ListItem key={`${option.type}-${option.label}`} onClick={() => handleOptionClick(option)}>
                    <div className="flex flex-row items-center gap-x-2">
                      {option.icon}
                      <span className={twMerge('overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-tight text-m-olive-800')}>
                        {option.label}
                      </span>
                    </div>
                    {option.type === 'related' && <span className="text-xs leading-tight text-m-olive-300">{'->'}</span>}
                  </ListItem>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
