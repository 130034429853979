import { useQueryKeys } from '../auth/hooks/useQueryKeys.ts';
import { useWorkspace } from '../auth/hooks/useWorkspace.tsx';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FormEvent, useMemo, useState } from 'react';
import { PageHeading } from '../../components/PageHeading.tsx';
import { Outlet } from 'react-router-dom';
import { CenteredLoader } from '../../shared/components/CenteredLoader.tsx';
import SearchIcon from '../../assets/icons/search-md.svg?react';
import { Button } from '../../shared/ui/Button/Button.tsx';
import { MembersTable, MemberTableItem } from './components/MembersTable.tsx';
import { Dialog } from '@headlessui/react';
import { toastError } from '../../utils/toast.tsx';
import { useAccount } from '../auth/hooks/useAccount.tsx';
import { tracking, TrackingEvent } from '../../tracking';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';

export const MembersList = () => {
  const queryKeys = useQueryKeys();
  const { currentWorkspaceId } = useWorkspace();
  const { currentAccountId } = useAccount();
  const queryClient = useQueryClient();
  const [isInviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');

  const handleInviteDialogClose = () => {
    setInviteEmail('');
    setInviteDialogOpen(false);
  };

  const sendInviteMutation = useMutation({
    mutationFn: (email: string) => {
      return bigdeltaAPIClient.v1.membersInvitationsCreate({
        account_id: currentAccountId,
        email: email,
      });
    },
    onSuccess: (data) => {
      tracking.track(TrackingEvent.InvitationSent, { email: data.email, 'account id': data.account_id });
      setSearchTerm('');
      handleInviteDialogClose();
      queryClient.invalidateQueries(queryKeys.members());
      return queryClient.invalidateQueries(queryKeys.invitations());
    },
    onError: (error: any) => {
      if (error?.response?.data?.error_type === 'MEMBER_ALREADY_INVITED') {
        toastError(`Member already invited!`);
      } else {
        toastError('An error occurred while sending an invite');
      }
    },
  });

  const handleSendInvite = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (inviteEmail) {
      sendInviteMutation.mutate(inviteEmail);
    }
  };

  const membersQuery = useQuery({
    queryKey: queryKeys.members(),
    queryFn: () => bigdeltaAPIClient.v1.membersList({ workspace_id: currentWorkspaceId }),
  });

  const invitationsQuery = useQuery({
    queryKey: queryKeys.invitations(),
    queryFn: () => bigdeltaAPIClient.v1.membersInvitationsList({ account_id: currentAccountId }),
  });

  const tableItems = useMemo(() => {
    const items: MemberTableItem[] = [];
    invitationsQuery?.data?.forEach((i) =>
      items.push({
        entryType: 'invitation',
        id: i.id,
        email: i.email,
      })
    );
    membersQuery?.data?.forEach((m) =>
      items.push({
        entryType: 'member',
        id: m.id,
        email: m.email,
      })
    );
    return items;
  }, [membersQuery, invitationsQuery]);

  const isEmpty = !tableItems || tableItems.length === 0;

  const [searchTerm, setSearchTerm] = useState('');

  const filteredItems = useMemo(() => {
    return tableItems.filter((item) => item.email.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [searchTerm, tableItems]);

  return (
    <>
      <div className="flex h-screen w-full flex-col">
        <div className="flex flex-col">
          {!membersQuery.isInitialLoading && (
            <>
              <div className="flex items-center justify-between" style={{ zIndex: 1 }}>
                <PageHeading breadcrumbs={[{ label: 'Members' }]} />
                <div className="flex items-center gap-x-2">
                  {!isEmpty && (
                    <div className="relative">
                      <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                        <SearchIcon className="h-5 w-5 text-m-gray-600" />
                      </div>
                      <input
                        type="text"
                        id="simple-search"
                        className="block w-full rounded-md border border-m-gray-300 p-2 ps-10 text-md"
                        placeholder="Search member"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  )}
                  <Button intent={isEmpty ? 'secondary' : 'brand'} size="lg" label="Invite member" onClick={() => setInviteDialogOpen(true)} />
                </div>
              </div>
              <hr className="mt-4 h-px bg-m-gray-300" />
              <MembersTable data={filteredItems} />
              <Outlet />
            </>
          )}
          {membersQuery.isInitialLoading && <CenteredLoader />}
        </div>
      </div>
      <Dialog open={isInviteDialogOpen} onClose={handleInviteDialogClose} className="relative z-50">
        <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="mx-auto flex w-1/3 max-w-xl flex-col gap-y-6 rounded-xl bg-white p-6">
            <Dialog.Title className="text-xl text-m-olive-700">Invite member to your Account</Dialog.Title>
            <form onSubmit={handleSendInvite} className="flex flex-col gap-y-6">
              <div className="flex flex-col gap-y-1.5">
                <label htmlFor="invite_email" className="text-md text-m-olive-700">
                  Email
                </label>
                <input
                  name="invite_email"
                  type="email"
                  className="rounded-lg border border-m-gray-300 px-3 py-2"
                  value={inviteEmail}
                  placeholder="name@example.com"
                  onChange={(e) => setInviteEmail(e.target.value)}
                />
              </div>
              <div className="flex justify-end gap-x-3.5">
                <Button type="button" label="Cancel" intent="secondary" size="lg" onClick={handleInviteDialogClose} />
                <Button label="Invite" intent="brand" size="lg" type="submit" disabled={!inviteEmail || sendInviteMutation.isLoading} />
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};
