import ColorHash from 'color-hash';
import { FC, useState } from 'react';
import RelatesIcon from '../../../../assets/icons/switch-horizontal-01.svg?react';
import { Dialog } from '@headlessui/react';
import { RelationshipDetails } from './RelationshipDetails';

const bgColorHash = new ColorHash({ saturation: 0.9, lightness: 0.9 });
const textColorHash = new ColorHash({ saturation: 0.7, lightness: 0.3 });

export interface ObjectRelationship {
  id: string;
  property: string;
  related_entity_id: string;
  related_entity_name: string;
  related_entity_property: string;
}

export const RelationshipCell: FC<{
  relationship: ObjectRelationship;
}> = ({ relationship }) => {
  const [isRelationshipDetailsDialogOpen, setIsRelationshipDetailsDialogOpen] = useState(false);

  if (!relationship) {
    return null;
  }

  return (
    <>
      <div
        className="flex max-w-fit cursor-pointer items-center justify-center gap-1 rounded-full px-2 py-1 text-xs font-medium"
        style={{ color: textColorHash.hex(relationship.related_entity_name), background: bgColorHash.hex(relationship.related_entity_name) }}
        onClick={() => setIsRelationshipDetailsDialogOpen(true)}
      >
        <RelatesIcon className="h-4" />
        <span className="mr-1">{relationship.related_entity_name}</span>
      </div>
      <Dialog open={isRelationshipDetailsDialogOpen} onClose={() => setIsRelationshipDetailsDialogOpen(false)} className="relative z-[999999]">
        <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-center justify-center">
          <Dialog.Panel className="mx-auto flex w-3/5 max-w-lg flex-col gap-y-6 rounded-xl bg-white">
            <Dialog.Title className="p-6 pb-0 text-xl text-m-olive-700">Relationship</Dialog.Title>
            <RelationshipDetails
              value={{
                entity_id: relationship.related_entity_id,
                entity_property: relationship.related_entity_property,
              }}
              onClose={() => setIsRelationshipDetailsDialogOpen(false)}
            />
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};
