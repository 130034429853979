import { MembersConfigTableLayoutListData } from '@bigdelta/lib-api-client';

type Column = MembersConfigTableLayoutListData['columns'][number];

export const getQueryKeyDependentColumnProperties = (columns: Column[]): Record<string, Pick<Column, 'sort'>> => {
  return columns.reduce<Record<string, Pick<Column, 'sort'>>>((acc, column) => {
    if (column.id) {
      acc[column.id] = { sort: column.sort };
    }
    return acc;
  }, {});
};
