import { useEffect } from 'react';
import { Key } from 'ts-key-enum';

export const useKeyboard = (keys: Key[], handler: () => void) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (keys.includes(e.key)) {
        handler();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handler, keys]);
};
