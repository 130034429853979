import { MembersConfigTableLayoutListData } from '@bigdelta/lib-api-client';
import { DEFAULT_COLUMN_WIDTH, DEFAULT_COLUMN_MAX_WIDTH, DEFAULT_COLUMN_MIN_WIDTH } from '../const';

export const getSizeProps = (column?: MembersConfigTableLayoutListData['columns'][number]) => {
  return {
    size: column?.width ?? DEFAULT_COLUMN_WIDTH,
    maxSize: DEFAULT_COLUMN_MAX_WIDTH,
    minSize: DEFAULT_COLUMN_MIN_WIDTH,
  };
};
