import { HTMLAttributes } from 'react';
import { ResourcePropertyType } from '@bigdelta/lib-shared';

import BooleanIcon from '../../assets/icons/check.svg?react';
import ListIcon from '../../assets/icons/list.svg?react';
import TextIcon from '../../assets/icons/letter-spacing-01.svg?react';
import ObjectIcon from '../../assets/icons/cube-01.svg?react';
import CalendarIcon from '../../assets/icons/calendar.svg?react';
import CurrencyIcon from '../../assets/icons/bank-note-02.svg?react';

const propertyIcons = {
  [ResourcePropertyType.BOOLEAN]: BooleanIcon,
  [ResourcePropertyType.STRING]: TextIcon,
  [ResourcePropertyType.OBJECT]: ObjectIcon,
  [ResourcePropertyType.DATETIME64]: CalendarIcon,
  [ResourcePropertyType.CURRENCY]: CurrencyIcon,
};

type RecordPropertyProps = HTMLAttributes<SVGElement> & {
  propertyType?: string | null;
};

export const RecordPropertyIcon = ({ propertyType, ...props }: RecordPropertyProps) => {
  const type = propertyType;

  if (!type) {
    return <ListIcon {...props} />;
  }

  const IconComponent = propertyIcons[type] || ListIcon;
  return <IconComponent {...props} />;
};
