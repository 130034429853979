import { QueryValueFilterOperator, ResourcePropertyType } from '@bigdelta/lib-shared';
import { v5 as uuidv5 } from 'uuid';
import { STANDARD_STRUCT_PROPERTY_TYPES, NESTED_PROPERTIES } from '../../properties/const';
import { FilterItem } from '../store';
import { RecordQueryFiltersRequestDef } from '@bigdelta/lib-api-client';

type PropertyCondition = NonNullable<Extract<RecordQueryFiltersRequestDef['conditions'][number], { record_property?: any }>>['record_property'];

export const getPropertyCondition = (item: FilterItem): PropertyCondition | undefined => {
  const property = item.property;

  if (!property?.attributeType) {
    return undefined;
  }

  if (STANDARD_STRUCT_PROPERTY_TYPES.includes(property.attributeType as ResourcePropertyType)) {
    return getNestedPropertyCondition(item);
  } else {
    return getStandardPropertyCondition(item);
  }
};

const getStandardPropertyCondition = (item: FilterItem) => {
  if (
    item.property?.attributeName &&
    item.propertyOperator &&
    [QueryValueFilterOperator.IS_SET, QueryValueFilterOperator.NOT_IS_SET].includes(item.propertyOperator)
  ) {
    return {
      property_id: item.property.attributeId,
      property_type: item.property.attributeType as ResourcePropertyType,
      name: item.property.attributeName,
      operator: item.propertyOperator,
    };
  }
  if (item.property?.attributeName && item.propertyOperator && item.data.value) {
    return {
      property_id: item.property.attributeId,
      property_type: item.property.attributeType as ResourcePropertyType,
      name: item.property.attributeName,
      operator: item.propertyOperator,
      value: item.data.value,
    };
  }
  return undefined;
};

const getNestedPropertyCondition = (item: FilterItem) => {
  const property = item.property;

  if (!property || !item.propertyOperator) {
    return undefined;
  }

  if (![QueryValueFilterOperator.IS_SET, QueryValueFilterOperator.NOT_IS_SET].includes(item.propertyOperator) && !item.data.value) {
    return undefined;
  }

  const nestedProperty = NESTED_PROPERTIES[property.attributeType];

  if (!nestedProperty) {
    return undefined;
  }

  const propertyName = [property.attributeName, nestedProperty.name].join('.');
  const propertyId = uuidv5(propertyName, import.meta.env.VITE_APP_METADATA_PROPERTY_NAMESPACE);
  const propertyType = nestedProperty.type;

  return {
    property_id: propertyId,
    property_type: propertyType,
    name: propertyName,
    operator: item.propertyOperator,
    value: item.data.value,
    top_level_property_type: property.attributeType as ResourcePropertyType,
  };
};
