import { RefObject, useEffect } from 'react';

export const useOutsideClickHandler = (ref: RefObject<any>, onOutsideClick: () => any) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref?.current?.contains?.(event.target)) {
        onOutsideClick?.();
      }
    };

    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [onOutsideClick, ref]);
};
