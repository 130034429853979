import { QueryReportTemplatesCreateData } from '@bigdelta/lib-api-client';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { FC, useMemo } from 'react';
import * as Table from '../../../components/Table';
import { twMerge } from '../../../utils/twMerge';
import { format, parseISO } from 'date-fns';
import Color from 'color';
import { twConfig } from '../../../utils/tailwindConfig';

const darkestColor = Color(twConfig.theme.colors['m-blue']['600']);
const lightestColor = Color(twConfig.theme.colors['m-white']); // Close to white

function getColorForValue(value: number): string {
  if (value < 0 || value > 1) {
    throw new Error('Value must be between 0 and 1');
  }

  if (value < 0.1) {
    return lightestColor.hex();
  }

  const step = Math.floor(value * 10) / 10; // Get the step value (0.1 increments)
  const colorRatio = step; // Ratio for interpolation

  const interpolatedColor = lightestColor.mix(darkestColor, colorRatio);
  return interpolatedColor.hex();
}

interface RetentionItem {
  retention_count: number;
  retention_rate: number;
}

interface QueryResultItem {
  timestamp: string;
  metric: number;
  retention: Record<string, RetentionItem | undefined>;
}

interface RetentionCohortProps {
  query: NonNullable<QueryReportTemplatesCreateData['website_website']>['retention_cohort_1_month'];
  displayType: 'percentage' | 'count';
}

const columnHelper = createColumnHelper<QueryResultItem>();

export const RetentionCohort: FC<RetentionCohortProps> = ({ query, displayType }) => {
  const formatTimestamp = (timestamp: string) => {
    return format(parseISO(timestamp), 'd MMM');
  };

  const data = useMemo(() => {
    if (!query?.result.length) {
      return [];
    }

    const sortedResults = [...query.result].sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()) as QueryResultItem[];

    return sortedResults;
  }, [query?.result]);

  const columns = useMemo(() => {
    if (!query?.result?.[0]) {
      return [];
    }

    const columnTimestampsList = Object.keys(query.result[0].retention).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

    const retentionTimestampCols = columnTimestampsList.map((timestamp) => {
      return columnHelper.accessor((queryResultItem) => queryResultItem.retention[timestamp], {
        id: timestamp,
        header: formatTimestamp(timestamp),
        size: 100,
        enableResizing: false,
        cell: (props) => {
          const retentionItem = props.getValue();

          if (!retentionItem) {
            return null;
          }

          const { retention_count, retention_rate } = retentionItem;

          const backgroundColor = getColorForValue(retention_rate);
          const color = retention_rate < 0.5 ? 'black' : 'white';
          const themeBorderColor = twConfig?.theme?.colors?.['m-olive']?.['100'] ?? 'transparent';
          const borderColor = retention_rate < 0.1 ? themeBorderColor : 'transparent';

          if (displayType === 'count') {
            return (
              <div className="rounded-lg p-2 text-white" style={{ backgroundColor, color, borderWidth: 1, borderColor }}>
                {retention_count}
              </div>
            );
          }

          if (displayType === 'percentage') {
            return (
              <div className="rounded-lg p-2 text-white" style={{ backgroundColor, color, borderWidth: 1, borderColor }}>
                {(retention_rate * 100).toFixed(0)}%
              </div>
            );
          }

          return null;
        },
      });
    });

    const cols = [
      columnHelper.accessor((queryResultItem) => queryResultItem.timestamp, {
        id: 'timestamp',
        header: 'Date',
        enableResizing: false,

        cell: (props) => {
          return formatTimestamp(props.getValue());
        },
      }),
      ...retentionTimestampCols,
    ];

    return cols;
  }, [displayType, query?.result]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const rowModel = table.getRowModel();
  const headerGroups = table.getHeaderGroups();

  const { rows } = rowModel;

  return (
    <div className="h-96 w-full overflow-auto overscroll-none rounded-lg">
      <Table.Root>
        {!!table.getLeafHeaders().length && (
          <thead className="sticky top-0 z-40">
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <Table.HeadCell
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: header.getSize() }}
                    className={twMerge(
                      'group border-0 shadow-border [--shadow-color:theme(colors.m-gray.300)]',
                      index === 0 && 'sticky left-0 z-40',
                      (header.column.columnDef.meta as any)?.headerClassName,
                      'p-2 shadow-none'
                    )}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </Table.HeadCell>
                ))}
              </tr>
            ))}
          </thead>
        )}
        {!!rows.length && (
          <tbody>
            {rows.map((row) => {
              return (
                <Table.BodyRow key={row.id}>
                  {row.getVisibleCells().map((cell, index) => (
                    <Table.BodyCell
                      key={cell.id}
                      className={twMerge(
                        (cell.column.columnDef.meta as any)?.cellClassName,
                        index === 0 && 'sticky left-0 bg-m-white',
                        'py-1 shadow-none',
                        index !== 0 && 'px-2 shadow-none'
                      )}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Table.BodyCell>
                  ))}
                </Table.BodyRow>
              );
            })}
          </tbody>
        )}
      </Table.Root>
    </div>
  );
};
