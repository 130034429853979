import { ChartType, TooltipItem } from 'chart.js';

import { ReportTypeVO } from '@bigdelta/lib-shared';

import { QueryMetaData } from '../../../../shared/types';
import { PointData } from '../../../../shared/reports/view/context/context';
import { Datapoint, Dataset } from '../../types';
import { formatMonetary } from '../../../../shared/utils/formatMonetary';

type ClickParams = Omit<PointData, 'objectData'>;

interface TooltipDatapointInfoProps<T extends ChartType> {
  title: string;
  metadata?: QueryMetaData;
  dataPoint: TooltipItem<T>;
  reportType: ReportTypeVO;
  onClick: (params: ClickParams) => void;
}

export const TooltipDatapointInfo = <T extends ChartType>({ title, metadata, dataPoint, reportType, onClick }: TooltipDatapointInfoProps<T>) => {
  const { parsed, formattedValue } = dataPoint || {};
  const dataset = dataPoint?.dataset as Dataset;
  const rawValue = (parsed as Datapoint)?.y;

  const value = metadata?.format?.monetary && typeof rawValue === 'number' ? formatMonetary(rawValue, metadata.format.monetary) : formattedValue;

  const handleClick = () => {
    const dataset = dataPoint?.dataset as Dataset;
    const data = dataset?.data;

    if (reportType === ReportTypeVO.FUNNEL) {
      const stage = dataset.data.findIndex((point) => point.x === dataPoint?.label);

      onClick({ queryName: metadata?.name as string, stage });
    } else {
      const from = (dataPoint?.raw as Datapoint)?.x as string;

      const elementIndex = data?.findIndex((point) => (point as Datapoint).x === from);
      const to = elementIndex + 1 < data?.length ? ((data[elementIndex + 1] as Datapoint).x as string) : undefined;
      onClick({ queryName: metadata?.name as string, from, to });
    }
  };

  return (
    <div className="flex items-center justify-start gap-x-2">
      <div className="size-3 rounded-sm" style={{ backgroundColor: dataset.color?.toString() }} />
      <div>{value}</div>
      <div>-</div>
      <div>{metadata?.query_name || 'Untitled'}</div>
      {Number(value) > 0 && (
        <button className="text-sm text-m-blue-600 underline" onClick={handleClick}>
          {title}
        </button>
      )}
    </div>
  );
};
