import { FC } from 'react';

import { PropsWithClassName } from '../../../types';

interface CellContentDefaultProps extends PropsWithClassName {
  text: string;
}

export const CellContentDefault: FC<CellContentDefaultProps> = ({ text }) => (
  <span className="shrink overflow-hidden text-ellipsis text-m-olive-400">{text}</span>
);
