import { ComponentType, forwardRef, HTMLAttributes } from 'react';
import { PropsWithClassName } from '../../types';
import { twMerge } from '../../utils/twMerge';

interface IconButton extends HTMLAttributes<HTMLDivElement>, PropsWithClassName {
  icon: ComponentType<PropsWithClassName>;
  iconClassName?: string;
}

export const IconButton = forwardRef<HTMLDivElement, IconButton>(({ icon, className, iconClassName, ...buttonProps }, ref) => {
  const Icon = icon;

  return (
    <div
      ref={ref}
      role="button"
      className={twMerge(
        'flex shrink-0 cursor-pointer items-center justify-center rounded-md bg-m-white p-1 text-m-olive-400 hover:bg-m-gray-300 hover:text-m-olive-500 disabled:bg-m-white disabled:text-m-olive-400',
        className
      )}
      {...buttonProps}
    >
      <Icon className={twMerge('size-4 cursor-pointer', iconClassName)} />
    </div>
  );
});
