import { useEffect, useState } from 'react';

import RemoveIcon from '../../../assets/icons/trash-01.svg?react';
import ConfirmIcon from '../../../assets/icons/check.svg?react';
import DeclineIcon from '../../../assets/icons/x.svg?react';
import { IconButton } from '../../../shared/components/IconButton';

interface RecordDeleteProps {
  selectedIds: string[];
  handleDelete: (selectedIds: string[]) => void;
}

export const RecordDelete = ({ selectedIds, handleDelete }: RecordDeleteProps) => {
  const [isConfirmation, setIsConfirmation] = useState(false);

  useEffect(() => {
    if (selectedIds.length === 0) {
      setIsConfirmation(false);
    }
  }, [selectedIds]);

  const showConfirmation = () => {
    setIsConfirmation(true);
  };

  const hideConfirmation = () => {
    setIsConfirmation(false);
  };

  const confirmDelete = () => {
    handleDelete(selectedIds);
    hideConfirmation();
  };

  return (
    <div className="flex items-center justify-start gap-2 bg-white p-2 text-sm text-m-olive-400">
      <span>{selectedIds.length} selected</span>
      {!isConfirmation && <IconButton icon={RemoveIcon} onClick={showConfirmation} />}
      {isConfirmation && (
        <>
          <IconButton icon={ConfirmIcon} onClick={confirmDelete} className="hover:text-m-green-600" />
          <IconButton icon={DeclineIcon} onClick={hideConfirmation} className="hover:text-m-red-600" />
          <p>Are you sure you want to remove the record{selectedIds.length > 1 ? 's' : ''}? This action is irreversible</p>
        </>
      )}
    </div>
  );
};
