import { PageHeading } from '../../../components/PageHeading';
import { Link, Outlet, useSearchParams } from 'react-router-dom';
import { ReportsTable } from '../components/ReportsTable';
import { useQuery } from '@tanstack/react-query';

import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { Button } from '../../../shared/ui/Button/Button';
import SearchIcon from '../../../assets/icons/search-md.svg?react';
import LineChartUpIcon from '../../../assets/icons/line-chart-up-01.svg?react';
import FilterFunnelIcon from '../../../assets/icons/filter-funnel-02.svg?react';
import GridIcon from '../../../assets/icons/grid-01.svg?react';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { useEffect, useMemo, useState } from 'react';
import { EmptyState } from '../../../shared/components/EmptyState.tsx';
import { CenteredLoader } from '../../../shared/components/CenteredLoader.tsx';
import { Context } from '@bigdelta/lib-shared';
import { Divider } from '../../../shared/ui/Divider/Divider';
import { Container } from '../../../shared/ui/Container/Container';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

const EMPTY_STATE_STAGES = [
  {
    icon: LineChartUpIcon,
    title: 'Visualise data',
    description: 'Combine events and records you want to track.',
  },
  {
    icon: FilterFunnelIcon,
    title: 'Filter and Breakdown',
    description: 'Use events and records properties to find relationships.',
  },
  {
    icon: GridIcon,
    title: 'Share in Dashboards',
    description: 'Make data easy to track by organising reports.',
  },
];

export const ReportsList = () => {
  const queryKeys = useQueryKeys();
  const { currentWorkspaceId } = useWorkspace();

  const [searchParams, setSearchParams] = useSearchParams();
  const context = searchParams.get('context') && Context[searchParams.get('context')!];

  useEffect(() => {
    if (!context) {
      setSearchParams({ context: Context.PRODUCT });
    }
  }, [context, setSearchParams]);

  const reportsQuery = useQuery({
    queryKey: [...queryKeys.reports(), context],
    queryFn: () => bigdeltaAPIClient.v1.reportsList({ workspace_id: currentWorkspaceId, context: context }),
  });

  const isEmpty = !reportsQuery?.data?.items || reportsQuery?.data?.items.length === 0;

  const [searchTerm, setSearchTerm] = useState('');

  const filteredReports = useMemo(() => {
    return reportsQuery?.data?.items.filter((report) => report.title.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [reportsQuery, searchTerm]);

  return (
    <div className="flex h-screen w-full flex-col">
      <div className="relative flex h-full flex-col">
        {!reportsQuery.isInitialLoading && (
          <>
            <Container>
              <div className="flex items-center justify-between" style={{ zIndex: 1 }}>
                <PageHeading breadcrumbs={[{ label: 'Reports' }]} />
                <div className="flex items-center gap-x-2">
                  {!isEmpty && (
                    <div className="relative">
                      <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                        <SearchIcon className="h-5 w-5 text-m-gray-600" />
                      </div>
                      <input
                        type="text"
                        id="simple-search"
                        className="block w-full rounded-md border border-m-gray-300 p-2 ps-10 text-md"
                        placeholder="Search report"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  )}
                  <Link to={{ pathname: 'new', search: searchParams.toString() }}>
                    <Button intent={isEmpty ? 'secondary' : 'brand'} size="lg" label="Create report" />
                  </Link>
                </div>
              </div>
            </Container>
            <Divider />
            {isEmpty && (
              <EmptyState heading="Generate Visual Reports" stages={EMPTY_STATE_STAGES}>
                <Link to={{ pathname: 'new', search: searchParams.toString() }}>
                  <Button intent="brand" size="xl" label="Create report" />
                </Link>
              </EmptyState>
            )}
            {!isEmpty && (
              <Container>
                <ReportsTable data={filteredReports} showActions={true} />
              </Container>
            )}
            <Outlet />
          </>
        )}
        {reportsQuery.isInitialLoading && <CenteredLoader />}
      </div>
    </div>
  );
};
