import { ValueSelectType, attributeTypeOperatorValueSelectMap } from '../filters/const';
import { ALL_EVENTS, FilterItem, FilterItemType } from '../filters/store';
import { EventQueryFiltersCondition } from './getEventFilterItemsFromSegment';
import { getTopLevelProperty } from './getTopLevelProperty';

export const getEventPropertyFilterFromCondition = (condition: EventQueryFiltersCondition): FilterItem | undefined => {
  if (
    !('event_property' in condition) ||
    !condition?.event_property?.name ||
    !condition?.event_property?.property_id ||
    !condition?.event_property.property_type
  ) {
    return;
  }

  const eventPropertyCondition = condition.event_property;

  const filterProperty = getTopLevelProperty(eventPropertyCondition) ?? eventPropertyCondition;

  const dataValType: ValueSelectType = attributeTypeOperatorValueSelectMap[filterProperty.property_type][filterProperty.operator];

  if (!dataValType) {
    return;
  }

  return {
    itemType: FilterItemType.EVENTS_PROPERTY,
    property: {
      attributeName: filterProperty.name,
      attributeId: filterProperty.property_id,
      attributeType: filterProperty.property_type,
    },
    propertyRelationships: [],
    data: {
      value: filterProperty.value as any,
      valueType: dataValType,
    },
    propertyOperator: filterProperty.operator as any,
    event: ALL_EVENTS,
    timeframe: {},
  };
};
