import { ValueSelectType, attributeTypeOperatorValueSelectMap } from '../filters/const';
import { ALL_EVENTS, FilterItem, FilterItemType } from '../filters/store';
import { getEventPropertyFilterFromCondition } from './getEventPropertyFilterFromCondition';
import { RecordQueryFiltersCondition } from './getRecordFilterItemsFromSegment';
import { getTopLevelProperty } from './getTopLevelProperty';

export const getRecordEventsFilterFromCondition = (condition: RecordQueryFiltersCondition): FilterItem | undefined => {
  if (!condition?.related_events?.filter) {
    return;
  }

  if (condition.related_events.filter.conditions[0] && 'event_property' in condition.related_events.filter.conditions[0]) {
    const eventPropertyCondition = condition.related_events.filter?.conditions[0].event_property;

    if (!eventPropertyCondition.name || !eventPropertyCondition.operator) {
      return;
    }

    if (!condition.related_events?.time) {
      return;
    }

    if (!eventPropertyCondition.property_id || !eventPropertyCondition.property_type) {
      return;
    }

    const filterProperty = getTopLevelProperty(eventPropertyCondition) ?? eventPropertyCondition;

    const dataValType: ValueSelectType = attributeTypeOperatorValueSelectMap[filterProperty.property_type][eventPropertyCondition.operator];

    if (!dataValType) {
      return;
    }

    const { name, property_id, property_type, operator, value } = filterProperty;

    return {
      itemType: FilterItemType.RECORDS_EVENT_PROPERTY,
      property: {
        attributeId: property_id,
        attributeName: name,
        attributeType: property_type,
      },
      propertyRelationships: [],
      data: {
        valueType: dataValType,
        value: value as any,
      },
      event: ALL_EVENTS,
      propertyOperator: operator as any,
      eventOperator: condition.related_events.operator as any,
      eventValue: condition.related_events.value as any,
      timeframe: {
        dateRangeType: condition.related_events?.time?.date_range_type,
        window: {
          unit: condition.related_events?.time?.window?.unit,
          value: condition.related_events?.time?.window?.value,
        },
        startAt: condition.related_events?.time?.start_at ?? undefined,
        endAt: condition.related_events?.time?.end_at ?? undefined,
      },
    };
  }

  if (condition.related_events.filter.conditions[0] && 'event_name' in condition.related_events.filter.conditions[0]) {
    if (!condition.related_events.filter.conditions[0].event_name) {
      return;
    }

    const eventName = condition.related_events.filter.conditions[0].event_name.value;

    if (!condition.related_events?.time) {
      return;
    }

    let eventPropertyItems: FilterItem[] | undefined;

    if (condition.related_events.filter.conditions.length > 1) {
      eventPropertyItems = condition.related_events.filter.conditions
        .filter((_, i) => i > 0)
        .map((condition) => {
          if ('event_property' in condition) {
            return getEventPropertyFilterFromCondition(condition);
          }
          return;
        })
        .flatMap((item) => (item ? [item] : []))
        .map((item) => ({ ...item, itemType: FilterItemType.RECORDS_EVENT_PROPERTY, event: undefined }));
    }

    return {
      itemType: FilterItemType.RECORDS_EVENT,
      event: eventName as any,
      eventOperator: condition.related_events.operator as any,
      eventValue: condition.related_events.value as any,
      data: {
        valueType: undefined,
        value: undefined,
      },
      items: eventPropertyItems,
      propertyRelationships: [
        {
          relationshipName: condition.related_events.relationship_name,
          objectId: '',
          objectWorkspaceId: '',
        },
      ],
      timeframe: {
        dateRangeType: condition.related_events?.time?.date_range_type,
        window: {
          unit: condition.related_events?.time?.window?.unit,
          value: condition.related_events?.time?.window?.value,
        },
        startAt: condition.related_events?.time?.start_at ?? undefined,
        endAt: condition.related_events?.time?.end_at ?? undefined,
      },
    };
  }
};
