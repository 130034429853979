import { createScopedHook, createScopedStore, stateReq } from '@lukesmurray/zustand-scoped';
import { ChartType, ReportTypeVO, TimeGranularity } from '@bigdelta/lib-shared';
import { produce } from 'immer';

import { TrendsBuilderInitialData, TrendsBuilderState, TrendsBuilderType, createTrendsBuilderStore } from './TrendsBuilder';
import { createFunnelBuilderStore, FunnelBuilderInitialData, FunnelBuilderState } from './FunnelBuilder';
import { granularityOptions, ReportTimerange } from '../const';

interface ReportActions {
  setTitle: (title: string) => void;
  setReportType: (reportType: ReportTypeVO) => void;
  setGranularity: (granularity: TimeGranularity) => void;
  setTimerange: (timerange: ReportTimerange) => void;
  setVisualization: (visualization: ChartType) => void;
  reset: (state?: ReportState) => void;

  addTrendsBuilder: (trendsBuilderInitialData: TrendsBuilderInitialData) => void;
  removeTrendsBuilder: (id: string) => void;
  addFunnelBuilder: (funnelBuilderInitialData: FunnelBuilderInitialData) => void;
  removeFunnelBuilder: (id?: string) => void;
}

export interface ReportState {
  id: string | null;
  reportType: ReportTypeVO | null;
  title: string | null;
  isHydrated: boolean;
  report: {
    trends: {
      builders: TrendsBuilderState[];
    };
    funnel: {
      builder: FunnelBuilderState | null;
    };
    // retention: {
    //   entryEvent: any;
    //   retentionEvent: any;
    //   period: any
    // };
  };
  breakdown: {
    properties: any[];
    values: any[];
    colors: Record<string, any>;
  };
  display: {
    visualization: ChartType | null;
    timerange: ReportTimerange | null; // decide on timerange type
    granularity: TimeGranularity | null;
  };
}

const reportInitialState = {
  id: null,
  reportType: null,
  title: null,
  isHydrated: false,
  report: {
    trends: {
      builders: [],
    },
    funnel: {
      builder: null,
    },
  },
  breakdown: {
    properties: [],
    values: [],
    colors: {},
  },
  display: {
    timerange: null,
    granularity: null,
    visualization: null,
  },
};

const createReportStore = createScopedStore<ReportState & ReportActions>()(() =>
  stateReq((set, get) => {
    const createNestedTrendsBuilderStore = (builderInitialData: TrendsBuilderInitialData) => {
      const selectBuilder = (state: ReportState) => {
        return state.report.trends.builders.find((builder) => builder.id === builderInitialData.id);
      };

      const resolveBuilder = (partial: TrendsBuilderState | ((builder: TrendsBuilderState) => TrendsBuilderState)) => {
        return typeof partial === 'function' ? partial(selectBuilder(get())!) : partial;
      };

      const setBuilder: Parameters<typeof createTrendsBuilderStore.scoped>[0] = (currentBuilderStateOrUpdater) => {
        const nextBuilderState = resolveBuilder(currentBuilderStateOrUpdater);

        return set((state) =>
          produce(state, (draft) => {
            const builderIdx = draft.report.trends.builders.findIndex((builder) => builder.id === nextBuilderState.id);

            if (builderIdx > -1) {
              draft.report.trends.builders[builderIdx] = nextBuilderState;
            }
          })
        );
      };

      const getBuilder: Parameters<typeof createTrendsBuilderStore.scoped>[1] = () => selectBuilder(get())!;

      return createTrendsBuilderStore.scoped(setBuilder, getBuilder, builderInitialData);
    };

    const createNestedFunnelBuilderStore = (builderInitialData: FunnelBuilderInitialData) => {
      const selectBuilder = (state: ReportState) => {
        return state.report.funnel.builder;
      };

      const resolveBuilder = (partial: FunnelBuilderState | ((builder: FunnelBuilderState) => FunnelBuilderState)) => {
        return typeof partial === 'function' ? partial(selectBuilder(get())!) : partial;
      };

      const setBuilder: Parameters<typeof createFunnelBuilderStore.scoped>[0] = (currentBuilderStateOrUpdater) => {
        const nextBuilderState = resolveBuilder(currentBuilderStateOrUpdater);

        return set((state) =>
          produce(state, (draft) => {
            const builder = draft.report.funnel.builder;

            if (builder) {
              draft.report.funnel.builder = nextBuilderState;
            }
          })
        );
      };
      const getBuilder: Parameters<typeof createFunnelBuilderStore.scoped>[1] = () => selectBuilder(get())!;
      return createFunnelBuilderStore.scoped(setBuilder, getBuilder, builderInitialData);
    };

    return {
      ...reportInitialState,

      setTitle: (title: string) =>
        set((state) =>
          produce(state, (draft) => {
            draft.title = title;
          })
        ),

      setReportType: (reportType) =>
        set((state) =>
          produce(state, (draft) => {
            draft.reportType = reportType;
          })
        ),

      setTimerange: (timerange) =>
        set((state) =>
          produce(state, (draft) => {
            draft.display.timerange = timerange;

            if (granularityOptions[timerange]?.indexOf(state.display.granularity!) === -1) {
              draft.display.granularity = granularityOptions[timerange][0];
            }
          })
        ),

      setGranularity: (granularity) =>
        set((state) =>
          produce(state, (draft) => {
            draft.display.granularity = granularity;
          })
        ),

      setVisualization: (visualization) =>
        set((state) =>
          produce(state, (draft) => {
            draft.display.visualization = visualization;
          })
        ),

      reset: (s = reportInitialState) =>
        set((state) =>
          produce(state, (draft) => ({
            ...draft,
            ...s,
          }))
        ),

      addFunnelBuilder: (funnelBuilderInitialData) =>
        set((state) =>
          produce(state, (draft) => {
            draft.report.funnel.builder = createNestedFunnelBuilderStore(funnelBuilderInitialData);
          })
        ),

      removeFunnelBuilder: () =>
        set((state) =>
          produce(state, (draft) => {
            draft.report.funnel.builder = null;
          })
        ),

      addTrendsBuilder: (trendsBuilderInitialData) =>
        set((state) =>
          produce(state, (draft) => {
            draft.report.trends.builders.push(createNestedTrendsBuilderStore(trendsBuilderInitialData));
          })
        ),

      removeTrendsBuilder: (id) =>
        set((state) =>
          produce(state, (draft) => {
            const builderToRemoveIdx = draft.report.trends.builders.findIndex((builder) => builder.id === id);

            if (builderToRemoveIdx > -1) {
              draft.report.trends.builders.splice(builderToRemoveIdx, 1);

              builderToRemoveIdx;

              draft.report.trends.builders.forEach((builder, index) => {
                if (index >= builderToRemoveIdx) {
                  const newName = String.fromCharCode('A'.charCodeAt(0) + index);
                  const oldName = builder.name;
                  builder.name = newName;

                  draft.report.trends.builders.forEach((formulaBuilder) => {
                    if (formulaBuilder.type === TrendsBuilderType.FORMULA && formulaBuilder.data.formula?.expression) {
                      formulaBuilder.data.formula.expression = formulaBuilder.data.formula.expression.replace(
                        new RegExp(`\\b${oldName}\\b`, 'g'),
                        newName
                      );
                    }
                  });
                }
              });
            }
          })
        ),
    };
  })
);

export const useReportStore = createReportStore();

export const useTrendsBuilderStoreBase = createScopedHook(
  useReportStore,
  (id: string) =>
    (state): TrendsBuilderState =>
      state.report.trends.builders.find((builder) => builder.id === id)!
);
export const useFunnelBuilderStoreBase = createScopedHook(
  useReportStore,
  () =>
    (state): FunnelBuilderState =>
      state.report.funnel.builder!
);

export const useTrendsBuilderStore = <TResult>(id: string, selector: (state: TrendsBuilderState) => TResult): TResult => {
  return useTrendsBuilderStoreBase(id, selector) as TResult;
};

export const useFunnelBuilderStore = <TResult>(_: any, selector: (state: FunnelBuilderState) => TResult): TResult => {
  return useFunnelBuilderStoreBase(null, selector) as TResult;
};
