import React, { HTMLProps } from 'react';
import { OverflowText } from '../../components/OverflowText';

interface TextViewProps extends HTMLProps<HTMLDivElement> {
  value: string;
  onClick?: () => void;
  overflowVisible?: boolean;
}

export const TextView: React.FC<TextViewProps> = ({ value, overflowVisible = false, ...props }) => {
  return (
    <OverflowText visible={overflowVisible} {...props}>
      {value.length ? value : <span>&nbsp;</span>}
    </OverflowText>
  );
};
