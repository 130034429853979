import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';

export const useMemoBuffer = <T>(value?: T): T => {
  const [buffer, setBuffer] = useState<T>(value);

  useEffect(() => {
    if (!isEqual(value, buffer)) {
      setBuffer(value);
    }
  }, [value, buffer]);

  return buffer;
};
