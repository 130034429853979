import { ChartData } from 'chart.js';
import { ComponentProps, FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { TimeGranularity } from '@bigdelta/lib-shared';
import { DISPLAY_FORMATS } from '../const';
import { formatMonetary } from '../../../shared/utils/formatMonetary';
import { Datapoint } from '../types';
import { merge } from 'lodash';
import { QueryMetaData } from '../../../shared/types';
import { ExternalTooltipHandler } from '../hooks/useExternalChartTooltip';

interface StackedBarTimeseriesChartProps {
  labels?: string[];
  datasets: ChartData<'bar', Datapoint[], string>['datasets'];
  granularity: TimeGranularity;
  metadata?: QueryMetaData;
  options?: ComponentProps<typeof Bar>['options'];
  tooltipHandler?: ExternalTooltipHandler<'bar'>;
}

export const StackedBarTimeseriesChart: FC<StackedBarTimeseriesChartProps> = ({ datasets, granularity, metadata, options, tooltipHandler }) => {
  return (
    <Bar
      data={{
        datasets,
      }}
      options={merge(
        {
          plugins: {
            datalabels: {
              display: false,
            },
            tooltip: {
              animation: false,
              enabled: !tooltipHandler,
              intersect: true,
              // TODO: define in d.ts
              position: 'pointer' as any,
              external: tooltipHandler ? tooltipHandler : undefined,
              callbacks: {
                label: (context) => {
                  const rawValue = context.parsed.y;

                  if (metadata?.format?.monetary && typeof rawValue === 'number') {
                    return formatMonetary(rawValue, metadata.format.monetary);
                  }

                  return context.formattedValue;
                },
              },
            },
          },
          maintainAspectRatio: false,
          responsive: true,
          elements: {
            point: {
              pointStyle: false,
            },
            line: {
              borderColor: '#5B70DF',
              tension: 0.01,
            },
          },
          scales: {
            x: {
              offset: true,
              stacked: true,
              offsetAfterAutoskip: true,
              type: 'time',
              time: {
                unit: granularity,
                displayFormats: DISPLAY_FORMATS,
              },
              ticks: {
                color: '#898A7E',
                display: !!datasets?.length,
                autoSkip: true,
                maxTicksLimit: 20,
                maxRotation: 0,
                minRotation: 0,
              },
              border: {
                display: false,
              },
              grid: {
                display: false,
              },
            },
            y: {
              offset: true,
              stacked: true,
              ticks: {
                color: '#898A7E',
                display: !!datasets?.length,
                callback: (rawValue) => {
                  if (metadata?.format?.monetary && typeof rawValue === 'number') {
                    return formatMonetary(rawValue, metadata.format.monetary);
                  }

                  return rawValue;
                },
              },
              border: {
                display: false,
              },
              grid: {
                color: '#EBECEB',
              },
            },
          },
        },
        options
      )}
    />
  );
};
