import { MetricMath, ResourcePropertyType } from '@bigdelta/lib-shared';
import { ReportsQueryRecords } from '../../../types';
import { Filter } from '../../../../../shared/filters/store';
import { getConditions } from '../../../../records/data/getRecordsQuery';

import { TrendsBuilderState, TrendsBuilderType } from '../../../store/TrendsBuilder';
import { QueryReportsCreatePayload } from '@bigdelta/lib-api-client';
import { isRecordCount } from '../../../store/common';

export const getQueryMetricRecord = (
  builder: TrendsBuilderState,
  filters: { [key: string]: Filter | null } | null
): QueryReportsCreatePayload['metrics'][number] | null => {
  const builderData = builder.data[TrendsBuilderType.OBJECT];

  if (!builderData?.workspaceObjectId) {
    return null;
  }

  let math: MetricMath | undefined;
  let mathTarget: ReportsQueryRecords['math_target'] | undefined;
  const record = isRecordCount(builderData.count) ? builderData.count.record : null;
  if (record?.type === 'total') {
    math = MetricMath.TOTAL;
  }

  if (record?.type === 'property') {
    const countPropertyRelationships = record.data.relationships;
    const countProperty = record.data.property;

    if (!countProperty) {
      return null;
    }

    if (countPropertyRelationships && countPropertyRelationships.length === 1) {
      math = builderData.count.aggregate;
      mathTarget = {
        record_property: {
          name: countProperty.property_name,
          property_type: countProperty.property_type as ResourcePropertyType,
          top_level_property_type: countProperty.top_level_property_type as ResourcePropertyType,
        },
      };
    }

    // TODO: Cap at 1 relationship level
    if (countPropertyRelationships && countPropertyRelationships.length == 2) {
      if (!countPropertyRelationships[1].relationshipName) {
        return null;
      }

      math = builderData.count.aggregate;
      mathTarget = {
        related_records: {
          relationship_name: countPropertyRelationships[1].relationshipName,
          property_name: countProperty.property_name,
          property_type: countProperty.property_type as ResourcePropertyType,
          top_level_property_type: countProperty.top_level_property_type as ResourcePropertyType,
        },
      };
    }
  }

  const recordsMath = math;

  if (recordsMath === undefined) {
    return null;
  }

  const filter = filters?.[builder.id] ?? null;

  const filterConditions = getConditions(filter);
  const hasFilterConditions = filter && filterConditions.length;

  return {
    records: {
      math: recordsMath,
      math_target: mathTarget,
      resource: {
        id: builderData.workspaceObjectId,
      },
      filter: hasFilterConditions ? { operator: filter.operator, conditions: filterConditions } : undefined,
    },
    name: builder.name,
    query_name: builder.label,
  };
};
