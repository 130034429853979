import { useObjectsQuery } from '../../../shared/data/useObjectsQuery.ts';
import { useMemo, useCallback, forwardRef, ComponentType, FC, useRef } from 'react';
import { capitalize } from 'lodash';
import { useRelationshipsQuery } from '../../../shared/data/useRelationshipsQuery.ts';
import { getObjectsRelatedToEvent } from '../../../shared/utils/getObjectsRelatedToEvent.ts';
import { getObjectRelationshipToEvent } from '../../../shared/utils/getObjectRelationshipToEvent.ts';
import { getRelatingAttributeByName } from '../../../shared/utils/getRelatingAttributeByName.ts';
import { RelationshipObjectData } from '../../../shared/types.ts';
import { Attribute } from '../../../shared/filters/store';
import * as SelectPrimitive from '@radix-ui/react-select';
import { twMerge } from 'tailwind-merge';
import { PropsWithClassName } from '../../../types.ts';
import { ObjectIcon } from '../../reports/components/common/ObjectIcon.tsx';
import { WorkspaceObjectType } from '@bigdelta/lib-shared';
import { Combobox } from '../../../components/Combobox';
import * as Popover from '@radix-ui/react-popover';
import { REMOTE_ID } from '../../records/const.ts';

export type ObjectSelectProps = {
  value: string;
  workspaceId: string;
  onObjectSelect: (details: { relationship: RelationshipObjectData | null; attribute: Attribute | null }) => void;
};

export const ObjectSelect = ({ value, workspaceId, onObjectSelect }: ObjectSelectProps) => {
  const { data: objectsData } = useObjectsQuery({ workspaceId });
  const { data: relationshipsData } = useRelationshipsQuery({ workspaceId });
  const popoverCloseRef = useRef(null);

  const relatedToEvent = useMemo(
    () => getObjectsRelatedToEvent({ relationships: relationshipsData?.relationships ?? [] }),
    [relationshipsData?.relationships]
  );

  const anyOption = useMemo(() => ({ id: '', object_type: 'ANY', plural_noun: 'Any Object' }), []);
  const options = (objectsData?.objects ?? []).filter((object) => relatedToEvent.map((rel) => rel.objectId).includes(object.id));
  const optionsWithAny = useMemo(() => [...options, anyOption], [anyOption, options]);

  const selectAnyObj = useCallback(() => {
    onObjectSelect({
      relationship: null,
      attribute: null,
    });
  }, [onObjectSelect]);

  const handleObjectChange = useCallback(
    (id: string) => {
      if (!id || !relationshipsData?.relationships) {
        selectAnyObj();
        return;
      }

      const relationship = getObjectRelationshipToEvent({ relationships: relationshipsData?.relationships, objectId: id });
      const filterRelationship = relatedToEvent.find((rel) => rel.objectId === id);

      if (!relationship || !filterRelationship) {
        selectAnyObj();
        return;
      }

      const attribute = getRelatingAttributeByName({ relationship, propertyName: REMOTE_ID });
      onObjectSelect({
        relationship: filterRelationship,
        attribute,
      });
    },
    [onObjectSelect, relatedToEvent, relationshipsData?.relationships, selectAnyObj]
  );

  const selectedOption = optionsWithAny.find((o) => o.id === value);

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <div className="flex flex-1 cursor-pointer items-center gap-x-1.5 rounded px-3 py-1.5 hover:bg-m-gray-300">
          <ObjectIcon objectType={selectedOption?.object_type} />
          <span className="whitespace-nowrap text-sm font-regular text-m-olive-700">
            {selectedOption?.plural_noun
              .split(' ')
              .map((w) => capitalize(w))
              .join(' ') ?? ''}
          </span>
        </div>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          align="start"
          className="w-[22rem] overflow-hidden rounded-lg border border-m-olive-100 bg-m-white shadow-md"
          sideOffset={12}
          alignOffset={-11}
        >
          <Popover.Close ref={popoverCloseRef} className="hidden" />
          <Combobox
            items={optionsWithAny ?? []}
            height={300}
            renderOption={(object) => (
              <div className="flex items-center gap-x-1 text-sm font-regular">
                <ObjectIcon objectType={WorkspaceObjectType[object.object_type]} />
                {object.plural_noun
                  .split(' ')
                  .map((w) => capitalize(w))
                  .join(' ')}
              </div>
            )}
            className="w-full gap-y-2 border-0 p-2"
            catchInputFocus={false}
            onChange={(option) => {
              if (!option) {
                return;
              }

              handleObjectChange(option?.id);
              if (popoverCloseRef.current) {
                (popoverCloseRef.current as HTMLButtonElement).click();
              }
            }}
            filterCompare={(objectItem, search) => {
              if (!search) {
                return true;
              }

              if (!objectItem) return false;

              if (objectItem.plural_noun.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
                return true;
              }

              return false;
            }}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

interface SelectItemContentProps {
  label?: string;
  icon?: ComponentType<PropsWithClassName>;
}

export const SelectItemContent: FC<SelectItemContentProps> = ({ label, icon }) => {
  const Icon = icon;

  return (
    <div className="flex items-center gap-x-1.5 px-3 py-1.5">
      {Icon && <Icon className="h-4 w-4 text-m-olive-400" />}
      <span className="whitespace-nowrap text-sm font-regular text-m-olive-700">{label}</span>
    </div>
  );
};

interface TriggerProps extends SelectPrimitive.SelectTriggerProps, SelectItemContentProps {
  placeholder: string;
}
export const Trigger = forwardRef<HTMLButtonElement, TriggerProps>(({ label, icon, placeholder, className, ...props }, ref) => {
  return (
    <SelectPrimitive.Trigger
      className={twMerge('flex w-full items-center justify-start rounded hover:bg-m-gray-300', className)}
      {...props}
      ref={ref}
    >
      <SelectPrimitive.Value placeholder={<SelectItemContent label={placeholder} />}>
        <SelectItemContent label={label} icon={icon} />
      </SelectPrimitive.Value>
    </SelectPrimitive.Trigger>
  );
});
