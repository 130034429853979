import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { useWorkspace } from '../../auth/hooks/useWorkspace';

import StripeIcon from '../../../assets/integrations/Stripe.svg?react';

import { IntegrationSetupIllustration } from './IntegrationSetupIllustration';
import { IntegrationSetupHeading } from './IntegrationSetupHeading';
import { toastError, toastSuccess } from '../../../utils/toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from '../../../routes';
import LoadingIcon from '../../../assets/icons/loading-06.svg?react';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface StripeIntegrationSetupProps {
  close: () => void;
}

export const StripeIntegrationSetup: FC<StripeIntegrationSetupProps> = ({ close }) => {
  const { currentWorkspaceId } = useWorkspace();

  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const error = searchParams.get('error');
  const state = searchParams.get('state');

  const [actionCompleted, setActionCompleted] = useState(false);

  const createStripeIntegrationMutation = useMutation({
    mutationFn: ({ code }: { code: string; objectSlug: string }) =>
      bigdeltaAPIClient.v1.integrationsStripeCreate({ workspace_id: currentWorkspaceId }, { code: code }),
    onSuccess: (data, variables) => {
      enableStripeIntegrationMutation.mutate({ integrationId: data.id, objectSlug: variables.objectSlug });
    },
    onError: () => {
      toastError('Failed to create Stripe integration');
      close();
    },
  });

  const enableStripeIntegrationMutation = useMutation({
    mutationFn: ({ integrationId, objectSlug }: { integrationId: string; objectSlug: string }) =>
      bigdeltaAPIClient.v1.integrationsStripeEnablePartialUpdate(integrationId, { workspace_object_slug: objectSlug }),
    onSuccess: (data) => {
      toastSuccess('Stripe integration created', '');
      queryClient.invalidateQueries(queryKeys.integrations()).then(() => navigate(`${AppRoutes.INTEGRATIONS}/${data.id}`));
    },
    onError: () => {
      toastError('Failed to create Stripe integration');
      navigate(AppRoutes.INTEGRATIONS);
    },
    onSettled: () => {
      close();
    },
  });

  const reconnectStripeIntegrationMutation = useMutation({
    mutationFn: ({ integrationId, code }: { integrationId: string; code: string }) =>
      bigdeltaAPIClient.v1.integrationsStripeReconnectPartialUpdate(integrationId, { code: code }),
    onSuccess: (data) => {
      toastSuccess('Stripe integration reconnected', '');
      queryClient.invalidateQueries(queryKeys.integrations()).then(() => navigate(`${AppRoutes.INTEGRATIONS}/${data.id}`));
    },
    onError: () => {
      toastError('Failed to reconnect Stripe integration');
      navigate(AppRoutes.INTEGRATIONS);
    },
    onSettled: () => {
      close();
    },
  });

  useEffect(() => {
    if (actionCompleted) {
      return;
    }

    setActionCompleted(true);

    if (!code || error) {
      close();
      navigate(AppRoutes.INTEGRATIONS);
      toastError(`Failed to ${state ? 'reconnect' : 'create'} Stripe integration`);
      return;
    }

    if (state) {
      const integrationId = parseIntegrationId(state);

      if (integrationId) {
        reconnectStripeIntegrationMutation.mutate({
          integrationId,
          code,
        });
      } else {
        close();
        navigate(AppRoutes.INTEGRATIONS);
        toastError('Failed to reconnect Stripe integration');
      }
    } else {
      createStripeIntegrationMutation.mutate({
        code: code,
        objectSlug: 'accounts',
      });
    }
    // On Load hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col items-center gap-y-8 p-6 pb-3">
      <IntegrationSetupIllustration name="Stripe" icon={StripeIcon} />
      <IntegrationSetupHeading heading="Connecting, please wait..." />
      <LoadingIcon className="h-8 w-8 animate-spin" />
    </div>
  );
};

function parseIntegrationId(state: string) {
  try {
    return JSON.parse(atob(state))?.integrationId;
  } catch (error) {
    return null;
  }
}
