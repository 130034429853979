import { createContext, Dispatch, SetStateAction } from 'react';
import { type ObjectsListData, type ReportsQueryRequestDef } from '@bigdelta/lib-api-client';
import { AttributesValue } from '../../../../features/reports/types.ts';

export interface PointData {
  stage?: number;
  from?: string;
  to?: string;
  queryName: string;
  objectData?: NonNullable<ObjectsListData['objects'][number]>;
}

export interface ReportViewContextState {
  highlightedElement?: AttributesValue | null;
  setHighlightedElement: (value: AttributesValue | null) => void;
  pointData?: PointData | null;
  setPointData?: Dispatch<SetStateAction<PointData | null>>;
  reportQuery?: ReportsQueryRequestDef;
}

export const ReportViewContext = createContext<ReportViewContextState | undefined>({
  highlightedElement: null,
  setHighlightedElement: () => {},
  setPointData: () => {},
  pointData: null,
  reportQuery: undefined,
});
