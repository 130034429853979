import * as PageHeader from '../../../shared/components/PageHeader';

import PresentationIcon from '../../../assets/icons/presentation-chart-01.svg?react';
import { useQuery } from '@tanstack/react-query';
import { QuickAccessLinkType } from '@bigdelta/lib-shared';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient';
import { Link, useParams } from 'react-router-dom';
import { orderBy } from 'lodash';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { FC, forwardRef } from 'react';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { twMerge } from '../../../utils/twMerge';

export const DropdownMenuItem = forwardRef<HTMLDivElement, DropdownMenu.DropdownMenuItemProps>(({ children, className, ...props }, ref) => (
  <DropdownMenu.Item
    className={twMerge(
      'flex select-none items-center gap-x-2 rounded p-0 text-xs text-m-olive-800 outline-none radix-highlighted:bg-m-gray-200',
      className
    )}
    {...props}
    ref={ref}
  >
    {children}
  </DropdownMenu.Item>
));

interface DashboardsDropdownProps {
  name: string;
}

const DashboardsDropdown: FC<DashboardsDropdownProps> = ({ name }) => {
  const queryKeys = useQueryKeys();
  const { currentWorkspaceId } = useWorkspace();

  const dashboardsQuery = useQuery({
    queryKey: queryKeys.dashboards(),
    queryFn: () => bigdeltaAPIClient.v1.dashboardsList({ workspace_id: currentWorkspaceId }),
  });

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <PageHeader.ItemSelectTrigger>
          <PageHeader.Item>{name}</PageHeader.Item>
        </PageHeader.ItemSelectTrigger>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        className="w-[var(--radix-dropdown-menu-trigger-width)] overflow-hidden rounded-md border border-m-olive-100 bg-m-white p-2 shadow-xl"
        sideOffset={4}
        align="start"
      >
        {dashboardsQuery.data?.items.map((dashboard) => (
          <DropdownMenuItem key={dashboard.id} asChild>
            <Link to={`/dashboards/${dashboard.id}`} className="truncate px-2 py-1.5">
              <span className="truncate">{dashboard.name}</span>
            </Link>
          </DropdownMenuItem>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export const DashboardHeader = () => {
  const { dashboardId } = useParams();
  const queryKeys = useQueryKeys();
  const dashboardQuery = useQuery({
    queryKey: queryKeys.dashboard(dashboardId),
    queryFn: () => bigdeltaAPIClient.v1.dashboardsDetail(dashboardId || ''),
    select: (dashboard) => {
      return {
        ...dashboard,
        panels: orderBy(dashboard.panels, (i) => i, 'asc'),
      };
    },
  });

  return (
    <PageHeader.Root>
      <PageHeader.Items>
        <PageHeader.Item icon={PresentationIcon}>Dashboards</PageHeader.Item>
        <PageHeader.Separator />

        <DashboardsDropdown name={dashboardQuery?.data?.name || ''} />
      </PageHeader.Items>

      <PageHeader.Shortcut
        config={{
          type: QuickAccessLinkType.DASHBOARD,
          label: dashboardQuery?.data?.name || '',
          data: { dashboard: { dashboardId: dashboardQuery.data.id } },
        }}
      />
    </PageHeader.Root>
  );
};
