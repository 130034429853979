export enum CurrencyCode {
  EUR = 'eur',
  GBP = 'gbp',
  USD = 'usd',
  CAD = 'cad',
  AUD = 'aud',
  NZD = 'nzd',
  AED = 'aed',
  INR = 'inr',
  CHF = 'chf',
  JPY = 'jpy',
  CNY = 'cny',
  PHP = 'php',
  ILS = 'ils',
  SGD = 'sgd',
  HKD = 'hkd',
  MYR = 'myr',
  NTD = 'ntd',
  NOK = 'nok',
  SEK = 'sek',
  ISK = 'isk',
  BEL = 'bel',
  PLN = 'pln',
  KRW = 'krw',
  DKK = 'dkk',
  BRL = 'brl',
}
