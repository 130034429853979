import { useFunnelBuilderStore } from '../../store/Report';
import { DataSelect } from './DataSelect';
import { FunnelBuilder } from './FunnelBuilder';

export const FunnelConfig = () => {
  const workspaceObjectId = useFunnelBuilderStore(null, (state) => state?.data.object?.workspaceObjectId);

  return (
    <div className="flex flex-col gap-y-2 py-4">
      <div className="flex flex-col items-stretch justify-between gap-y-2">
        <div className="text-md font-medium">Data</div>
        {!workspaceObjectId && (
          <div className="rounded-lg border border-m-olive-100">
            <DataSelect />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-y-2">
        <FunnelBuilder />
      </div>
    </div>
  );
};
