import { FC } from 'react';

import { RecordPropertyIcon } from '../../../shared/components/RecordPropertyIcon';
import { OverflowText } from '../../../shared/components/OverflowText';

interface PropertyNameProps {
  type: string;
  propertyName: string;
}

export const PropertyName: FC<PropertyNameProps> = ({ type, propertyName }) => {
  return (
    <div className="flex flex-nowrap items-center justify-start text-sm">
      <RecordPropertyIcon className="mr-2 h-4 shrink-0" propertyType={type} />
      <OverflowText>{propertyName}</OverflowText>
    </div>
  );
};
