import { useQuery } from '@tanstack/react-query';

import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient';

export const useObjects = (enabled = true) => {
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  const { data } = useQuery({
    queryKey: queryKeys.list('object', 'active_and_virtual'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId, status: 'ACTIVE_AND_VIRTUAL' }),
    enabled,
  });

  const objects = data?.objects || [];

  return objects;
};
