import { FC, PropsWithChildren, ReactNode, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import ExpandIcon from '../../assets/icons/align-right-01.svg?react';
import QuestionIcon from '../../assets/icons/question.svg?react';
import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../features/auth/hooks/useQueryKeys';
import { useWorkspace } from '../../features/auth/hooks/useWorkspace';
import { getQuickAccessLinkData } from '../../features/navigation/utils';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';
import { FavoriteButton } from './FavoriteButton';
import { Tooltip } from '../ui/Tooltip/Tooltip';
import { SVGComponent } from '../../types';
import { useShortcuts } from '../hooks/useShortcuts';
import { SavedLink, useDrawerStore } from '../../features/navigation/store';

import ChevronDownIcon from '../../assets/icons/chevron-down.svg?react';

const Separator = () => <span className="mx-1 text-m-olive-400">/</span>;

const Root: FC<PropsWithChildren> = ({ children }) => {
  const { isOpen, toggleIsOpen } = useDrawerStore();

  return (
    <div className="h-18 flex items-center gap-x-3">
      {!isOpen && (
        <button
          className={twMerge(
            'ml-2 rounded border border-m-olive-400 p-0.5 text-m-olive-400 transition-colors duration-300 hover:bg-m-olive-400 hover:text-m-coconut-600'
          )}
          onClick={toggleIsOpen}
        >
          <ExpandIcon className="h-3 w-3" />
        </button>
      )}
      {children}
    </div>
  );
};

const Items: FC<PropsWithChildren> = ({ children }) => {
  return <div className="flex items-center gap-x-1">{children}</div>;
};

interface PageHeaderItemProps {
  icon?: SVGComponent;
  children: ReactNode;
  description?: string;
  to?: string;
}

const Item: FC<PageHeaderItemProps> = ({ icon: Icon, children, description, to }) => {
  const content = (
    <>
      {Icon && <Icon className="mr-1.5 inline-block h-4 w-4" />}
      <span className="truncate first-letter:capitalize">{children}</span>
      {description && (
        <Tooltip content={description}>
          <div className="ml-1">
            <QuestionIcon className="relative h-4 w-4 text-m-olive-400" />
          </div>
        </Tooltip>
      )}
    </>
  );

  if (to) {
    return (
      <Link className="flex max-w-96 items-center truncate text-m-olive-400 underline" to={to}>
        {content}
      </Link>
    );
  }

  return <span className="flex max-w-96 items-center">{content}</span>;
};

interface ShortcutProps {
  config: Omit<SavedLink, 'id' | 'order'>;
}

const Shortcut: FC<ShortcutProps> = ({ config }) => {
  const queryKeys = useQueryKeys();
  const { currentWorkspaceId } = useWorkspace();
  const { addShortcut, removeShortcut, getMatchingShortcut } = useShortcuts();

  const quickAccessLinksQuery = useQuery({
    queryKey: queryKeys.list('quick-access-link'),
    queryFn: () =>
      bigdeltaAPIClient.v1.membersConfigQuickAccessLinksList({
        workspace_id: currentWorkspaceId,
      }),
    staleTime: Infinity,
  });

  const data = getQuickAccessLinkData(config.type, config.data);
  const existingQuickAccessLink = getMatchingShortcut(config.type, data ?? {});

  const handleFavoriteToggle = () => {
    if (!quickAccessLinksQuery.data) return;

    if (existingQuickAccessLink) {
      removeShortcut(existingQuickAccessLink.id);
    } else {
      addShortcut({
        type: config.type,
        order: quickAccessLinksQuery.data.quick_access_links.length,
        data: data || {},
        label: config.label,
      });
    }
  };

  if (!quickAccessLinksQuery.data) return null;

  return <FavoriteButton active={!!existingQuickAccessLink} onToggle={handleFavoriteToggle} />;
};

const ItemSelectTrigger = forwardRef<HTMLButtonElement, PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>>(
  ({ children, className, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={twMerge(
          'flex items-center gap-x-1 rounded-lg border border-m-olive-100 px-2 py-1 transition-colors duration-300 hover:bg-m-gray-200 radix-state-open:bg-m-gray-200 min-w-32',
          className
        )}
        {...props}
      >
        {children}
        <ChevronDownIcon className="h-4 w-4" />
      </button>
    );
  }
);

export { Root, Items, Item, ItemSelectTrigger, Separator, Shortcut };
