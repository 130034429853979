import Color from 'color';

import { ChartType, ReportTypeVO, TimeGranularity } from '@bigdelta/lib-shared';
import { QueryReportsCreateData } from '@bigdelta/lib-api-client';
import { ReportChart } from '../../../features/reports/components/ReportChart';
import { twConfig } from '../../../utils/tailwindConfig';

interface CellContentChartProps {
  dataQueries?: QueryReportsCreateData['queries'];
}

const mainChartColor = twConfig.theme.colors['m-blue']['600'];
const inactiveColor = twConfig.theme.colors['m-olive']['100'];
const transparentBlue = Color(mainChartColor).alpha(0.2).string();

export const CellContentChart = ({ dataQueries }: CellContentChartProps) => {
  const isEmpty = dataQueries?.[0]?.result?.length === 0;

  const isInactive = isEmpty || dataQueries?.[0]?.result.every(({ metric }) => metric === 0);

  return (
    <div className="max-h-9">
      <ReportChart
        reportType={ReportTypeVO.CHART}
        chartType={ChartType.LINE}
        dataQueries={dataQueries}
        granularity={TimeGranularity.DAY}
        fillColor={transparentBlue}
        borderColor={isInactive && inactiveColor}
        lineOptions={{
          hover: {
            mode: null as any,
          },
          scales: {
            y: {
              display: false,
            },
            x: {
              display: false,
            },
          },
          plugins: {
            tooltip: {
              enabled: false,
            },
          },
          elements: {
            line: {
              tension: 0.3,
              borderWidth: 2,
              fill: true,
              backgroundColor: transparentBlue,
            },
          },
        }}
      />
    </div>
  );
};
