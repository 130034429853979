import { QueryReportsCreateData } from '@bigdelta/lib-api-client';
import { FC, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { AliasPrefix } from '../store';
import { includes, orderBy, words } from 'lodash';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useHighlightedElementPropertyValue } from '../../../shared/hooks/useHighlightedElementPropertyValue.ts';
import { PropertyNameObject } from '../../../shared/types.ts';
import { ExternalTooltipHandler } from '../hooks/useExternalChartTooltip.tsx';

Chart.register(ChartDataLabels);

const smallBarSize = 40;

interface HorizontalBarChartProps {
  dataQuery?: QueryReportsCreateData['queries'][0];
  breakdownSelectedValues?: { property_value: string }[];
  breakdownProperty?: PropertyNameObject;
  breakdownPropertyPrefix?: AliasPrefix;
  breakdownSelectedValuesColorMap?: Record<string, string>;
  tooltipHandler?: ExternalTooltipHandler<'bar'>;
}

export const HorizontalBarChart: FC<HorizontalBarChartProps> = ({
  dataQuery,
  breakdownProperty,
  breakdownSelectedValuesColorMap,
  breakdownSelectedValues,
  tooltipHandler,
}) => {
  const highlightedElementPropertyValue = useHighlightedElementPropertyValue(breakdownSelectedValues);
  const key: string = useMemo(() => {
    if (!breakdownProperty) {
      return 'test';
    }
    const propertyWords = words(breakdownProperty.property_name);
    const keyVariants = [['event_attr', ...propertyWords].join('_'), ['record_attr', ...propertyWords].join('_')];
    return keyVariants.find((variant) => dataQuery?.result.some((item) => Object.keys(item).includes(variant))) || '';
  }, [dataQuery?.result, breakdownProperty]);

  const filteredData = useMemo(() => {
    if (breakdownSelectedValues?.length === 0 || !breakdownProperty) {
      return dataQuery?.result || [];
    }

    const valuesToDisplay = breakdownSelectedValues?.map((item) => item.property_value);

    return dataQuery?.result.filter((item) => includes(valuesToDisplay, item[key]));
  }, [breakdownSelectedValues, breakdownProperty, dataQuery?.result, key]);

  const defaultLabels = useMemo(() => (filteredData?.length ? ['total'] : []), [filteredData?.length]);
  const sortedResult = useMemo(() => {
    return orderBy(filteredData || [], ['metric'], ['desc']);
  }, [filteredData]);
  const { data, labels } = useMemo(() => {
    const data = sortedResult.map((item) => item['metric']);
    const labels = breakdownProperty ? sortedResult.map((item) => item[key]) : defaultLabels;
    return { data, labels };
  }, [breakdownProperty, sortedResult, defaultLabels, key]);

  const backgroundColors = useMemo(() => {
    return sortedResult.map((item) => {
      const color = breakdownSelectedValuesColorMap?.[item[key]];
      if (highlightedElementPropertyValue && highlightedElementPropertyValue !== item[key]) {
        return `${color}30`;
      }
      return color;
    });
  }, [sortedResult, breakdownSelectedValuesColorMap, key, highlightedElementPropertyValue]);

  return (
    <Bar
      data={{
        labels,
        datasets: [
          {
            data,
            backgroundColor: backgroundColors,
            label: dataQuery?.id,
            barThickness: 20,
            borderRadius: 4,
            borderSkipped: false,
          },
        ],
      }}
      plugins={[ChartDataLabels]}
      options={{
        animation: false,
        plugins: {
          datalabels: {
            align: (context) => {
              const bar = context.chart.getDatasetMeta(0).data[context.dataIndex] as any;
              return bar.width <= smallBarSize ? 'end' : 'start';
            },
            anchor: 'end',
            color: (context) => {
              const bar = context.chart.getDatasetMeta(0).data[context.dataIndex] as any;
              return bar.width <= smallBarSize ? '#000' : '#fff';
            },
          },
          tooltip: {
            enabled: !tooltipHandler,
            external: tooltipHandler ? tooltipHandler : undefined,
          },
        },
        indexAxis: 'y',
        scales: {
          y: {
            border: {
              display: false,
            },
            beginAtZero: true,
            grid: {
              display: false,
            },
          },
          x: {
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
      }}
    />
  );
};
