import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface FloatingState {
  floatingCellId: string | null;
}

interface FloatingStateAction {
  remember: (data: FloatingState['floatingCellId']) => void;
  forget: () => void;
}

const initialState = {
  floatingCellId: null,
};

export const useRememberFloating = create<FloatingState & FloatingStateAction>()(
  immer((set) => ({
    ...initialState,
    remember: (data) => set(() => ({ floatingCellId: data })),
    forget: () => set(() => ({ floatingCellId: null })),
  }))
);
