import { MetadataResourcesPropertiesValuesListData, QueryReportsCreatePayload } from '@bigdelta/lib-api-client';

// export type QueryInsightsGroupItem = Extract<QueryReportsCreatePayload['group'], Array<any>>[number];

export type QueryInsightsGroupItem = any;

export type AttributesValue = MetadataResourcesPropertiesValuesListData['items'][number];

export interface Datapoint {
  x: number | string;
  y: number;
  conversion_rate?: number;
  title?: string;
}
export interface Dataset {
  data: Datapoint[];
  color: string;
  regularColor: string;
  backgroundColor?: string;
  label: string;
}

export enum TemplateRelationship {
  EVENT_USERS = 'event_users',
  EVENT_WORKSPACES = 'event_workspaces',
  EVENT_ACCOUNTS = 'event_accounts',
}

export type ReportsQueryRecords = NonNullable<QueryReportsCreatePayload['metrics'][number]['records']>;
export type ReportsQueryEvents = NonNullable<QueryReportsCreatePayload['metrics'][number]['events']>;

export enum ReportViewMode {
  CHART = 'chart',
  BOTH = 'both',
  BREAKDOWN = 'breakdown',
}

export enum ContextMenuAction {
  SAVE_AS_METRIC = 'SAVE_AS_METRIC',
  UPDATE_METRIC = 'UPDATE_METRIC',
  RENAME = 'RENAME',
  REMOVE = 'REMOVE',
}
