import { Float } from '@headlessui-float/react';
import { FocusTrap, Popover } from '@headlessui/react';
import { RecordPropertyMultilevelSelectContent } from './RecordPropertyMultilevelSelectContent';
import { FC, ReactNode, useState } from 'react';
import { RelationshipEntityType } from '@bigdelta/lib-shared';
import { isNil, last } from 'lodash';
import { SelectTriggerSmall } from '../../components/SelectTriggerSmall';
import { useWorkspace } from '../../features/auth/hooks/useWorkspace';
import { useObjectsQuery } from '../data/useObjectsQuery';
import { PropertyNameObject, RelationshipObjectData } from '../types';
import { OpenTrigger } from './OpenTrigger';
import { REMOTE_ID } from '../../features/records/const';

interface RecordPropertyMultilevelSelectProps {
  parentEntityType: RelationshipEntityType;
  parentEntityId: string | null;
  onChange: (propertyNameObject: PropertyNameObject | null, relationships: RelationshipObjectData[] | null) => void;
  triggerLabel: ReactNode;
  reset: () => void;
  buttonClassName?: string;
  maxLevels?: number;
  shouldOpen?: boolean;
}

export const RecordPropertyMultilevelSelect: FC<RecordPropertyMultilevelSelectProps> = ({
  parentEntityType,
  parentEntityId,
  triggerLabel,
  onChange,
  reset,
  buttonClassName,
  maxLevels,
  shouldOpen = false,
}) => {
  const { currentWorkspaceId } = useWorkspace();
  const [recordSearch, setRecordSearch] = useState('');

  const { data: objectList } = useObjectsQuery({ workspaceId: currentWorkspaceId });

  const handleRelatedPropertyChange = (propertyNameObject: PropertyNameObject | null, relationships: RelationshipObjectData[] | null) => {
    if (!propertyNameObject || !relationships?.length) {
      reset();
      return;
    }

    const relatedObjectId = last(relationships)?.objectId;

    if (!relatedObjectId) {
      reset();
      return;
    }

    const currentObject = objectList?.objects.find((obj) => obj.id === relatedObjectId);

    const propertyId = propertyNameObject.property_id;

    if (!propertyId) {
      reset();
      return;
    }

    const propertyName = propertyNameObject.property_name;
    const propertyNamePathTokens = propertyNameObject.property_name.split('.');
    const propertyFromObject = getPropertyByPath(currentObject?.properties, propertyNamePathTokens);

    if (!propertyFromObject && propertyName !== REMOTE_ID) {
      reset();
      return;
    }

    const propertyType = propertyFromObject?.type ?? 'string';

    onChange(
      {
        property_id: propertyId,
        property_type: propertyType,
        property_name: propertyName,
      },
      relationships
    );
  };

  return (
    <Popover>
      {({ open, close }) => (
        <Float
          placement="bottom-start"
          shift={{
            mainAxis: true,
            crossAxis: true,
          }}
          offset={4}
          portal
        >
          <Popover.Button as={SelectTriggerSmall} open={open} className={buttonClassName} textClassName="overflow-hidden flex-1 text-left">
            {triggerLabel}
            <OpenTrigger shouldOpen={shouldOpen} />
          </Popover.Button>
          <Popover.Panel className="w-72">
            <FocusTrap>
              <RecordPropertyMultilevelSelectContent
                searchQuery={recordSearch}
                setSearchQuery={setRecordSearch}
                maxLevels={maxLevels}
                parentEntityType={parentEntityType}
                parentEntityId={parentEntityId}
                onChange={(...args) => {
                  handleRelatedPropertyChange(...args);
                  close();
                }}
              />
            </FocusTrap>
          </Popover.Panel>
        </Float>
      )}
    </Popover>
  );
};

const getPropertyByPath = (properties: any, pathTokens: string[]) => {
  if (isNil(properties) || pathTokens.length === 0) {
    return properties;
  }

  const propertyName = pathTokens.shift();
  const currentLevelProperty = properties.find((prop) => prop.name === propertyName);

  if (pathTokens.length === 0) {
    return currentLevelProperty;
  } else {
    return getPropertyByPath(currentLevelProperty?.properties, pathTokens);
  }
};
