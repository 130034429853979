import { useQuery } from '@tanstack/react-query';
import { getMetrics } from '../utils/getMetrics';
import { getGrouping } from '../utils/getGrouping';

import { normalizeData } from '../utils/normalizeData';
import { ReportSourceType, ReportsState } from '../store';
import { getTimeFromRange } from '../utils/getTimeFromRange';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { ChartType, ReportTypeVO } from '@bigdelta/lib-shared';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface UseReportQueryArgs {
  reportType: ReportTypeVO;
  reportQueryState: ReportsState['query'];
  chartType: ChartType;
  metricId?: string | null;
  workspaceId?: string | null;
  disabled?: boolean;
}

export const useQueryReportsQuery = ({ reportType, reportQueryState, chartType, metricId, workspaceId, disabled }: UseReportQueryArgs) => {
  const queryKeys = useQueryKeys();

  return useQuery({
    queryKey: queryKeys.withWorkspace('reports', 'create', reportQueryState, chartType),
    queryFn: async () => {
      if (!reportQueryState.metricType || (!metricId && reportQueryState.metricType !== ReportSourceType.FORMULA) || !metricId) {
        throw new Error('Invalid query');
      }

      const metrics = getMetrics(
        reportType,
        reportQueryState.metricType,
        metricId,
        { items: reportQueryState.filter ?? [], operator: 'and' },
        reportQueryState
      );
      const grouping = getGrouping(reportQueryState.metricType, reportQueryState.breakdownProperty, chartType);
      const displayOptions = chartType === ChartType.HORIZONTAL ? undefined : { time_granularity: reportQueryState.granularity };
      const time = getTimeFromRange(reportQueryState.timeKey);

      const data = await bigdeltaAPIClient.v1.queryReportsCreate(
        { workspace_id: workspaceId! },
        {
          display_options: displayOptions,
          grouping,
          metrics: metrics ?? [],
          time,
        }
      );

      if (reportQueryState.breakdownPropertyPrefix) {
        normalizeData(data, reportQueryState.breakdownProperty, reportQueryState.breakdownPropertyPrefix);
      }

      return data;
    },
    enabled: !disabled && !!workspaceId && !!reportQueryState.metricType && (!!metricId || reportQueryState.metricType === ReportSourceType.FORMULA),
    refetchOnWindowFocus: false,
    keepPreviousData: false,
  });
};
