import { ComboboxOptionProps, Combobox as ComboboxPrimitive } from '@headlessui/react';
import { ComboboxInput } from '../../../../components/Combobox/ComboboxInput.tsx';
import { FC, forwardRef, useState } from 'react';

import CursorBoxIcon from '../../../../assets/icons/cursor-box.svg?react';
import CursorIcon from '../../../../assets/icons/cursor-click-01.svg?react';
import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../../auth/hooks/useQueryKeys.ts';

import { useWorkspace } from '../../../auth/hooks/useWorkspace.tsx';
import { RecordPropertyIcon } from '../../../../shared/components/RecordPropertyIcon.tsx';

import { MetadataResourcesPropertiesNamesListData } from '@bigdelta/lib-api-client';
import { EventCountData } from '../../store/common.ts';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient.ts';
import { createMathPropertyDetails } from '../../utils/trends/createMathPropertyDetails';

enum OptionType {
  // EVENT_INITIAL = 'event_initial',
  EVENT = 'event',
  PROPERTY = 'property',
  ALL_EVENTS = 'all',
}

interface Option {
  type: OptionType;
  data: {
    [OptionType.EVENT]?: {
      event: string;
    };
    [OptionType.PROPERTY]?: {
      property: MetadataResourcesPropertiesNamesListData['items'][number];
    };
  };
}

export const ComboboxOption = forwardRef<HTMLLIElement, ComboboxOptionProps<any, any>>(({ children, ...props }, ref) => {
  return (
    <ComboboxPrimitive.Option
      className="flex cursor-default items-center gap-x-2 rounded-md px-2.5 py-3 text-sm leading-tight ui-selected:bg-m-gray-200 ui-active:bg-m-gray-200"
      ref={ref}
      {...props}
    >
      {children}
    </ComboboxPrimitive.Option>
  );
});

interface EventPropertyCountComboboxProps {
  onChange: (value: EventCountData | null) => void;
  defaultMode?: 'event' | 'property';
  showAllEvents?: boolean;
  defaultEvent?: string;
}

export const EventPropertyCountCombobox: FC<EventPropertyCountComboboxProps> = ({ onChange, defaultMode, showAllEvents = true, defaultEvent }) => {
  const { currentWorkspaceId } = useWorkspace();

  const [search, setSearch] = useState('');

  const [mode, setMode] = useState<'event' | 'property'>(defaultMode ?? 'event');
  const [value, setValue] = useState<Option | null>(null);

  const queryKeys = useQueryKeys();

  const eventsQuery = useQuery({
    queryKey: queryKeys.list('event'),
    queryFn: () =>
      bigdeltaAPIClient.v1.metadataEventsList({
        workspace_id: currentWorkspaceId,
      }),
  });

  const selectedEvent = defaultEvent ?? value?.data?.[OptionType.EVENT]?.event;

  const eventPropertiesQuery = useQuery({
    queryKey: queryKeys.list('metadata', 'properties', 'event', selectedEvent),
    queryFn: () =>
      bigdeltaAPIClient.v1.metadataResourcesPropertiesNamesList({
        workspace_id: currentWorkspaceId,
        resource_type: 'EVENT',
        resource_id: selectedEvent,
      }),
    enabled: !!selectedEvent && mode === 'property',
  });

  const handleSelectAllEvents = () => {
    onChange({
      type: 'allEvents',
      data: {},
    });
  };

  const handleSelectEvent = (event: string | null) => {
    if (!event) {
      onChange(null);
      return;
    }

    onChange({
      type: 'event',
      data: {
        event,
      },
    });
  };

  const handleSelectEventProperty = (selectedProperty: MetadataResourcesPropertiesNamesListData['items'][number] | null) => {
    if (!selectedProperty) {
      onChange(null);
      return;
    }

    const eventName = value?.data?.[OptionType.EVENT]?.event ?? '';
    const property = createMathPropertyDetails(selectedProperty);

    onChange({
      type: 'property',
      data: {
        property: {
          event: eventName,
          property: property,
        },
      },
    });
  };

  const handleChange = (val: Option) => {
    setSearch('');

    if (mode === 'event') {
      if (val.type === OptionType.ALL_EVENTS) {
        handleSelectAllEvents();
        return;
      }

      setMode('property');
      setValue(val);
    }

    if (mode === 'property') {
      if (val.type === OptionType.EVENT) {
        handleSelectEvent(val.data?.[OptionType.EVENT]?.event ?? null);
        return;
      }

      if (val.type === OptionType.PROPERTY) {
        handleSelectEventProperty(val.data?.[OptionType.PROPERTY]?.property ?? null);
        return;
      }

      setValue(value);
    }
  };

  const events = eventsQuery.data?.items.filter((event) => event.event_name.toLocaleLowerCase().includes(search.toLocaleLowerCase()));

  const eventProperties = eventPropertiesQuery.data?.items.filter((property) =>
    property.property_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );

  return (
    <ComboboxPrimitive value={value} onChange={handleChange}>
      <ComboboxInput value={search} onChange={(e) => setSearch(e.target.value)} />
      <ComboboxPrimitive.Options static className="flex flex-col overflow-hidden pt-2">
        {mode === 'event' && showAllEvents && (
          <ComboboxOption
            key="all"
            value={{
              type: OptionType.ALL_EVENTS,
              data: {},
            }}
          >
            <CursorBoxIcon className="h-4 w-4" />
            <span>All Events</span>
          </ComboboxOption>
        )}
        {mode === 'property' && !!value?.data?.[OptionType.EVENT] && (
          <ComboboxOption
            key={value?.data?.[OptionType.EVENT]?.event}
            value={{
              type: OptionType.EVENT,
              data: {
                [OptionType.EVENT]: {
                  event: value?.data?.[OptionType.EVENT]?.event,
                },
              },
            }}
          >
            <CursorBoxIcon className="h-4 w-4" />
            <span>{value?.data[OptionType.EVENT]?.event}</span>
          </ComboboxOption>
        )}
        <ComboboxPrimitive.Option disabled value="0" className="px-3 py-1 text-xxs font-medium text-m-olive-400">
          Properties
        </ComboboxPrimitive.Option>
        <div className="min-h-0 shrink overflow-auto">
          {mode === 'event' &&
            events?.map((event) => (
              <ComboboxOption
                key={event.event_name}
                value={{
                  type: OptionType.EVENT,
                  data: {
                    [OptionType.EVENT]: {
                      event: event.event_name,
                    },
                  },
                }}
              >
                <CursorIcon className="h-4 w-4" />
                <span>{event.event_name}</span>
              </ComboboxOption>
            ))}
          {mode === 'property' &&
            eventProperties?.map((property) => (
              <ComboboxOption key={property.property_id} value={{ type: OptionType.PROPERTY, data: { [OptionType.PROPERTY]: { property } } }}>
                <RecordPropertyIcon propertyType={property.property_type} className="h-4 w-4 shrink-0 text-m-olive-600" />
                <span>{property.property_name}</span>
              </ComboboxOption>
            ))}
        </div>
      </ComboboxPrimitive.Options>
    </ComboboxPrimitive>
  );
};
