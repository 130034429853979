import { MetadataResourcesPropertiesNamesListData } from '@bigdelta/lib-api-client';
import { ResourcePropertyType } from '@bigdelta/lib-shared';
import { STANDARD_STRUCT_PROPERTY_TYPES, NESTED_PROPERTIES } from '../../../../shared/properties/const';
import { v5 as uuidv5 } from 'uuid';

export const createMathPropertyDetails = (property: MetadataResourcesPropertiesNamesListData['items'][number]) => {
  if (STANDARD_STRUCT_PROPERTY_TYPES.includes(property.property_type as ResourcePropertyType)) {
    const nestedProperty = NESTED_PROPERTIES[property.property_type as ResourcePropertyType];
    const fullPropertyName = [property.property_name, nestedProperty.name].join('.');
    const propertyId = uuidv5(fullPropertyName, import.meta.env.VITE_APP_METADATA_PROPERTY_NAMESPACE);
    const propertyType = nestedProperty.type;

    return {
      property_id: propertyId,
      property_type: propertyType as ResourcePropertyType,
      property_name: fullPropertyName,
      top_level_property_type: property.property_type as ResourcePropertyType,
    };
  }

  return property;
};
