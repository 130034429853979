import { FC, useEffect, useState } from 'react';
import { getStringValue } from '../../../properties/Currency/utils';
import { DebouncedInput } from './DebouncedInput';

interface PropertyValueCurrencyFieldProps {
  onChange: (value: number | undefined) => void;
  value: number | undefined;
}

export const PropertyValueCurrencyField: FC<PropertyValueCurrencyFieldProps> = ({ value, onChange }) => {
  const [stringValue, setStringValue] = useState(() => getStringValue(value));

  useEffect(() => {
    setStringValue(getStringValue(value));
  }, [value]);

  const handleChange = (newValue: string) => {
    setStringValue(newValue);
    if (newValue === '') {
      onChange(undefined);
    } else {
      const numberValue = Math.round(parseFloat(newValue) * 100);
      if (!isNaN(numberValue)) {
        onChange(numberValue);
      }
    }
  };

  return <DebouncedInput type="number" value={stringValue} onChange={handleChange} />;
};
