import { FC } from 'react';
import { FilterItemType, FilterKey, getFilter, useFilterStore } from '../../store';
import { FilterContext } from '../../context/context';
import { MetricsFilterAdd } from './MetricsFilterAdd';
import { MetricsRecordPropertyFilterRow } from './MetricsRecordPropertyFilterRow';
import { ReportConfigSectionButton } from '../../../../features/reports/components/ReportConfigSectionButton';
import { twMerge } from 'tailwind-merge';
import { MetricsPropertyFilterRow } from './MetricsPropertyFilterRow';

interface MetricsFilter {
  filterKey: FilterKey;
  workspaceId: string;
  mode?: 'inline' | 'stacked';
  segments?: boolean;
  filterItemTypes: FilterItemType[];
  resourceId?: string;
}

export const MetricsFilter: FC<MetricsFilter> = ({ filterKey, workspaceId, mode = 'inline', filterItemTypes, resourceId }) => {
  const filter = useFilterStore((state) => getFilter(state, filterKey));

  return (
    <FilterContext.Provider value={{ filterKey, workspaceId, mode }}>
      <div className="flex items-start justify-between">
        <div className={twMerge('flex max-w-full flex-1 flex-col items-start', mode === 'inline' && 'pb-6')}>
          {!!filter?.items?.length && (
            <div className={twMerge('flex w-full flex-col ', mode === 'inline' && 'gap-y-2')}>
              {filter?.items.map((filterItem, index) => {
                const key = `${JSON.stringify(filterItem)}-${index}`;
                switch (filterItem.itemType) {
                  case FilterItemType.METRICS_RECORD_PROPERTY:
                    return <MetricsRecordPropertyFilterRow index={index} key={key} filter={filterItem} />;
                  case FilterItemType.METRICS_PROPERTY:
                    return resourceId && <MetricsPropertyFilterRow index={index} filterItem={filterItem} resourceId={resourceId} />;
                  default:
                    return null;
                }
              })}
            </div>
          )}
          {mode === 'stacked' && (
            <div className="px-2 pt-2">
              <MetricsFilterAdd as={ReportConfigSectionButton} filterItemTypes={filterItemTypes} resourceId={resourceId} />
            </div>
          )}
          {mode === 'inline' && (
            <div className="pt-2">
              <MetricsFilterAdd filterItemTypes={filterItemTypes} resourceId={resourceId} />
            </div>
          )}
        </div>
        {/*<div>{segments && filter ? <SaveSegmentButtons objectId={undefined} filter={filter} queryType="EVENTS" /> : null}</div>*/}
      </div>
    </FilterContext.Provider>
  );
};
