import { InputProps } from '../Input/Input.tsx';
import { FormElementWrapper, FormElementWrapperProps } from '../FormElementWrapper/FormElementWrapper.tsx';
import { TagsEdit } from '../../properties/Tags/TagsEdit.tsx';
import { Control, useController } from 'react-hook-form';

interface FieldValues {
  [key: string]: string[];
}

type FormTagsInputProps = FormElementWrapperProps & {
  required?: boolean;
  name: string;
  control: Control<FieldValues>;
  inputProps?: InputProps;
};

export const FormTagsInput = ({ inputProps, control, name, required, errorMessage, label, ...rest }: FormTagsInputProps) => {
  const { field } = useController<FieldValues>({
    name,
    control,
    rules: { required },
  });

  return (
    <FormElementWrapper label={label} errorMessage={errorMessage}>
      <TagsEdit
        {...rest}
        {...inputProps}
        className="rounded-lg border border-m-olive-100 px-4 py-2 text-sm focus:border-m-blue-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-m-olive-500"
        ref={field.ref}
        name={field.name}
        onBlur={field.onBlur}
        editable={!field.disabled}
        onChange={field.onChange}
        value={field.value}
      />
    </FormElementWrapper>
  );
};
