import {
  EventsQueryRequestQueryPropertyConditions,
  QueryEventsCreatePayload,
  QueryRecordsCreatePayload,
  RecordQueryFiltersRequestDef,
} from '@bigdelta/lib-api-client';
import { FilterItem, FilterItemType } from '../../../shared/filters/store';
import { DateRangeType } from '@bigdelta/lib-shared';
import { getRelatedRecordConditions } from '../../../shared/utils/getRelatedRecordConditions';
import { getPropertyCondition } from '../../../shared/filters/utils/getPropertyCondition';

const getTime = (timeframe: FilterItem['timeframe']): QueryEventsCreatePayload['time'] | undefined => {
  if (!timeframe.dateRangeType) {
    return undefined;
  }

  if (timeframe.dateRangeType === DateRangeType.OVER_ALL_TIME) {
    return {
      date_range_type: DateRangeType.OVER_ALL_TIME,
    };
  }

  if (timeframe.dateRangeType === DateRangeType.IN_THE_LAST) {
    if (timeframe.window?.unit && timeframe.window.value) {
      return {
        date_range_type: DateRangeType.IN_THE_LAST,
        window: {
          unit: timeframe.window.unit,
          value: timeframe.window.value,
        },
      };
    }
    return undefined;
  }

  if (timeframe.dateRangeType === DateRangeType.AFTER) {
    if (timeframe.startAt) {
      return {
        date_range_type: DateRangeType.AFTER,
        start_at: new Date(timeframe.startAt).toISOString(),
      };
    }
    return undefined;
  }

  if (timeframe.dateRangeType === DateRangeType.BEFORE) {
    if (timeframe.endAt) {
      return {
        date_range_type: DateRangeType.BEFORE,
        end_at: new Date(timeframe.endAt).toISOString(),
      };
    }
    return undefined;
  }

  if (timeframe.dateRangeType === DateRangeType.ABSOLUTE) {
    if (timeframe.startAt && timeframe.endAt) {
      return {
        date_range_type: DateRangeType.BEFORE,
        start_at: new Date(timeframe.startAt).toISOString(),
        end_at: new Date(timeframe.endAt).toISOString(),
      };
    }
    return undefined;
  }

  return undefined;
};

export const getConditions = (filter: { items: FilterItem[]; operator: 'and' | 'or' } | null): RecordQueryFiltersRequestDef['conditions'] => {
  if (!filter?.items?.length) {
    return [];
  }

  return filter.items.reduce<RecordQueryFiltersRequestDef['conditions']>((acc, item) => {
    if (item.itemType === FilterItemType.RECORDS_PROPERTY && item.propertyRelationships.length === 1) {
      const property = item.property;

      if (!property?.attributeType) {
        return acc;
      }

      const condition = { record_property: getPropertyCondition(item) };

      if (condition) {
        return [...acc, condition];
      }

      return acc;
    }

    if (item.itemType === FilterItemType.RECORDS_PROPERTY && item.propertyRelationships.length > 1) {
      const property = item.property;

      if (property && item.propertyOperator && item.data.value) {
        const condition = { record_property: getPropertyCondition(item) };

        if (!condition) {
          return acc;
        }

        return [...acc, ...getRelatedRecordConditions(item.propertyRelationships.slice(1), condition.record_property)];
      }

      return acc;
    }

    if (item.itemType === FilterItemType.RECORDS_EVENT) {
      const objectToEventRelationship = item.propertyRelationships[0];

      const conditions: EventsQueryRequestQueryPropertyConditions = [];
      if (item.event) {
        conditions.push({
          event_name: {
            operator: 'equals',
            value: item.event,
          },
        });
      }

      if (item.items && item.items.length > 0) {
        item.items.forEach((nestedItem) => {
          if (nestedItem.property && nestedItem.propertyOperator && nestedItem.data.value) {
            conditions.push({
              event_property: getPropertyCondition(nestedItem),
            });
          }
        });
      }

      if (item.property && item.propertyOperator && item.data.value) {
        conditions.push({
          event_property: getPropertyCondition(item),
        });
      }

      if (objectToEventRelationship && objectToEventRelationship.relationshipName && item.eventOperator && item.eventValue) {
        return [
          ...acc,
          {
            related_events: {
              relationship_name: objectToEventRelationship.relationshipName,
              operator: item.eventOperator,
              value: item.eventValue,
              filter: {
                operator: 'and' as const,
                conditions,
              },
              time: getTime(item.timeframe),
            },
          },
        ];
      }
    }

    if (item.itemType === FilterItemType.RECORDS_EVENT_NAME) {
      const objectToEventRelationship = item.propertyRelationships[0];
      if (objectToEventRelationship && item.event && item.eventOperator && objectToEventRelationship.relationshipName) {
        return [
          ...acc,
          {
            related_events: {
              relationship_name: objectToEventRelationship.relationshipName,
              operator: item.eventOperator,
              value: item.eventValue,
              filter: {
                operator: 'and' as const,
                conditions: [
                  {
                    event_name: {
                      operator: 'equals',
                      value: item.event,
                    },
                  },
                ],
              },
              time: getTime(item.timeframe),
            },
          },
        ];
      }
    }
    if (item.itemType === FilterItemType.RECORDS_EVENT_PROPERTY) {
      const objectToEventRelationship = item.propertyRelationships[0];
      if (
        objectToEventRelationship.relationshipName &&
        item.property &&
        item.propertyOperator &&
        item.data.value &&
        item.eventOperator &&
        item.eventValue
      ) {
        return [
          ...acc,
          {
            related_events: {
              relationship_name: objectToEventRelationship.relationshipName,
              operator: item.eventOperator,
              value: item.eventValue,
              filter: {
                operator: 'and' as const,
                conditions: [
                  {
                    event_property: getPropertyCondition(item),
                  },
                ],
              },
              time: getTime(item.timeframe),
            },
          },
        ];
      }
    }
    return acc;
  }, []);
};

export const getRecordsQuery = (
  objectId: string,
  stateFilter: { items: FilterItem[]; operator: 'and' | 'or' } | null
): QueryRecordsCreatePayload['query'] => {
  const conditions = getConditions(stateFilter);
  const filter = stateFilter && conditions.length ? { operator: stateFilter.operator, conditions } : undefined;
  return {
    resource: { id: objectId ?? '' },
    filter,
  };
};
