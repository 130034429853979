import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient';
import { ObjectsCreateData, ObjectsCreatePayload } from '@bigdelta/lib-api-client';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { ObjectsRoutes } from '../../../routes';
import { toastError, toastSuccess } from '../../../utils/toast';
import { useNavigate } from 'react-router-dom';

interface UseCreateObjectMutationArgs {
  objectId?: string;
}

export const useCreateObjectMutation = ({ objectId }: UseCreateObjectMutationArgs) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();

  return useMutation({
    mutationFn: ({ workspaceId, payload }: { workspaceId: string; payload: ObjectsCreatePayload }) =>
      bigdeltaAPIClient.v1.objectsCreate({ workspace_id: workspaceId }, payload),
    onSuccess: (object: ObjectsCreateData) => {
      queryClient.invalidateQueries(queryKeys.list('object'));
      objectId && queryClient.invalidateQueries(queryKeys.object(objectId));
      toastSuccess('Created', 'Object created successfully');
      navigate(ObjectsRoutes.SETTINGS_WORKSPACE_LIST + '/' + object.id);
    },
    onError: () => {
      toastError('An error occurred while creating the object');
    },
  });
};
