import React, { useState, useEffect, useRef } from 'react';
import { PropsWithClassName } from '../../../types';
import { twMerge } from '../../../utils/twMerge';
import { Key } from 'ts-key-enum';

interface TextEditProps extends PropsWithClassName {
  value: string;
  onSave: (newValue: string) => void;
  onCancel: () => void;
  editable?: boolean;
}

export const TextEdit: React.FC<TextEditProps> = ({ value, onSave, onCancel, className, editable = true }) => {
  const [editValue, setEditValue] = useState(value ?? '');
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

      const lineHeight = parseInt(getComputedStyle(textareaRef.current).lineHeight);
      const rows = Math.ceil(textareaRef.current.scrollHeight / lineHeight);
      textareaRef.current.rows = rows;
    }
  };

  useEffect(() => {
    adjustHeight();
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(editValue.length, editValue.length);
    }
  }, [editValue]);

  useEffect(() => {
    setEditValue(value);
    setTimeout(adjustHeight, 0);
  }, [value]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === Key.Enter && !e.shiftKey) {
      e.preventDefault();
      onSave(editValue);
    }
    if (e.key === Key.Escape) {
      setEditValue(value);
      onCancel();
    }
    if (e.code === 'Space') {
      e.stopPropagation();
    }
  };

  return (
    <textarea
      ref={textareaRef}
      value={editValue}
      onChange={(e) => {
        setEditValue(e.target.value);
        adjustHeight();
      }}
      onBlur={() => onSave(editValue)}
      onKeyDown={handleKeyDown}
      rows={1}
      autoFocus
      className={twMerge(
        'w-full resize-none overflow-hidden !break-normal border-none bg-transparent p-0 outline-none focus:outline-none focus:ring-0',
        className
      )}
      readOnly={!editable}
    />
  );
};
