import { CellContext, Row, flexRender } from '@tanstack/react-table';
import * as Table from '../../../components/Table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { FC } from 'react';
import { SkeletonBlock } from '../../../components/SkeletonBlock';

import ArrowUpIcon from '../../../assets/icons/arrow-up.svg?react';
import ArrowDownIcon from '../../../assets/icons/arrow-down.svg?react';
import { twMerge } from 'tailwind-merge';
import { To } from 'react-router-dom';
import React from 'react';
import { ColumnResizeHandle } from './ColumnResizeHandle';
import { MemoizedTableBody } from './MemoizedTableBody';
import { UseContainerTableProps } from '../hooks/useContainer';

const skeletonHeaderCols = [...Array(5).keys()];

const DataTableHeaderSkeleton = () => {
  return (
    <thead>
      <tr>
        {skeletonHeaderCols.map((item) => (
          <Table.HeadCell key={item}>
            <SkeletonBlock />
          </Table.HeadCell>
        ))}
      </tr>
    </thead>
  );
};

const skeletonRows = [...Array(10).keys()];

interface DataTableBodySkeletonProps {
  cols: any[];
}

const DataTableBodySkeleton: FC<DataTableBodySkeletonProps> = ({ cols }) => {
  return (
    <tbody>
      {skeletonRows.map((rowItem) => (
        <tr key={rowItem}>
          {cols.map((item, index) => (
            <Table.BodyCell key={`${index}-${item}`} className={twMerge('px-3 py-2')}>
              <SkeletonBlock />
            </Table.BodyCell>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

// TODO: replace any to a generic
interface DataTableProps extends UseContainerTableProps {
  isDataFetching: boolean;
  isHeaderFetching?: boolean;
  table: import('@tanstack/table-core').Table<any>;
  headerActions?: React.ReactNode;
  onRowClick?: (data: any) => void;
  onCellMouseOver?: (e: React.MouseEvent<HTMLTableCellElement>, context: CellContext<any, any>) => void;
  onRowMouseEnter?: (data: any) => void;
  onRowMouseLeave?: (data: any) => void;
  link?: (row: Row<any>) => To;
}

export const DataTable: FC<DataTableProps> = ({
  isDataFetching,
  isHeaderFetching = false,
  table,
  tableContainerRef,
  headerActions,
  onRowClick,
  onCellMouseOver,
  onRowMouseEnter,
  onRowMouseLeave,
  link,
}) => {
  const headerGroups = table.getHeaderGroups();
  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => tableContainerRef?.current,
    overscan: 25,
    estimateSize: () => 45,
    count: rows.length,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  const totalSize = rowVirtualizer.getTotalSize();

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  const showHeaderSkeleton = !table.getLeafHeaders().length && isHeaderFetching;

  const bodySkeletonCols = showHeaderSkeleton
    ? skeletonHeaderCols
    : table
        .getAllLeafColumns()
        .filter((_, i) => i > 0)
        .map(({ id }) => id);

  const colIds = table.getAllFlatColumns().map((col) => col.id);

  const getTableSize = () => {
    return Math.max(table.getCenterTotalSize(), tableContainerRef.current?.clientWidth ?? 0);
  };
  const selected = rows.map((row) => row.getIsSelected());

  return (
    <Table.Root style={{ width: getTableSize() }}>
      {showHeaderSkeleton && <DataTableHeaderSkeleton />}
      {!!table.getLeafHeaders().length && (
        <thead className="sticky top-0 z-40">
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <Table.HeadCell
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{ width: header.getSize() }}
                  className={twMerge(
                    'group select-none border-0 [--shadow-color:theme(colors.m-gray.300)]',
                    (header.column.columnDef.meta as any)?.headerClassName,
                    header.column.getIsPinned() && 'sticky left-0 z-20',
                    index > 0 && 'shadow-[inset_0px_-0.5px_0px_0.25px_var(--shadow-color)]',
                    index === 0 && 'shadow-[inset_-0.5px_-0.5px_0px_0.25px_var(--shadow-color)]'
                  )}
                  onClick={header.column.getToggleSortingHandler()}
                  resizeHandle={
                    header.column.getCanResize() && (
                      <ColumnResizeHandle
                        onDoubleClick={() => header.column.resetSize()}
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                      />
                    )
                  }
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  {(header.column.getCanSort() &&
                    {
                      asc: <ArrowUpIcon className="h-4 w-4" />,
                      desc: <ArrowDownIcon className="h-4 w-4" />,
                    }[header.column.getIsSorted() as string]) ??
                    null}
                </Table.HeadCell>
              ))}
            </tr>
          ))}
          {!!headerActions && (
            <tr>
              <th className="p-0 font-regular" colSpan={table.getLeafHeaders().length}>
                {headerActions}
              </th>
            </tr>
          )}
        </thead>
      )}
      {!!rows.length && !showHeaderSkeleton && (
        <MemoizedTableBody
          rows={rows}
          virtualRows={virtualRows}
          paddingTop={paddingTop}
          paddingBottom={paddingBottom}
          rowVirtualizer={rowVirtualizer}
          onRowClick={onRowClick}
          onCellMouseOver={onCellMouseOver}
          onRowMouseEnter={onRowMouseEnter}
          onRowMouseLeave={onRowMouseLeave}
          link={link}
          selected={selected}
          colIds={colIds}
        />
      )}
      {(isDataFetching || isHeaderFetching) && <DataTableBodySkeleton cols={bodySkeletonCols} />}
    </Table.Root>
  );
};
