import { CellContext, Row } from '@tanstack/react-table';
import { VirtualItem, Virtualizer } from '@tanstack/react-virtual';
import { memo } from 'react';
import { To } from 'react-router-dom';
import { BodyRow } from '../../../components/Table';
import { isEqual } from 'lodash';
import { DataTableCell } from './DataTableCell';

export const MemoizedTableBody = memo<{
  rows: Row<any>[];
  virtualRows: VirtualItem[];
  paddingTop: number;
  paddingBottom: number;
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
  onRowClick?: (data: any) => void;
  onCellMouseOver?: (e: React.MouseEvent<HTMLTableCellElement>, context: CellContext<any, any>) => void;
  onRowMouseEnter?: (data: any) => void;
  onRowMouseLeave?: (data: any) => void;
  link?: (row: Row<any>) => To;
  colIds: string[];
  selected: boolean[];
}>(
  ({ rows, virtualRows, paddingTop, paddingBottom, rowVirtualizer, onRowClick, onCellMouseOver, onRowMouseEnter, onRowMouseLeave, link }) => {
    return (
      <tbody>
        {paddingTop > 0 && (
          <tr>
            <td style={{ height: `${paddingTop}px` }} />
          </tr>
        )}
        {virtualRows.map((virtualRow) => {
          const row = rows[virtualRow.index];
          return (
            <BodyRow
              key={row.id}
              className={row.getIsSelected() ? 'selected' : null}
              onClick={() => onRowClick && onRowClick(row)}
              onMouseEnter={() => onRowMouseEnter && onRowMouseEnter(row)}
              onMouseLeave={() => onRowMouseLeave && onRowMouseLeave(row)}
            >
              {row.getVisibleCells().map((cell, idx) => (
                <DataTableCell
                  key={cell.id}
                  cell={cell}
                  index={virtualRow.index}
                  measureElement={rowVirtualizer.measureElement}
                  onCellMouseOver={onCellMouseOver}
                  link={link}
                  cellIndex={idx}
                />
              ))}
            </BodyRow>
          );
        })}
        {paddingBottom > 0 && (
          <tr>
            <td style={{ height: `${paddingBottom}px` }} />
          </tr>
        )}
      </tbody>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.rows === nextProps.rows &&
      prevProps.virtualRows === nextProps.virtualRows &&
      prevProps.paddingTop === nextProps.paddingTop &&
      prevProps.paddingBottom === nextProps.paddingBottom &&
      isEqual(prevProps.selected, nextProps.selected) &&
      isEqual(prevProps.colIds, nextProps.colIds)
    );
  }
);
