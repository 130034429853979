import { ResourcePropertyType } from '@bigdelta/lib-shared';

export const mapToUserFriendlyDataType = (resourcePropertyType: ResourcePropertyType): string => {
  switch (resourcePropertyType) {
    case ResourcePropertyType.STRING:
      return 'Text';
    case ResourcePropertyType.NUMBER:
      return 'Number';
    case ResourcePropertyType.BOOLEAN:
      return 'Boolean';
    case ResourcePropertyType.DATETIME64:
      return 'DateTime64';
    case ResourcePropertyType.STRING_ARRAY:
      return 'Text List';
    case ResourcePropertyType.NUMBER_ARRAY:
      return 'Number List';
    case ResourcePropertyType.TIMESTAMP_ARRAY:
      return 'Timestamp List';
    case ResourcePropertyType.BOOLEAN_ARRAY:
      return 'Boolean List';
    case ResourcePropertyType.OBJECT:
      return 'Struct';
    case ResourcePropertyType.CURRENCY:
      return 'Currency';
    case ResourcePropertyType.RELATION:
      return 'Relation';
    case ResourcePropertyType.RELATION_ARRAY:
      return 'Relation List';
  }
};
