import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient';
import { ObjectsPartialUpdatePayload } from '@bigdelta/lib-api-client';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { AxiosError } from 'axios';
import { toastError, toastSuccess } from '../../../utils/toast';

interface UseUpdateObjectMutationArgs {
  objectId?: string;
}

export const useUpdateObjectMutation = ({ objectId }: UseUpdateObjectMutationArgs) => {
  const { currentWorkspaceId } = useWorkspace();

  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();

  const mutator = useMutation({
    mutationFn: ({ objectId, workspaceId, payload }: { objectId: string; workspaceId: string; payload: ObjectsPartialUpdatePayload }) =>
      bigdeltaAPIClient.v1.objectsPartialUpdate(objectId, { workspace_id: workspaceId }, payload),
    onSuccess: (data) => {
      queryClient.invalidateQueries(queryKeys.list('object'));
      queryClient.invalidateQueries(queryKeys.object(objectId));
      queryClient.invalidateQueries(queryKeys.single('object', currentWorkspaceId, data.api_slug));
      queryClient.invalidateQueries(queryKeys.list('object-record', objectId));
      queryClient.removeQueries(queryKeys.list('object-record', objectId));
      toastSuccess('Updated', 'Object updated successfully');
    },
    onError: (error: AxiosError<{ message: string }>) => {
      toastError(error?.response?.data?.message || 'An error occurred while updating the object');
    },
  });

  if (!objectId) {
    return {
      mutate: () => {},
      isLoading: false,
      isSuccess: false,
      isError: false,
      error: null,
    };
  }

  return mutator;
};
