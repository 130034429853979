import { useFilterContext } from '../../context/useFilterContext';
import { useFilterStore } from '../../store';
import { PropertyValueTextField } from './PropertyValueTextField';
import { PropertyValueComboboxSingle } from './PropertyValueComboboxSingle';
import { ValueSelectType } from '../../const';
import { AttributesValue } from '../../../../features/reports/types';
import { PropertyValueNumberField } from './PropertyValueNumberField';
import { FC } from 'react';
import { PropertyValueComboboxMultiple } from './PropertyValueComboboxMultiple';
import { PropertyValueDate } from './PropertyValueDate';
import { PropertyValueDateRange } from './PropertyValueDateRange';
import { MetadataResourcePropertyType, ResourcePropertyType } from '@bigdelta/lib-shared';
import { PropertyValueCurrencyField } from './PropertyValueCurrencyField';

interface PropertyValueProps {
  // objectId?: string;
  index: number;
  resourceId?: string;
  resourceType: MetadataResourcePropertyType;
}

export const PropertyValue: FC<PropertyValueProps> = ({ index, resourceId, resourceType }) => {
  const { filterKey } = useFilterContext();
  const filterItem = useFilterStore((state) => state.getFilterItem([...filterKey, index]));

  const setItemDataValue = useFilterStore((state) => state.setItemDataValue);

  const propertyType = filterItem?.property?.attributeType;

  const handleTextFieldChange = (val: string) => {
    if (!filterKey) {
      return;
    }
    setItemDataValue(filterKey, index, val);
  };

  const handleNumberFieldChange = (val: number | undefined) => {
    if (!filterKey) {
      return;
    }

    setItemDataValue(filterKey, index, val);
  };

  const handleComboboxSingleChange = (val: AttributesValue | null) => {
    if (!filterKey) {
      return;
    }
    const { property_value: propertyValue } = val ?? { property_value: '' };
    setItemDataValue(filterKey, index, propertyValue);
  };

  const handleComboboxMultipleChange = (val: AttributesValue[]) => {
    if (!filterKey) {
      return;
    }
    const values = val.map(({ property_value }) => property_value);
    setItemDataValue(filterKey, index, values);
  };

  if (filterItem?.data.valueType === ValueSelectType.TEXT_FIELD) {
    return (
      <PropertyValueTextField assignedOperator={filterItem.propertyOperator} value={filterItem?.data.value || ''} onChange={handleTextFieldChange} />
    );
  }

  if (filterItem?.data.valueType === ValueSelectType.COMBOBOX_SINGLE) {
    return (
      <PropertyValueComboboxSingle
        resourceId={resourceId}
        propertyId={filterItem?.property?.attributeId}
        filterItem={filterItem}
        resourceType={resourceType}
        onChange={handleComboboxSingleChange}
      />
    );
  }

  if (filterItem?.data.valueType === ValueSelectType.COMBOBOX_MULTIPLE) {
    return (
      <PropertyValueComboboxMultiple
        resourceId={resourceId}
        propertyId={filterItem?.property?.attributeId}
        filterItem={filterItem}
        resourceType={resourceType}
        onChange={handleComboboxMultipleChange}
      />
    );
  }

  if (filterItem?.data.valueType === ValueSelectType.NUMBER_FIELD && propertyType === ResourcePropertyType.NUMBER) {
    return <PropertyValueNumberField value={filterItem?.data.value || undefined} onChange={handleNumberFieldChange} />;
  }

  if (filterItem?.data.valueType === ValueSelectType.NUMBER_FIELD && propertyType === ResourcePropertyType.CURRENCY) {
    return <PropertyValueCurrencyField value={filterItem?.data.value || undefined} onChange={handleNumberFieldChange} />;
  }

  if (filterItem?.data.valueType === ValueSelectType.DATE_FIELD) {
    return <PropertyValueDate index={index} />;
  }

  if (filterItem?.data.valueType === ValueSelectType.DATE_RANGE_FIELD) {
    return <PropertyValueDateRange index={index} />;
  }

  return null;
};
