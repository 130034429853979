import * as DropdownMenu from '../../../shared/ui/Menu/Menu';
import DotsIcon from '../../../assets/icons/dots-vertical.svg?react';
import XIcon from '../../../assets/icons/x.svg?react';
import { FC } from 'react';

interface DashboardPanelContextMenuProps {
  onRemove: () => void;
}

export const DashboardPanelContextMenu: FC<DashboardPanelContextMenuProps> = ({ onRemove }) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className="rounded text-m-olive-400 hover:bg-m-gray-300">
          <DotsIcon className="h-5 w-5" />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content align="start">
          <DropdownMenu.Item onSelect={onRemove}>
            <XIcon className="h-5 w-5 text-m-red-600" />
            <span>Remove from dashboard</span>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
