import Color from 'color';
import { isUndefined } from 'mathjs';
import ColorHash from 'color-hash';
import { FC } from 'react';

import { QueryReportsCreatePayload, ReportsDetailData } from '@bigdelta/lib-api-client';
import { ChartType, ReportTypeVO, TimeGranularity } from '@bigdelta/lib-shared';

import { HorizontalBarChart } from '../../reports/components/HorizontalBarChart';
import { ReportChart } from '../../reports/components/ReportChart.tsx';
import { AliasPrefix } from '../../reports/store';
import { AttributesValue } from '../../reports/types';

import { twConfig } from '../../../utils/tailwindConfig.ts';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { useQuery } from '@tanstack/react-query';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { getTimeFromRange } from '../../reports/utils/getTimeFromRange';
import { merge, omit } from 'lodash';
import { tracking, TrackingEvent } from '../../../tracking';
import { AppRoutes } from '../../../routes';
import { useNavigate } from 'react-router-dom';
import { useDashboardStore } from '../store';

import { DashboardPanelContextMenu } from './DashboardPanelContextMenu';

// TODO: Implement when breakdown/grouping is reintroduced
const aliasPrefix = AliasPrefix.RECORD_ATTRIBUTE;

const colorHash = new ColorHash();

const getGrouping = (groupingConfig: ReportsDetailData['grouping_config']) => {
  const selectedGroups = groupingConfig?.selected_groups;
  const groupProperty = groupingConfig?.groups?.[0];

  if (groupProperty?.attribute_id) {
    const breakdownVals = selectedGroups?.reduce((acc, selectedGroup) => {
      const property = selectedGroup.group_attributes?.[0];

      if (!property?.attribute_id) {
        return acc;
      }

      const breakdownProp = {
        property_name: property.attribute_name,
        property_id: property.attribute_id,
        property_value: property.attribute_value,
      };

      return [...acc, breakdownProp];
    }, [] as AttributesValue[]);

    if (!breakdownVals) {
      return {};
    }

    return {
      breakdownProperty: {
        property_name: groupProperty.attribute_name,
        property_id: groupProperty.attribute_id,
      },
      breakdownSelectedValues: breakdownVals,
      breakdownSelectedValuesColorMap: breakdownVals.reduce(
        (acc, val) => {
          acc[val.property_value] = !isUndefined(val.property_value) ? colorHash.hex(val.property_value) : '#000000';
          return acc;
        },
        {} as Record<string, string>
      ),
    };
  }

  return {};
};

interface DashboardPanelReportProps {
  reportId: string;
  onRemovePanel: () => void;
}

// TODO: Extract composable DashboardPanel Base components (container, title, etc.)
export const DashboardPanelReport: FC<DashboardPanelReportProps> = ({ reportId, onRemovePanel }) => {
  const queryKeys = useQueryKeys();
  const { currentWorkspaceId } = useWorkspace();
  const navigate = useNavigate();

  const { timerange, granularity, search } = useDashboardStore();

  const reportConfigQuery = useQuery({
    queryKey: queryKeys.report(reportId),
    queryFn: () => bigdeltaAPIClient.v1.reportsDetail(reportId, { workspace_id: currentWorkspaceId }),
  });

  const reportQuery = useQuery({
    queryKey: queryKeys.queryReport(reportConfigQuery.data?.id ?? '', timerange, granularity),
    queryFn: () => {
      const reportConfigDataQuery = reportConfigQuery.data?.query;

      if (!reportConfigDataQuery) {
        return;
      }

      const time = getTimeFromRange(timerange);

      let query: QueryReportsCreatePayload = merge(reportConfigDataQuery, {
        display_options: {
          time_granularity: granularity,
        },
        time,
      });

      const isHorizontalPeriodChart = reportConfigDataQuery.display_options?.chart_type === ChartType.HORIZONTAL;

      if (isHorizontalPeriodChart) {
        query = merge(query, {
          grouping: {
            filter: [{ data_created: { operator: 'in period' } }],
          },
        });

        query = omit(query, ['display_options']);
      }

      return bigdeltaAPIClient.v1.queryReportsCreate({ workspace_id: currentWorkspaceId }, query);
    },
    enabled: !!reportConfigQuery.data,
  });

  const { breakdownProperty, breakdownSelectedValues, breakdownSelectedValuesColorMap } = getGrouping(reportConfigQuery.data?.grouping_config);
  const positiveColor = twConfig.theme.colors['m-blue']['600'];
  const negativeColor = twConfig.theme.colors['m-red']['600'];
  const average = reportQuery.data?.queries[0]?.metadata?.aggregation?.result?.[0]?.average;

  const transparentColor = Color(average >= 0 ? positiveColor : negativeColor)
    .alpha(0.2)
    .string();

  const handleViewReport = () => {
    tracking.track(TrackingEvent.DashboardReportClicked, { 'report id': reportConfigQuery.data?.id, 'report name': reportConfigQuery.data?.title });
    navigate(`${AppRoutes.REPORTS}/${reportId}`);
  };

  const title = reportConfigQuery.data?.title;
  const chartType = reportConfigQuery.data?.query?.display_options?.chart_type ?? ChartType.LINE;

  if (!title?.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-5 rounded-lg border border-m-gray-300 p-5">
      <div className="flex items-center justify-between gap-x-2">
        <h3 className="cursor-pointer text-sm font-medium text-m-olive-900" onClick={handleViewReport}>
          {reportConfigQuery.data?.title}
        </h3>
        <DashboardPanelContextMenu onRemove={onRemovePanel} />
      </div>

      <div className="flex">
        {[ChartType.LINE, ChartType.STACKED].includes(chartType as ChartType) && (
          <ReportChart
            reportType={reportConfigQuery.data?.type as ReportTypeVO}
            chartType={chartType as ChartType}
            dataQueries={reportQuery.data?.queries}
            fillColor={chartType === ChartType.LINE ? transparentColor : undefined}
            granularity={reportConfigQuery.data?.query?.display_options?.time_granularity as TimeGranularity}
            breakdownProperty={breakdownProperty}
            breakdownPropertyPrefix={AliasPrefix.RECORD_ATTRIBUTE}
            breakdownSelectedValues={breakdownSelectedValues}
            breakdownSelectedValuesColorMap={breakdownSelectedValuesColorMap}
            lineOptions={{
              scales: {
                y: {
                  position: 'right',
                  ticks: {
                    maxTicksLimit: 3,
                  },
                },
                x: {
                  grid: {
                    display: true,
                  },
                  border: {
                    dash: [8],
                  },
                },
              },
              elements: {
                line: {
                  fill: true,
                },
              },
            }}
            stackedBarOptions={{
              scales: {
                y: {
                  position: 'right',
                  ticks: {
                    maxTicksLimit: 3,
                  },
                },
                x: {
                  display: false,
                },
              },
            }}
          />
        )}
        {chartType === ChartType.HORIZONTAL && (
          <HorizontalBarChart
            dataQuery={reportQuery.data?.queries?.[0]}
            breakdownProperty={breakdownProperty}
            breakdownPropertyPrefix={aliasPrefix}
            breakdownSelectedValues={breakdownSelectedValues}
            breakdownSelectedValuesColorMap={breakdownSelectedValuesColorMap}
          />
        )}
      </div>
    </div>
  );
};
