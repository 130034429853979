import { useCallback, useMemo, useState } from 'react';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { RelationshipEntityType, ResourcePropertyType, WorkspaceObjectStatus, WorkspaceObjectType } from '@bigdelta/lib-shared';
import { capitalize, compact } from 'lodash';
import * as Tabs from '../../../shared/ui/Tabs/Tabs';

import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { PageHeading } from '../../../components/PageHeading.tsx';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import SearchIcon from '../../../assets/icons/search-md.svg?react';

import { ObjectFormSchema } from '../components/ObjectFormSchema.ts';
import { toastError, toastSuccess } from '../../../utils/toast.tsx';
import { ObjectsRoutes } from '../../../routes';
import { PropertyFormSchema } from '../components/properties/PropertyFormSchema.ts';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';
import { REMOTE_ID } from '../../records/const.ts';
import { useObjectNameById } from '../hooks/useObjectNameById';
import { PropertiesTab } from '../components/properties/PropertiesTab';
import { ObjectProperty } from '../types';
import { ConfigurationTab } from '../components/ConfigurationTab';
import { useCreateObjectMutation } from '../hooks/useCreateObjectMutation';
import { useUpdateObjectMutation } from '../hooks/useUpdateObjectMutation';
import { useAddRelationshipMutation } from '../hooks/useAddRelationshipMutation';

const removeSpecialChars = (value: string): string => {
  return value.replace(/[^A-Za-z0-9]/g, '');
};

const useDeactivateObjectMutation = () => {
  const queryClient = useQueryClient();
  const { currentWorkspaceId } = useWorkspace();
  const navigate = useNavigate();
  const queryKeys = useQueryKeys();
  return useMutation({
    mutationFn: (objectId: string) =>
      bigdeltaAPIClient.v1.objectsStatusPartialUpdate(objectId, { workspace_id: currentWorkspaceId }, { status: WorkspaceObjectStatus.INACTIVE }),
    onSuccess: () => {
      toastSuccess('Object deactivated', 'The object has been deactivated successfully');
      queryClient.invalidateQueries(queryKeys.list('object'));
      navigate(ObjectsRoutes.SETTINGS_WORKSPACE_LIST);
    },
    onError: () => {
      toastError('Failed to deactivate object');
    },
  });
};

export const ObjectPage = () => {
  const queryKeys = useQueryKeys();
  const { currentWorkspaceId } = useWorkspace();
  const params = useParams();
  const objectId = params['objectId'] as string;

  const [searchTerm, setSearchTerm] = useState('');
  const [isAddPropertyDialogOpen, setIsAddPropertyDialogOpen] = useState(false);

  const objectNameById = useObjectNameById();

  const objectQuery = useQuery({
    queryKey: queryKeys.object(objectId),
    queryFn: () => bigdeltaAPIClient.v1.objectsDetail(objectId, { workspace_id: currentWorkspaceId }),
    enabled: !!objectId,
  });

  const defaultValues: ObjectFormSchema | undefined = useMemo(() => {
    if (!objectQuery.isSuccess) {
      return undefined;
    }

    return {
      singular_noun: objectQuery.data.singular_noun,
      plural_noun: objectQuery.data.plural_noun,
      api_slug: objectQuery.data.api_slug,
      label_property_0: objectQuery.data.label_properties.length > 0 ? objectQuery.data.label_properties[0] : undefined,
      label_property_1: objectQuery.data.label_properties.length > 1 ? objectQuery.data.label_properties[1] : undefined,
    };
  }, [objectQuery.isSuccess, objectQuery.data]);

  const createObjectMutation = useCreateObjectMutation({ objectId });

  const updateObjectMutation = useUpdateObjectMutation({ objectId });

  const addRelationshipMutation = useAddRelationshipMutation({ onSuccess: () => setIsAddPropertyDialogOpen(false) });
  const deactivateObjectMutation = useDeactivateObjectMutation();

  const onCreateObject = useCallback(
    (input: ObjectFormSchema) => {
      createObjectMutation.mutate({
        workspaceId: currentWorkspaceId,
        payload: {
          singular_noun: input.singular_noun as string,
          plural_noun: input.plural_noun as string,
          api_slug: input.api_slug as string,
          label_properties: compact([input.label_property_0, input.label_property_1]),
          records_changelog_enabled: true,
        },
      });
    },
    [currentWorkspaceId, createObjectMutation]
  );

  const onUpdateObject = useCallback(
    (input: ObjectFormSchema) => {
      const payload =
        objectQuery.data?.object_type === WorkspaceObjectType.CUSTOM
          ? {
              singular_noun: input.singular_noun,
              plural_noun: input.plural_noun,
              label_properties: compact([input.label_property_0, input.label_property_1]),
            }
          : {
              label_properties: compact([input.label_property_0, input.label_property_1]),
            };

      updateObjectMutation.mutate({
        objectId: objectId,
        workspaceId: currentWorkspaceId,
        payload,
      });
    },
    [currentWorkspaceId, objectId, objectQuery.data?.object_type, updateObjectMutation]
  );

  const onAddProperty = useCallback(
    (input: PropertyFormSchema) => {
      const mergedProperties = [...(objectQuery.data?.properties || []), input];
      updateObjectMutation.mutate(
        {
          objectId: objectId,
          workspaceId: currentWorkspaceId,
          payload: {
            properties: mergedProperties,
          },
        },
        {
          onSuccess: () => {
            if (input.type === ResourcePropertyType.RELATION || input.type === ResourcePropertyType.RELATION_ARRAY) {
              const currentObjectName = objectNameById.get(objectId);
              const otherObjectName = objectNameById.get(input.entity_id);

              addRelationshipMutation.mutate({
                name:
                  `${removeSpecialChars(currentObjectName)}_${removeSpecialChars(input.entity_property)}_` +
                  `${removeSpecialChars(otherObjectName)}_${removeSpecialChars(input.entity_property)}`,
                display_name: `${capitalize(currentObjectName)}(${input.name}) & ${capitalize(otherObjectName)}(${input.entity_property})`,
                first_entity_id: objectId,
                first_entity_type: RelationshipEntityType.OBJECT,
                first_entity_property: input.name,
                second_entity_id: input.entity_id,
                second_entity_type: RelationshipEntityType.OBJECT,
                second_entity_property: input.entity_property,
              });
            } else {
              setIsAddPropertyDialogOpen(false);
            }
          },
        }
      );
    },
    [currentWorkspaceId, objectId, objectQuery.data?.properties, updateObjectMutation, addRelationshipMutation, objectNameById]
  );

  const onEditProperty = useCallback(
    (input: PropertyFormSchema) => {
      const property = objectQuery.data?.properties?.find((p) => p.name === input.name);

      if (!property) {
        return;
      }

      const mergedProperties = objectQuery.data?.properties?.map((p) => (p.name === input.name ? input : p));

      updateObjectMutation.mutate({
        objectId: objectId,
        workspaceId: currentWorkspaceId,
        payload: {
          properties: mergedProperties,
        },
      });
    },
    [currentWorkspaceId, objectId, objectQuery.data?.properties, updateObjectMutation]
  );

  const allObjectProperties = useMemo<ObjectProperty[]>(() => {
    return (
      objectQuery.data?.properties?.map<ObjectProperty>(({ name, type, show_in_record_creation_flow, read_only }) => ({
        name,
        type: type as ResourcePropertyType,
        show_in_record_creation_flow: show_in_record_creation_flow || false,
        read_only: read_only || false,
        unique: name === REMOTE_ID,
      })) || []
    );
  }, [objectQuery.data?.properties]);

  const filteredObjectProperties = useMemo<ObjectProperty[]>(() => {
    return allObjectProperties.filter((property) => {
      const searchablePropertyName = property.name === REMOTE_ID ? 'id' : property.name;
      return searchablePropertyName.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [allObjectProperties, searchTerm]);

  const [searchParams, setSearchParams] = useSearchParams();

  const DEFAULT_TAB = 'configuration';
  const PARAMS_TAB_KEY = 'tab';

  const activeTab = searchParams.get(PARAMS_TAB_KEY) ?? DEFAULT_TAB;

  const setTab = (tab: string) => {
    setSearchParams({ tab });
  };

  if (objectId && objectQuery.data?.status === WorkspaceObjectStatus.INACTIVE) {
    return <Navigate to={ObjectsRoutes.SETTINGS_WORKSPACE_LIST} />;
  }

  return (
    <div className="flex h-screen w-full flex-col">
      <div className="flex items-center justify-between" style={{ zIndex: 1 }}>
        <div>
          <PageHeading
            breadcrumbs={[
              {
                label: 'Objects',
                to: ObjectsRoutes.SETTINGS_WORKSPACE_LIST,
                description: 'Manage objects and their properties',
              },
              { label: objectId ? capitalize(objectQuery.data?.plural_noun) || '' : 'New Object' },
            ]}
          />
        </div>
        <div className="flex items-center gap-x-2">
          {activeTab === 'properties' && objectId && (
            <>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                  <SearchIcon className="h-5 w-5 text-m-gray-600" />
                </div>
                <input
                  type="text"
                  id="simple-search"
                  className="block w-full rounded-md border border-m-gray-300 p-2 ps-10 text-md"
                  placeholder="Search property"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <Button intent="brand" size="lg" label="Add property" onClick={() => setIsAddPropertyDialogOpen(true)} />
            </>
          )}
        </div>
      </div>
      <Tabs.Root value={activeTab} onValueChange={setTab} className="flex grow flex-col">
        <Tabs.List>
          <Tabs.Trigger value="configuration" className="grow-0 px-6">
            Configuration
          </Tabs.Trigger>
          <Tabs.Trigger value="properties" className="grow-0 px-6" disabled={!objectId}>
            Properties
          </Tabs.Trigger>
        </Tabs.List>
        <div className="w-full border-t border-m-gray-300"></div>
        <div className="flex justify-center overflow-scroll">
          <Tabs.Content value="configuration" className="flex-grow flex-col p-4 radix-state-active:flex">
            <ConfigurationTab
              objectId={objectId}
              objectQuery={objectQuery}
              defaultValues={defaultValues}
              onUpdateObject={onUpdateObject}
              onCreateObject={onCreateObject}
              onDeactivateObject={deactivateObjectMutation.mutate}
            />
          </Tabs.Content>
          <Tabs.Content value="properties" className="flex-grow flex-col p-4 radix-state-active:flex">
            {objectId && (
              <PropertiesTab
                objectId={objectId}
                filteredObjectProperties={filteredObjectProperties}
                onEditProperty={onEditProperty}
                onAddProperty={onAddProperty}
                isAddPropertyDialogOpen={isAddPropertyDialogOpen}
                setIsAddPropertyDialogOpen={setIsAddPropertyDialogOpen}
              />
            )}
          </Tabs.Content>
        </div>
      </Tabs.Root>
    </div>
  );
};
