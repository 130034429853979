import { InfiniteData, QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient';
import { toastError } from '../../../utils/toast';
import { ObjectsRecordsCreateData } from '@bigdelta/lib-api-client';

interface OptimisticMutationParameters {
  objectSlug: string;
  workspaceId: string;
  key: QueryKey;
  onSettled?: () => void;
}

type Payload = string[];

type InfiniteObjectRecords = InfiniteData<{ items: ObjectsRecordsCreateData[] }>;

const updateValue =
  (payload: Payload) =>
  (old: InfiniteObjectRecords | undefined): InfiniteObjectRecords | undefined => {
    if (!old) return old;

    return {
      ...old,
      pages: old.pages.map((page) => ({
        ...page,
        items: page.items.filter((item) => !payload.includes(item.id)),
      })),
    };
  };

export const useDeleteObjectRecords = ({ objectSlug, workspaceId, key, onSettled }: OptimisticMutationParameters) => {
  const queryClient = useQueryClient();

  const recordRemoveMutation = useMutation({
    mutationFn: (payload: Payload) =>
      bigdeltaAPIClient.v1.objectsRecordsArchiveCreate(objectSlug, { workspace_id: workspaceId }, { record_ids: payload }),
    onMutate: async (payload: Payload) => {
      const previousRecord = queryClient.getQueryData<InfiniteObjectRecords>(key);

      queryClient.setQueryData(key, updateValue(payload));

      return { previousRecord };
    },

    onError: (_err, _payload, context) => {
      if (context?.previousRecord) {
        queryClient.setQueryData(key, context.previousRecord);
      }
      toastError('Failed to remove record(s)');
    },

    onSettled: (_updated) => {
      onSettled?.();
    },
  });

  const handleUpdateProperty = (payload: Payload) => {
    const previousRecords = queryClient.getQueryData(key);

    if (!previousRecords || payload.length === 0) {
      return;
    }

    recordRemoveMutation.mutate(payload);
  };

  return handleUpdateProperty;
};
