import { useState } from 'react';
import { FormInput } from '../../../../shared/ui/FormInput/FormInput.tsx';
import { useForm } from 'react-hook-form';
import { ImageDisplay } from '../../../../shared/components/ImageDisplay.tsx';
import { Button } from '../../../../shared/ui/Button/Button.tsx';
import { UploadImageButton } from '../../../../shared/components/UploadImageButton.tsx';
import { useUser } from '../../../auth/hooks/useUser.ts';
import { toastError, toastSuccess } from '../../../../utils/toast.tsx';
import QuestionIcon from '../../../../assets/icons/question.svg?react';
import { Timezones } from '@bigdelta/lib-shared';
import { useUpdateProfileMutation } from '../../../records/data/useUpdateProfileMutation.ts';
import { captureException } from '@sentry/react';

export type ProfileFormSchema = {
  first_name: string;
  last_name: string;
  timezone: string;
  new_photo?: string;
};

export const ProfileSettingsPage = () => {
  const { user } = useUser();
  const mutation = useUpdateProfileMutation();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<ProfileFormSchema>({
    defaultValues: {
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      timezone: user.preferred_timezone || 'UTC',
      new_photo: null,
    },
  });

  const onSubmit = async (data: ProfileFormSchema) => {
    setLoading(true);

    try {
      await mutation.mutateAsync({
        memberId: user.id,
        data: {
          first_name: data.first_name,
          last_name: data.last_name,
          preferred_timezone: data.timezone,
          photo: data.new_photo,
        },
      });

      toastSuccess('Success', 'Profile updated');
      reset({}, { keepValues: true });
    } catch (e) {
      captureException(e);

      toastError('An error occurred while updating your profile');
    } finally {
      setLoading(false);
    }
  };

  const { new_photo, timezone } = watch();

  return (
    <div className="flex h-screen w-full flex-col items-center">
      <div className="flex w-full max-w-screen-md flex-col gap-8">
        <div className="flex flex-col">
          <div className="text-2xl font-medium text-m-olive-900">Profile</div>
          <div className="flex items-center gap-1 text-md text-m-olive-500">
            <QuestionIcon className="h-4 w-4" />
            Changes to your profile will apply to all of your workspaces
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <div className="text-sm font-medium">Profile photo</div>
              <div className="flex items-center gap-4">
                <ImageDisplay imageSrc={new_photo || user.photo_image_url} circle />
                <div>
                  <UploadImageButton onChange={(imageSrc) => setValue('new_photo', imageSrc, { shouldDirty: true })} circle />
                </div>
              </div>
            </div>
            <div className="flex w-full gap-4">
              <FormInput
                className="w-full"
                label="First name"
                inputProps={{
                  ...register('first_name'),
                  placeholder: 'First name',
                }}
                errorMessage={errors.first_name?.message}
              />
              <FormInput
                className="w-full"
                label="Last name"
                inputProps={{
                  ...register('last_name'),
                  placeholder: 'Last name',
                }}
                errorMessage={errors.last_name?.message}
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-sm font-medium">Preferred timezone</div>
              <select
                value={timezone}
                className="rounded-lg border border-m-olive-100 text-sm"
                onChange={(evt) => setValue('timezone', evt.target.value, { shouldDirty: true })}
              >
                {Timezones.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <Button label="Save changes" intent="brand" disabled={!isDirty || !isValid || loading} loading={loading} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
