export const getStringValue = (valueInCents: number | null | undefined): string => {
  if (valueInCents === undefined || valueInCents === null) {
    return '';
  }

  const formatter = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
  });

  return formatter.format(valueInCents / 100);
};
