import { ChangeEvent, FC, useMemo, useState } from 'react';
import { MetadataResourcesPropertiesValuesListData } from '@bigdelta/lib-api-client';
import { Popover } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import { ComboboxMultipleInfinite } from '../../../../components/Combobox';
import { FilterItem } from '../../store';
import { ValueSelectType } from '../../const';
import { useDebounce } from '@uidotdev/usehooks';
import { useMetadataPropertiesValuesInfiniteQuery } from '../../../data/useMetadataAttributeValuesInfiniteQuery';
import { ComboboxMultipleLabel } from './ComboboxMultipleLabel';
import { last } from 'lodash';
import { useWorkspace } from '../../../../features/auth/hooks/useWorkspace';
import { MetadataResourcePropertyType } from '@bigdelta/lib-shared';
import { FilterTrigger } from '../../../../components/FilterTrigger.tsx';

// TODO: number and boolean types clashing
type AttributeValueObject = MetadataResourcesPropertiesValuesListData['items'][number];

interface PropertyValueComboboxMultipleProps {
  filterItem: FilterItem;
  onChange: (value: AttributeValueObject[]) => void;
  resourceId?: string;
  propertyId?: string;
  resourceType: MetadataResourcePropertyType;
}

export const PropertyValueComboboxMultiple: FC<PropertyValueComboboxMultipleProps> = ({
  filterItem,
  onChange,
  resourceId,
  propertyId,
  resourceType,
}) => {
  const { currentWorkspaceId } = useWorkspace();

  const [rawSearchQuery, setRawSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(rawSearchQuery, 700);

  const selectedItems = useMemo(() => {
    if (filterItem.data.valueType === ValueSelectType.COMBOBOX_MULTIPLE && Array.isArray(filterItem.data.value) && !!filterItem.data.value?.length) {
      return filterItem.data.value.map((property_value) => ({ property_value }));
    } else {
      return [];
    }
  }, [filterItem.data.value, filterItem.data.valueType]);

  const currentEntityId = last(filterItem.propertyRelationships)?.objectId ?? resourceId;

  const {
    data: metadataValues,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isFetching,
    isSuccess,
  } = useMetadataPropertiesValuesInfiniteQuery({
    resourceId: currentEntityId,
    propertyId,
    workspaceId: currentWorkspaceId,
    searchQuery: debouncedSearchQuery,
    resourceType,
  });

  const flatMetadataValues = useMemo(() => (metadataValues ? metadataValues.pages.flatMap((d) => d.items) : []), [metadataValues]);

  const value = filterItem.data.value || [];

  if (!Array.isArray(value)) {
    return null;
  }

  return (
    <Popover>
      {({ open }) => (
        <Float placement="bottom-start" offset={4} portal>
          <Popover.Button as={FilterTrigger} className="group-hover:bg-m-gray-100" open={open}>
            <ComboboxMultipleLabel value={value} />
          </Popover.Button>
          <Popover.Panel>
            <ComboboxMultipleInfinite
              items={flatMetadataValues}
              selectedItems={selectedItems as any}
              onChange={onChange}
              onQueryChange={(e: ChangeEvent<HTMLInputElement>) => setRawSearchQuery(e.target.value)}
              query={rawSearchQuery}
              height={380}
              catchInputFocus
              renderOption={(item) => {
                return item?.property_value;
              }}
              by={(a, b) => a?.property_value === b?.property_value}
              isFetching={isFetching}
              hasNextPage={!!hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              fetchNextPage={fetchNextPage}
              isSuccess={isSuccess}
            />
          </Popover.Panel>
        </Float>
      )}
    </Popover>
  );
};
