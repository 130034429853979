"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventsDataQueryRequestDef = exports.EventsDataQueryVODef = exports.EventsDataQueryFilterRecursiveVODef = exports.EventsQueryRequestQueryDef = exports.EventsQueryRequestQueryWithoutPartitionDef = exports.EventsQueryRequestQueryPropertyConditions = exports.EventsDataQueryFieldSelectVODef = exports.EventQueryFilterConditionRequestDef = exports.EventsDataQueryFilterVODef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const queryFiltersVO_1 = require("./queryFiltersVO");
const model_1 = require("../model");
const queryTypes_1 = require("./queryTypes");
const dataQueryPropertiesVO_1 = require("./dataQueryPropertiesVO");
const createRelationshipVO_1 = require("./createRelationshipVO");
const types_1 = require("../types");
const queryPropagatePropertyVO_1 = require("./queryPropagatePropertyVO");
const FilterValueDef = typebox_1.Type.Union([
    typebox_1.Type.Null(),
    typebox_1.Type.String(),
    typebox_1.Type.Number(),
    typebox_1.Type.Boolean(),
    typebox_1.Type.Array(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Number(), typebox_1.Type.Boolean()])),
]);
const EventPropertyFilterVODef = typebox_1.Type.Object({
    name: typebox_1.Type.String(),
    operator: types_1.QueryValueFilterOperatorType,
    value: typebox_1.Type.Optional(FilterValueDef),
    property_id: typebox_1.Type.Optional(typebox_1.Type.String()),
    property_type: typebox_1.Type.Optional(types_1.ResourcePropertyTypeType),
    top_level_property_type: typebox_1.Type.Optional(types_1.ResourcePropertyTypeType),
});
const EventFieldFilterVODef = typebox_1.Type.Object({
    name: typebox_1.Type.String(),
    dataType: typebox_1.Type.Enum(model_1.ClickHouseDataType),
    operator: types_1.QueryValueFilterOperatorType,
    value: FilterValueDef,
});
const EventNameFilterVODef = typebox_1.Type.Object({
    operator: types_1.QueryValueFilterOperatorType,
    value: typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Array(typebox_1.Type.String())]),
});
const EventRelatedRecordsFilterVODef = typebox_1.Type.Object({
    relationship_name: createRelationshipVO_1.RelationshipNameLowerCaseDef,
    filter: typebox_1.Type.Optional(typebox_1.Type.Unsafe(typebox_1.Type.Ref('RecordQueryFiltersRequestDef'))),
    primary: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
    select_all_fields: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
    relation_alias: typebox_1.Type.Optional(typebox_1.Type.String()),
    optional: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    propagate_properties: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(queryPropagatePropertyVO_1.QueryPropagatePropertyVODef)),
    relationship_properties: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(typebox_1.Type.Ref(dataQueryPropertiesVO_1.DataQueryRelationshipPropertiesVODef))),
    first_event_per_record: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
    last_event_per_record: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
});
const EventRelatedRecordsRequestFilterDef = typebox_1.Type.Object({
    relationship_name: createRelationshipVO_1.RelationshipNameLowerCaseDef,
    primary: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
    filter: typebox_1.Type.Optional(typebox_1.Type.Unsafe(typebox_1.Type.Ref('RecordQueryFiltersRequestDef'))),
    first_event_per_record: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
    last_event_per_record: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
});
const PartitionByDef = typebox_1.Type.Object({
    partition_property: typebox_1.Type.Object({
        name: typebox_1.Type.String(),
    }),
});
const EventPartitionFilterVODef = typebox_1.Type.Object({
    partition_condition: typebox_1.Type.Literal('latest'),
    partition_by: typebox_1.Type.Array(PartitionByDef),
    filter: typebox_1.Type.Optional(typebox_1.Type.Ref('EventsDataQueryFilterRecursiveVODef')),
});
exports.EventsDataQueryFilterVODef = typebox_1.Type.Object({
    [queryTypes_1.EventsDataQueryFilterType.EVENT_PROPERTY]: typebox_1.Type.Optional(EventPropertyFilterVODef),
    [queryTypes_1.EventsDataQueryFilterType.EVENT_FIELD]: typebox_1.Type.Optional(EventFieldFilterVODef),
    [queryTypes_1.EventsDataQueryFilterType.EVENT_NAME]: typebox_1.Type.Optional(EventNameFilterVODef),
    [queryTypes_1.EventsDataQueryFilterType.RELATED_RECORDS]: typebox_1.Type.Optional(EventRelatedRecordsFilterVODef),
}, { additionalProperties: false });
exports.EventQueryFilterConditionRequestDef = typebox_1.Type.Object({
    [queryTypes_1.EventsDataQueryFilterType.EVENT_PROPERTY]: typebox_1.Type.Optional(EventPropertyFilterVODef),
    [queryTypes_1.EventsDataQueryFilterType.EVENT_NAME]: typebox_1.Type.Optional(EventNameFilterVODef),
    [queryTypes_1.EventsDataQueryFilterType.RELATED_RECORDS]: typebox_1.Type.Optional(EventRelatedRecordsRequestFilterDef),
});
exports.EventsDataQueryFieldSelectVODef = typebox_1.Type.Object({
    field: typebox_1.Type.Optional(typebox_1.Type.String()),
    expression: typebox_1.Type.Optional(typebox_1.Type.String()),
    alias: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
});
exports.EventsQueryRequestQueryPropertyConditions = typebox_1.Type.Array(typebox_1.Type.Union([
    (0, queryFiltersVO_1.QueryFiltersVODef)((0, typebox_2.OptionalNullable)(exports.EventQueryFilterConditionRequestDef)),
    exports.EventQueryFilterConditionRequestDef,
]), {
    $id: 'EventsQueryRequestQueryPropertyConditions',
});
const EventsQueryRequestQueryProperties = {
    operator: queryFiltersVO_1.NestedQueryFilterOperatorType,
    conditions: typebox_1.Type.Ref(exports.EventsQueryRequestQueryPropertyConditions),
};
exports.EventsQueryRequestQueryWithoutPartitionDef = typebox_1.Type.Object(Object.assign({}, EventsQueryRequestQueryProperties));
const EventPartitionFilterRequestDef = typebox_1.Type.Object({
    partition_condition: typebox_1.Type.Literal('latest'),
    partition_by: typebox_1.Type.Array(PartitionByDef),
    filter: (0, typebox_2.OptionalNullable)(exports.EventsQueryRequestQueryWithoutPartitionDef),
});
exports.EventsQueryRequestQueryDef = typebox_1.Type.Object(Object.assign(Object.assign({}, EventsQueryRequestQueryProperties), { partition: (0, typebox_2.OptionalNullable)(EventPartitionFilterRequestDef) }), {
    $id: 'EventsQueryRequestQueryDef',
});
const CommonEventsDataQueryProperties = (queryDef) => ({
    properties: typebox_1.Type.Optional(dataQueryPropertiesVO_1.DataQueryPropertiesVODef),
    relationships: typebox_1.Type.Optional(dataQueryPropertiesVO_1.DataQueryRelationshipsVODef),
    sort: (0, typebox_2.OptionalNullable)((0, typebox_2.OptionalNullableRefArray)(typebox_1.Type.Ref(types_1.DataQuerySortElementDef))),
    query: typebox_1.Type.Optional(queryDef),
    time: queryTypes_1.TimeRangeWithShiftVODef,
    limit: typebox_1.Type.Number({ minimum: 1, maximum: 100, default: 50 }),
    offset: typebox_1.Type.Number({ minimum: 0, default: 0 }),
});
exports.EventsDataQueryFilterRecursiveVODef = typebox_1.Type.Object({
    operator: queryFiltersVO_1.NestedQueryFilterOperatorType,
    partition: (0, typebox_2.OptionalNullable)(EventPartitionFilterVODef),
    conditions: typebox_1.Type.Array(typebox_1.Type.Union([(0, queryFiltersVO_1.QueryFiltersVODef)((0, typebox_2.OptionalNullable)(exports.EventsDataQueryFilterVODef)), exports.EventsDataQueryFilterVODef])),
}, {
    $id: 'EventsDataQueryFilterRecursiveVODef',
});
exports.EventsDataQueryVODef = typebox_1.Type.Object(Object.assign({}, CommonEventsDataQueryProperties(typebox_1.Type.Object({
    filter: exports.EventsDataQueryFilterRecursiveVODef,
    fields: typebox_1.Type.Optional(typebox_1.Type.Array(exports.EventsDataQueryFieldSelectVODef)),
}))));
exports.EventsDataQueryRequestDef = typebox_1.Type.Object(Object.assign({}, CommonEventsDataQueryProperties(typebox_1.Type.Object({
    filter: typebox_1.Type.Ref(exports.EventsQueryRequestQueryDef),
}))), { $id: 'EventsDataQueryRequestDef' });
