import { FC, HTMLAttributes, ReactNode } from 'react';
import { AliasPrefix } from '../store';
import { ReportChart } from './ReportChart.tsx';
import { twMerge } from 'tailwind-merge';
import { ChartType, ReportTypeVO, TimeGranularity } from '@bigdelta/lib-shared';
import { PropertyNameObject } from '../../../shared/types.ts';
import { ReportViewMode } from '../types.ts';
import { QueryReportsCreateData } from '@bigdelta/lib-api-client';
import { UseQueryResult } from '@tanstack/react-query';

export type ReportChartViewProps = HTMLAttributes<HTMLElement> & {
  reportType: ReportTypeVO;
  emptyMessage?: ReactNode;
  workspaceId?: string;
  chartType: ChartType;
  reportViewMode: ReportViewMode;
  hideChart?: boolean;
  reportQuery: UseQueryResult<QueryReportsCreateData, unknown>;
  isHasSource: boolean;
  timeGranularity: TimeGranularity;
  breakdownSelectedValues?: { property_value: string }[];
  breakdownProperty?: PropertyNameObject;
  breakdownPropertyPrefix?: AliasPrefix;
  breakdownSelectedValuesColorMap?: Record<string, string>;
};

const MessageBox = ({ children }) => (
  <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
    <div className="rounded-lg bg-m-gray-300 p-4 text-center text-sm font-medium text-m-olive-500">{children}</div>
  </div>
);

export const ReportChartView: FC<ReportChartViewProps> = ({
  reportType,
  reportQuery,
  chartType,
  emptyMessage,
  reportViewMode,
  hideChart,
  className,
  isHasSource,
  timeGranularity,
  breakdownSelectedValues,
  breakdownProperty,
  breakdownPropertyPrefix,
  breakdownSelectedValuesColorMap,
}) => {
  const isEmpty = !reportQuery.data;
  return (
    <div className={twMerge('relative', className)}>
      {!hideChart && (chartType === ChartType.LINE || chartType === ChartType.STACKED || chartType === ChartType.HORIZONTAL) && (
        <ReportChart
          showTooltips
          reportType={reportType}
          chartType={chartType}
          reportViewMode={reportViewMode}
          dataQueries={reportQuery.data?.queries}
          granularity={timeGranularity}
          breakdownProperty={breakdownProperty}
          breakdownPropertyPrefix={breakdownPropertyPrefix}
          breakdownSelectedValues={breakdownSelectedValues}
          breakdownSelectedValuesColorMap={breakdownSelectedValuesColorMap}
        />
      )}
      {!reportQuery.isSuccess && !isHasSource && <MessageBox>Add data source to build a report</MessageBox>}
      {isHasSource && isEmpty && emptyMessage && <MessageBox>{emptyMessage}</MessageBox>}
    </div>
  );
};
