import { ChartData, ChartOptions, Point, ScriptableLineSegmentContext, TooltipItem } from 'chart.js';
import { ComponentProps, FC } from 'react';
import { Line } from 'react-chartjs-2';
import merge from 'lodash/merge';
import { isToday } from 'date-fns';

import { TimeGranularity } from '@bigdelta/lib-shared';
import { QueryReportsCreateData } from '@bigdelta/lib-api-client';

import { DISPLAY_FORMATS } from '../const';
import { formatMonetary } from '../../../shared/utils/formatMonetary';
import { roundToDecimalPlaces } from '../../../utils/roundToDecimalPlaces.ts';
import { Datapoint } from '../types';
import { QueryMetaData } from '../../../shared/types.ts';
import { ExternalTooltipHandler } from '../hooks/useExternalChartTooltip.tsx';

interface LineChartProps {
  datasets: ChartData<'line', Datapoint[], string>['datasets'];
  granularity: TimeGranularity;
  dataQueries?: QueryReportsCreateData['queries'];
  options?: ComponentProps<typeof Line>['options'];
  tooltipHandler?: ExternalTooltipHandler<'line'>;
}

export const LineChart: FC<LineChartProps> = ({ datasets, granularity, dataQueries, options, tooltipHandler }) => {
  const getMetadata = (index: number) => dataQueries?.[index]?.metadata as QueryMetaData;

  return (
    <Line
      data={{
        datasets,
      }}
      key={JSON.stringify(datasets)}
      redraw={false}
      options={merge(
        {
          plugins: {
            datalabels: {
              display: false,
            },
            grid: {
              display: false,
            },
            tooltip: {
              animation: false,
              enabled: !tooltipHandler,
              position: 'nearest',
              external: tooltipHandler ? tooltipHandler : undefined,
              caretPadding: 8,
              usePointStyle: true,

              callbacks: {
                labelPointStyle: () => {
                  return {
                    pointStyle: 'rectRounded',
                    rotation: 0,
                  };
                },
                labelColor: function (context) {
                  return {
                    borderColor: context?.dataset?.borderColor?.toString() ?? '',
                    backgroundColor: context?.dataset?.borderColor?.toString() ?? '',
                    borderWidth: 0,
                    borderRadius: 4,
                  };
                },
                label: (context) => {
                  const rawValue = context.parsed.y;
                  const monetaryFormat = getMetadata(context.dataset.order as number)?.format?.monetary;

                  if (monetaryFormat && typeof rawValue === 'number') {
                    return formatMonetary(rawValue, monetaryFormat);
                  }

                  return context.formattedValue;
                },
              },
            },
          },
          y: {
            ticks: {
              color: '#898A7E',
              display: !!datasets?.length,
              callback: (rawValue) => {
                const monetaryFormat = getMetadata(0)?.format?.monetary;

                if (monetaryFormat && typeof rawValue === 'number') {
                  return formatMonetary(rawValue, monetaryFormat);
                } else if (typeof rawValue === 'number') {
                  return roundToDecimalPlaces(rawValue, 6);
                }
              },
            },
          },
          animation: {
            duration: 0,
          },
          interaction: {
            intersect: false,
          },
          maintainAspectRatio: false,
          responsive: true,
          segment: (context: TooltipItem<'line'>) => ({
            borderDash: (segment: ScriptableLineSegmentContext) => {
              const isDateToday = isToday(new Date((context.dataset.data[segment.p1DataIndex] as Point).x));
              const isLastSegment = context.dataset?.data.length - 1 === segment.p1DataIndex;

              return isLastSegment && isDateToday ? [8, 8] : undefined;
            },
          }),
          elements: {
            point: {
              backgroundColor: '#5B70DF',
              hoverBackgroundColor: '#5B70DF',
              hoverBorderColor: '#fff',
              borderColor: 'transparent',
              borderWidth: 2,
              hoverBorderWidth: 2,
              radius: 3,
              hoverRadius: 5,
            },
            line: {
              borderColor: '#5B70DF',
              tension: 0.01,
            },
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: granularity,
                displayFormats: DISPLAY_FORMATS,
              },
              ticks: {
                color: '#898A7E',
                display: !!datasets?.length,
                autoSkip: true,
                maxTicksLimit: 20,
                maxRotation: 0,
                minRotation: 0,
              },
              border: {
                display: false,
              },
              grid: {
                display: false,
              },
            },
            y: {
              ticks: {
                color: '#898A7E',
                display: !!datasets?.length,
                callback: (rawValue) => {
                  const monetaryFormat = getMetadata(0)?.format?.monetary;
                  if (monetaryFormat && typeof rawValue === 'number') {
                    return formatMonetary(rawValue, monetaryFormat);
                  }

                  return rawValue;
                },
              },
              border: {
                display: false,
              },
              grid: {
                color: '#EBECEB',
              },
              beginAtZero: true,
            },
          },
        } as ChartOptions<'line'>,
        options
      )}
    />
  );
};
