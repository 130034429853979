import { PageHeading } from '../../../components/PageHeading';
import { Button } from '../../../shared/ui/Button/Button';
import ArrowUpIcon from '../../../assets/icons/arrow-up-right.svg?react';

import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';

import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { useEffect, useMemo, useState } from 'react';
import { AVAILABLE_INTEGRATIONS, IntegrationRowData, UNAVAILABLE_INTEGRATIONS } from '../const';

import { IntegrationRow } from '../components/IntegrationRow';
import { IntegrationModal } from '../components/IntegrationModal';
import { tracking, TrackingEvent } from '../../../tracking';
import { IntegrationMetricsModal } from '../components/IntegrationMetricsModal';
import { Link, useLocation } from 'react-router-dom';
import { IntegrationStatus, IntegrationType } from '@bigdelta/lib-shared';
import { AppRoutes } from '../../../routes';
import { orderBy } from 'lodash';
import { CenteredLoader } from '../../../shared/components/CenteredLoader.tsx';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

export const IntegrationsPage = () => {
  const { currentWorkspaceId } = useWorkspace();

  const { pathname } = useLocation();
  const queryKeys = useQueryKeys();

  const [selectedIntegrationSetup, setSelectedIntegrationSetup] = useState<IntegrationRowData | null>(null);

  const [selectedShowMetricsIntegrationName, setSelectedShowMetricsIntegrationName] = useState<string | null>(null);

  const integrationsQuery = useQuery({
    queryKey: queryKeys.integrations(),
    queryFn: () => bigdeltaAPIClient.v1.integrationsList({ workspace_id: currentWorkspaceId }),
    refetchInterval: 15000,
  });

  const selectedMetrics = useMemo(() => {
    if (!selectedShowMetricsIntegrationName) {
      return;
    }

    return AVAILABLE_INTEGRATIONS.find(({ name }) => name === selectedShowMetricsIntegrationName)?.metrics;
  }, [selectedShowMetricsIntegrationName]);

  useEffect(() => {
    if (pathname === AppRoutes.INTEGRATIONS_STRIPE_AUTH) {
      setSelectedIntegrationSetup(AVAILABLE_INTEGRATIONS.find((i) => i.type === IntegrationType.STRIPE));
    }
  }, [pathname]);

  return (
    <>
      {integrationsQuery.isInitialLoading && <CenteredLoader />}
      {!integrationsQuery.isInitialLoading && (
        <div className="flex flex-col">
          <div className="flex items-center justify-between pb-6">
            <PageHeading breadcrumbs={[{ label: 'Integrations' }]} />
            <div>
              <Link target="_blank" to="https://docs.bigdelta.com/start_here">
                <Button intent="brand" size="lg" label="Custom API Integration" trailingIcon={ArrowUpIcon} onClick={() => {}} />
              </Link>
            </div>
          </div>
          <div className="w-full overflow-x-auto pb-6">
            <table className="w-full table-auto whitespace-nowrap text-m-olive-600">
              <tbody>
                {AVAILABLE_INTEGRATIONS.map((integration) => {
                  const integrationData = orderBy(integrationsQuery.data, 'created_at', 'desc')?.find((data) => data.type === integration.type);

                  return (
                    <IntegrationRow
                      key={integration.name}
                      name={integration.name}
                      description={integration.description}
                      icon={integration.icon}
                      docs={integration.docs}
                      records={integration.records}
                      metrics={integration.metrics}
                      data={integrationData}
                      available={true}
                      onConnect={() => {
                        if (integration.name === 'Stripe') {
                          if (!integrationData) {
                            tracking.track(TrackingEvent.StripeIntegrationInitiated);
                          } else if (integrationData.status === IntegrationStatus.BROKEN) {
                            window.location.href = integration.link + '&state=' + btoa(JSON.stringify({ integrationId: integrationData.id }));
                          }
                        }

                        if (integration.link) {
                          window.location.href = integration.link;
                        } else {
                          setSelectedIntegrationSetup(integration);
                        }
                      }}
                      onShowMetrics={(name) => setSelectedShowMetricsIntegrationName(name)}
                    />
                  );
                })}
                {UNAVAILABLE_INTEGRATIONS.map((integration) => (
                  <IntegrationRow
                    key={integration.name}
                    name={integration.name}
                    description={integration.description}
                    icon={integration.icon}
                    records={integration.records}
                    metrics={integration.metrics}
                    available={false}
                  />
                ))}
              </tbody>
            </table>
          </div>
          {selectedMetrics && (
            <IntegrationMetricsModal open={!!selectedMetrics} onClose={() => setSelectedShowMetricsIntegrationName(null)} metrics={selectedMetrics} />
          )}
          <IntegrationModal
            open={!!selectedIntegrationSetup}
            onClose={() => setSelectedIntegrationSetup(null)}
            Component={selectedIntegrationSetup?.setupComponent}
          />
        </div>
      )}
    </>
  );
};
