import { ReactNode, useEffect, useRef } from 'react';
import { QueryReportsByObjectTypeData } from '@bigdelta/lib-api-client';
import { add, isAfter, isBefore, lastDayOfMonth, sub } from 'date-fns';
import { Link, useParams } from 'react-router-dom';

import { PropsWithClassName } from '../../../types';
import { twMerge } from '../../../utils/twMerge';
import { RecordTimelineEvents } from './RecordTimelineEvents';
import { useReportsJourney } from '../../../shared/data/useReportQuery';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { useObjectQuery } from '../../../shared/data/useObjectQuery';

type RowProps = PropsWithClassName & {
  children?: ReactNode;
};

type EventData = QueryReportsByObjectTypeData['result'][number];

const byDate = ({ timestamp: a }: EventData, { timestamp: b }: EventData) => new Date(a).getTime() - new Date(b).getTime();

interface UserEvents {
  slug: string;
  id: string;
  name: string;
  events: EventData[];
}
const groupByUser = (acc: UserEvents[], event: EventData) => {
  const index = acc.findIndex((el) => el.id === event.record_id && el.slug === event.object_slug);
  if (index >= 0) {
    acc[index].events = [...acc[index].events, event];
  } else {
    acc.push({
      id: event.record_id,
      slug: event.object_slug,
      name: event.record_name,
      events: [event],
    });
  }

  return acc;
};

const Row = ({ children, className, ...rest }: RowProps) => (
  <div
    className={twMerge(
      'flex min-h-12 shrink-0 flex-nowrap items-center justify-start whitespace-nowrap border-b border-l border-r px-3 py-2.5',
      className
    )}
    {...rest}
  >
    {children || ' '}
  </div>
);

const DashRow = ({ children, className, ...rest }: RowProps) => (
  <Row className={twMerge('border-dashed border-l-transparent', className)} {...rest}>
    {children || ' '}
  </Row>
);

const getMonthsList = () => {
  const year = sub(new Date(), { years: 1 });

  return Array.from({ length: 12 }, (_, i) => {
    const date = add(year, { months: i + 1 });
    return `${new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date)} ${date.getFullYear()}`;
  });
};

export const RecordTimeline = () => {
  const { remoteRecordId, objectSlug } = useParams();
  const { currentWorkspaceId } = useWorkspace();

  const { data } = useReportsJourney({ recordId: remoteRecordId, workspaceId: currentWorkspaceId, objectSlug });
  const { data: object } = useObjectQuery({ objectSlug: objectSlug ?? '', workspaceId: currentWorkspaceId });

  const journey = data?.result || [];
  const lastMonthRef = useRef(null);

  const months = getMonthsList();
  const userEvents = journey.reduce(groupByUser, []);
  const isEmpty = userEvents.length === 0;

  useEffect(() => {
    if (lastMonthRef.current) {
      lastMonthRef.current.scrollIntoView();
    }
  }, []);

  return (
    <>
      <div className="flex flex-row border-gray-200">
        <div className="flex w-1/5 flex-col items-start justify-center">
          <Row className="w-full border-l-transparent text-sm font-medium text-m-olive-500">User</Row>
          {userEvents.map(({ name, slug, id }) => (
            <Row className="w-full truncate border-l-transparent text-md text-m-olive-600" key={name}>
              <Link to={`/records/${slug}/${id}`}>{name}</Link>
            </Row>
          ))}
        </div>
        <div className="flex w-4/5 flex-col items-start justify-center overflow-x-auto">
          <div className="flex w-full flex-row items-center justify-start">
            {months.map((month, index) => {
              const isLastMonth = index === months.length - 1;
              const monthStart = new Date(month);
              const monthEndDate = lastDayOfMonth(monthStart);
              const isBetweenDates = ({ timestamp }: EventData) => {
                const eventTime = new Date(timestamp);
                return isAfter(eventTime, monthStart) && isBefore(eventTime, monthEndDate);
              };

              return (
                <div
                  key={month}
                  ref={isLastMonth ? lastMonthRef : null}
                  className="flex flex-grow flex-col items-center justify-center even:bg-m-gray-300"
                >
                  <DashRow className="w-full border-x-2 text-sm text-m-olive-500">{month}</DashRow>
                  {userEvents.map(({ name, events }) => {
                    const monthEvents = events.filter(isBetweenDates).sort(byDate);

                    return (
                      <DashRow key={`${month}-${name}`} className="w-full justify-center border-x-2">
                        {monthEvents.length && <RecordTimelineEvents monthEvents={monthEvents} />}
                      </DashRow>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {isEmpty && <div className="flex items-center justify-center py-4 text-md text-m-olive-400">This {object.singular_noun} has no events</div>}
    </>
  );
};
