import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { TimeGranularity } from '@bigdelta/lib-shared';
import { granularityOptions, ReportTimerange } from '../../reports/const';

interface DashboardState {
  granularity: TimeGranularity;
  timerange: ReportTimerange;
  search: string;
}

interface DashboardActions {
  setGranularity: (granularity: TimeGranularity) => void;
  setTimerange: (timerange: ReportTimerange) => void;
  setSearch: (search: string) => void;
}

const initialState: DashboardState = {
  granularity: TimeGranularity.WEEK,
  timerange: ReportTimerange['3M'],
  search: '',
};

export const useDashboardStore = create<DashboardState & DashboardActions>()(
  immer((set) => ({
    ...initialState,

    setTimerange: (timerange) =>
      set((state) => {
        state.timerange = timerange;
        if (granularityOptions[timerange]?.indexOf(state.granularity!) === -1) {
          state.granularity = granularityOptions[timerange][0];
        }
      }),

    setGranularity: (granularity) =>
      set((state) => {
        state.granularity = granularity;
      }),

    setSearch: (search) =>
      set((state) => {
        state.search = search;
      }),
  }))
);
