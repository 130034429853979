import { Cell, Row } from '@tanstack/react-table';
import { FC, useCallback } from 'react';
import { Link, To } from 'react-router-dom';
import { ConditionalWrap } from '../../utils/ConditionalWrap';
import { twMerge } from '../../../utils/twMerge';
import { PropsWithClassName, SVGComponent, UpdateParams } from '../../../types';

import IconFromUrl from '../../components/IconFromUrl';
import { CellContentText } from './CellContentText';
import { useOptimisticObjectRecordMutation } from '../../data/useOptimisticObjectRecordMutation';
import { CellContentNumber } from './CellContentNumber';
import { ResourcePropertyType } from '@bigdelta/lib-shared';
import { CellContentDateTime } from './CellContentDateTime';
import { CellContentCurrency } from './CellContentCurrency';
import { CurrencyProperty } from '../../properties/types';
import { isBooleanOrEmpty, isCurrencyOrEmpty, isNumberOrEmpty, isStringArrayOrEmpty, isStringOrEmpty } from '../../properties/utils';
import { CellContentDefault } from './CellContentDefault';
import { NestedObjectDef } from '@bigdelta/lib-api-client';
import { CellContentTags } from './CellContentTags';
import { Checkbox } from '../../ui/Checkbox/Checkbox';

interface CellContentProps extends PropsWithClassName {
  value: string | CurrencyProperty | number | null | undefined | boolean | NestedObjectDef | NestedObjectDef[string];
  cell?: Cell<any, unknown>;
  type?: ResourcePropertyType;
  link?: (row: Row<any>) => To;
  leadingIcon?: SVGComponent;
  leadingIconUrl?: string;
  trailingIcon?: SVGComponent;
  updateParams?: UpdateParams;
  editable?: boolean;
  disabled?: boolean;
}

export const CellContent: FC<CellContentProps> = ({
  link,
  cell,
  value,
  type,
  updateParams,
  leadingIcon,
  leadingIconUrl,
  trailingIcon,
  className,
  disabled = true,
  editable = false,
}) => {
  const LeadingIcon = leadingIcon;
  const TrailingIcon = trailingIcon;

  const handleUpdateProperty = useOptimisticObjectRecordMutation({
    id: updateParams?.valueId,
    objectSlug: updateParams?.objectSlug,
    workspaceId: updateParams?.workspaceId,
    key: updateParams?.queryKey,
    skipQueryCancel: true,
  });
  const isLink = !!link && !!cell;

  const getInputField = useCallback(() => {
    const handleUpdate = (value: string | string[] | number | boolean | null | undefined | CurrencyProperty) => {
      if (!updateParams) {
        return;
      }

      handleUpdateProperty(updateParams.propertyName, value);
    };

    if (type === ResourcePropertyType.CURRENCY && isCurrencyOrEmpty(value)) {
      return <CellContentCurrency value={value} onChange={handleUpdate} disabled={disabled} />;
    }

    if (type === ResourcePropertyType.NUMBER && isNumberOrEmpty(value)) {
      return <CellContentNumber value={value} onChange={handleUpdate} disabled={disabled} />;
    }

    if (type === ResourcePropertyType.BOOLEAN && isBooleanOrEmpty(value)) {
      return (
        <div className={twMerge('grow rounded-md border-0 px-2 py-1.5 focus-within:ring-2 focus-within:ring-m-blue-400')}>
          <Checkbox checked={value ?? false} onChange={handleUpdate} disabled={!editable} />
        </div>
      );
    }

    if (type === ResourcePropertyType.STRING_ARRAY && isStringArrayOrEmpty(value)) {
      return <CellContentTags tags={value} onChange={handleUpdate} cellId={cell?.id} editable={editable} disabled={disabled} />;
    }

    if (type && [ResourcePropertyType.DATETIME64, ResourcePropertyType.STRING].includes(type) && isStringOrEmpty(value)) {
      switch (type) {
        case ResourcePropertyType.DATETIME64:
          return <CellContentDateTime value={value} onChange={handleUpdate} disabled={disabled} />;
        case ResourcePropertyType.STRING:
        default:
          return (
            <CellContentText
              value={value}
              onChange={handleUpdate}
              className={isLink ? 'cursor-pointer' : ''}
              disabled={disabled}
              editable={editable}
            />
          );
      }
    }

    return <CellContentDefault text={JSON.stringify(value)} />;
  }, [type, value, updateParams, handleUpdateProperty, disabled, cell?.id, isLink, editable]);

  return (
    <>
      <ConditionalWrap
        condition={!!link && !!cell}
        wrap={(children) => (
          <Link to={link ? link(cell!.row) : ''} className="block truncate px-2">
            {children}
          </Link>
        )}
      >
        <div className={twMerge('flex h-full items-center gap-x-1.5', disabled && !link ? 'text-m-olive-500' : 'text-m-olive-600', className)}>
          {!leadingIconUrl && LeadingIcon && <LeadingIcon className="h-4 w-4 shrink-0" />}
          {leadingIconUrl && <IconFromUrl iconUrl={leadingIconUrl} />}
          {getInputField()}
          {TrailingIcon && <TrailingIcon className="h-4 w-4 shrink-0" />}
        </div>
      </ConditionalWrap>
    </>
  );
};
