import { useMemo } from 'react';
import { MembersConfigTableLayoutListData, MetadataResourcesPropertiesNamesListData, QueryEventsCreateData } from '@bigdelta/lib-api-client';
import { ColumnDef, ColumnHelper } from '@tanstack/react-table';
import { suppressConsoleWarn } from '../../../shared/utils/suppressConsoleWarn';
import { format } from 'date-fns';
import { CellContent } from '../../../shared/tables/components/CellContent';

import EventIcon from '../../../assets/icons/cursor-click-01.svg?react';
import { DataTableColumnActions } from '../../../shared/tables/components/DataTableColumnActions';
import { RelationshipEntityType, ResourcePropertyType, TableColumnType, TableResourceType, TableSortDirection } from '@bigdelta/lib-shared';
import { useTableLayout, UseTableLayoutArgs } from '../../../shared/tables/hooks/useTableLayout';

import PlusIcon from '../../../assets/icons/plus.svg?react';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { useQuery } from '@tanstack/react-query';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { getTableSortEnumVal } from '../../../shared/tables/utils/getTableSortEnumVal';
import { getRelatedObjectsList } from '../../../shared/tables/utils/getRelatedObjectsList';
import { renderRelatedRecordColumnHeader } from '../../../shared/tables/utils/renderRelatedRecordColumnHeader';
import { REMOTE_ID } from '../../records/const';
import { resolveRelations } from '../../records/hooks/useRecordsTableColumns/utils/resolveRelations';
import { CellContentRelations } from '../../../shared/tables/components/CellContentRelations';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';
import { getUserFriendlyPropertyName } from '../../records/hooks/useRecordsTableColumns/utils/getUserFriendlyPropertyName';
import { ColumnActionType } from '../../../shared/tables/types.ts';
import { getSizeProps } from '../../../shared/tables/utils/getSizeProps';
import { get, last } from 'lodash';
import { CellContentRelationProperties } from '../../../shared/tables/components/CellContentRelationProperties';

const getFieldActions = (column: MembersConfigTableLayoutListData['columns'][number]): ColumnActionType[] => {
  const actions = [ColumnActionType.SORT];

  if (column.field?.field_name === 'created_at') {
    actions.push(ColumnActionType.ADD_RIGHT);
  }

  return actions;
};

interface UseTableColumnsArgs {
  columnHelper: ColumnHelper<QueryEventsCreateData['items'][number]>;
  metadataAttributeNameItems?: MetadataResourcesPropertiesNamesListData['items'];
  layout: UseTableLayoutArgs['layout'];
  onLayoutChange: UseTableLayoutArgs['onChange'];
}

export const useEventsTableColumns = ({ metadataAttributeNameItems, columnHelper, layout, onLayoutChange }: UseTableColumnsArgs) => {
  const { onActionSelect, extractRelationshipInfo } = useTableLayout({
    resourceType: TableResourceType.EVENT,
    layout,
    onChange: onLayoutChange,
  });

  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();
  const relationshipsQuery = useQuery({
    queryKey: queryKeys.relationships(),
    queryFn: () => bigdeltaAPIClient.v1.relationshipsList({ workspace_id: currentWorkspaceId }),
  });
  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object', 'columns'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId, status: 'ACTIVE_AND_VIRTUAL' }),
  });

  return useMemo(() => {
    if (!metadataAttributeNameItems || !relationshipsQuery.data || !objectsQuery.data) {
      return [];
    }

    const eventPropertyColumns =
      layout?.columns
        .map((column, index) => {
          const columnField = column.field;

          if (column.type === TableColumnType.FIELD && columnField) {
            return columnHelper.accessor((event) => event[columnField.field_name], {
              id: column.id ?? columnField.field_name,
              ...getSizeProps(column),
              header: () => (
                <DataTableColumnActions
                  key={columnField.field_name}
                  resourceType={TableResourceType.EVENT}
                  actions={getFieldActions(column)}
                  onSelect={(action) => onActionSelect(columnField.field_name, action, column)}
                  sort={column.sort as TableSortDirection}
                >
                  {getUserFriendlyPropertyName(columnField.field_name)}
                </DataTableColumnActions>
              ),
              cell: (props) => {
                let value;
                suppressConsoleWarn(() => {
                  if (columnField.field_name === 'created_at') {
                    value = format(new Date(props.getValue()), 'yyyy-MM-dd HH:mm:ss');
                  } else {
                    value = props.getValue();
                  }

                  return value;
                });
                return (
                  <CellContent
                    value={value}
                    leadingIcon={columnField.field_name === 'event_name' ? EventIcon : undefined}
                    type={ResourcePropertyType.STRING}
                    disabled={true}
                    editable={false}
                  />
                );
              },
            });
          }

          if (column.type === TableColumnType.PROPERTY && column.property) {
            const columnProperty = column.property;

            if (!columnProperty) {
              return;
            }

            return columnHelper.accessor((row) => (column.property?.property_name ? get(row.properties, column.property?.property_name) : null), {
              id: column.id ?? `${column.property?.property_name}-${index}`,
              ...getSizeProps(column),
              header: () => (
                <DataTableColumnActions
                  resourceType={TableResourceType.EVENT}
                  actions={[
                    ColumnActionType.ADD_LEFT,
                    ColumnActionType.ADD_RIGHT,
                    ...(index !== 0 ? [ColumnActionType.MOVE_LEFT] : []),
                    ...(index !== layout.columns.length - 1 ? [ColumnActionType.MOVE_RIGHT] : []),
                    ColumnActionType.SORT,
                    ColumnActionType.HIDE,
                  ]}
                  onSelect={(action) => onActionSelect(columnProperty.property_name, action, column)}
                  sort={getTableSortEnumVal(column.sort)}
                >
                  {getUserFriendlyPropertyName(column.property?.property_name)}
                </DataTableColumnActions>
              ),
              cell: (info) => {
                const valueFn = () => info.getValue();
                let value: ReturnType<typeof valueFn> | undefined;

                suppressConsoleWarn(() => {
                  value = info.getValue();
                });

                const propertyType =
                  metadataAttributeNameItems?.find((item) => item.property_name === column.property?.property_name)?.property_type ??
                  ResourcePropertyType.STRING;

                return (
                  <CellContent
                    key={`${column.id}-${info.cell.row.id}`}
                    type={propertyType as ResourcePropertyType | undefined}
                    cell={info.cell}
                    value={value}
                    disabled={false}
                    editable={false}
                  />
                );
              },
            });
          }

          if (column.type === TableColumnType.RELATIONSHIP) {
            const { names: relationshipNames, property } = extractRelationshipInfo(column.relationship);

            if (!property) {
              return;
            }

            const relatedObjectsList = getRelatedObjectsList(
              relationshipNames,
              RelationshipEntityType.EVENT,
              undefined,
              relationshipsQuery.data?.relationships,
              objectsQuery.data?.objects
            ).flatMap((o) => (o ? [o] : []));

            if (!relatedObjectsList || relatedObjectsList?.find((object) => !object)) {
              // throw error
              return;
            }

            const isPropertyId = property.property_name === REMOTE_ID;

            return columnHelper.accessor(
              (row) => {
                return {
                  relations: resolveRelations(row.relationships?.relationship_properties, column.relationship!),
                };
              },
              {
                id: column.id ?? `${JSON.stringify(column)}-${index}`,
                ...getSizeProps(column),
                header: () => (
                  <DataTableColumnActions
                    resourceType={TableResourceType.EVENT}
                    onSelect={(action) => onActionSelect('', action, column)}
                    actions={[
                      ColumnActionType.ADD_LEFT,
                      ColumnActionType.ADD_RIGHT,
                      ...(index !== 0 ? [ColumnActionType.MOVE_LEFT] : []),
                      ...(index !== layout.columns.length - 1 ? [ColumnActionType.MOVE_RIGHT] : []),
                      ColumnActionType.SORT,
                      ColumnActionType.HIDE,
                    ]}
                    sort={getTableSortEnumVal(column.sort)}
                  >
                    {renderRelatedRecordColumnHeader(property, relatedObjectsList)}
                  </DataTableColumnActions>
                ),
                meta: {
                  headerClassName: 'p-0',
                },
                cell: (info) => {
                  const { relations } = info.getValue();
                  const relationObject = last(relatedObjectsList);

                  if (!relationObject || !relations) {
                    return null;
                  }

                  if (isPropertyId) {
                    return <CellContentRelations relations={relations} objectList={objectsQuery.data.objects} />;
                  }

                  return <CellContentRelationProperties property={property} relations={relations} workspaceObject={relationObject} />;
                },
              }
            );
          }
        })
        .flatMap((column) => (column ? [column] : [])) ?? [];

    const emptyColumnWithInsert = columnHelper.display({
      id: 'add_column',
      header: () => (
        <DataTableColumnActions
          resourceType={TableResourceType.EVENT}
          onSelect={(action) => {
            onActionSelect('', action);
          }}
          actions={[ColumnActionType.ADD_TO_END]}
          defaultAction={ColumnActionType.ADD_TO_END}
        >
          <PlusIcon className="h-4 w-4" />
        </DataTableColumnActions>
      ),
      ...getSizeProps(),
      enableResizing: false,
      meta: {
        headerClassName: 'p-0',
      },
    });

    return [...eventPropertyColumns, emptyColumnWithInsert] as ColumnDef<QueryEventsCreateData['items'][number], any>[];
  }, [
    metadataAttributeNameItems,
    relationshipsQuery.data,
    objectsQuery.data,
    columnHelper,
    layout?.columns,
    onActionSelect,
    extractRelationshipInfo,
  ]);
};
