import { createColumnHelper, useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { FC, FormEvent, useMemo, useState } from 'react';
import { ToolsTable } from '../../../shared/components/ToolsTable';

import { suppressConsoleWarn } from '../../../shared/utils/suppressConsoleWarn';
import DeleteIcon from '../../../assets/icons/trash-01.svg?react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { Dialog } from '@headlessui/react';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { formatDistanceToNow, isPast } from 'date-fns';
import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { upperFirst } from 'lodash';
import { BigQuerySyncStatus } from '@bigdelta/lib-shared';
import LoadingIcon from '../../../assets/icons/loading-05.svg?react';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

const columnHelper = createColumnHelper<any[number]>();

interface BigQueryRecordSyncsTableProps {
  integrationId: string;
  recordSyncs?: any;
}

export const BigQueryRecordSyncsTable: FC<BigQueryRecordSyncsTableProps> = ({ integrationId, recordSyncs }) => {
  const { currentWorkspaceId } = useWorkspace();

  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();

  const [isRemoveSyncDialogOpen, setRemoveSyncDialogOpen] = useState(false);
  const [targetedSyncId, setTargetedSyncId] = useState('');

  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId }),
  });

  const removeSyncMutation = useMutation({
    mutationFn: (syncId: string) => bigdeltaAPIClient.v1.integrationsBigquerySyncsDelete(integrationId, syncId),
    onSuccess: (data) => {
      handleRemoveSyncDialogClose();
      return queryClient.invalidateQueries(queryKeys.integration(data.id));
    },
  });

  const handleRemoveSync = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    removeSyncMutation.mutate(targetedSyncId);
  };

  const handleRemoveSyncDialogClose = () => {
    setTargetedSyncId('');
    setRemoveSyncDialogOpen(false);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('workspace_object_slug', {
        id: 'workspace_object_slug',
        header: 'Workspace object',
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          return upperFirst(objectsQuery.data?.objects?.find((o) => o.api_slug === value)?.plural_noun);
        },
      }),
      columnHelper.accessor('dataset', {
        id: 'dataset',
        header: 'Dataset',
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          return value;
        },
      }),
      columnHelper.accessor('table', {
        id: 'table',
        header: 'Table',
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          return value;
        },
      }),
      columnHelper.accessor('primary_key_column', {
        id: 'primary_key_column',
        header: 'Primary key',
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          return value;
        },
      }),
      columnHelper.accessor('status', {
        id: 'status',
        header: 'Status',
        size: 200,
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          function getDisplayStatus(status: BigQuerySyncStatus) {
            if (!status) {
              return 'Initialising...';
            }

            switch (status) {
              case BigQuerySyncStatus.CREATING_SNAPSHOT:
                return 'Creating Snapshot...';
              case BigQuerySyncStatus.IDENTIFYING_CHANGES:
                return 'Identifying Changes...';
              case BigQuerySyncStatus.FETCHING_DATA:
                return 'Fetching Data...';
              case BigQuerySyncStatus.IMPORTING_DATA:
                return 'Importing Data...';
              case BigQuerySyncStatus.COMPLETED:
                return 'Completed';
              case BigQuerySyncStatus.FAILED:
                return 'Failed';
            }
          }

          const bigQuerySyncStatus = value ? (value as BigQuerySyncStatus) : null;
          const displayStatus = getDisplayStatus(bigQuerySyncStatus);

          return (
            <div className="flex items-center gap-x-1 text-sm">
              {![BigQuerySyncStatus.COMPLETED, BigQuerySyncStatus.FAILED].includes(bigQuerySyncStatus) && (
                <LoadingIcon className="w-5 animate-spin" />
              )}
              <div>{displayStatus}</div>
            </div>
          );
        },
      }),
      columnHelper.accessor('last_sync_at', {
        id: 'last_sync_at',
        header: 'Last synced',
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          if (!value) {
            return 'N/A';
          }

          return formatDistanceToNow(new Date(value ?? ''), { addSuffix: true });
        },
      }),
      columnHelper.accessor('next_sync_at', {
        id: 'next_sync_at',
        header: 'Next sync',
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          if (!value) {
            return 'N/A';
          }

          const date = new Date(value ?? '');

          if (isPast(date)) {
            return 'now';
          }

          return formatDistanceToNow(date, { addSuffix: true });
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: 'Actions',
        size: 80,
        cell: (context) => {
          return (
            <div className="flex items-center gap-x-2">
              <button
                onClick={() => {
                  setTargetedSyncId(context.row.id);
                  setRemoveSyncDialogOpen(true);
                }}
              >
                <DeleteIcon className="h-5 w-5 cursor-pointer text-m-red-600 hover:text-m-red-700" />
              </button>
            </div>
          );
        },
      }),
    ],
    [objectsQuery.data?.objects]
  );

  const table = useReactTable({
    columns,
    data: recordSyncs ?? [],
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
  });

  return (
    <>
      <ToolsTable data={recordSyncs} table={table} />
      <Dialog open={isRemoveSyncDialogOpen} onClose={handleRemoveSyncDialogClose} className="relative z-50">
        <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="mx-auto flex w-1/3 max-w-xl flex-col gap-y-6 rounded-xl bg-white p-6">
            <Dialog.Title className="text-xl text-m-olive-700">Remove sync</Dialog.Title>
            <form onSubmit={handleRemoveSync} className="flex flex-col gap-y-6">
              <div className="flex flex-col gap-y-1.5">
                <p className="text-lg text-m-olive-700">
                  Are you sure you want to remove this sync? Once removed, data will no longer sync between your table and our platform.
                </p>
              </div>
              <div className="flex justify-end gap-x-3.5">
                <Button type="button" label="Cancel" intent="secondary" size="lg" onClick={handleRemoveSyncDialogClose} />
                <Button
                  label="Remove"
                  intent="destructive"
                  size="lg"
                  type="submit"
                  loading={removeSyncMutation.isLoading}
                  disabled={removeSyncMutation.isLoading}
                />
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};
