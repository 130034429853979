import { FC, useState, useLayoutEffect } from 'react';
import { Key } from 'ts-key-enum';

interface NumberTextEditProps {
  value: number | null | undefined;
  onChange: (val: number | null | undefined) => void;
  onCancel: () => void;
}

export const NumberTextEdit: FC<NumberTextEditProps> = ({ value, onChange, onCancel }) => {
  const getStringValue = (value: number | null | undefined): string => {
    if (value === undefined || value === null) {
      return '';
    }
    return value.toString();
  };

  const [editValue, setEditValue] = useState<string>(getStringValue(value));

  useLayoutEffect(() => {
    setEditValue(getStringValue(value));
  }, [value]);

  const handleSave = () => {
    const numberValue = editValue.length ? Number(editValue) : undefined;

    onChange(numberValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Key.Escape) {
      setEditValue(getStringValue(value));
      onCancel();
    } else if (e.key === Key.Enter) {
      handleSave();
    }
  };

  return (
    <input
      type="number"
      value={editValue}
      className="w-full border-none bg-transparent p-0 text-sm focus:outline-none focus:ring-0"
      onChange={(e) => {
        setEditValue(e.target.value ?? undefined);
      }}
      autoFocus
      onBlur={handleSave}
      onKeyDown={handleKeyDown}
    />
  );
};
