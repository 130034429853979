import { FC, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { ObjectFormSchema } from './ObjectFormSchema';
import { Button } from '../../../shared/ui/Button/Button';
import { ObjectProfileForm } from './ObjectProfileForm';
import { CenteredLoader } from '../../../shared/components/CenteredLoader';
import { WorkspaceObjectType } from '@bigdelta/lib-shared';

interface ConfigurationTabProps {
  objectId: string | undefined;
  objectQuery: any;
  defaultValues: ObjectFormSchema | null;
  onUpdateObject: (input: ObjectFormSchema) => void;
  onCreateObject: (input: ObjectFormSchema) => void;
  onDeactivateObject: (objectId: string) => void;
}

export const ConfigurationTab: FC<ConfigurationTabProps> = ({
  objectId,
  objectQuery,
  defaultValues,
  onUpdateObject,
  onCreateObject,
  onDeactivateObject,
}) => {
  const [isDeactivateConfirmationOpen, setIsDeactivateConfirmationOpen] = useState(false);

  return (
    <>
      <Dialog open={isDeactivateConfirmationOpen} onClose={() => setIsDeactivateConfirmationOpen(false)} className="relative z-[999999]">
        <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-center justify-center">
          <Dialog.Panel className="mx-auto flex w-3/5 max-w-lg flex-col gap-y-6 rounded-xl bg-white">
            <Dialog.Title className="p-6 pb-0 text-xl text-m-olive-700">Please Confirm</Dialog.Title>
            <div className="px-6 text-sm">Are you sure you want to deactivate this object?</div>
            <div className="flex justify-end gap-x-2 px-6 pb-6">
              <Button size="sm" intent="tertiary" onClick={() => setIsDeactivateConfirmationOpen(false)} label="Cancel" />
              <Button size="sm" intent="destructive" onClick={() => objectId && onDeactivateObject(objectId)} label="Deactivate" />
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
      <div className="flex justify-center">
        <div className="w-1/2">
          {objectId && (
            <>
              {!objectQuery.isInitialLoading && (
                <ObjectProfileForm
                  isCustomObject={objectQuery.data?.object_type === WorkspaceObjectType.CUSTOM}
                  initialValues={defaultValues}
                  objectProperties={[
                    ...(objectQuery.data?.properties?.filter((p) => p.type === 'string' || p.type === 'string[]').map((p) => p.name) || []),
                  ]}
                  deactivateButton={
                    <Button label="Deactivate object" intent="destructive" fullWidth onClick={() => setIsDeactivateConfirmationOpen(true)} />
                  }
                  renderSubmitButton={(isDirty, _isValid, handleSubmit) => (
                    <Button disabled={!isDirty} label="Save" intent="brand" fullWidth onClick={handleSubmit(onUpdateObject)} />
                  )}
                />
              )}
              {objectQuery.isInitialLoading && <CenteredLoader />}
            </>
          )}
          {!objectId && (
            <ObjectProfileForm
              isCustomObject={true}
              initialValues={{}}
              objectProperties={[]}
              renderSubmitButton={(_isDirty, isValid, handleSubmit) => (
                <Button label="Create object" intent="brand" fullWidth onClick={handleSubmit(onCreateObject)} disabled={!isValid} />
              )}
            />
          )}
        </div>
      </div>
    </>
  );
};
