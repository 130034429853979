import { SidePanel, SidePanelFooterWrapper } from '../../../shared/components/SidePanel.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReportStore } from '../../reports/store';
import { useCallback, useEffect, useMemo } from 'react';
import { AppRoutes } from '../../../routes';
import { MetricForm } from '../../../components/MetricForm/MetricForm.tsx';
import { MetricFormSchema } from '../../../components/MetricForm/MetricFormSchema.ts';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MetricsCreatePayload, MetricsUpdatePayload } from '@bigdelta/lib-api-client';

import { toastError, toastSuccess } from '../../../utils/toast.tsx';
import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { useUser } from '../../auth/hooks/useUser.ts';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

export const CreateMetricPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { currentWorkspaceId } = useWorkspace();
  const { reset } = useReportStore(null, { topLevelReportQuery: true })((state) => state);
  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();
  const { user } = useUser();

  useEffect(() => {
    return () => {
      reset();
    };
  }, [pathname, reset]);

  const defaultValues: MetricFormSchema = useMemo(() => {
    return {
      key: '',
      display_name: '',
      description: '',
      owner: user.id,
    };
  }, [user]);

  const saveMetricMutation = useMutation({
    mutationFn: ({ payload }: { payload: MetricsCreatePayload }) => bigdeltaAPIClient.v1.metricsCreate(payload),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.metrics());
      toastSuccess('Created', 'Metric created successfully');
      navigate(AppRoutes.METRICS);
    },
    onError: (error: any) => {
      if (error?.response?.data?.message) {
        toastError(`An error occurred while creating the metric: ${error?.response?.data?.message}`);
      } else {
        toastError('An error occurred while creating the metric');
      }
    },
  });

  const onSubmit = useCallback(
    (data: MetricFormSchema) => {
      saveMetricMutation.mutate({
        payload: {
          display_name: data.display_name,
          description: data.description,
          owner: data.owner,
          query_configuration: data.query_configuration as MetricsUpdatePayload['query_configuration'],
          workspace_id: currentWorkspaceId,
        },
      });
    },
    [currentWorkspaceId, saveMetricMutation]
  );

  return (
    <SidePanel closeOnClickOutside title="New metric" onClose={() => navigate(AppRoutes.METRICS)}>
      <MetricForm
        defaultValues={defaultValues}
        renderSubmitButton={(handleSubmit, disabled) => (
          <SidePanelFooterWrapper>
            <Button label="Save metric" intent="brand" fullWidth onClick={handleSubmit(onSubmit)} disabled={disabled} />
          </SidePanelFooterWrapper>
        )}
      />
    </SidePanel>
  );
};
