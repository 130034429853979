import { FC, FormEvent, useMemo, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { toastError, toastSuccess } from '../../../utils/toast.tsx';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { upperFirst } from 'lodash';
import { Listbox, Option } from '../../../shared/components/Listbox.tsx';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface AddBigQueryRecordSyncDialogProps {
  integrationId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const AddBigQueryRecordSyncDialog: FC<AddBigQueryRecordSyncDialogProps> = ({ integrationId, isOpen, onClose }) => {
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();
  const queryClient = useQueryClient();

  const [selectedObject, setSelectedObject] = useState<Option<string> | null>(null);
  const [selectedDataset, setSelectedDataset] = useState<Option<string> | null>(null);
  const [selectedTable, setSelectedTable] = useState<Option<string> | null>(null);
  const [selectedPrimaryKeyColumn, setSelectedPrimaryKeyColumn] = useState<Option<string> | null>(null);

  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId }),
    enabled: isOpen,
  });

  const availableObjects = useMemo(() => {
    if (!objectsQuery.data?.objects) return [];

    return objectsQuery.data?.objects || [];
  }, [objectsQuery.data?.objects]);

  const datasetsQuery = useQuery({
    queryKey: [...queryKeys.integration(integrationId), 'datasets'],
    queryFn: () => bigdeltaAPIClient.v1.integrationsBigqueryDatasetsDetail(integrationId),
    keepPreviousData: true,
    refetchInterval: 5000,
    enabled: isOpen,
  });

  const availableDatasets = useMemo(() => {
    if (!datasetsQuery.data?.datasets) return [];

    return datasetsQuery.data?.datasets;
  }, [datasetsQuery.data?.datasets]);

  const availableTables = useMemo(() => {
    if (!selectedDataset?.value) return [];

    return availableDatasets.find((d) => d.name === selectedDataset.value)?.tables || [];
  }, [availableDatasets, selectedDataset]);

  const eligiblePrimaryKeys = useMemo(() => {
    if (!selectedTable?.value) return [];

    return availableTables.find((t) => t.name === selectedTable.value)?.columns?.filter((c) => c.mode === 'REQUIRED' && c.type === 'STRING');
  }, [availableTables, selectedTable]);

  const handleCloseDialog = () => {
    setSelectedObject(null);
    setSelectedDataset(null);
    setSelectedTable(null);
    setSelectedPrimaryKeyColumn(null);
    onClose();
  };

  const addBigQueryRecordSyncMutation = useMutation({
    mutationFn: (integrationId: string) => {
      return bigdeltaAPIClient.v1.integrationsBigquerySyncsCreate(integrationId, {
        sink_type: 'record',
        record: {
          workspace_object_slug: selectedObject.value,
          dataset: selectedDataset.value,
          table: selectedTable.value,
          primary_key_column: selectedPrimaryKeyColumn.value,
        },
      });
    },
    onSuccess: () => {
      handleCloseDialog();
      toastSuccess('Added', 'BigQuery sync added successfully');
      return queryClient.invalidateQueries(queryKeys.integration(integrationId));
    },
    onError: () => {
      toastError('An error occurred while adding BigQuery sync!');
    },
  });

  const handleAddSync = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addBigQueryRecordSyncMutation.mutate(integrationId);
  };

  return (
    <Dialog open={isOpen} onClose={handleCloseDialog} className="relative z-50">
      <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="mx-auto flex w-1/3 max-w-xl flex-col gap-y-6 rounded-xl bg-white p-6">
          <Dialog.Title className="text-xl text-m-olive-700">Object sync</Dialog.Title>
          <form onSubmit={handleAddSync} className="flex flex-col gap-y-6">
            <div className="flex flex-col gap-y-1.5">
              <label className="text-md font-medium text-m-olive-700">Workspace object</label>
              <Listbox
                options={availableObjects.map((object) => ({
                  value: object.api_slug,
                  label: upperFirst(object.plural_noun),
                }))}
                value={selectedObject}
                placeholder="Object"
                onChange={(data) => setSelectedObject(data)}
              />
              <label className="pt-2 text-md font-medium text-m-olive-700">Dataset</label>
              <Listbox
                options={availableDatasets.map((d) => ({
                  value: d.name,
                  label: d.name,
                }))}
                value={selectedDataset}
                placeholder="Dataset"
                disabled={availableDatasets.length === 0}
                onChange={(data) => {
                  if (!selectedDataset || data.value !== selectedDataset.value) {
                    setSelectedDataset(data);
                    setSelectedTable(null);
                    setSelectedPrimaryKeyColumn(null);
                  }
                }}
              />
              <label className="pt-2 text-md font-medium text-m-olive-700">Table</label>
              <Listbox
                options={availableTables.map((t) => ({
                  value: t.name,
                  label: t.name,
                }))}
                value={selectedTable}
                placeholder="Table"
                disabled={!selectedDataset || availableTables.length === 0}
                onChange={(data) => {
                  if (!selectedTable || data.value !== selectedTable.value) {
                    setSelectedTable(data);
                    setSelectedPrimaryKeyColumn(null);
                  }
                }}
              />
              <label className="pt-2 text-md font-medium text-m-olive-700">Primary key column</label>
              <Listbox
                options={eligiblePrimaryKeys.map((pk) => ({
                  value: pk.name,
                  label: pk.name,
                }))}
                value={selectedPrimaryKeyColumn}
                placeholder="Column"
                disabled={!selectedTable || eligiblePrimaryKeys.length === 0}
                onChange={(data) => setSelectedPrimaryKeyColumn(data)}
              />
            </div>
            <div className="flex justify-end gap-x-3.5">
              <Button type="button" label="Cancel" intent="secondary" size="lg" onClick={handleCloseDialog} />
              <Button
                label="Add"
                intent="brand"
                size="lg"
                type="submit"
                disabled={!selectedPrimaryKeyColumn || addBigQueryRecordSyncMutation.isLoading}
                loading={addBigQueryRecordSyncMutation.isLoading}
              />
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
