export enum ColumnActionType {
  SORT = 'SORT',
  HIDE = 'HIDE',

  ADD_LEFT = 'ADD_LEFT',
  ADD_RIGHT = 'ADD_RIGHT',

  ADD_TO_END = 'ADD_TO_END',

  MOVE_LEFT = 'MOVE_LEFT',
  MOVE_RIGHT = 'MOVE_RIGHT',
}