import React from 'react';
import { useShowContentClick } from '../../hooks/useShowContentClick';
import { FloatingPortal } from '@floating-ui/react';
import { twMerge } from '../../../utils/twMerge';
import { CurrencyEdit } from '../../properties/Currency/CurrencyEdit';
import { CurrencyView } from '../../properties/Currency/CurrencyView';
import { CurrencyProperty } from '../../properties/types';

interface CellContentCurrencyProps {
  value: CurrencyProperty | undefined | null;
  onChange: (value: CurrencyProperty | undefined | null) => void;
  disabled?: boolean;
}

export const CellContentCurrency: React.FC<CellContentCurrencyProps> = ({ value, onChange, disabled = false }) => {
  const { floatingPortalContentProps, referenceProps, forceClose, isOpen } = useShowContentClick({ disabled });
  floatingPortalContentProps.className = twMerge(
    floatingPortalContentProps.className,
    'flex h-fit w-full rounded-md px-0 py-0.5 focus-within:ring-2 focus-within:ring-m-blue-400 hover:bg-m-gray-300'
  );

  const handleSave = (value: CurrencyProperty | undefined | null) => {
    forceClose();
    onChange?.(value);
  };

  return (
    <>
      <div {...referenceProps} className={twMerge('flex h-full w-full cursor-text items-center', disabled && 'text-m-olive-400')}>
        <CurrencyView value={value} />
      </div>
      {isOpen && (
        <FloatingPortal>
          <div {...floatingPortalContentProps}>
            <CurrencyEdit value={value} onChange={handleSave} />
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
