import { AutomationWorkflowBlockType, DelayTimeUnit } from '@bigdelta/lib-shared';
import { FC, useEffect, useState } from 'react';
import { AutomationsCreateData } from '@bigdelta/lib-api-client';
import { merge } from 'lodash';
import { Input } from '../../../../../shared/ui/Input/Input';
import { useDebounce } from '@uidotdev/usehooks';

type AutomationBlock = AutomationsCreateData['configuration']['trigger']['block'];

interface ActionDelayConfigProps {
  id?: string;
  type: AutomationWorkflowBlockType;
  block?: AutomationBlock;
  onChange: (block: AutomationBlock) => void;
}

const TIME_UNIT_VALUES = [
  { label: 'Minutes', value: 'minute' },
  { label: 'Hours', value: 'hour' },
  { label: 'Days', value: 'day' },
  { label: 'Weeks', value: 'week' },
];

//TODO: Loading state
export const ActionDelayConfig: FC<ActionDelayConfigProps> = ({ id, block, onChange }) => {
  const [rawValue, setRawValue] = useState(block?.delay?.value?.toString() ?? '');
  const debouncedValue = useDebounce(rawValue, 400);

  useEffect(() => {
    if (
      (block?.delay?.value === undefined && !debouncedValue) ||
      block?.delay?.value === parseInt(debouncedValue) ||
      isNaN(parseInt(debouncedValue))
    ) {
      return;
    }

    onChange(
      merge(block, {
        id,
        type: AutomationWorkflowBlockType.DELAY,
        delay: {
          value: parseInt(debouncedValue),
        },
      })
    );
  }, [block, debouncedValue, id, onChange]);

  const handleChangeUnit = (unit: DelayTimeUnit) => {
    onChange(
      merge(block, {
        id,
        type: AutomationWorkflowBlockType.DELAY,
        delay: {
          unit,
        },
      })
    );
  };

  return (
    <div>
      <div className="flex flex-col items-stretch">
        <div className="flex flex-col gap-y-6">
          <div className="flex flex-col gap-y-2">
            <label className="text-md">Delay until</label>
            <div className="flex gap-x-2">
              <Input
                type="number"
                min={1}
                placeholder="Number of time units"
                className="w-6/12"
                value={rawValue}
                onChange={(evt) => setRawValue(evt.target.value)}
              />
              <select
                value={block?.delay?.unit}
                className="w-6/12 rounded-lg border border-m-olive-100 text-sm"
                onChange={(evt) => handleChangeUnit(evt.target.value as DelayTimeUnit)}
              >
                {TIME_UNIT_VALUES.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
