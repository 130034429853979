import { isEqual } from 'lodash';
import { PropertyNameObject, RelationshipObjectData } from '../../../shared/types';
import { MetricMath, ResourcePropertyType } from '@bigdelta/lib-shared';

export enum ObjectCountType {
  RECORD = 'record',
  EVENT = 'event',
}

export type ConversionRateCountType = 'conversion_rate';

export interface RecordCount {
  aggregate: MetricMath;
  type: ObjectCountType.RECORD;
  record: RecordCountData;
}

export interface EventCount {
  aggregate: MetricMath;
  type: ObjectCountType.EVENT;
  event: EventCountData;
}

export function isRecordCount(count?: ObjectCount): count is RecordCount {
  return count?.type === ObjectCountType.RECORD;
}

export function isEventCount(count?: ObjectCount): count is EventCount {
  return count?.type === ObjectCountType.EVENT;
}

export type ObjectCount = RecordCount | EventCount;

export interface ConversionRateCount {
  type: ConversionRateCountType;
}

export type WithTopLevelPropertyType = {
  top_level_property_type?: ResourcePropertyType;
};

export interface RecordCountData {
  type: 'total' | 'property';
  data: {
    property?: PropertyNameObject & WithTopLevelPropertyType;
    relationships?: RelationshipObjectData[];
  };
}

export interface EventCountData {
  type: 'allEvents' | 'event' | 'property';
  data: {
    event?: string;
    property?: {
      event: string;
      property: PropertyNameObject & WithTopLevelPropertyType;
    };
  };
}

export interface ReportBuilderStateData<InitialBuilderStateData = unknown> {
  id: string; // uuid
  metricId?: string;
  initialMetricBuilderData?: InitialBuilderStateData;
  hasUnsavedMetricChanges?: boolean;
  data: any;
}

export interface ReportBuilderState<InitialData = unknown> {
  resetInitialMetricBuilderData: () => void;
  setBuilder: (data: InitialData) => void;
}

export type GenericReportBuilder<InitialData = unknown> = ReportBuilderStateData<InitialData> & ReportBuilderState<InitialData>;
export type UseStore<TState extends GenericReportBuilder<InitialData>, InitialData = unknown> = <TResult>(
  id: string,
  selector: (state: TState) => TResult
) => TResult;

const getDataForComparison = <State extends ReportBuilderStateData<InitialState>, InitialState = unknown>(state: State | InitialState): State => {
  return Object.entries(state as Record<string, unknown>).reduce((acc, [key, value]) => {
    if (['data', 'label'].includes(key)) {
      acc[key] = value;
    }
    return acc;
  }, {} as State);
};

const hasUnsavedMetricChangesSelector = <State extends ReportBuilderStateData<InitialState>, InitialState = unknown>(state: State): boolean => {
  if (!state.initialMetricBuilderData) {
    return false;
  }

  const builderData = getDataForComparison(state);
  const initialData = getDataForComparison<State>(state.initialMetricBuilderData);

  return !isEqual(builderData, initialData);
};

export const useHasUnsavedChanges = <Builder extends GenericReportBuilder>(id: string, useStoreSelector: UseStore<Builder>) => {
  return useStoreSelector(id, hasUnsavedMetricChangesSelector);
};
