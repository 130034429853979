import { NestedObjectDef, ObjectsRecordsPartialUpdateData, ObjectsRecordsPartialUpdatePayload } from '@bigdelta/lib-api-client';
import { produce } from 'immer';

import { type InfiniteData, QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { toastError } from '../../utils/toast';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient';
import { NestedValue } from '../types';
import { isEqual } from 'lodash';

interface OptimisticMutationParameters {
  objectSlug: string;
  id: string;
  workspaceId: string;
  key: QueryKey;
  skipQueryCancel?: boolean;
}

type InfiniteResult = InfiniteData<{ items: ObjectsRecordsPartialUpdateData[] }>;

function isInfinite(data: InfiniteResult | ObjectsRecordsPartialUpdateData): data is InfiniteResult {
  return (data as InfiniteResult).pages !== undefined;
}

export const useOptimisticObjectRecordMutation = <
  P extends ObjectsRecordsPartialUpdatePayload,
  D extends ObjectsRecordsPartialUpdateData | InfiniteResult,
>({
  objectSlug,
  id,
  workspaceId,
  key,
  skipQueryCancel = false,
}: OptimisticMutationParameters) => {
  const queryClient = useQueryClient();

  const updateValue =
    (payload: NestedObjectDef | ObjectsRecordsPartialUpdateData | P | undefined) =>
    (old: D | undefined): D | undefined => {
      if (!old) return old;

      if (!isInfinite(old)) {
        return {
          ...old,
          properties: {
            ...old?.properties,
            ...payload,
          },
        };
      } else {
        const newPages = old.pages.map((page) => ({
          ...page,
          items: page.items.map((item) => (item.id === id ? { ...item, properties: { ...item.properties, ...payload } } : item)),
        }));

        return {
          ...old,
          pages: newPages,
        };
      }
    };

  const recordPartialMutation = useMutation({
    mutationFn: (payload: P) => bigdeltaAPIClient.v1.objectsRecordsUpdate(objectSlug, id, { workspace_id: workspaceId }, payload),
    onMutate: async (payload: P) => {
      if (!skipQueryCancel) {
        await queryClient.cancelQueries({ queryKey: key });
      }

      const previousRecord = queryClient.getQueryData<D | undefined>(key);
      queryClient.setQueryData(key, updateValue(payload?.properties || payload));

      return { previousRecord };
    },

    onError: (_err, _payload, context) => {
      if (context?.previousRecord) {
        queryClient.setQueryData(key, context.previousRecord);
      }
      toastError('Failed to update record properties');
    },
    onSettled: (data) => {
      queryClient.setQueryData<D>(key, updateValue(data?.properties || data));
    },
  });

  const handleUpdateProperty = (propertyKey: string, value: NestedValue | undefined) => {
    const previousRecord = queryClient.getQueryData<D>(key);

    if (!previousRecord) {
      return;
    }

    const currentProperties = isInfinite(previousRecord)
      ? previousRecord.pages.find((page) => page.items.find((item) => item.id === id))?.items.find((item) => item.id === id)?.properties
      : previousRecord?.properties;

    if (!currentProperties) {
      return;
    }

    const newProperties = produce(currentProperties, (draft) => {
      if (value === undefined || value === null) {
        delete draft[propertyKey];
      } else {
        draft[propertyKey] = value;
      }
    });

    if (isEqual(currentProperties, newProperties)) {
      return;
    }

    recordPartialMutation.mutate({ properties: newProperties } as P);
  };

  return handleUpdateProperty;
};
