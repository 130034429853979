import { ChartType, TooltipModel } from 'chart.js';
import { useCallback } from 'react';

import { ReportTypeVO } from '@bigdelta/lib-shared';
import { MetricsListData, ObjectsListData, QueryReportsCreateData } from '@bigdelta/lib-api-client';

import { PointData } from '../../../../shared/reports/view/context/context.ts';
import { Dataset } from '../../types.ts';
import { useReportStore } from '../../store/Report.ts';
import { TooltipDatapointInfo } from './TooltipDatapointInfo.tsx';
import { MetricElement } from '../../../../shared/slate';
import { VariableInputElementType } from '../../../../shared/types.ts';
import { TrendsBuilderState } from '../../store/TrendsBuilder.ts';

type ObjectData = NonNullable<ObjectsListData['objects'][number]>;

interface TooltipContentProps<T extends ChartType> {
  dataQueries?: QueryReportsCreateData['queries'];
  openSidebar: () => void;
  onSetData: (dates: PointData) => void;
  context?: TooltipModel<T>;
  reportType: ReportTypeVO;
  objects: ObjectsListData['objects'];
  metrics: MetricsListData['items'];
}

export const TooltipContent = <T extends ChartType>({
  context,
  reportType,
  objects = [],
  metrics = [],
  onSetData,
  openSidebar,
  dataQueries,
}: TooltipContentProps<T>) => {
  const { report } = useReportStore();
  const trendsBuilders = report.trends.builders;
  const funnelBuilder = report.funnel.builder;

  const dataPoint = context?.dataPoints?.[0];
  const { label } = dataPoint || {};
  const formattedLabel = label?.split('_')[0];

  const handleOpenSidebar = useCallback(
    (objectData: ObjectData | undefined) =>
      ({ queryName, from, to, stage }: PointData) => {
        onSetData({ from, to, stage, queryName, objectData });
        openSidebar();
      },
    [openSidebar, onSetData]
  );

  if (context?.dataPoints?.length === 0) {
    return null;
  }

  const getBuilder = (queryName: string) => {
    if (reportType === ReportTypeVO.CHART) {
      return trendsBuilders.find((builder) => builder.name === queryName);
    }
    if (reportType === ReportTypeVO.FUNNEL) {
      return funnelBuilder;
    }
  };

  const getRelatedObject = (query: QueryReportsCreateData['queries'][number]): ObjectData | undefined => {
    const builder = getBuilder(query?.metadata?.name as string);

    if ((builder as TrendsBuilderState)?.data?.formula) {
      const metricElement = ((builder as TrendsBuilderState).data.formula?.expressionRaw?.[0] as MetricElement).children.find(
        (child) => (child as MetricElement).type === VariableInputElementType.Metric
      ) as MetricElement;
      const metricId = metricElement.metric?.id;
      const metric = metrics.find((metric) => metric.id === metricId);
      const resourceId = metric?.query_configuration?.metric_query?.records?.resource.id;

      return objects.find((object) => object.id === resourceId);
    }

    if ((builder as TrendsBuilderState)?.data.metric) {
      const metricId = (builder as TrendsBuilderState).data.metric?.metricId;
      const metric = metrics.find((metric) => metric.id === metricId);
      const resourceId = metric?.query_configuration?.metric_query?.records?.resource.id;

      return objects.find((object) => object.id === resourceId);
    }

    if (builder?.data.object) {
      return objects.find((object) => object.id === builder?.data?.object?.workspaceObjectId);
    }
  };

  return (
    <div className="flex flex-col items-start justify-start whitespace-nowrap text-start">
      <div>{formattedLabel}</div>
      {context?.dataPoints?.map((dataPoint) => {
        const query = dataQueries?.find((query) => query.id === (dataPoint.dataset as Dataset).label);
        const object = getRelatedObject(query);

        return (
          <TooltipDatapointInfo
            key={dataPoint.datasetIndex}
            reportType={reportType}
            metadata={query?.metadata}
            dataPoint={dataPoint}
            title={`View ${object?.plural_noun || 'records'}`}
            onClick={handleOpenSidebar(object)}
          />
        );
      })}
    </div>
  );
};
