import { useCallback, useMemo, useState } from 'react';
import { ObjectsDetailData, QueryRecordsCreateData } from '@bigdelta/lib-api-client';
import { ColumnHelper, Row } from '@tanstack/react-table';
import { useObjectsQuery } from '../../../../shared/data/useObjectsQuery';
import { DataTableColumnActions } from '../../../../shared/tables/components/DataTableColumnActions';
import { QueryKey, useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../../auth/hooks/useQueryKeys';

import { useWorkspace } from '../../../auth/hooks/useWorkspace';
import { RelationshipEntityType, ResourcePropertyType, TableColumnType, TableResourceType, TrendType } from '@bigdelta/lib-shared';
import { getColumnActions } from './utils/getColumnActions';

import PlusIcon from '../../../../assets/icons/plus.svg?react';
import { REMOTE_ID } from '../../const';
import { CellContent } from '../../../../shared/tables/components/CellContent';
import { getWorkspaceObjectIcon } from '../../../../shared/utils/getWorkspaceObjectIcon';
import { useTableLayout, UseTableLayoutArgs } from '../../../../shared/tables/hooks/useTableLayout';
import { renderValue } from '../../../../shared/tables/utils/renderValue';
import { getRelatedObjectsList } from '../../../../shared/tables/utils/getRelatedObjectsList';
import { suppressConsoleWarn } from '../../../../shared/utils/suppressConsoleWarn';
import { getTableSortEnumVal } from '../../../../shared/tables/utils/getTableSortEnumVal';
import { CellContentRelations } from '../../../../shared/tables/components/CellContentRelations';
import { resolveRelations } from './utils/resolveRelations';
import { renderRelatedRecordColumnHeader } from '../../../../shared/tables/utils/renderRelatedRecordColumnHeader';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient.ts';
import { getUserFriendlyPropertyName } from './utils/getUserFriendlyPropertyName';
import { capitalize, get, last } from 'lodash';
import { ColumnActionType } from '../../../../shared/tables/types.ts';
import { CellContentChart } from '../../../../shared/tables/components/CellContentChart';
import { getTimerangeFromQueryTime } from '../../../reports/utils/trends/getTimerangeFromQueryTime';
import { getSizeProps } from '../../../../shared/tables/utils/getSizeProps';
import { IconButton } from '../../../../shared/components/IconButton.tsx';
import { RecordCreateModal } from '../../components/RecordCreateModal.tsx';
import { Checkbox } from '../../../../shared/ui/Checkbox/Checkbox.tsx';
import { CellContentRelationProperties } from '../../../../shared/tables/components/CellContentRelationProperties';

interface UseTableColumnsArgs {
  columnHelper: ColumnHelper<QueryRecordsCreateData['items'][number]>;
  workspaceObject?: ObjectsDetailData;
  displayProperties?: boolean;
  enableActions?: boolean;
  enableEditing?: boolean;
  enableSelection?: boolean;
  layout: UseTableLayoutArgs['layout'];
  onLayoutChange: UseTableLayoutArgs['onChange'];
  trends?: QueryRecordsCreateData['trends'];
  queryKey?: QueryKey;
  editable?: boolean;
  allowCreate?: boolean;
}

const isRelationProperty = (property: string) => property.includes('.');

export const useRecordsTableColumns = ({
  columnHelper,
  workspaceObject,
  displayProperties = true,
  enableActions = true,
  enableEditing = false,
  enableSelection = false,
  layout,
  trends,
  onLayoutChange,
  queryKey,
  editable = true,
  allowCreate = true,
}: UseTableColumnsArgs) => {
  const { currentWorkspaceId } = useWorkspace();
  const [createRecord, setCreateRecord] = useState(false);

  const queryKeys = useQueryKeys();

  const objectsQuery = useObjectsQuery({ workspaceId: currentWorkspaceId });

  const relationshipsQuery = useQuery({
    queryKey: queryKeys.relationships(),
    queryFn: () => bigdeltaAPIClient.v1.relationshipsList({ workspace_id: currentWorkspaceId }),
  });

  const { onActionSelect, extractRelationshipInfo } = useTableLayout({
    resourceType: TableResourceType.OBJECT,
    resourceId: workspaceObject?.id,
    layout,
    onChange: onLayoutChange,
  });

  const cellLink = useCallback(
    (row: Row<QueryRecordsCreateData['items'][number]>) => ({
      pathname: `/records/${workspaceObject?.api_slug}/${row.original.id as string}`,
    }),
    [workspaceObject?.api_slug]
  );
  const handleCreate = () => {
    setCreateRecord(true);
  };

  const objectId = workspaceObject?.id;
  const objectSlug = workspaceObject?.api_slug;
  const objectWorkspaceId = workspaceObject?.workspace_id;
  const objectType = workspaceObject?.object_type;
  const iconProperty = workspaceObject?.icon_property;

  return useMemo(() => {
    if (!layout || !relationshipsQuery.data || !objectId || !objectsQuery.data) {
      return [];
    }

    const cols = layout.columns
      .map((column, idx) => {
        const columnsCount = layout.columns.length;
        const propertyName = column.property?.property_name;
        const propertyDetails = workspaceObject.properties?.find(({ name }) => name === propertyName);

        if (column.type === TableColumnType.LABEL) {
          return columnHelper.accessor(
            (row) => {
              const labelProperties = workspaceObject.label_properties;

              const firstValidLabelValue = labelProperties.map((property) => row.properties?.[property]).find((v) => v);

              return firstValidLabelValue ?? row.id;
            },
            {
              id: column.id ?? 'label',
              ...getSizeProps(column),
              header: () => (
                <div className="flex w-full items-center justify-between gap-x-2 hover:bg-m-gray-200">
                  {allowCreate && <RecordCreateModal open={createRecord} object={workspaceObject} setOpen={setCreateRecord} listKey={queryKey} />}
                  <DataTableColumnActions
                    resourceId={objectId}
                    resourceType={TableResourceType.OBJECT}
                    onSelect={(action) => onActionSelect(REMOTE_ID, action, column)}
                    actions={getColumnActions(idx, columnsCount, true)}
                    enable={enableActions}
                    sort={getTableSortEnumVal(column.sort)}
                  >
                    {capitalize(workspaceObject.singular_noun)}
                  </DataTableColumnActions>
                  {allowCreate && <IconButton icon={PlusIcon} className="mr-2" onClick={handleCreate} />}
                </div>
              ),
              cell: (info) => {
                const value = info.getValue();

                const leadingIconUrl = iconProperty && info.row.original.properties?.[iconProperty];
                const defaultLeadingIcon = objectType ? getWorkspaceObjectIcon(objectType) : undefined;

                return (
                  <CellContent
                    cell={info.cell}
                    value={renderValue(value ?? null)}
                    type={ResourcePropertyType.STRING}
                    link={cellLink}
                    leadingIcon={defaultLeadingIcon}
                    leadingIconUrl={typeof leadingIconUrl === 'string' ? leadingIconUrl : undefined}
                    className={'font-medium'}
                    disabled={true}
                    editable={false}
                  />
                );
              },
            }
          );
        }

        if (column.type === TableColumnType.PROPERTY) {
          if (!propertyName) {
            return null;
          }

          return columnHelper.accessor(
            (row) => {
              if (propertyName === REMOTE_ID) {
                return row.id;
              }

              return propertyName ? get(row.properties, propertyName) : null;
            },
            {
              id: column.id ?? `${propertyName}-${idx}`,
              ...getSizeProps(column),
              header: () => (
                <DataTableColumnActions
                  resourceId={objectId}
                  resourceType={TableResourceType.OBJECT}
                  onSelect={(action) => onActionSelect(propertyName, action, column)}
                  actions={getColumnActions(idx, columnsCount, false)}
                  enable={enableActions}
                  sort={getTableSortEnumVal(column.sort)}
                >
                  {getUserFriendlyPropertyName(propertyName)}
                </DataTableColumnActions>
              ),
              meta: {
                headerClassName: 'p-0',
              },
              cell: (info) => {
                const valueFn = () => info.getValue();
                let value: ReturnType<typeof valueFn> | undefined;

                suppressConsoleWarn(() => {
                  value = info.getValue();
                });

                const editProps =
                  enableEditing && !isRelationProperty(propertyName) && objectSlug && objectWorkspaceId && info.row.original.id && queryKey
                    ? {
                        objectSlug,
                        queryKey,
                        workspaceId: objectWorkspaceId,
                        valueId: info.row.original.id,
                        propertyName,
                      }
                    : undefined;

                return (
                  <CellContent
                    key={`${column.id}-${info.row.id}`}
                    type={propertyDetails?.type as ResourcePropertyType | undefined}
                    value={value}
                    cell={info.cell}
                    updateParams={editProps}
                    disabled={false}
                    editable={editable}
                  />
                );
              },
            }
          );
        }

        if (column.type === TableColumnType.RELATIONSHIP) {
          const { names: relationshipNames, property } = extractRelationshipInfo(column.relationship);

          const isPropertyId = property?.property_name === REMOTE_ID;

          if (!property) {
            return;
          }

          const relatedObjectList = getRelatedObjectsList(
            relationshipNames,
            RelationshipEntityType.OBJECT,
            objectId,
            relationshipsQuery.data?.relationships,
            objectsQuery.data?.objects
          ).flatMap((o) => (o ? [o] : []));

          if (!relatedObjectList || relatedObjectList.find((object) => !object)) {
            // throw error
            return;
          }

          return columnHelper.accessor(
            (row) => {
              return {
                relations: resolveRelations(row.relationships?.relationship_properties, column.relationship!),
              };
            },
            {
              id: column.id ?? `${JSON.stringify(column)}-${idx}`,
              ...getSizeProps(column),
              header: () => (
                <DataTableColumnActions
                  resourceId={objectId}
                  resourceType={TableResourceType.OBJECT}
                  onSelect={(action) => onActionSelect('', action, column)}
                  actions={getColumnActions(idx, columnsCount, false)}
                  enable={enableActions}
                  sort={getTableSortEnumVal(column.sort)}
                >
                  {renderRelatedRecordColumnHeader(property, relatedObjectList)}
                </DataTableColumnActions>
              ),
              meta: {
                headerClassName: 'p-0',
              },
              cell: (info) => {
                const { relations } = info.getValue();
                const relationObject = last(relatedObjectList);

                if (!relationObject || !relations) {
                  return null;
                }

                if (isPropertyId) {
                  return <CellContentRelations relations={relations} objectList={objectsQuery.data.objects} />;
                }

                return <CellContentRelationProperties property={property} relations={relations} workspaceObject={relationObject} />;
              },
            }
          );
        }

        if (column.type === TableColumnType.TREND) {
          const trendData = column.trend;

          if (!trendData) {
            return;
          }

          const timeData = trendData.time;

          const timerange = timeData && getTimerangeFromQueryTime(timeData);
          const headerLabel = `${trendData.type} (${timerange})`;

          return columnHelper.accessor(
            (row) => {
              return row.id ? trends?.[TrendType.activity]?.[row.id] : null;
            },
            {
              id: column.id ?? TrendType.activity,
              ...getSizeProps(column),
              header: () => (
                <DataTableColumnActions
                  resourceId={objectId}
                  resourceType={TableResourceType.OBJECT}
                  onSelect={(action) => onActionSelect('', action, column)}
                  actions={[ColumnActionType.MOVE_LEFT, ColumnActionType.MOVE_RIGHT]}
                  enable={enableActions}
                >
                  <span className="capitalize">{headerLabel}</span>
                </DataTableColumnActions>
              ),
              cell: (info) => {
                const trendData = info.getValue();

                if (!trendData) {
                  return null;
                }

                return <CellContentChart dataQueries={trendData.queries} />;
              },
            }
          );
        }
      })
      .flatMap((col) => (col ? col : []));

    const addColumn = enableActions
      ? [
          columnHelper.display({
            id: 'add_column',
            header: () => (
              <DataTableColumnActions
                resourceId={objectId}
                resourceType={TableResourceType.OBJECT}
                onSelect={(action) => onActionSelect('', action)}
                actions={[ColumnActionType.ADD_TO_END]}
                defaultAction={ColumnActionType.ADD_TO_END}
                enable={enableActions}
              >
                <PlusIcon className="h-4 w-4" />
              </DataTableColumnActions>
            ),
            ...getSizeProps(),
            enableResizing: false,
            meta: {
              headerClassName: 'p-0',
            },
          }),
        ]
      : [];

    const selectColumn = enableSelection
      ? [
          columnHelper.display({
            id: 'select_column',
            size: 24,
            minSize: 24,
            maxSize: 24,
            header: ({ table }) => {
              const selected = table.getRowModel().rows.map((row) => row.getIsSelected());
              const isAllSelected = selected.every((isSelected) => isSelected) && selected.length > 0;
              const isSomeSelected = selected.some((isSelected) => isSelected);

              return (
                <div className="flex w-full items-center justify-center">
                  <Checkbox
                    checked={isSomeSelected && !isAllSelected ? 'indeterminate' : isAllSelected}
                    onChange={(value) => {
                      table.setRowSelection((_old) => table.getRowModel().rows.reduce((collection, row) => ({ ...collection, [row.id]: value }), {}));
                    }}
                  />
                </div>
              );
            },
            cell: ({ row }) => (
              <div className="flex items-center justify-center">
                <Checkbox checked={row.getIsSelected()} disabled={!row.getCanSelect()} onChange={row.getToggleSelectedHandler()} />
              </div>
            ),
            enableResizing: false,
            meta: {
              headerClassName: 'p-0',
            },
          }),
        ]
      : [];

    const allColumns = [...selectColumn, ...cols, ...addColumn];

    return displayProperties ? allColumns : allColumns.filter((col) => col.id === 'label');
  }, [
    layout,
    relationshipsQuery.data,
    objectId,
    objectsQuery.data,
    enableActions,
    columnHelper,
    enableSelection,
    displayProperties,
    workspaceObject,
    allowCreate,
    createRecord,
    queryKey,
    onActionSelect,
    iconProperty,
    objectType,
    cellLink,
    enableEditing,
    objectSlug,
    objectWorkspaceId,
    editable,
    extractRelationshipInfo,
    trends,
  ]);
};
