import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { PointData, ReportViewContext } from '../../../shared/reports/view/context/context.ts';
import { AttributesValue, ReportViewMode } from '../types.ts';

import { ReportTypeVO, TimeGranularity } from '@bigdelta/lib-shared';
import { useReportQuery } from '../../../shared/data/useReportQuery.ts';
import { SaveReport } from '../components/SaveReport.tsx';
import { ReportConfig } from '../components/ReportConfig.tsx';
import { useReportStore } from '../store/Report.ts';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';

import { QueryReportsCreatePayload } from '@bigdelta/lib-api-client';
import { ReportChartView } from '../components/ReportChartView.tsx';
import { ReportHeading } from '../components/ReportHeading.tsx';
import { getReportQueryRequestMetrics } from '../utils/trends/getReportQueryRequestMetrics';
import { GranularitySelect } from '../components/trends/GranularitySelect.tsx';
import { Timerange } from '../components/trends/Timerange.tsx';
import { getQueryTimeFromTimerange } from '../utils/trends/getQueryTimeFromTimerange.ts';
import { Filter, FilterItem, getFilter, useFilterStore } from '../../../shared/filters/store/index.ts';
import { transformSavedReportToState } from '../utils/trends/transformSavedReportToState/index';
import { Divider } from '../../../shared/ui/Divider/Divider';
import { Container } from '../../../shared/ui/Container/Container';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';
import { Link } from '../../../shared/ui/Link/Link.tsx';

// TODO: Update when a real funnel report article is available
const FUNNEL_REPORTS_HELP_URL = 'https://help.bigdelta.com/en/collections/10202118-reports-and-dashboards';

export const ReportPage = () => {
  const { reset, addTrendsBuilder, ...state } = useReportStore();
  const [pointData, setPointData] = useState<PointData | null>(null);
  const queryKeys = useQueryKeys();
  const { currentWorkspaceId } = useWorkspace();

  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId }),
  });

  const trendsBuilderIds = useMemo(() => {
    return state.report.trends.builders.map((builder) => builder.id);
  }, [state.report.trends.builders]);

  const trendsBuildersFilters = useFilterStore((filterState) => {
    return trendsBuilderIds.reduce<Record<string, Filter | null>>((result, builderId) => {
      return {
        ...result,
        [builderId]: getFilter(filterState, ['reports', builderId]),
      };
    }, {});
  });

  const funnelSteps = useFilterStore((filterState) => {
    const workspaceObjectId = state?.report.funnel.builder?.data.object?.workspaceObjectId;

    const object = objectsQuery.data?.objects?.find((object) => object.id === workspaceObjectId);

    if (!object) {
      return null;
    }
    return getFilter(filterState, ['funnel', object.api_slug])?.items || [];
  });

  const setFilter = useFilterStore((state) => state.setFilter);

  const { reportId } = useParams();
  const [highlightedElement, setHighlightedElement] = useState<AttributesValue | null>(null);

  const savedReport = useReportQuery({ reportId });

  const relationshipsQuery = useQuery({
    queryKey: queryKeys.list('relationship'),
    queryFn: () => bigdeltaAPIClient.v1.relationshipsList({ workspace_id: currentWorkspaceId }),
  });

  // TODO: move into a hook
  const hydrateReportsStoreTrends = useCallback(() => {
    if (!savedReport.data || !reportId || !objectsQuery.data || !relationshipsQuery.data) {
      return;
    }

    const { reportData, builderFilterMap, builders } = transformSavedReportToState(
      savedReport.data,
      currentWorkspaceId,
      relationshipsQuery.data.relationships,
      objectsQuery.data.objects
    );

    if (reportData) {
      reset(reportData);
      Object.entries(builderFilterMap).forEach(([builderId, { operator, items }]) => {
        setFilter(['reports', builderId], { items, operator });
      });
      // TODO: Create an action to insert multiple builders
      builders.forEach((builder) => {
        addTrendsBuilder(builder);
      });
    }
  }, [addTrendsBuilder, currentWorkspaceId, objectsQuery.data, relationshipsQuery.data, reportId, reset, savedReport.data, setFilter]);

  useEffect(() => {
    if (savedReport.data?.type === ReportTypeVO.CHART) {
      hydrateReportsStoreTrends();
    }
  }, [hydrateReportsStoreTrends, savedReport.data?.type]);

  const getFunnelFilterConditions = (step: FilterItem) => {
    return step?.items?.length && step?.items.some(({ data }) => !!data.value)
      ? {
          filter: {
            operator: 'and',
            conditions: step.items.map(({ property, propertyOperator, data }) => ({
              event_property: {
                operator: propertyOperator,
                name: property?.attributeName,
                value: data.value,
                property_type: property?.attributeType,
                property_id: property?.attributeId,
              },
            })),
          },
        }
      : {};
  };

  const generateMetrics = (embedMetrics: boolean): QueryReportsCreatePayload['metrics'] => {
    if (state.reportType === ReportTypeVO.FUNNEL) {
      const funnelObject = state?.report.funnel.builder?.data.object;
      const relationship_name = funnelSteps?.[0]?.propertyRelationships[0]?.relationshipName;

      return [
        {
          funnel: {
            relationship_name,
            // TODO: add object filters
            // filter?: RecordQueryFiltersRequestDef,
            sequence_completion_window: funnelObject?.conversionWindow,
            event_sequence: funnelSteps?.map((step) => ({
              event_name: { name: step.event },
              ...getFunnelFilterConditions(step),
            })),
          },
        },
      ] as QueryReportsCreatePayload['metrics'];
    } else if (state.reportType === ReportTypeVO.CHART) {
      return getReportQueryRequestMetrics(state, relationshipsQuery.data?.relationships, embedMetrics, trendsBuildersFilters);
    }
    return [];
  };
  const getPayload = (embedMetrics: boolean): QueryReportsCreatePayload | undefined => {
    if (!state.display.timerange) {
      return;
    }

    return {
      display_options: {
        chart_type: 'line',
        time_granularity: state.display.granularity ?? undefined,
      },
      metrics: generateMetrics(embedMetrics),
      time: getQueryTimeFromTimerange(state.display.timerange),
    };
  };

  // TODO: validate
  const queryPayload = getPayload(true);
  const savePayload = getPayload(false);
  const getIsQueryEnabled = () => {
    if (!currentWorkspaceId || !queryPayload || !relationshipsQuery?.data) {
      return false;
    }

    if (state.reportType === ReportTypeVO.CHART) {
      return true;
    }
    if (state.reportType === ReportTypeVO.FUNNEL) {
      return (funnelSteps?.length ?? 0) > 1;
    }

    return false;
  };

  const reportQuery = useQuery({
    queryKey: queryKeys.withWorkspace('report', 'query', queryPayload),
    queryFn: () => {
      if (!queryPayload) {
        throw new Error('Query payload error');
      }

      return bigdeltaAPIClient.v1.queryReportsCreate({ workspace_id: currentWorkspaceId! }, queryPayload);
    },
    enabled: getIsQueryEnabled(),
  });

  // TODO: if id not null, show savedReportQuery loading

  const reportTitle = reportId ? state.title : 'New Report';
  const getEmptyMessage = () => {
    if (state.reportType === ReportTypeVO.FUNNEL) {
      return (
        <div className="flex flex-col items-center justify-center gap-y-2">
          <p>Select at least two events as funnel steps to get started.</p>
          <p>
            <Link target="_blank" to={FUNNEL_REPORTS_HELP_URL}>
              Learn more
            </Link>{' '}
            more about funnel reports.
          </p>
        </div>
      );
    }
  };

  return (
    <ReportViewContext.Provider value={{ highlightedElement, setHighlightedElement, reportQuery: queryPayload, pointData, setPointData }}>
      <Container className="flex items-center justify-between">
        <ReportHeading reportId={reportId} reportTitle={reportTitle} />
        {state.reportType && savePayload && (
          <SaveReport reportType={state.reportType} reportQueryRequest={savePayload} reportTemplateQueryRequest={undefined} />
        )}
      </Container>
      <Divider />
      <Container className="shrink grow pb-6">
        <div className="flex h-full w-full gap-x-6 overflow-hidden">
          <div className="flex max-h-full w-[22rem] shrink-0 flex-col overflow-y-auto overflow-x-hidden pt-2">
            <ReportConfig reportQuery={reportQuery} />
          </div>
          <div className="w-px bg-m-gray-300" />
          <div className="flex w-full min-w-0 grow flex-col gap-4 pt-6">
            {state.reportType && (
              <div className="flex max-h-full grow flex-col gap-y-8">
                {state.reportType !== ReportTypeVO.FUNNEL && (
                  <div className="flex items-center justify-between">
                    <Timerange />
                    <GranularitySelect />
                  </div>
                )}
                {state.display.visualization && (
                  <ReportChartView
                    reportType={state.reportType}
                    chartType={state.display.visualization}
                    reportViewMode={ReportViewMode.CHART}
                    emptyMessage={getEmptyMessage()}
                    reportQuery={reportQuery}
                    isHasSource={true}
                    timeGranularity={TimeGranularity.DAY}
                    className="relative min-h-0 shrink grow"
                    breakdownSelectedValues={[]}
                    breakdownSelectedValuesColorMap={{}}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </Container>
    </ReportViewContext.Provider>
  );
};
