import { NestedObjectDef } from '@bigdelta/lib-api-client';
import { FC } from 'react';
import { twMerge } from '../../utils/twMerge';
import { PropsWithClassName } from '../../types';

interface IconFromUrlProps extends PropsWithClassName {
  iconUrl?: NestedObjectDef[string];
  size?: number;
}

const IconFromUrl: FC<IconFromUrlProps> = ({ iconUrl, className }) => {
  if (!iconUrl || typeof iconUrl !== 'string') {
    return null;
  }

  return (
    <div className={twMerge('flex h-4 w-4 shrink-0 items-center justify-center overflow-hidden rounded-sm', className)}>
      <img src={iconUrl} className="w-full shrink-0" alt="Icon" />
    </div>
  );
};

export default IconFromUrl;
