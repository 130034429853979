import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Control, useForm } from 'react-hook-form';
import capitalize from 'lodash/capitalize';

import {
  ObjectsDetailData,
  ObjectsRecordsCreatePayload,
  ObjectsRecordsPartialUpdateData,
  WorkspaceObjectPropertiesSchemaDef,
} from '@bigdelta/lib-api-client';
import { InfiniteData, QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { Key } from 'ts-key-enum';

import { Button } from '../../../shared/ui/Button/Button';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient';
import { useOutsideClickHandler } from '../../../shared/hooks/useOutsideClickHandler';
import { REMOTE_ID } from '../const';
import { toastError } from '../../../utils/toast';
import { FormInput } from '../../../shared/ui/FormInput/FormInput';
import { FormTagsInput } from '../../../shared/ui/FormTagsInput/FormTagsInput';
import { FormCheckbox } from '../../../shared/ui/FormCheckbox/FormCheckbox';
import { useKeyboard } from '../../tree/hooks/useKeyboard';
import { produce } from 'immer';

interface CreateRecordModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  object: ObjectsDetailData;
  listKey: QueryKey;
}

type ListData = InfiniteData<{ items: ObjectsRecordsPartialUpdateData[]; total: number }>;

const formatLabel = (label: string) => capitalize(label.replace(/_/g, ' '));

export const RecordCreateModal: React.FC<CreateRecordModalProps> = ({ open, object, setOpen, listKey }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useOutsideClickHandler(ref, () => setOpen(false));
  useKeyboard([Key.Escape], () => setOpen(false));

  const createRecord = useMutation({
    mutationFn: (payload: ObjectsRecordsCreatePayload) => {
      return bigdeltaAPIClient.v1.objectsRecordsCreate(object.api_slug, { workspace_id: object.workspace_id }, payload);
    },
    onMutate: async (payload) => {
      const previousRecord = queryClient.getQueryData<ListData>(listKey);

      queryClient.setQueryData<ListData>(listKey, (old) => {
        const [firstPage, ...otherPages] = old.pages;
        const firstPageCopy = { ...firstPage };
        const date = new Date().toISOString();

        // insert with faked data
        firstPageCopy.items.unshift({ id: uuidv4(), system_id: uuidv4(), created_at: date, updated_at: date, properties: payload.properties });
        firstPageCopy.total += 1;

        return {
          ...old,
          pages: [firstPageCopy, ...otherPages],
        };
      });

      return { previousRecord };
    },

    onError: (_err, _payload, context) => {
      if (context?.previousRecord) {
        queryClient.setQueryData(listKey, context.previousRecord);
      }
      toastError('Failed to create a record');
    },
    onSettled: (data) => {
      setOpen(false);

      queryClient.setQueryData<ListData>(listKey, (old) => {
        const [firstPage, ...otherPages] = old.pages;
        const firstPageCopy = { ...firstPage };
        const recordIndex = firstPageCopy.items.findIndex((item) => item.properties[REMOTE_ID] === data?.properties[REMOTE_ID]);
        firstPageCopy.items[recordIndex] = data;

        return {
          ...old,
          pages: [firstPageCopy, ...otherPages],
        };
      });
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (properties: ObjectsRecordsCreatePayload['properties']) => {
    const id = properties.id ? String(properties.id) : uuidv4();

    createRecord.mutate({
      id,
      properties: produce(properties, (draft) => {
        if (properties[REMOTE_ID]) {
          delete draft[REMOTE_ID];
        }
      }),
    });
  };

  const getInput = ({ type, name, required }: WorkspaceObjectPropertiesSchemaDef, control: Control) => {
    const errorMessage = errors[name]?.message as string;

    switch (type) {
      case 'string':
        return <FormInput label={formatLabel(name)} inputProps={{ ...register(name, { required }) }} errorMessage={errorMessage} />;
      case 'string[]':
        return <FormTagsInput label={formatLabel(name)} name={name} control={control} errorMessage={errorMessage} />;
      case 'number':
        return <FormInput label={formatLabel(name)} inputProps={{ ...register(name, { required }), type: 'number' }} errorMessage={errorMessage} />;
      case 'boolean':
        return <FormCheckbox name={name} control={control} required={required} label={formatLabel(name)} errorMessage={errorMessage} />;
      default:
        return <FormInput label={formatLabel(name)} inputProps={{ ...register(name, { required }) }} errorMessage={errorMessage} />;
    }
  };

  return (
    <Dialog.Root open={open} defaultOpen={false}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-50 bg-m-black bg-opacity-50 backdrop-blur-sm" />
        <Dialog.Content
          ref={ref}
          className="fixed left-1/2 top-1/2 z-50 w-full max-w-2xl -translate-x-1/2 -translate-y-1/2 transform rounded-lg bg-m-white p-8 shadow-lg"
        >
          <Dialog.Title className="mb-6 text-center text-display-sm text-m-olive-800">Create new {object.singular_noun}</Dialog.Title>
          <Dialog.Description className="mb-8 text-md text-m-olive-600">Enter {object.singular_noun} details</Dialog.Description>
          <form onSubmit={handleSubmit(handleSave)} className="flex flex-col gap-y-4">
            {object.properties
              ?.filter(({ show_in_record_creation_flow }) => show_in_record_creation_flow)
              .map((property) => {
                const input = getInput(property, control);
                return (
                  <div key={property.name} className="flex w-full items-center">
                    {input}
                  </div>
                );
              })}
            <div className="flex w-full justify-between pt-6">
              <Button type="submit" intent="brand" size="lg" label="Save" />
              <Button onClick={handleClose} intent="secondary" size="lg" label="Cancel" />
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
