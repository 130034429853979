import { MetricMath, NestedQueryFilterOperator, QueryValueFilterOperator, RelationshipEntityType, ResourcePropertyType } from '@bigdelta/lib-shared';
import { ReportsQueryEvents } from '../../../types';

import { RelationshipsListData, ReportsQueryRequestDef } from '@bigdelta/lib-api-client';
import { TrendsBuilderState, TrendsBuilderType } from '../../../store/TrendsBuilder';
import { Filter, FilterItemType } from '../../../../../shared/filters/store';
import { getConditions } from '../../../../records/data/getRecordsQuery';
import { isEventCount, ObjectCountType } from '../../../store/common';
import { REMOTE_ID } from '../../../../records/const';
import { getPropertyCondition } from '../../../../../shared/filters/utils/getPropertyCondition';

const getEventCountFilterConditions = (filter: Filter | null) => {
  const countEventFilterItems = filter?.items.filter((item) => item.itemType === FilterItemType.TRENDS_COUNT_EVENT_PROPERTY);
  return countEventFilterItems?.map((item) => ({ event_property: getPropertyCondition(item) })).flatMap((cond) => (cond ? [cond] : [])) ?? [];
};

export const getQueryMetricEvent = (
  builder: TrendsBuilderState,
  relationships: RelationshipsListData['relationships'] | undefined,
  filters: { [key: string]: Filter | null } | null
): ReportsQueryRequestDef['metrics'][number] | null => {
  const getObjectEventRelationship = (workspaceObjectId: string) => {
    if (!relationships) {
      return;
    }

    return relationships.find((relationship) => {
      if (relationship.first_entity_type === RelationshipEntityType.EVENT && relationship.second_entity_id === workspaceObjectId) {
        return relationship;
      }
      if (relationship.second_entity_type === RelationshipEntityType.EVENT && relationship.first_entity_id === workspaceObjectId) {
        return relationship;
      }
      return;
    });
  };

  const getDefaultQueryMetricEventRelatedRecordCondition = (relationshipName: string) => ({
    related_records: {
      relationship_name: relationshipName,
      primary: true,
      filter: {
        operator: NestedQueryFilterOperator.AND,
        conditions: [
          {
            record_property: {
              name: REMOTE_ID,
              operator: QueryValueFilterOperator.IS_SET,
            },
          },
        ],
      },
    },
  });

  const getQueryMetricEventAllEventsData = () => {
    let math: MetricMath | undefined;
    let mathTarget: ReportsQueryEvents['math_target'];
    const conditions: NonNullable<ReportsQueryEvents['filter']>['conditions'] = [];

    if (builderData?.count.type !== ObjectCountType.EVENT) {
      return null;
    }

    if (!builderData?.workspaceObjectId) {
      return null;
    }

    if (builderData.count.aggregate === MetricMath.TOTAL) {
      math = MetricMath.TOTAL;
    }

    if (builderData.count.aggregate === MetricMath.DISTINCT_TOTAL) {
      const relationship = getObjectEventRelationship(builderData.workspaceObjectId);

      if (!relationship) {
        return null;
      }

      math = MetricMath.DISTINCT_TOTAL;
      mathTarget = {
        related_records: {
          relationship_name: relationship.name,
        },
      };
    }

    return {
      math,
      mathTarget,
      conditions,
    };
  };

  const getQueryMetricEventEventData = () => {
    let math: MetricMath | undefined;
    let mathTarget: ReportsQueryEvents['math_target'];
    let conditions: NonNullable<ReportsQueryEvents['filter']>['conditions'] = [];
    const event = isEventCount(builderData?.count) ? builderData?.count.event : null;

    if (!builderData?.workspaceObjectId || !event?.data.event) {
      return null;
    }

    if (!event.data.event) {
      return null;
    }

    if (builderData.count.aggregate === MetricMath.TOTAL) {
      math = MetricMath.TOTAL;
    }

    if (builderData.count.aggregate === MetricMath.DISTINCT_TOTAL) {
      const relationship = getObjectEventRelationship(builderData.workspaceObjectId);

      if (!relationship) {
        return null;
      }

      math = MetricMath.DISTINCT_TOTAL;
      mathTarget = {
        related_records: {
          relationship_name: relationship.name,
        },
      };
    }

    conditions = [
      {
        event_name: {
          operator: QueryValueFilterOperator.EQUALS,
          value: event.data.event,
        },
      },
    ];

    return {
      math,
      mathTarget,
      conditions,
    };
  };

  const getQueryMetricEventPropertyData = () => {
    const event = isEventCount(builderData?.count) ? builderData?.count.event : null;

    if (!event) {
      return null;
    }

    let conditions: NonNullable<ReportsQueryEvents['filter']>['conditions'] = [];

    if (!event.data.property || !event.data.property.event) {
      return null;
    }

    const math: MetricMath | undefined = builderData?.count.aggregate;
    const mathTarget: ReportsQueryEvents['math_target'] = {
      event_property: {
        name: event.data.property.property.property_name,
        property_type: event.data.property.property.property_type as ResourcePropertyType,
        top_level_property_type: event.data.property.property.top_level_property_type as ResourcePropertyType,
      },
    };

    conditions = [
      {
        event_name: {
          operator: QueryValueFilterOperator.EQUALS,
          value: event.data.property.event,
        },
      },
    ];

    return {
      math,
      mathTarget,
      conditions,
    };
  };

  const builderData = builder.data[TrendsBuilderType.OBJECT];

  if (!builderData?.workspaceObjectId) {
    return null;
  }

  const relationship = getObjectEventRelationship(builderData.workspaceObjectId);

  if (!relationship) {
    return null;
  }

  if (builderData.count.type !== ObjectCountType.EVENT) {
    return null;
  }

  let data:
    | ReturnType<typeof getQueryMetricEventAllEventsData>
    | ReturnType<typeof getQueryMetricEventEventData>
    | ReturnType<typeof getQueryMetricEventPropertyData>;

  if (builderData.count.event?.type === 'allEvents') {
    data = getQueryMetricEventAllEventsData();
  }

  if (builderData.count.event?.type === 'event') {
    data = getQueryMetricEventEventData();
  }

  if (builderData.count.event?.type === 'property') {
    data = getQueryMetricEventPropertyData();
  }

  if (!data!) {
    return null;
  }

  const filter = filters?.[builder.id] ?? null;

  const recordFilterConditions = getConditions(filter);
  const countPropertyConditions = getEventCountFilterConditions(filter);

  /* 
  filter.conditions should always have the length of 2: 
    [0] - conditions to determine the parent object, and the event needed for counting - [0][0] - object relationship filter, [0][1] - event name filter, [0][2] - event properties filter conditions
    [1] - regular filter conditions (cohort filter)
  */
  return {
    events: {
      math: data.math ?? MetricMath.TOTAL,
      math_target: data.mathTarget,
      filter: {
        operator: NestedQueryFilterOperator.AND,
        conditions: [
          {
            operator: NestedQueryFilterOperator.AND,
            conditions: [
              // Condition to relate it to the parent object
              {
                operator: NestedQueryFilterOperator.AND,
                conditions: [getDefaultQueryMetricEventRelatedRecordCondition(relationship.name)],
              },
              // Condition to filter the event by name (if particular event is selected in 'count')
              {
                operator: NestedQueryFilterOperator.AND,
                conditions: data.conditions,
              },
              // Condition with nested property conditions
              {
                operator: NestedQueryFilterOperator.AND,
                conditions: countPropertyConditions,
              },
            ],
            // conditions: [getDefaultQueryMetricEventRelatedRecordCondition(relationship.name), ...data.conditions],
          },
          {
            related_records: {
              relationship_name: relationship.name,
              filter: {
                operator: NestedQueryFilterOperator.AND,
                conditions: recordFilterConditions,
              },
            },
          },
          // TODO: add filter conditions here
        ],
      },
    },
    query_name: builder.label,
    name: builder.name,
  };
};
