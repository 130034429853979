import React, { useState } from 'react';
import { TextEdit } from '../../properties/Text/TextEdit';
import { TextView } from '../../properties/Text/TextView';
import { useShowContentClick } from '../../hooks/useShowContentClick';
import { FloatingPortal } from '@floating-ui/react';
import { twMerge } from '../../../utils/twMerge';
import { type TextProps } from '../../properties/Text/Text';
import { PropsWithClassName } from '../../../types';

interface CellContentTextProps extends TextProps, PropsWithClassName {
  disabled: boolean;
  editable: boolean;
}

export const CellContentText: React.FC<CellContentTextProps> = ({ value, className, overflowVisible = false, onChange, disabled, editable }) => {
  const [textValue, setTextValue] = useState(value ?? '');
  const { floatingPortalContentProps, referenceProps, forceClose, isOpen } = useShowContentClick({ disabled });
  floatingPortalContentProps.className = twMerge(
    floatingPortalContentProps.className,
    'flex h-fit w-full rounded-md focus-within:ring-2 focus-within:ring-m-blue-400 hover:bg-m-gray-300'
  );

  const handleSave = (newValue: string) => {
    setTextValue(newValue);
    forceClose();
    onChange?.(newValue);
  };

  return (
    <>
      <div {...referenceProps} className={twMerge('flex h-full w-full cursor-text items-center px-2', className)}>
        <TextView value={textValue} className="grow" overflowVisible={overflowVisible} />
      </div>
      {isOpen && (
        <FloatingPortal>
          <div {...floatingPortalContentProps}>
            <TextEdit value={textValue} onSave={handleSave} onCancel={forceClose} className="text-sm" editable={editable} />
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
