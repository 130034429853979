import { EventsMath, ReportSourceType, useReportStore } from '../store';
import { ReportCreateSelectedMetricContainer } from './ReportCreateSelectedMetricContainer';
import { Popover } from '@headlessui/react';
import { EventsCombobox } from '../../../shared/components/EventsCombobox';
import { MetadataEventsListData } from '@bigdelta/lib-api-client';
import EventIcon from '../../../assets/icons/cursor-click-01.svg?react';
import { OpenTrigger } from '../../../shared/components/OpenTrigger';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { useFilterStore } from '../../../shared/filters/store';
import { MetricEventsMath } from './MetricEventsMath';
import { RelationshipEntityType } from '@bigdelta/lib-shared';
import { RelatedObjectSelect } from '../../../shared/components/RelatedObjectSelect';
import { EventPropertySelect } from './EventPropertySelect';
import { ReportCreateFilterEvents } from './ReportCreateFilterEvents';
import { FloatWrapper } from './FloatWrapper.tsx';
import { useReportMetric } from '../../auth/hooks/useReportMetric';
import { twMerge } from 'tailwind-merge';
import { FormulaMetricReference } from './FormulaMetricReference.tsx';
import { tracking, TrackingEvent } from '../../../tracking';

export const ReportCreateSelectedMetricEvents = () => {
  const { inline, filterKey, onRemoveMetric } = useReportMetric();
  const { currentWorkspaceId } = useWorkspace();
  const {
    setEventMathRelatedObject,
    setMetricEvent,
    clearMetric,
    config: { eventMathRelatedObject, eventMath, metricEvent },
    id: reportId,
  } = useReportStore()((state) => state);

  const clearFilter = useFilterStore((state) => state.clearFilter);

  const handleEventChange = (data: MetadataEventsListData['items'][number] | null) => {
    tracking.track(TrackingEvent.ReportDataSelected, {
      'report id': reportId,
      'data type': ReportSourceType.EVENTS,
      'data name': data?.event_name,
    });
    setMetricEvent(data?.event_name);
    clearFilter(filterKey);
  };

  const handleRemoveMetric = () => {
    clearMetric();
    clearFilter(filterKey);
    onRemoveMetric && onRemoveMetric();
  };

  const shouldShowObjectSelect = eventMath === EventsMath.DISTINCT_RECORDS;
  const shouldShowEventPropertySelect =
    eventMath === EventsMath.AGGREGATE_AVERAGE ||
    eventMath === EventsMath.AGGREGATE_DISTINCT_COUNT ||
    eventMath === EventsMath.AGGREGATE_MEDIAN ||
    eventMath === EventsMath.AGGREGATE_NPS ||
    eventMath === EventsMath.AGGREGATE_SUM;

  return (
    <ReportCreateSelectedMetricContainer
      onRemove={handleRemoveMetric}
      filterKey={filterKey}
      source={
        <Popover className="flex-1">
          {({ close }) => (
            <FloatWrapper portal adaptiveWidth offset={2} inline={inline}>
              <Popover.Button className="flex-start flex w-full items-center gap-x-2 rounded p-2 text-sm hover:bg-m-gray-200">
                <FormulaMetricReference />
                <OpenTrigger shouldOpen={!metricEvent} />
                <EventIcon className="h-5 w-5 text-m-blue-600" />
                {!metricEvent && <span className="text-m-olive-600">Select event</span>}
                {metricEvent && <span className="text-m-olive-900">{metricEvent}</span>}
              </Popover.Button>
              <Popover.Panel>
                <EventsCombobox
                  workspaceId={currentWorkspaceId}
                  value={undefined}
                  onChange={handleEventChange}
                  close={close}
                  includeAny
                  className={twMerge(inline && 'mt-2')}
                />
              </Popover.Panel>
            </FloatWrapper>
          )}
        </Popover>
      }
    >
      <hr className="h-px bg-m-gray-300" />
      <div className="p-2">
        <div className="flex items-center gap-x-2">
          <MetricEventsMath />
          {shouldShowObjectSelect && (
            <RelatedObjectSelect
              workspaceId={currentWorkspaceId}
              onChange={setEventMathRelatedObject}
              value={eventMathRelatedObject}
              parentEntityType={RelationshipEntityType.EVENT}
              parentEntityWorkspaceId={currentWorkspaceId}
              labelPrefix=""
              className="ml-0 flex items-center gap-x-1.5 rounded p-0.5 pl-1.5 text-sm text-m-olive-400 hover:bg-m-gray-300"
            />
          )}
          {shouldShowEventPropertySelect && <EventPropertySelect />}
        </div>
      </div>
      {metricEvent ? (
        <>
          <hr className="h-px bg-m-gray-300" />
          <div className="pb-2">
            <ReportCreateFilterEvents filterKey={filterKey} />
          </div>
        </>
      ) : (
        <></>
      )}
    </ReportCreateSelectedMetricContainer>
  );
};
