import { ObjectsDetailData, ObjectsRecordDetailData, ObjectsRecordsChangesDetailData } from '@bigdelta/lib-api-client';
import { FC, useState } from 'react';
import * as Feed from '../../../shared/ui/Feed/Feed';
import { ActionType, AuthorType, ResourcePropertyType } from '@bigdelta/lib-shared';

import PencilIcon from '../../../assets/icons/pencil-01.svg?react';
import PlusIcon from '../../../assets/icons/plus.svg?react';
import TrashIcon from '../../../assets/icons/trash-01.svg?react';
import { format, formatDistanceToNow } from 'date-fns';
import { PropertyName } from './PropertyName';
import { AddedValue, RemovedValue, UpdatedValue } from './ChangedValue';
import { getRecordLabel } from '../../../shared/utils/getRecordLabel';
import { RecordIcon } from '../../../shared/components/RecordIcon';
import { Tooltip } from '../../../shared/ui/Tooltip/Tooltip';

type ModifiedBy = ObjectsRecordsChangesDetailData[number]['modified_by'];

const getAuthorName = (author: ModifiedBy) => {
  switch (author.type) {
    case AuthorType.member:
      return author.member ? `${author.member.first_name} ${author.member.last_name}` : 'Unknown Member';
    case AuthorType.tracking_key:
      return author.tracking_key ? `${author.tracking_key.name}` : 'Unknown Tracking Key';
    case AuthorType.api_key:
      return author.api_key ? `${author.api_key.name}` : 'Unknown API Key';
    case AuthorType.integration:
      return author.integration ? `${author.integration.type}` : 'Unknown Integration';
    case AuthorType.automation:
      return author.automation ? `${author.automation.name}` : 'Unknown Automation';
    case AuthorType.system:
      return 'System';
    default:
      return 'Unknown';
  }
};

const getIcon = (changeType: string) => {
  switch (changeType) {
    case 'created':
      return <PlusIcon className="h-3 w-3" />;
    case 'updated':
      return <PencilIcon className="h-3 w-3" />;
    case 'archived':
      return <TrashIcon className="h-3 w-3" />;
  }
};

interface AuthorTypeBadgeProps {
  type: AuthorType;
}

const AuthorTypeBadge: FC<AuthorTypeBadgeProps> = ({ type }) => {
  if (type === AuthorType.member) return null;
  return <span className="text-sm font-medium uppercase leading-5 text-m-gray-500">{type.replace('_', ' ')}</span>;
};

interface AuthorNameBadgeProps {
  author: ModifiedBy;
}

const AuthorNameBadge: FC<AuthorNameBadgeProps> = ({ author }) => {
  const getIconUrl = () => {
    if (author.type === AuthorType.member && author.member) {
      return author.member.photo_image_url;
    }
    return null;
  };

  const getInitial = () => {
    if (author.type === AuthorType.member && author.member && author.member.email) {
      return author.member.email[0].toUpperCase();
    }
    return null;
  };

  const iconUrl = getIconUrl();

  return (
    <div className="flex items-center gap-x-1 rounded-md border border-m-olive-100 px-1 py-0.5 text-m-olive-600">
      {author.type === AuthorType.member && (
        <div className="flex-shrink-0">
          {iconUrl ? (
            <img src={iconUrl} alt="" className="h-4 w-4 rounded-full" />
          ) : (
            <div className="flex h-4 w-4 items-center justify-center rounded-full bg-m-blue-500 text-xxs font-medium text-white">{getInitial()}</div>
          )}
        </div>
      )}
      <span className="truncate">{getAuthorName(author)}</span>
    </div>
  );
};

interface AuthorBadgeProps {
  author: ModifiedBy;
}

const AuthorBadge: FC<AuthorBadgeProps> = ({ author }) => {
  const showNameBadge = [AuthorType.member, AuthorType.tracking_key, AuthorType.api_key, AuthorType.integration, AuthorType.automation];

  return (
    <div className="flex items-center gap-x-1">
      <AuthorTypeBadge type={author.type as AuthorType} />
      {showNameBadge.includes(author.type as AuthorType) && <AuthorNameBadge author={author} />}
    </div>
  );
};

interface ChangeItemProps {
  change: ObjectsRecordsChangesDetailData[number];
  object: ObjectsDetailData;
  record: ObjectsRecordDetailData;
}

interface ChangeTypeDisplayProps {
  changeType: string;
  changedPropertiesCount: number;
  object: ObjectsDetailData;
  record: ObjectsRecordDetailData;
}

const ChangeTypeDisplay: React.FC<ChangeTypeDisplayProps> = ({ changeType, changedPropertiesCount, object, record }) => {
  const getUpdatedText = () => {
    if (changeType === 'updated' && changedPropertiesCount > 0) {
      return ` ${changedPropertiesCount} ${changedPropertiesCount === 1 ? 'property' : 'properties'}`;
    }
    return '';
  };

  const getCreatedText = () => {
    if (changeType === 'created') {
      return (
        <span className="flex items-center gap-x-1 font-medium">
          <RecordIcon object={object} record={record} />
          {getRecordLabel(object.label_properties, record)}
        </span>
      );
    }
    return null;
  };

  return (
    <span className="flex items-center gap-x-1.5 text-m-olive-600">
      {changeType}
      {getUpdatedText()}
      {getCreatedText()}
    </span>
  );
};

// New component for the Show/Hide changes button
interface ToggleChangesButtonProps {
  showProperties: boolean;
  setShowProperties: (show: boolean) => void;
  changedPropertiesCount: number;
}

const ToggleChangesButton: FC<ToggleChangesButtonProps> = ({ showProperties, setShowProperties, changedPropertiesCount }) => {
  if (changedPropertiesCount === 0) return null;

  return (
    <button className="text-md text-m-blue-600" onClick={() => setShowProperties(!showProperties)}>
      {showProperties ? 'Hide changes' : 'Show changes'}
    </button>
  );
};

export const ChangeItem: FC<ChangeItemProps> = ({ change, object, record }) => {
  const [showProperties, setShowProperties] = useState(false);

  const getChangedPropertiesCount = () => {
    if (change.type === 'updated') {
      return Object.keys(change.properties).length;
    }
    return 0;
  };

  const changedPropertiesCount = getChangedPropertiesCount();

  const getPropertyType = (propertyName: string): ResourcePropertyType | undefined => {
    return object.properties?.find(({ name }) => name === propertyName)?.type as ResourcePropertyType | undefined;
  };

  return (
    <Feed.Item>
      <Feed.ItemIcon icon={getIcon(change.type)} />
      <Feed.ItemContent className="flex grow flex-col items-start gap-y-2">
        <Feed.ItemTitle className="gap-x-1.5">
          <AuthorBadge author={change.modified_by} />
          <ChangeTypeDisplay changeType={change.type} changedPropertiesCount={changedPropertiesCount} object={object} record={record} />
          <span className="text-m-gray-600">•</span>
          <Tooltip content={format(new Date(change.modified_at), 'PPpp')}>
            <Feed.ItemMeta>{formatDistanceToNow(new Date(change.modified_at), { addSuffix: true })}</Feed.ItemMeta>
          </Tooltip>
        </Feed.ItemTitle>
        <ToggleChangesButton showProperties={showProperties} setShowProperties={setShowProperties} changedPropertiesCount={changedPropertiesCount} />
        {showProperties && changedPropertiesCount > 0 && (
          <div className="flex w-full flex-col gap-y-2 py-3">
            {Object.entries(change.properties).map(([key, value]) => (
              <div className="flex w-full items-start justify-start" key={key}>
                <div className="w-1/5 truncate capitalize text-m-olive-500">
                  <PropertyName type={getPropertyType(key) ?? ResourcePropertyType.STRING} propertyName={key} />
                </div>
                <div className="flex max-w-xl grow items-center justify-start gap-x-0.5 gap-y-2 overflow-hidden align-middle leading-6 text-m-olive-600">
                  {value.action === ActionType.updated && (
                    <UpdatedValue oldValue={value.old_value} newValue={value.new_value} propertyType={getPropertyType(key)} />
                  )}
                  {value.action === ActionType.added && <AddedValue value={value.new_value} propertyType={getPropertyType(key)} />}
                  {value.action === ActionType.removed && <RemovedValue value={value.old_value} propertyType={getPropertyType(key)} />}
                </div>
              </div>
            ))}
          </div>
        )}
      </Feed.ItemContent>
    </Feed.Item>
  );
};
