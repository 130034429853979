import { ObjectsDetailData, QueryRecordsCreateData } from '@bigdelta/lib-api-client';
import { createColumnHelper, getCoreRowModel, type RowSelectionState, useReactTable } from '@tanstack/react-table';
import { FC, HTMLAttributes, ReactNode, useEffect, useMemo, useState } from 'react';
import { useObjectQuery } from '../../../shared/data/useObjectQuery';
import { useObjectRecordsInfiniteQuery } from '../data/useObjectRecordsInfiniteQuery';
import { useFlatInfiniteQueryData } from '../hooks/useFlatInfiniteQueryData';
import { FilterKey, getFilter, useFilterStore } from '../../../shared/filters/store';
import { useRecordsContext } from '../context/useRecordsContext';
import { useFetchMoreOnBottomReached } from '../hooks/useFetchMoreOnBottomReached';
import { useRecordsTableColumns } from '../hooks/useRecordsTableColumns';
import { DataTable } from '../../../shared/tables/components/DataTable.tsx';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { tracking, TrackingEvent } from '../../../tracking';
import { isNil, merge } from 'lodash';
import { twMerge } from '../../../utils/twMerge.ts';
import { Button } from '../../../shared/ui/Button/Button';
import { DataTableEmpty } from '../../../shared/tables/components/DataTableEmpty';
import { TableColumnType, TableResourceType } from '@bigdelta/lib-shared';
import { useTableLayoutData } from '../../../shared/tables/hooks/useTableLayoutData';
import { useTableResizeObserver } from '../hooks/useTableResizeObserver';
import { useContainer } from '../../../shared/tables/hooks/useContainer';
import { useDeleteObjectRecords } from '../data/useDeleteObectRecords.ts';
import { RecordDelete } from './RecordDelete.tsx';

const columnHelper = createColumnHelper<QueryRecordsCreateData['items'][number]>();
interface StandardRecordsTableProps {
  filterKey: FilterKey;
}

enum EmptyStateType {
  NoRecords = 'no-records',
  NoRecordsMatchingFilter = 'no-records-matching-filter',
  Error = 'error',
}

export const StandardRecordsTable: FC<StandardRecordsTableProps & HTMLAttributes<HTMLDivElement>> = ({ filterKey, className, ...restProps }) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({}); //manage your own row selection state

  const { objectSlug, workspaceId } = useRecordsContext();

  const { currentWorkspaceId } = useWorkspace();

  const filter = useFilterStore((state) => getFilter(state, filterKey));
  const clearFilter = useFilterStore((state) => state.clearFilter);

  const { data: object, isError: isObjectError } = useObjectQuery({ objectSlug, workspaceId });

  const { containerProps, tableProps } = useContainer();

  const emptyStates: Record<
    EmptyStateType,
    { heading: (object?: ObjectsDetailData) => string; description?: (object?: ObjectsDetailData) => string; actionSlot?: ReactNode }
  > = {
    [EmptyStateType.NoRecords]: {
      heading: (object) => `Add your first ${object?.singular_noun}`,
      description: (object) => `Use our API or manually add your first ${object?.singular_noun} to get started.`,
    },
    [EmptyStateType.NoRecordsMatchingFilter]: {
      heading: (object) => `No ${object?.plural_noun} match set filters`,
      description: () => `Looks like no records in your workspace match the filter that's been set.`,
      actionSlot: <Button intent="primary" label="Clear filters" size="sm" onClick={() => clearFilter(filterKey)} />,
    },
    [EmptyStateType.Error]: {
      heading: () => 'There was problem loading data. Please try again.',
      actionSlot: <Button intent="primary" label="Reload" size="sm" onClick={() => window.location.reload()} />,
    },
  };

  const { tableLayoutQuery, tableLayoutUpdateMutation, mutateColumnWidth } = useTableLayoutData({
    resourceType: TableResourceType.OBJECT,
    resourceId: object?.id,
  });

  const {
    data: records,
    fetchNextPage,
    isFetching: isRecordsFetching,
    isFetchingNextPage: isRecordsFetchingNextPage,
    isError: isRecordsError,
    isSuccess: isRecordsSuccess,
    queryKey,
  } = useObjectRecordsInfiniteQuery({ id: object?.id, filter: filter, workspaceId: currentWorkspaceId, tableLayoutData: tableLayoutQuery.data });
  const handleDelete = useDeleteObjectRecords({ objectSlug, workspaceId, key: queryKey, onSettled: () => setRowSelection({}) });

  const totalRecordsCount = records?.pages?.[0].totalRecordsCount;

  const flatRecords = useFlatInfiniteQueryData(records);
  const flatTrends = useMemo(() => records?.pages.reduce<QueryRecordsCreateData['trends']>((acc, page) => merge(acc, page.trends), {}), [records]);
  const fetchMoreOnBottomReached = useFetchMoreOnBottomReached({
    fetchNextPage,
    isFetching: isRecordsFetching,
    records: records,
    tableContainerRef: tableProps.tableContainerRef,
  });

  const columns = useRecordsTableColumns({
    columnHelper: columnHelper,
    workspaceObject: object,
    layout: tableLayoutQuery.data,
    onLayoutChange: tableLayoutUpdateMutation.mutate,
    trends: flatTrends,
    queryKey,
    enableEditing: true,
    enableSelection: true,
  });

  const labelColumnId = tableLayoutQuery.data?.columns.find((c) => c.type === TableColumnType.LABEL)?.id;
  const columnPinning = {
    right: [],
    left: labelColumnId ? ['select_column', labelColumnId] : [],
  };

  const table = useReactTable({
    data: flatRecords,
    defaultColumn: {
      enableResizing: true,
      enableSorting: false,
    },
    columns,
    state: {
      columnPinning,
      rowSelection,
    },
    getRowId: (row) => row.id,
    manualSorting: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
  });

  useTableResizeObserver(table.getState(), mutateColumnWidth);

  useEffect(() => {
    if (filter?.items.length && filter?.items.length > 0) {
      const relationshipFilterUsed = filter.items.flatMap((f) => f.propertyRelationships).some((r) => !isNil(r.relationshipName));

      tracking.track(TrackingEvent.RecordListFiltered, {
        'object id': object?.id,
        'object name': object?.singular_noun,
        'filter type': filter.items.map((f) => f.itemType),
        'filter level': relationshipFilterUsed ? 1 : 0,
      });
    }
  }, [filter?.items, object?.id, object?.singular_noun]);

  const getEmptyStateType = () => {
    if (isObjectError || isRecordsError) {
      return EmptyStateType.Error;
    }
    if (isRecordsSuccess && totalRecordsCount === 0) {
      return EmptyStateType.NoRecords;
    }
    if (isRecordsSuccess && !flatRecords.length && filter?.items.length) {
      return EmptyStateType.NoRecordsMatchingFilter;
    }

    return;
  };

  const emptyStateType = getEmptyStateType();
  const emptyStateData = emptyStateType && emptyStates[emptyStateType];
  const selectedIds = useMemo(
    () => Object.entries(rowSelection).reduce<string[]>((acc, [id, value]) => (value ? [...acc, id] : acc), []),
    [rowSelection]
  );

  return (
    <div
      className={twMerge('relative h-full overflow-x-auto overscroll-none', className)}
      onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
      {...containerProps}
      {...restProps}
    >
      <DataTable
        isDataFetching={(isRecordsFetching && !records) || isRecordsFetchingNextPage}
        isHeaderFetching={false}
        headerActions={selectedIds.length ? <RecordDelete selectedIds={selectedIds} handleDelete={handleDelete} /> : null}
        table={table}
        {...tableProps}
      />
      {emptyStateData && (
        <DataTableEmpty
          heading={emptyStateData.heading(object)}
          description={emptyStateData.description && emptyStateData.description(object)}
          actionSlot={emptyStateData.actionSlot}
        />
      )}
    </div>
  );
};
