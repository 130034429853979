import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { FilterItem } from '../../../shared/filters/store';
import { EventsDataQueryRequestDef } from '@bigdelta/lib-api-client';
import { getEventsTime } from './getEventsTime';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { getEventsQueryWithImplicitFilters } from './getEventsQueryWithImplicitFilters.ts';
import { DataQueryRelationshipPropertiesVO, RelationshipEntityType, TableResourceType } from '@bigdelta/lib-shared';
import { mapToDataQueryRelationshipProperties } from '../../../shared/tables/utils/mapToDataQueryRelationshipProperties.ts';
import { getQuerySort } from '../../../shared/tables/utils/getQuerySort.ts';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';
import { getQueryKeyDependentColumnProperties } from '../../../shared/tables/utils/getQueryKeyDependentColumnProperties';

const LIMIT = 50;

interface UseEventsInfiniteQueryArgs {
  workspaceId?: string;
  objectFilterItems: { items: FilterItem[]; operator: 'and' | 'or' } | null;
  eventFilterItems: { items: FilterItem[]; operator: 'and' | 'or' } | null;
}

export const useEventsInfiniteQuery = ({ workspaceId, objectFilterItems, eventFilterItems }: UseEventsInfiniteQueryArgs) => {
  const queryKeys = useQueryKeys();

  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: workspaceId! }),
    enabled: !!workspaceId,
  });

  const relationshipsQuery = useQuery({
    queryKey: queryKeys.relationships(),
    queryFn: () => bigdeltaAPIClient.v1.relationshipsList({ workspace_id: workspaceId! }),
    enabled: !!workspaceId,
  });

  const tableLayoutQuery = useQuery({
    queryKey: queryKeys.tableLayout(TableResourceType.EVENT),
    queryFn: () => bigdeltaAPIClient.v1.membersConfigTableLayoutList({ workspace_id: workspaceId!, resource_type: TableResourceType.EVENT }),
    enabled: !!workspaceId,
  });

  const queryKeyDependentColumnProperties = getQueryKeyDependentColumnProperties(tableLayoutQuery.data?.columns ?? []);

  return useInfiniteQuery({
    queryKey: queryKeys.list('event', objectFilterItems, eventFilterItems, queryKeyDependentColumnProperties),
    queryFn: async ({ pageParam = 0 }) => {
      if (!tableLayoutQuery.data || !objectsQuery.data || !relationshipsQuery.data) {
        throw Error('Table layout is not loaded');
      }

      let sort: EventsDataQueryRequestDef['sort'];

      const limit = LIMIT;
      const offset = pageParam * limit;
      const query = getEventsQueryWithImplicitFilters(objectFilterItems, eventFilterItems);
      const time = getEventsTime([...(objectFilterItems?.items ?? []), ...(eventFilterItems?.items ?? [])]);

      let relationships: EventsDataQueryRequestDef['relationships'] = undefined;
      let relationshipProperties: DataQueryRelationshipPropertiesVO[] | null = null;
      tableLayoutQuery.data?.columns.forEach((column) => {
        if (column.type === 'relationship' && column.relationship) {
          try {
            relationshipProperties = mapToDataQueryRelationshipProperties(
              relationshipProperties,
              column.relationship,
              relationshipsQuery.data.relationships,
              RelationshipEntityType.EVENT,
              undefined,
              objectsQuery.data.objects
            );
          } catch (e) {
            console.error(e);
          }
        }
      });

      if (relationshipProperties) {
        relationships = {
          relationship_properties: relationshipProperties,
        };
      }

      try {
        sort = getQuerySort(
          tableLayoutQuery.data,
          RelationshipEntityType.EVENT,
          null,
          objectsQuery.data.objects,
          relationshipsQuery.data.relationships
        );
      } catch (e) {
        console.error(e);
      }

      return bigdeltaAPIClient.v1.queryEventsCreate(
        { workspace_id: workspaceId ?? '' },
        {
          query,
          relationships,
          time,
          sort,
          limit,
          offset,
        }
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!workspaceId && !!tableLayoutQuery.data && !!relationshipsQuery.data && !!objectsQuery.data,
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = lastPage.items.length === LIMIT ? allPages.length : undefined;
      return nextPage;
    },
  });
};
