import { FocusTrap, Popover } from '@headlessui/react';
import { ReportCreateSelectedMetricAggregateTrigger } from './ReportCreateSelectedMetricContainer';
import ChevronRightIcon from '../../../assets/icons/chevron-right.svg?react';
import { Menu, MenuGroup, MenuItem } from '../../../shared/components/Menu';
import { EventsMath, useReportsStore } from '../store';
import { useReportMetric } from '../../auth/hooks/useReportMetric';
import { FloatWrapper } from './FloatWrapper.tsx';

const MATH_VALUE_LABEL_MAP = {
  [EventsMath.TOTAL_EVENTS]: 'Total events',
  [EventsMath.AGGREGATE_SUM]: 'Sum',
  [EventsMath.AGGREGATE_AVERAGE]: 'Average',
  [EventsMath.AGGREGATE_MEDIAN]: 'Median',
  [EventsMath.AGGREGATE_NPS]: 'NPS',
  [EventsMath.AGGREGATE_DISTINCT_COUNT]: 'Distinct count',
  [EventsMath.DISTINCT_RECORDS]: 'Unique records',
  aggregate: 'Aggregate',
};

export const MetricEventsMath = () => {
  const { inline } = useReportMetric();
  const eventMath = useReportsStore((state) => state.config.eventMath);
  const setEventMath = useReportsStore((state) => state.setEventMath);

  return (
    <Popover>
      {({ close }) => (
        <FloatWrapper offset={4} portal inline={inline}>
          <Popover.Button as={ReportCreateSelectedMetricAggregateTrigger}>
            <span className="overflow-hidden text-ellipsis whitespace-nowrap">{eventMath && MATH_VALUE_LABEL_MAP[eventMath]}</span>
          </Popover.Button>
          <Popover.Panel>
            <FocusTrap>
              <div className="flex w-64 flex-col gap-y-px rounded-xl border border-m-gray-300 bg-m-white p-2.5 text-md text-m-olive-900 shadow-xl">
                <Menu
                  onChange={(data) => {
                    setEventMath(data as EventsMath);
                    close();
                  }}
                >
                  <MenuGroup>
                    <MenuItem key={EventsMath.TOTAL_EVENTS} value={EventsMath.TOTAL_EVENTS}>
                      {MATH_VALUE_LABEL_MAP[EventsMath.TOTAL_EVENTS]}
                    </MenuItem>
                    <MenuItem key={EventsMath.DISTINCT_RECORDS} value={EventsMath.DISTINCT_RECORDS}>
                      {MATH_VALUE_LABEL_MAP[EventsMath.DISTINCT_RECORDS]}
                    </MenuItem>

                    <MenuItem key={MATH_VALUE_LABEL_MAP['aggregate']} value="aggregate">
                      <div className="flex w-full items-center justify-between">
                        <span>{MATH_VALUE_LABEL_MAP['aggregate']}</span>
                        <ChevronRightIcon className="h-4 w-4 text-m-olive-600" />
                      </div>
                    </MenuItem>
                  </MenuGroup>

                  <MenuGroup value="aggregate">
                    <MenuItem key={EventsMath.AGGREGATE_SUM} value={EventsMath.AGGREGATE_SUM}>
                      {MATH_VALUE_LABEL_MAP[EventsMath.AGGREGATE_SUM]}
                    </MenuItem>
                    <MenuItem key={EventsMath.AGGREGATE_AVERAGE} value={EventsMath.AGGREGATE_AVERAGE}>
                      {MATH_VALUE_LABEL_MAP[EventsMath.AGGREGATE_AVERAGE]}
                    </MenuItem>
                    <MenuItem key={EventsMath.AGGREGATE_MEDIAN} value={EventsMath.AGGREGATE_MEDIAN}>
                      {MATH_VALUE_LABEL_MAP[EventsMath.AGGREGATE_MEDIAN]}
                    </MenuItem>
                    <MenuItem key={EventsMath.AGGREGATE_DISTINCT_COUNT} value={EventsMath.AGGREGATE_DISTINCT_COUNT}>
                      {MATH_VALUE_LABEL_MAP[EventsMath.AGGREGATE_DISTINCT_COUNT]}
                    </MenuItem>
                  </MenuGroup>
                </Menu>
              </div>
            </FocusTrap>
          </Popover.Panel>
        </FloatWrapper>
      )}
    </Popover>
  );
};
