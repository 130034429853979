import { ComponentProps, FC, useMemo, useState } from 'react';

import { BarElement, CategoryScale, Chart, Filler, LinearScale, LineElement, PointElement, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { QueryReportsCreateData } from '@bigdelta/lib-api-client';
import { ChartType, ReportTypeVO, TimeGranularity } from '@bigdelta/lib-shared';
import isEqual from 'lodash/isEqual';
import { Bar, Line } from 'react-chartjs-2';

import { StackedBarTimeseriesChart } from './StackedBarTimeseriesChart.tsx';
import { LineChart } from './LineChart.tsx';
import { useChartDataMap } from '../hooks/useChartDataMap.ts';
import { useHighlightedElementPropertyValue } from '../../../shared/hooks/useHighlightedElementPropertyValue.ts';
import { PropertyNameObject } from '../../../shared/types.ts';
import { AliasPrefix } from '../store/index.ts';
import { VerticalBarChart } from './VerticalBarChart.tsx';
import { ReportViewMode } from '../types.ts';
import { ExternalTooltipHandler, useExternalChartTooltip } from '../hooks/useExternalChartTooltip.tsx';
import { TooltipContent } from './common/TooltipContent.tsx';
import { SidePanel } from '../../../shared/components/SidePanel.tsx';
import { EventDetails } from './EventDetails.tsx';
import { useReportViewContext } from '../../../shared/reports/view/context/useReportViewContext.ts';
import { useObjects } from '../hooks/useObjects.ts';
import { PointData } from '../../../shared/reports/view/context/context.ts';
import { HorizontalBarChart } from './HorizontalBarChart.tsx';
import { useQuery } from '@tanstack/react-query';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';

Chart.register(BarElement);
Chart.register(LineElement);

Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(PointElement);
Chart.register(LinearScale);
Chart.register(Tooltip);
Chart.register(TimeScale);
Chart.register(Filler);

(Tooltip.positioners as any).pointer = function (_: any, evtPos: any) {
  return evtPos;
};

interface ReportChartTimeseriesProps {
  reportType: ReportTypeVO;
  chartType: ChartType;
  granularity: TimeGranularity;
  dataQueries?: QueryReportsCreateData['queries'];
  reportViewMode?: ReportViewMode;
  lineOptions?: ComponentProps<typeof Line>['options'];
  stackedBarOptions?: ComponentProps<typeof Bar>['options'];

  breakdownSelectedValues?: { property_value: string }[];
  breakdownProperty?: PropertyNameObject;
  breakdownPropertyPrefix?: AliasPrefix;
  breakdownSelectedValuesColorMap?: Record<string, string>;

  fillColor?: string;
  borderColor?: string;
  showTooltips?: boolean;
}

export const ReportChart: FC<ReportChartTimeseriesProps> = ({
  reportType,
  chartType,
  granularity,
  reportViewMode,
  dataQueries,
  lineOptions,
  stackedBarOptions,
  breakdownProperty,
  breakdownSelectedValues,
  breakdownSelectedValuesColorMap,
  breakdownPropertyPrefix,
  fillColor,
  borderColor,
  showTooltips = false,
}) => {
  const { currentWorkspaceId } = useWorkspace();
  const highlightedElementPropertyValue = useHighlightedElementPropertyValue(breakdownSelectedValues);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const { setPointData, pointData } = useReportViewContext();
  const objects = useObjects(!!dataQueries?.length);
  const queryKeys = useQueryKeys();

  const { data } = useQuery({
    queryKey: queryKeys.metrics(),
    queryFn: () => bigdeltaAPIClient.v1.metricsList({ workspace_id: currentWorkspaceId }),
  });

  const metrics = data?.items || [];
  const { Tooltip, externalTooltipHandler } = useExternalChartTooltip({
    isStatic: chartType === ChartType.STACKED || reportType === ReportTypeVO.FUNNEL,
  });

  const handleOpenSidebar = () => {
    setSidebarOpen(true);
  };

  const dataMap = useChartDataMap({
    reportType,
    dataQueries,
    breakdownProperty,
    breakdownSelectedValues,
    breakdownSelectedValuesColorMap,
    breakdownPropertyPrefix,
  });

  const handleDataSet = (dates: PointData) => {
    if (!isEqual(pointData, dates)) {
      setPointData?.(dates);
    }
  };
  const chartDatasets = useMemo(() => {
    return Object.keys(dataMap ?? {}).map((key) => {
      const data = { ...dataMap[key] };

      if (highlightedElementPropertyValue && key !== highlightedElementPropertyValue) {
        data.color = `${data.regularColor}30`;
      } else {
        data.color = data.regularColor;
      }

      let colorKey;
      switch (chartType) {
        case ChartType.STACKED:
          colorKey = 'backgroundColor';
          break;
        case ChartType.LINE:
          colorKey = 'borderColor';
          break;
        default:
          colorKey = 'color';
      }

      return {
        ...data,
        ...{ [colorKey]: data.color, ...(fillColor ? { backgroundColor: fillColor } : {}), ...(borderColor ? { borderColor: borderColor } : {}) },
      };
    });
  }, [dataMap, highlightedElementPropertyValue, chartType, fillColor, borderColor]);

  const chartKey = useMemo(() => {
    return `${reportViewMode}-${JSON.stringify(chartDatasets)}`;
  }, [chartDatasets, reportViewMode]);

  const getChart = () => {
    if (reportType === ReportTypeVO.FUNNEL) {
      return (
        <VerticalBarChart
          tooltipHandler={externalTooltipHandler as ExternalTooltipHandler<'bar'>}
          datasets={chartDatasets}
          key={chartKey}
          metadata={dataQueries?.[0]?.metadata}
        />
      );
    }

    if (chartType === ChartType.HORIZONTAL) {
      return (
        <HorizontalBarChart
          // TODO: Add custom tooltip handler when stacked charts are fixed
          // tooltipHandler={externalTooltipHandler as ExternalTooltipHandler<'bar'>}
          dataQuery={dataQueries?.[0]}
          breakdownProperty={breakdownProperty}
          breakdownPropertyPrefix={breakdownPropertyPrefix}
          breakdownSelectedValues={breakdownSelectedValues}
          breakdownSelectedValuesColorMap={breakdownSelectedValuesColorMap}
        />
      );
    }
    if (chartType === ChartType.STACKED) {
      return (
        <StackedBarTimeseriesChart
          tooltipHandler={externalTooltipHandler as ExternalTooltipHandler<'bar'>}
          datasets={chartDatasets}
          key={chartKey}
          granularity={granularity}
          metadata={dataQueries?.[0]?.metadata}
          options={stackedBarOptions}
        />
      );
    }

    if (chartType === ChartType.LINE) {
      return (
        <LineChart
          tooltipHandler={externalTooltipHandler as ExternalTooltipHandler<'line'>}
          datasets={chartDatasets}
          key={chartKey}
          granularity={granularity}
          dataQueries={dataQueries}
          options={lineOptions}
        />
      );
    }
  };

  return (
    <>
      {getChart()}
      {showTooltips && (
        <>
          <Tooltip
            render={(tooltipContext) => (
              <TooltipContent
                reportType={reportType}
                objects={objects}
                metrics={metrics}
                context={tooltipContext}
                dataQueries={dataQueries}
                onSetData={handleDataSet}
                openSidebar={handleOpenSidebar}
              />
            )}
          />
          <SidePanel
            closeOnClickOutside
            title={`View ${pointData?.objectData?.plural_noun || 'records'}`}
            isOpen={isSidebarOpen}
            setIsOpen={setSidebarOpen}
          >
            <EventDetails />
          </SidePanel>
        </>
      )}
    </>
  );
};
