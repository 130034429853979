import {
  FC,
  HTMLAttributes,
  KeyboardEventHandler,
  PropsWithChildren,
  ReactNode,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useState,
} from 'react';

import { twMerge } from '../../../../utils/twMerge';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import XCloseIcon from '../../../../assets/icons/x-close.svg?react';
import DotsVerticalIcon from '../../../../assets/icons/dots-vertical.svg?react';

import CalculatorIcon from '../../../../assets/icons/calculator.svg?react';
import PencilIcon from '../../../../assets/icons/pencil-01.svg?react';

import { SVGComponent } from '../../../../types';

import { Tooltip } from '../../../../shared/ui/Tooltip/Tooltip';
import { GenericReportBuilder, UseStore, useHasUnsavedChanges } from '../../store/common';
import { TrendsBuilderState } from '../../store/TrendsBuilder';
import { ContextMenuAction } from '../../types';
import { IconButton } from '../../../../shared/components/IconButton';

interface ContainerProps<Builder extends GenericReportBuilder> extends PropsWithChildren {
  storeSelector: UseStore<Builder>;
  isLoading?: boolean;
}

const StoreContext = createContext<UseStore<GenericReportBuilder>>(null);
const useContextStore = () => useContext(StoreContext);

export const Container = <Builder extends GenericReportBuilder>({ children, storeSelector, isLoading = false }: ContainerProps<Builder>) => (
  <StoreContext.Provider value={storeSelector}>
    <div className={twMerge('group/builder rounded-lg border border-m-olive-100 p-4 shadow-sm', isLoading && 'pointer-events-none animate-pulse')}>
      {children}
    </div>
  </StoreContext.Provider>
);

const Name: FC<{ name: string; color?: string }> = ({ name, color }) => (
  <div
    style={{ backgroundColor: color }}
    className="flex h-5 w-5 shrink-0 items-center justify-center rounded-sm bg-m-gray-300 p-1 text-xs leading-3 text-m-white"
  >
    {name}
  </div>
);

interface LabelProps {
  id: string;
}

const Label: FC<LabelProps> = ({ id }) => {
  const useStore = useContextStore();
  const label = useStore(id, (state) => (state as TrendsBuilderState).label);

  const isEditingLabel = useStore(id, (state) => (state as TrendsBuilderState).isEditingLabel);
  const setIsEditingLabel = useStore(id, (state) => (state as TrendsBuilderState).setIsEditingLabel);

  const setLabel = useStore(id, (state) => (state as TrendsBuilderState).setLabel);

  const [value, setValue] = useState(label);

  const handleStartEditing = () => {
    setIsEditingLabel(true);
    setValue(label);
  };

  const handleSave = () => {
    setIsEditingLabel(false);
    setLabel(value);
  };

  const handleCancel = () => {
    setIsEditingLabel(false);
    setValue(label);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    switch (event.key) {
      case 'Enter':
        handleSave();
        break;
      case 'Escape':
        handleCancel();
        break;
      default:
        break;
    }
  };

  const onRefChange = useCallback((node: HTMLInputElement | null) => {
    if (node) {
      setTimeout(() => {
        node.focus();
      }, 1);
    }
  }, []);

  if (!isEditingLabel) {
    return (
      <button className="shrink grow truncate text-left text-xs font-medium text-m-olive-600" onClick={handleStartEditing}>
        {label ? label : 'Untitled'}
      </button>
    );
  }

  return (
    <>
      <Tooltip content="Create a name to save as metric" open={isEditingLabel && !value?.length}>
        <input
          className="my-px mr-px grow rounded-md px-1 py-0 text-xs"
          onBlur={handleSave}
          value={value || ''}
          onChange={(e) => setValue(e.target.value)}
          ref={onRefChange}
          onKeyDown={handleKeyDown}
          data-tooltip-id={id}
        />
      </Tooltip>
    </>
  );
};

export const Remove: FC<HTMLAttributes<HTMLDivElement>> = (props) => <IconButton icon={XCloseIcon} {...props} />;

interface MetricIndicatorProps {
  id: string;
}

const MetricIndicator: FC<MetricIndicatorProps> = ({ id }) => {
  const useStore = useContextStore();

  const metricId = useStore(id, (state) => state.metricId);
  const hasUnsavedChanges = useHasUnsavedChanges(id, useStore);

  return (
    metricId && (
      <Tooltip content="This metric has unsaved changes" disabled={!hasUnsavedChanges}>
        <div className="relative">
          <CalculatorIcon className="h-4 w-4 shrink-0 text-m-olive-400" />
          {hasUnsavedChanges && (
            <div className="absolute right-0 top-0 h-2 w-2 -translate-y-px translate-x-px rounded-full border border-m-white bg-m-orange-600" />
          )}
        </div>
      </Tooltip>
    )
  );
};

interface HeadingProps {
  id: string;
  name: string;
  color?: string;
}

export const Heading: FC<HeadingProps> = ({ id, name, color }) => (
  <div className="flex min-w-0 shrink grow items-center gap-x-1.5 overflow-hidden">
    <Name name={name} color={color} />
    <div className="flex min-w-0 shrink grow items-center gap-x-1.5">
      <Label id={id} />
      <MetricIndicator id={id} />
    </div>
  </div>
);

interface SelectedProps {
  icon: FC<React.SVGProps<SVGSVGElement>>;
  name: string;
  endSlot?: ReactNode;
  filters?: ReactNode;
}

export const Selected: FC<SelectedProps> = ({ icon: Icon, name, endSlot, filters }) => (
  <div className="flex flex-col overflow-hidden">
    <div className={twMerge('flex items-center justify-between border border-m-olive-100 px-3 py-2', filters ? 'rounded-t-lg' : 'rounded-lg')}>
      <div className="flex items-center gap-x-2">
        <Icon className="h-4 w-4 shrink-0 text-m-olive-400" />
        <div className="truncate text-sm font-medium text-m-olive-700">{name}</div>
      </div>
      {endSlot && <div className="flex items-center justify-end">{endSlot}</div>}
    </div>
    {filters && <div className="rounded-b-lg">{filters}</div>}
  </div>
);

const DropdownMenuItem = forwardRef<HTMLDivElement, DropdownMenu.DropdownMenuItemProps>((props, ref) => (
  <DropdownMenu.Item
    ref={ref}
    {...props}
    className="flex select-none items-center gap-x-2 rounded px-2 py-1.5 text-xs text-m-olive-800 outline-none radix-highlighted:bg-m-gray-200"
  ></DropdownMenu.Item>
));

interface ContextMenuItemProps extends DropdownMenu.DropdownMenuItemProps {
  icon: SVGComponent;
  label: string;
}

const ContextMenuItem = forwardRef<HTMLDivElement, ContextMenuItemProps>(({ icon: Icon, label, ...props }, ref) => (
  <DropdownMenuItem className="flex items-center gap-x-2" ref={ref} {...props}>
    <div className="p-1">
      <Icon className="h-4 w-4 text-m-olive-400" />
    </div>
    <span>{label}</span>
  </DropdownMenuItem>
));

interface ContextMenuProps {
  onSelect: (action: ContextMenuAction) => void;
  metricId?: string;
}

export const ContextMenu: FC<ContextMenuProps> = ({ onSelect, metricId }) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <IconButton className="radix-state-open:bg-m-gray-300" icon={DotsVerticalIcon} />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="rounded-md border border-m-olive-100 bg-m-white p-2 shadow-sm"
          side="right"
          align="start"
          alignOffset={-10}
          sideOffset={4}
        >
          {metricId && (
            <ContextMenuItem
              className="flex items-center gap-x-2"
              onSelect={() => onSelect(ContextMenuAction.UPDATE_METRIC)}
              label="Save metric"
              icon={CalculatorIcon}
            />
          )}
          <ContextMenuItem
            className="flex items-center gap-x-2"
            onSelect={() => onSelect(ContextMenuAction.SAVE_AS_METRIC)}
            label="Save as new metric"
            icon={CalculatorIcon}
          />
          <ContextMenuItem
            className="flex items-center gap-x-2"
            onSelect={() => onSelect(ContextMenuAction.RENAME)}
            label="Rename"
            icon={PencilIcon}
          />
          <ContextMenuItem
            className="flex items-center gap-x-2"
            onSelect={() => onSelect(ContextMenuAction.REMOVE)}
            label="Remove"
            icon={XCloseIcon}
          />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
