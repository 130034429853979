import { FC, HTMLProps } from 'react';
import { CurrencyProperty } from '../types';
import { getStringValue } from './utils';

interface CurrencyViewProps extends Omit<HTMLProps<HTMLDivElement>, 'value'> {
  value: CurrencyProperty | undefined | null;
}

export const CurrencyView: FC<CurrencyViewProps> = ({ value, ...props }) => {
  return (
    <div className="flex grow items-baseline gap-x-1 px-2 py-1.5" {...props}>
      {value && (
        <>
          <span className="uppercase">{value?.currency_code}</span>
          <span>{getStringValue(value?.amount_in_cents)}</span>
        </>
      )}
      {!value && <span className="w-full">&nbsp;</span>}
    </div>
  );
};
