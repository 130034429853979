import { TimeGranularity } from '@bigdelta/lib-shared';

export enum ReportTimerange {
  '24H' = '24H',
  '7D' = '7D',
  '30D' = '30D',
  '3M' = '3M',
  '6M' = '6M',
  '12M' = '12M',
  '24M' = '24M',
}

export const DISPLAY_FORMATS = {
  hour: 'HH:mm',
  day: 'MMM dd',
  week: 'MMM dd',
  month: 'MMM dd',
  quarter: 'MMM dd',
};

export const granularityOptions = {
  [ReportTimerange['24H']]: [TimeGranularity.HOUR],
  [ReportTimerange['7D']]: [TimeGranularity.HOUR, TimeGranularity.DAY],
  [ReportTimerange['30D']]: [TimeGranularity.DAY, TimeGranularity.WEEK],
  [ReportTimerange['3M']]: [TimeGranularity.DAY, TimeGranularity.WEEK, TimeGranularity.MONTH],
  [ReportTimerange['6M']]: [TimeGranularity.WEEK, TimeGranularity.MONTH, TimeGranularity.QUARTER],
  [ReportTimerange['12M']]: [TimeGranularity.WEEK, TimeGranularity.MONTH, TimeGranularity.QUARTER],
  [ReportTimerange['24M']]: [TimeGranularity.WEEK, TimeGranularity.MONTH, TimeGranularity.QUARTER],
};
