import { NestedObjectDef } from '@bigdelta/lib-api-client';
import { ResourcePropertyType } from '@bigdelta/lib-shared';
import { FC } from 'react';

import { Text } from '../../../shared/properties/Text/Text';
import { Tags } from '../../../shared/properties/Tags/Tags';
import { NumberText } from '../../../shared/properties/NumberText/NumberText';
import { DateTime } from '../../../shared/properties/Datetime/DateTime';
import { isStringOrEmpty, isStringArrayOrEmpty, isNumberOrEmpty, isCurrencyOrEmpty, isBooleanOrEmpty } from '../../../shared/properties/utils';
import { Currency } from '../../../shared/properties/Currency/Currency';
import { BooleanText } from '../../../shared/properties/BooleanText/BooleanText';
import { OverflowText } from '../../../shared/components/OverflowText';

interface PropertyValueProps {
  value: NestedObjectDef[string] | undefined;
  overflowVisible?: boolean;
  propertyType?: ResourcePropertyType;
  onChange?: (value: NestedObjectDef[string] | undefined) => void;
  disabled?: boolean;
}

export const PropertyValue: FC<PropertyValueProps> = ({ value, overflowVisible = false, propertyType, onChange, disabled = false }) => {
  if (propertyType === ResourcePropertyType.STRING && isStringOrEmpty(value)) {
    return <Text value={value} overflowVisible={overflowVisible} onChange={onChange} disabled={disabled} />;
  }

  if (propertyType === ResourcePropertyType.STRING_ARRAY && isStringArrayOrEmpty(value)) {
    return <Tags value={value} onChange={onChange} disabled={disabled} />;
  }

  if (propertyType === ResourcePropertyType.NUMBER && isNumberOrEmpty(value)) {
    return <NumberText value={value} onChange={onChange} disabled={disabled} />;
  }

  if (propertyType === ResourcePropertyType.DATETIME64 && isStringOrEmpty(value)) {
    return <DateTime value={value} onChange={onChange} disabled={disabled} />;
  }

  if (propertyType === ResourcePropertyType.CURRENCY && isCurrencyOrEmpty(value)) {
    return <Currency value={value} onChange={onChange} disabled={disabled} />;
  }

  if (propertyType === ResourcePropertyType.BOOLEAN && isBooleanOrEmpty(value)) {
    return <BooleanText value={value} onChange={onChange} disabled={disabled} />;
  }

  if (!propertyType) {
    return value ? <OverflowText visible={overflowVisible}>{JSON.stringify(value)}</OverflowText> : (value as null);
  }

  console.warn(`PropertyValue: Unsupported property type: ${propertyType}`);

  return null;
};
