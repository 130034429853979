import { Select, SelectItem, SelectItemProps } from '../../../shared/ui/Select/Select';
import { useReportStore } from '../store/Report';

type SelectItem = Omit<SelectItemProps, 'children'> & {
  label: string;
};

interface VisualizationSelectProps {
  items: SelectItem[];
}

export const VisualizationSelect = ({ items }: VisualizationSelectProps) => {
  const visualization = useReportStore((state) => state.display.visualization);
  const setVisualization = useReportStore((state) => state.setVisualization);

  return (
    <div className="flex flex-col gap-y-2 py-4">
      <div className="text-md font-medium">Visualization</div>
      <Select value={visualization ?? undefined} onValueChange={setVisualization} hasArrow>
        {items.map(({ label, ...itemProps }) => (
          <SelectItem key={label} {...itemProps}>
            {label}
          </SelectItem>
        ))}
      </Select>
    </div>
  );
};
