import { FC, FormEvent, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toastError, toastSuccess } from '../../../utils/toast.tsx';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface CreateTrackingKeyDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateTrackingKeyDialog: FC<CreateTrackingKeyDialogProps> = ({ isOpen, onClose }) => {
  const queryKeys = useQueryKeys();
  const queryClient = useQueryClient();
  const { currentWorkspaceId } = useWorkspace();

  const handleTrackingKeyDialogClose = () => {
    setTrackingKeyName('');
    onClose();
  };

  const createTrackingKeyMutation = useMutation({
    mutationFn: (name: string) => {
      return bigdeltaAPIClient.v1.authTrackingKeyCreate({
        workspace_id: currentWorkspaceId,
        name: name,
      });
    },
    onSuccess: () => {
      handleTrackingKeyDialogClose();
      toastSuccess('Created', 'Tracking Key created successfully');
      return queryClient.invalidateQueries(queryKeys.trackingKeys());
    },
    onError: () => {
      toastError('An error occurred while creating Tracking Key');
    },
  });

  const [TrackingKeyName, setTrackingKeyName] = useState('');

  const handleSubmitTrackingKey = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (TrackingKeyName) {
      createTrackingKeyMutation.mutate(TrackingKeyName);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleTrackingKeyDialogClose} className="relative z-50">
      <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="mx-auto flex w-1/3 max-w-xl flex-col gap-y-6 rounded-xl bg-white p-6">
          <Dialog.Title className="text-xl text-m-olive-700">Create Tracking Key</Dialog.Title>
          <form onSubmit={handleSubmitTrackingKey} className="flex flex-col gap-y-6">
            <div className="flex flex-col gap-y-1.5">
              <label htmlFor="tracking_key_name" className="text-md text-m-olive-700">
                Name
              </label>
              <input
                name="tracking_key_name"
                className="rounded-lg border border-m-gray-300 px-3 py-2"
                value={TrackingKeyName}
                placeholder="My Tracking Key name"
                onChange={(e) => setTrackingKeyName(e.target.value)}
              />
            </div>
            <div className="flex justify-end gap-x-3.5">
              <Button type="button" label="Cancel" intent="secondary" size="lg" onClick={handleTrackingKeyDialogClose} />
              <Button label="Create Tracking Key" intent="brand" size="lg" type="submit" disabled={!TrackingKeyName} />
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
