import React from 'react';
import IconFromUrl from './IconFromUrl';
import { ObjectsDetailData } from '@bigdelta/lib-api-client';
import { getWorkspaceObjectIcon } from '../utils/getWorkspaceObjectIcon';
import { WithProperties } from '../utils/getRecordLabel';
import { PropsWithClassName } from '../../types';
import { twMerge } from 'tailwind-merge';

interface RecordIconProps extends PropsWithClassName {
  record: WithProperties;
  object: ObjectsDetailData;
}

export const RecordIcon: React.FC<RecordIconProps> = ({ object, record, className }) => {
  const iconUrl = object.icon_property ? record.properties?.[object.icon_property] : undefined;
  const FallbackIcon = getWorkspaceObjectIcon(object.object_type);

  return (
    <>
      {iconUrl ? (
        <IconFromUrl iconUrl={iconUrl} className={twMerge('h-4 w-4 shrink-0', className)} />
      ) : (
        <FallbackIcon className={twMerge('h-4 w-4 shrink-0', className)} />
      )}
    </>
  );
};
