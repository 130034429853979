import { ObjectsListData } from '@bigdelta/lib-api-client';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { ObjectsRoutes, RecordsRoutes } from '../../../routes';
import { capitalize } from 'lodash';
import { WorkspaceObjectStatus, WorkspaceObjectType } from '@bigdelta/lib-shared';
import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { toastError, toastSuccess } from '../../../utils/toast.tsx';
import { SkeletonBlock } from '../../../components/SkeletonBlock.tsx';
import EyeIcon from '../../../assets/icons/eye.svg?react';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { suppressConsoleWarn } from '../../../shared/utils/suppressConsoleWarn.ts';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { ToolsTable } from '../../../shared/components/ToolsTable.tsx';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

const columnHelper = createColumnHelper<ObjectsListData['objects'][number]>();

interface ObjectsTableProps {
  data?: ObjectsListData['objects'];
}

export const ObjectsTable: FC<ObjectsTableProps> = ({ data }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();
  const { currentWorkspaceId } = useWorkspace();

  const activateObjectMutation = useMutation({
    mutationFn: (objectId: string) =>
      bigdeltaAPIClient.v1.objectsStatusPartialUpdate(objectId, { workspace_id: currentWorkspaceId }, { status: WorkspaceObjectStatus.ACTIVE }),
    onSuccess: () => {
      toastSuccess('Object activated', 'The object has been activated successfully');
      return queryClient.invalidateQueries(queryKeys.list('object'));
    },
    onError: () => {
      toastError('Failed to activate object');
    },
  });

  const objectsRecordsCountQuery = useQuery({
    queryKey: queryKeys.objectRecordsCount(),
    queryFn: () => bigdeltaAPIClient.v1.objectsRecordsCountList({ workspace_id: currentWorkspaceId }),
  });

  const objectRecordsCountByObjectId = useMemo(() => {
    if (objectsRecordsCountQuery.data) {
      return new Map(Object.entries(objectsRecordsCountQuery.data));
    } else {
      return new Map();
    }
  }, [objectsRecordsCountQuery.data]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('plural_noun', {
        id: 'plural_noun',
        header: 'Object',
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          return capitalize(value);
        },
      }),
      columnHelper.accessor('object_type', {
        id: 'object_type',
        header: 'Type',
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          return value === WorkspaceObjectType.CUSTOM ? 'Custom' : 'Standard';
        },
      }),
      columnHelper.display({
        id: 'records',
        header: 'Records',
        cell: (context) => {
          const count = objectRecordsCountByObjectId?.get(context.row.id);
          return (
            <div>
              {objectsRecordsCountQuery.isLoading && <SkeletonBlock className="h-2 w-10" />}
              {!objectsRecordsCountQuery.isLoading && (count ? count : 0)}
            </div>
          );
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: 'Actions',
        cell: (context) => {
          return (
            <>
              {context.row.original.status === 'INACTIVE' && (
                <div className="flex items-center gap-x-2">
                  <Button
                    intent="brand"
                    size="xs"
                    label="Activate"
                    fullWidth
                    onClick={(event) => {
                      event.stopPropagation();
                      activateObjectMutation.mutate(context.row.id);
                    }}
                    className="max-w-16"
                  />
                </div>
              )}
              {context.row.original.status === 'ACTIVE' && (
                <div className="flex items-center gap-x-2">
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      navigate(`${RecordsRoutes.INDEX}/${context.row.original.api_slug}`);
                    }}
                  >
                    <EyeIcon className="h-5 w-5 cursor-pointer text-m-olive-300 hover:text-m-olive-400" />
                  </button>
                </div>
              )}
            </>
          );
        },
      }),
    ],
    [activateObjectMutation, navigate, objectRecordsCountByObjectId, objectsRecordsCountQuery.isLoading]
  );

  const table = useReactTable({
    columns,
    data: data ?? [],
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
  });

  return (
    <ToolsTable
      data={data}
      onRowClick={(row) => navigate(`${ObjectsRoutes.SETTINGS_WORKSPACE_LIST}/${row.id}`)}
      table={table}
      isRowDisabled={(data) => data.status === 'INACTIVE'}
    />
  );
};
