import { FC, useState } from 'react';
import type { CurrencyProperty, EditablePropertyType } from '../types';
import { CurrencyView } from './CurrencyView';
import { CurrencyEdit } from './CurrencyEdit';
import { twMerge } from '../../../utils/twMerge';

type CurrencyProps = EditablePropertyType<CurrencyProperty>;

export const Currency: FC<CurrencyProps> = ({ value, onChange, disabled }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (value: CurrencyProperty | undefined | null) => {
    onChange?.(value);
    setIsEditing(false);
  };

  return (
    <div
      className={twMerge(
        'flex w-full shrink cursor-text items-baseline gap-x-1 overflow-hidden rounded-md text-sm focus-within:ring-2 focus-within:ring-m-blue-400 hover:cursor-default',
        !disabled && 'hover:bg-m-gray-300'
      )}
    >
      {isEditing ? (
        <CurrencyEdit value={value} onChange={handleChange} />
      ) : (
        <CurrencyView
          value={value}
          onClick={() => {
            if (!disabled) {
              setIsEditing(true);
            }
          }}
        />
      )}
    </div>
  );
};
